import React, { FC, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import { useDispatch, useSelector } from 'react-redux';
import SelectState from '../../common/CommonOfferComponent/SelectState/SelectState';
import TextInput from '../../common/CommonOfferComponent/TextInput/TextInput';
import { reserVationValidation, isReservationFormValid } from '../../../util/offerValidation';
import { States_US } from '../../../util/States';
import { RootState } from '../../../redux/rootReducer';
import ContactInfoModalForm from '../contactInfoIneligibleState/ContactInfoIneligibleStateModal';
import {
  createNewOfferLeadRequest,
  updateNewOfferLeadRequest,
  reSetNewOfferLead,
  reSetContactInfo,
} from '../../../features/newOffer/NewOfferSlice';
import { requestPayload, requestUpdateLeadPayload } from '../../../util/leadRequestMapping';
import useTimer from '../../../features/newOfferPayment/UseTimer';
import './ReservePage.scss';
import ContinuePayment from './ContinuePayment';

interface ReservationPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  isShowState: boolean;
}
const ReservePage: FC<ReservationPanelProps> = (props: ReservationPanelProps) => {
  const { localize } = props;
  const dispatch = useDispatch();
  const { startTimer, stopTimer, remainingTime } = useTimer();
  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const getCreatedLeadDetail = useSelector((state: RootState) => state.newOfferDetails.newOfferLeadDetail);
  const isCreatedLeadErr = useSelector((state: RootState) => state.newOfferDetails.hasLeadError);
  const isPayBtnDisabled: any = useSelector((state: RootState) => state.newOfferDetails.isPaynowDisabled);
  const resErrInfo: any = useSelector((state: RootState) => state.newOfferDetails.resContactErr);
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const getOfferType: any = sessionStorage.getItem('offer-type');
  const isPaid = getOfferType === 'paid' ? true : false;
  const PhoneNo = (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) || '';
  const urlParams = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const webUrl = urlParams?.url || '';

  const [isOpt, setIsOpt] = useState(contactInfo?.isOpt || false);
  const [isPaynowDisabled, setIsPaynowDisabled] = useState(isPayBtnDisabled || true);
  const [isOpenOfferModal, setIsOpenOfferModal] = useState(false);
  const isStateErr = isPaid ? false : true;
  const [resFormData, setResFormData] = useState(
    contactInfo || {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      state: '',
    },
  );
  const [resError, setResError] = useState(
    resErrInfo || {
      firstError: true,
      lastError: true,
      phoneError: true,
      emailError: true,
      stateError: isStateErr,
    },
  );
  const [resErrorMsg, setResErrorMsg] = useState({
    firstErrorMsg: '',
    lastErrorMsg: '',
    phoneErrorMsg: '',
    emailErrorMsg: '',
    stateErrorMsg: '',
  });

  useEffect(() => {
    if (isCreatedLeadErr) {
      stopTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedLeadErr]);
  useEffect(() => {
    const storedStartTime = sessionStorage.getItem('timerStartTime');
    if (remainingTime <= 0 && remainingTime !== null && storedStartTime) {
      dispatch(reSetNewOfferLead());
      stopTimer();
      dispatch(reSetContactInfo());
      window?.location?.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const handleKeyDown = () => {};
  useEffect(() => {
    let tempMsg = {
      firstErrorMsg: '',
      lastErrorMsg: '',
      phoneErrorMsg: '',
      emailErrorMsg: '',
      stateErrorMsg: '',
    };
    const getResErr: any = isPaid ? { ...resError, stateError: false } : resError;
    const isDisable = isReservationFormValid(getResErr, isPaid);
    if (isDisable) {
      setResErrorMsg(tempMsg);
    }
    setIsPaynowDisabled(!isDisable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resError]);

  const handleFirstNameChange = (value: string) => {
    setResFormData({ ...resFormData, firstName: value });
    handleBlurFn('fName', 'firstError', 'firstErrorMsg', value);
  };
  const handleLastNameChange = (value: string) => {
    setResFormData({ ...resFormData, lastName: value });
    handleBlurFn('lName', 'lastError', 'lastErrorMsg', value);
  };
  const handlePhoneNumberChange = (value: string) => {
    setResFormData({ ...resFormData, phoneNumber: value });
    handleBlurFn('phone', 'phoneError', 'phoneErrorMsg', value);
  };
  const handleEmailAddressChange = (value: string) => {
    setResFormData({ ...resFormData, emailAddress: value });
    handleBlurFn('email', 'emailError', 'emailErrorMsg', value);
  };
  const handleSelectStateChange = (value: string) => {
    setResFormData({ ...resFormData, state: value });
    if (value !== '') {
      setResError({ ...resError, stateError: false });
      setResErrorMsg({ ...resErrorMsg, stateErrorMsg: '' });
    } else {
      setResError({ ...resError, stateError: true });
    }
  };
  const handleSelectStateBlur = () => {
    const stData = resFormData?.state || '';
    const fnError = reserVationValidation('state', stData);
    setResError({ ...resError, stateError: fnError?.isError });
    setResErrorMsg({ ...resErrorMsg, stateErrorMsg: fnError?.msg });
  };
  const handleFirstNameBlur = () => {
    const fnError = reserVationValidation('fName', resFormData.firstName);
    setResError({ ...resError, firstError: fnError?.isError });
    setResErrorMsg({ ...resErrorMsg, firstErrorMsg: fnError?.msg });
  };
  const handleLastNameBlur = () => {
    const lnError = reserVationValidation('lName', resFormData.lastName);
    setResError({ ...resError, lastError: lnError?.isError });
    setResErrorMsg({ ...resErrorMsg, lastErrorMsg: lnError?.msg });
  };
  const handlePhoneBlur = () => {
    const phoneError = reserVationValidation('phone', resFormData.phoneNumber);
    setResError({ ...resError, phoneError: phoneError?.isError });
    setResErrorMsg({ ...resErrorMsg, phoneErrorMsg: phoneError?.msg });
  };
  const handleEmailBlur = () => {
    const emailError = reserVationValidation('email', resFormData.emailAddress);
    setResError({ ...resError, emailError: emailError?.isError });
    setResErrorMsg({ ...resErrorMsg, emailErrorMsg: emailError?.msg });
  };
  const handleCheckboxClick = () => {
    setIsOpt((prevState) => !prevState);
  };
  const handleBlurFn = (name: string, errName: string, msg: string, value: any) => {
    const getErr = reserVationValidation(name, value);

    setResError({ ...resError, [errName]: getErr?.isError });
    setResErrorMsg({ ...resErrorMsg, [msg]: getErr?.msg });
  };
  const onCloseIneligibleStateModalForm = () => {
    const saveData = { ...resFormData, isOpt };
    if (getCreatedLeadDetail) {
      const updateLeadRequestPayload = requestUpdateLeadPayload(saveData, getCreatedLeadDetail, false, newOfferData);
      dispatch(updateNewOfferLeadRequest(updateLeadRequestPayload));
    } else {
      const leadrequestPayload = requestPayload(saveData, newOfferData, false, webUrl);
      dispatch(createNewOfferLeadRequest(leadrequestPayload));
      startTimer();
    }
    setIsOpenOfferModal(false);
    setResFormData({ ...resFormData, state: '' });
    setResError({ ...resError, stateError: true });
  };
  const setIsOpenInEligible = (val: any) => {
    setIsOpenOfferModal(val);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpt(event.target.checked);
  };
  return (
    <>
      {isOpenOfferModal && (
        <ContactInfoModalForm
          onCloseIneligibleStateModalForm={onCloseIneligibleStateModalForm}
          stateProvince={resFormData?.state}
          phone={PhoneNo}
        />
      )}
      <div className="reservatioPageContainer">
        <div className="formCardTitleContainer">
          <span className="reservationCardTitle">
            {localize('preview-sales-engine.static-content.copy.pbeo.contact-information-header')}
          </span>
          <span className="reservationCardDescription">
            {localize('preview-sales-engine.static-content.copy.pbeo.step-1-all-fields-required-label')}
          </span>
        </div>
        <div className="nameRowContainer">
          <TextInput
            label={localize('preview-sales-engine.static-content.copy.contact-information-page.first-name')}
            value={resFormData.firstName}
            type="text"
            onChange={handleFirstNameChange}
            handleInputBlur={handleFirstNameBlur}
            isError={resError.firstError}
            errMsg={localize(resErrorMsg.firstErrorMsg)}
            maxLength={30}
          />
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.contact-information-page.last-name`)}
            value={resFormData.lastName}
            type="text"
            onChange={handleLastNameChange}
            handleInputBlur={handleLastNameBlur}
            isError={resError.lastError}
            errMsg={localize(`${resErrorMsg.lastErrorMsg}`)}
            maxLength={30}
          />
        </div>
        <div className="nameRowContainer">
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.pbeo.phone-number-label`)}
            value={resFormData.phoneNumber}
            type="tel"
            onChange={handlePhoneNumberChange}
            handleInputBlur={handlePhoneBlur}
            isError={resError.phoneError}
            errMsg={localize(resErrorMsg.phoneErrorMsg)}
            maxLength={10}
          />
        </div>
        <div className="nameRowContainer">
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.pbeo.email`)}
            value={resFormData.emailAddress}
            type="email"
            onChange={handleEmailAddressChange}
            handleInputBlur={handleEmailBlur}
            isError={resError.emailError}
            errMsg={localize(resErrorMsg.emailErrorMsg)}
            maxLength={35}
          />
        </div>
        {isStateErr && (
          <div className="nameRowContainer">
            <SelectState
              label={localize('preview-sales-engine.static-content.copy.contact-information-page.state')}
              value={resFormData.state}
              onChange={handleSelectStateChange}
              handleSelectBlur={handleSelectStateBlur}
              isError={resError.stateError}
              errMsg={localize(resErrorMsg.stateErrorMsg)}
              StateData={States_US}
            />
          </div>
        )}
        <div
          className="checkRowContainer"
          onKeyDown={handleKeyDown}
          arial-label={'check'}
          role="link"
          tabIndex={0}
          onClick={handleCheckboxClick}
        >
          <input
            type="checkbox"
            id="checkBox_reservation"
            checked={isOpt}
            onChange={handleCheckboxChange}
            name="checkbox"
            className="reservationCheckBox"
            aria-label="resCheckBox"
          />
          <span
            className="reservationCheckDescription"
            dangerouslySetInnerHTML={{
              __html: localize('preview-sales-engine.static-content.copy.pbeo.contact-consent-checkbox-description'),
            }}
          ></span>
        </div>
        <ContinuePayment
          isPaynowDisabled={isPaynowDisabled}
          resFormData={resFormData}
          resError={resError}
          isOpt={isOpt}
          setIsOpenInEligible={setIsOpenInEligible}
        />
      </div>
    </>
  );
};
export default withRouter(withLocalization(ReservePage));
