import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { loadOfferDetails } from '../../features/offerSearch/offerSearchSlice';
import { LAST_NAME } from '../../util/strings';
import { isValidPin, LAST_NAME_TEXT_MAX_LENGTH, PIN_MAX_LENGTH, validateFields } from '../../util/validation';
// import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  pinId?: string;
  history: any;
}
const Search: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { pinId, localize } = props;
  const dispatch = useDispatch();
  const [pinValue, setPinValue] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameHasError, setLastNameHasError] = useState(false);
  const [pinHasError, setPinHasError] = useState(false);
  const [buttonStatusEnabled, setButtonStatusEnabled] = useState(false);

  useEffect(() => {
    pinId && pinId.toLowerCase() !== 'error' ? setPinValue(pinId) : setPinValue('');
    pinId ? (isValidPin(pinId) ? setPinHasError(false) : setPinHasError(true)) : setPinHasError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (e) => {
    sessionStorage.setItem('Persist:StateSearch:Active', 'false');
    sessionStorage.setItem('Persist:IsPin', 'true');
    e.preventDefault();
    if (pinValue?.length > 0 && lastName.length > 0) {
      window.scrollTo(0, 0);
      dispatch(loadOfferDetails(pinValue.toUpperCase(), lastName));
    }
  };

  const onPinChange = (evt) => {
    const pinValue = evt.target.value;
    setPinValue(pinValue);
    if (!isValidPin(pinValue)) {
      setPinHasError(true);
      setButtonStatusEnabled(false);
    } else {
      setPinHasError(false);
      if (lastName) {
        setButtonStatusEnabled(true);
      } else {
        setButtonStatusEnabled(false);
      }
    }
  };

  const onLastNameChange = (evt) => {
    const lastName = evt.target.value;
    setLastName(lastName);
    if (validateFields(LAST_NAME, lastName)) {
      setLastNameHasError(true);
      setButtonStatusEnabled(false);
    } else {
      setLastNameHasError(false);
      if (pinValue && !pinHasError) {
        setButtonStatusEnabled(true);
      } else {
        setButtonStatusEnabled(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="search-container">
        <div className="col-md-12">
          <h1 className="card-title ">
            {localize('preview-sales-engine.static-content.copy.prospects.letsfindoffer')}
          </h1>
          <hr />
          <p
            className="card-text"
            dangerouslySetInnerHTML={{
              __html: localize('preview-sales-engine.static-content.copy.prospects.brandgreetlastname'),
            }}
          ></p>
          <div className="inputWrapper">
            <form onSubmit={onFormSubmit}>
              <label htmlFor="pin">
                {localize('preview-sales-engine.static-content.copy.associate-search-page.pin-label')}
                <input
                  className="pin-input"
                  type="text"
                  id="pin"
                  name="PIN"
                  value={pinValue}
                  maxLength={PIN_MAX_LENGTH}
                  onChange={onPinChange}
                />
                {!pinValue ? (
                  // eslint-disable-next-line react/no-unescaped-entities
                  <span className="error"></span>
                ) : pinHasError ? (
                  <span className="error">
                    {localize(
                      'preview-sales-engine.static-content.copy.associate-search-page.pin-field-errors#pattern',
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </label>
              <label htmlFor="lastName" className="btnTop card-text">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.last-name')}
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  onChange={onLastNameChange}
                  maxLength={LAST_NAME_TEXT_MAX_LENGTH}
                />
                {!lastName ? (
                  // eslint-disable-next-line react/no-unescaped-entities
                  <span className="error"></span>
                ) : lastNameHasError ? (
                  <span className="error">
                    {localize('preview-sales-engine.static-content.copy.prospects.lastnamevalidation')}
                  </span>
                ) : (
                  <></>
                )}
              </label>
              <div className="">
                {buttonStatusEnabled ? (
                  <input
                    type="submit"
                    className="btn"
                    value={localize('preview-sales-engine.static-content.copy.prospects.findmyoffer')}
                  ></input>
                ) : (
                  <input
                    type="submit"
                    className="btn"
                    disabled
                    value={localize('preview-sales-engine.static-content.copy.prospects.findmyoffer')}
                  ></input>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withLocalization(Search));
