/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import withLocalization from '../../hoc/withLocalization';
import TextInput from '../../common/CommonOfferComponent/TextInput/TextInput';
import SelectState from '../../common/CommonOfferComponent/SelectState/SelectState';
import { States_US } from '../../../util/States';
import { billingDataValidation, checkBtnDisable, isBillingDataValid } from './BillingDetailsValidation';
import { setBillingDetailsInfo, setBillingErrInfo } from '../../../features/newOfferPayment/NewOfferPaymentSlice';
import { createNewOfferLeadRequest, updateNewOfferLeadRequest } from '../../../features/newOffer/NewOfferSlice';
import { billingUpdateLeadPayload, billingRequestPayload } from '../../../util/leadRequestMapping';
import { logAnalyticData } from '../../../analytics';
import { RootState } from '../../../redux/rootReducer';
import ContactInfoModalForm from '../contactInfoIneligibleState/ContactInfoIneligibleStateModal';

import './BillingDetails.scss';

interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  packageData: any;
}
const BillingDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize } = props;
  const dispatch = useDispatch();
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const billingData: any = useSelector((state: RootState) => state.newOfferPaymentDetails.billingDetailInfo);
  const billingError = useSelector((state: RootState) => state.newOfferPaymentDetails.BillingDetailErr);
  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const geturlDetail = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const getCreatedLeadDetail = useSelector((state: RootState) => state.newOfferDetails.newOfferLeadDetail);
  const isPaid = geturlDetail?.isPaid || false;
  const webUrl = geturlDetail?.url || '';
  const isFillAddress = (billingError?.cityError || billingError?.addressError) && isPaid;
  const PhoneNo = (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) || '';
  const isValueInArray = (value: string, excData: any): boolean => {
    return excData.some((obj: any) => obj?.stateProvinceDescription === value);
  };
  const [isIneligibleModal, setIsIneligibleModal] = useState(false);
  const [billingErrorMsg, setBillingErrorMsg] = useState({
    addressErrMsg: '',
    cityErrMsg: '',
    zipCodeErrMsg: '',
    stateErrMsg: '',
  });
  const analyliticFunc = () => {
    const offerVal = (newOfferData?.offers && newOfferData?.offers[0]) || '';
    const roomType = (offerVal?.roomTypes && offerVal?.roomTypes[0]) || {};
    const stateCode = roomType?.property?.stateCode;
    const flowIdentifier = isPaid ? 'paid' : 'organic';
    const destination = geturlDetail?.destination;
    const brand = geturlDetail?.brand;
    const loc = offerVal?.loc || '';
    const offerCode = offerVal?.offerCode;
    logAnalyticData(
      {
        event: 'PbeAddPaymentInformation',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC | PBE Step2 Add Payment Information',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        formLOC: loc, //
        destination: destination,
        resortCity: roomType?.property?.city,
        resortState: roomType?.property?.stateCode,
        resortCountry: 'USA',
        resortName: roomType?.property?.name,
        resortBrand: brand,
        offerCode: 'Vacation Offer',
        offerType: 'Open-Dated',
        flowIdentifier: flowIdentifier,
        retailPrice: offerVal?.retailValue?.replace(/[$,]/g, '') || '',
        packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
        depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        currency: 'USD',
        quanity: '1',
        resortMarsha: roomType?.property?.marshaCode,
        userSelectedState: isPaid ? billingData?.state : contactInfo?.state,
        bookingStepName: 'MVC | PBE Step2 Add Payment Information',
      },
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    analyliticFunc();
  }, []);
  useEffect(() => {
    let tempMsg = {
      addressErrMsg: '',
      cityErrMsg: '',
      zipCodeErrMsg: '',
      stateErrMsg: '',
    };
    const errObj = checkBtnDisable(billingData);
    //setBillingsError(errObj);
    dispatch(setBillingErrInfo(errObj));
    const isDisable = isBillingDataValid(errObj);
    if (isDisable) {
      setBillingErrorMsg(tempMsg);
    }
    // setIsPaynowDisabled(!isDisable);
  }, [billingData]);
  const handleAddressChange = (value: string) => {
    handleBillingData('address', value);
    handleBillingBlur('address', 'addressError', 'addressErrMsg', value);
  };
  const handleCityChange = (value: string) => {
    handleBillingData('city', value);
    handleBillingBlur('city', 'cityError', 'cityErrMsg', value);
  };
  const handleZipCodeChange = (value: string) => {
    handleBillingData('zipCode', value);
    handleBillingBlur('zipCode', 'zipCodeError', 'zipCodeErrMsg', value);
  };
  const modalAnalyliticFunc = (selState: any) => {
    const offerDetails = (newOfferData?.offers && newOfferData?.offers[0]) || {};
    const roomType = (offerDetails?.roomTypes && offerDetails?.roomTypes[0]) || '';
    const pushToDataLayer = () => {
      window.dataLayer.push({
        event: 'error_message',
        type: 'non elegible state',
        residency: selState,
        resort: roomType?.property?.name,
      });
    };
    pushToDataLayer();
    logAnalyticData(
      {
        event: 'PbeContinueToPayment',
        siteSection: '',
        pageName: '',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: 'non elegible state', //
        underSelectedState: selState, //'[Destination]',
        resortName: roomType?.property?.name,
      },
    );
  };
  const handleStateChange = (value: string) => {
    handleBillingData('state', value);
    const stateExclusionsData: any = newOfferData?.offers[0]?.stateExclusions || [];
    const isExclusionState = isValueInArray(value, stateExclusionsData);
    if (isPaid) {
      if (isExclusionState) {
        modalAnalyliticFunc(value);
        setIsIneligibleModal(isExclusionState);
      } else {
        if (getCreatedLeadDetail) {
          const updateLeadRequestPayload = billingUpdateLeadPayload(
            contactInfo,
            getCreatedLeadDetail,
            value,
            billingData?.zipCode,
            billingData?.address,
            newOfferData,
            true,
          );
          dispatch(updateNewOfferLeadRequest(updateLeadRequestPayload));
        } else {
          const leadrequestPayload = billingRequestPayload(
            contactInfo,
            newOfferData,
            true,
            billingData?.state,
            billingData?.zipCode,
            webUrl,
          );
          dispatch(createNewOfferLeadRequest(leadrequestPayload));
        }
      }
    }
  };
  const handleAddressBlur = () => {
    handleBillingBlur('address', 'addressError', 'addressErrMsg');
  };
  const handleCityBlur = () => {
    handleBillingBlur('city', 'cityError', 'cityErrMsg');
  };
  const handleStateBlur = () => {
    handleBillingBlur('state', 'stateError', 'stateErrMsg');
  };
  const handleZipCodeBlur = () => {
    handleBillingBlur('zipCode', 'zipCodeError', 'zipCodeErrMsg');
  };
  const handleBillingData = (field: string, value: string) => {
    const billingObj = { ...billingData, [field]: value };
    dispatch(setBillingDetailsInfo(billingObj));
  };
  const handleBillingBlur = (name: string, field: string, msg: string, val: any = '') => {
    const chVal = val ? val : billingData[name];
    const fnError = billingDataValidation(name, chVal);
    const billingErrObj = { ...billingError, [field]: fnError?.isError };
    dispatch(setBillingErrInfo(billingErrObj));
    setBillingErrorMsg({ ...billingErrorMsg, [msg]: fnError?.msg });
  };

  const modal_desc = `You've selected a billing address in ${billingData?.state} that is restricted from this offer. We can help. Our vacation specialists can personalize an offer for you. `;

  const onCloseIneligibleStateModalForm = () => {
    if (billingData?.state !== '') {
      if (getCreatedLeadDetail) {
        const updateLeadRequestPayload = billingUpdateLeadPayload(
          contactInfo,
          getCreatedLeadDetail,
          billingData?.state,
          billingData?.zipCode,
          billingData?.address,
          newOfferData,
          false,
        );
        dispatch(updateNewOfferLeadRequest(updateLeadRequestPayload));
      } else {
        const leadrequestPayload = billingRequestPayload(
          contactInfo,
          newOfferData,
          false,
          billingData?.state,
          billingData?.zipCode,
          webUrl,
        );
        dispatch(createNewOfferLeadRequest(leadrequestPayload));
      }
      setIsIneligibleModal(false);
      handleBillingData('state', '');
    }
  };
  return (
    <>
      {isIneligibleModal && (
        <ContactInfoModalForm
          onCloseIneligibleStateModalForm={onCloseIneligibleStateModalForm}
          stateProvince={billingData?.state}
          phone={PhoneNo}
          desc={modal_desc}
        />
      )}
      <div className="billingDetails-container">
        <div className="billingDetails-title">
          <span className="billingDetails-title-span">
            {localize(`preview-sales-engine.static-content.copy.pbeo.billing-address-label`)}
          </span>
        </div>
        <div className="billing-RowContainer">
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.contact-information-page.street-address`)}
            value={billingData.address}
            type="text"
            onChange={handleAddressChange}
            handleInputBlur={handleAddressBlur}
            isError={billingError?.addressError}
            errMsg={localize(billingErrorMsg.addressErrMsg)}
            isErrBorder={true}
            maxLength={30}
          />
        </div>
        <div className="billing-RowContainer">
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.contact-information-page.city`)}
            value={billingData.city}
            type="text"
            onChange={handleCityChange}
            handleInputBlur={handleCityBlur}
            isError={billingError?.cityError}
            errMsg={localize(billingErrorMsg.cityErrMsg)}
            isErrBorder={true}
            maxLength={30}
          />
          <SelectState
            label={localize('preview-sales-engine.static-content.copy.contact-information-page.state')}
            value={billingData.state}
            onChange={handleStateChange}
            handleSelectBlur={handleStateBlur}
            isError={billingError?.stateError}
            errMsg={localize(billingErrorMsg.stateErrMsg)}
            StateData={States_US}
            isErrBorder={true}
            isFillAddress={isFillAddress}
          />
          <TextInput
            label={localize(`preview-sales-engine.static-content.copy.contact-information-page.zip-code`)}
            value={billingData.zipCode}
            type="tel"
            onChange={handleZipCodeChange}
            handleInputBlur={handleZipCodeBlur}
            isError={billingError?.zipCodeError}
            errMsg={localize(billingErrorMsg.zipCodeErrMsg)}
            isErrBorder={true}
            maxLength={5}
          />
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(BillingDetails));
