import React, { ReactElement, useEffect, useState } from 'react';

interface ThemeSelectorType {
  children: ReactElement;
  theme?: string;
}
const ThemeSelector = (props: ThemeSelectorType): ReactElement => {
  const { children, theme } = props;
  const [DynamicComponent, setDynamicComponent] = useState<any>(null);

  useEffect(() => {
    const Component = React.lazy(() => import(`./Brands/${theme}/index.tsx`));
    return setDynamicComponent(Component);
  }, [theme]);

  //const CHOSEN_THEME = theme;
  return (
    <>
      <React.Suspense fallback={<></>}>{DynamicComponent && <DynamicComponent></DynamicComponent>}</React.Suspense>
      {children}
    </>
  );
};
export default ThemeSelector;
