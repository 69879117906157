import React, { FC, ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import {
  setAvailabilityRequest,
  setLoadingStatus,
  setOfferMonthRangeDesktop,
  setOfferMonthRangeMobile,
  setOpenDateSelector,
} from '../../features/offerAvailability/offerAvailabilitySlice';
import { AvailabilityDateRequest, AvailableDates } from '../../features/offerAvailability/types';
import { setSelectedRoom } from '../../features/offerSearch/offerSearchSlice';
import { Offer } from '../../features/offerSearch/types';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand, getFormattedCurrencyValueByBrand } from '../../util';
import { createMonthList, getMonthEndDate, getMonthsBetweenDates } from '../../util/date';
import { OFFER_DATES_PATH, OFFER_ELIGIBILITY_PATH } from '../../util/strings';
import DopInformation from '../common/DopInformation/DopInformation';
import ParseHtml from '../common/ParseHtml';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import { NavigationSteps } from './OfferNavigation';
import OpenDatedNotice from './OpenDatedNotice';

interface RoomTypeProp {
  offer: Offer | undefined;
  history?: any;
  localize?: any;
  pin?: string;
  loc?: string;
  isDatedOffer: boolean;
  brand?: string;
  phoneNumber: string;
}

const RoomTypeInformation: FC<RoomTypeProp> = (props: RoomTypeProp): ReactElement => {
  const { localize, offer, pin, loc, isDatedOffer, phoneNumber } = props;
  const property = offer?.property;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const premiums =
    offer && offer.premiums
      ? offer?.premiums
          .filter((x) => x !== null)
          .join(', ')
          .trim()
      : '';
  const availabilityDates: AvailableDates = useSelector((state: RootState) => state.availabilityDates);
  const selectedHearingADACode: string | undefined = availabilityDates.hearingADACode;
  const selectedADARoomCode: string | undefined = availabilityDates.roomADACode;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;

  const monthNames = [
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jan'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#feb'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#mar'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#apr'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#may'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jun'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jul'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#aug'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#sept'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#oct'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#nov'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#dec'),
  ];
  const onViewOfferClicked = (evt) => {
    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step3-SelectAccommodations',
        siteSection: 'Preview Booking Engine',
        pageName: 'home page',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        pageName: 'PBE – Select Accommodations',
        resortID: '[Resort ID]',
        resortName: property?.name,
        resortCity: property?.city,
        resortState: property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: property?.marshaCode,
        resortBrand: brandName,
        formLOC: offerDetails.searchParameter,
        accommodationsType: availabilityDates.availabilityRequest?.accomodationCode,
        acessibleSlected: availabilityDates.roomADACode,
        flowIdentifier: flowIdentifier,
        bookingStepName: 'Step 3 - Select Accommodations',
      },
    );
    trackAnalyticData('Details of Participation');

    const selectedIndex = parseInt(evt.target.id, 10);
    if (offer && offer.roomTypes) {
      const selectedRoom = offer.roomTypes[selectedIndex];
      const offerStartDate = new Date(offer.bookFromDate);
      const offerEndDate = new Date(offer.bookToDate);
      const monthBetweenDates = getMonthsBetweenDates(offerStartDate, offerEndDate);
      const monthListForDesktop = createMonthList(offerStartDate, offerEndDate, monthBetweenDates, monthNames, false);
      const monthListForMobile = createMonthList(offerStartDate, offerEndDate, monthBetweenDates, monthNames, true);
      const startDate = new Date(offer.bookFromDate);
      const calculatedDate = isMobile ? startDate : new Date(startDate.setMonth(startDate.getMonth() + 1));
      const endString = getMonthEndDate(calculatedDate.getMonth(), calculatedDate.getFullYear());
      const calStartDate =
        offer.bookFromDate && offer.bookFromDate.length > 0 && offer.bookFromDate.indexOf('T') > 0
          ? offer.bookFromDate.split('T')[0]
          : offer.bookFromDate;
      const calEndDate =
        offer.bookToDate && offer.bookToDate.length > 0 && offer.bookToDate.indexOf('T') > 0
          ? endString
          : offer.bookToDate;
      let preferences: string[] | undefined = [];
      if (selectedHearingADACode !== undefined) {
        preferences.push(selectedHearingADACode);
      }
      if (selectedADARoomCode !== undefined) {
        preferences.push(selectedADARoomCode);
      }
      if (preferences.length === 0) {
        preferences = undefined;
      }
      const availabilityRequest: AvailabilityDateRequest = {
        accomodationCode: selectedRoom.accomodationCode,
        roomTypeCode: selectedRoom.roomTypeCode,
        beginDate: calStartDate,
        endDate: calEndDate,
        offerCode: offer.offerCode,
        pin: pin,
        loc: loc,
        preferences: preferences,
      };
      dispatch(setSelectedRoom(selectedRoom));
      dispatch(setAvailabilityRequest(availabilityRequest));
      if (monthListForDesktop) dispatch(setOfferMonthRangeDesktop(monthListForDesktop));
      if (monthListForMobile) dispatch(setOfferMonthRangeMobile(monthListForMobile));
      if (!isDatedOffer) {
        dispatch(setOpenDateSelector(true));
        dispatch(setLoadingStatus(true));
      }
      props.history?.push(isDatedOffer ? OFFER_DATES_PATH : OFFER_ELIGIBILITY_PATH);
    }
  };
  const roomTypes = offer?.roomTypes.map((room, index) => {
    return (
      <>
        <div key={index} className="offerDisplay">
          {room.maxAdditionalNights > 0 ? (
            <div className="additionalNightsRibbon">
              {localize('preview-sales-engine.static-content.copy.prospects.additionalavailablenights')}
            </div>
          ) : (
            <></>
          )}
          <div className="row ">
            <div className="col-lg-9 col-sm-7">
              <div>
                <span className="roomDescription">{room.roomTypeDescription.toLocaleLowerCase()}</span>{' '}
                {localize('preview-sales-engine.static-content.copy.prospects.from-label').toLocaleLowerCase()}{' '}
                <span className="cost">{getFormattedCurrencyValueByBrand(room.startingCost, props.brand)}</span>{' '}
                {localize('preview-sales-engine.static-content.copy.prospects.perstay')}
              </div>
              <div>
                {localize('preview-sales-engine.static-content.copy.select-offer-page.days-nights', [
                  room.numberOfDays,
                  room.numberOfNights,
                ])}
                <div className="vr"></div>
                {localize('preview-sales-engine.static-content.copy.select-offer-page.max-guests')} {room.sleepCapacity}
              </div>
              {premiums && (
                <p>
                  <ParseHtml
                    htmlInput={`${localize('preview-sales-engine.static-content.copy.prospects.plustext')} ${premiums}`}
                  ></ParseHtml>
                </p>
              )}
            </div>
            <div className="col-lg-3 col-sm-5 rightAlign">
              <div>
                <Button variant="primary roomTypeSelectButton" id={index.toString()} onClick={onViewOfferClicked}>
                  {localize('preview-sales-engine.static-content.copy.prospects.selectaccomodations')}
                </Button>
                <DopInformation dopCodeDetails={offer.dopCodeDetails} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <OpenDatedNotice
                noticeLocation={NavigationSteps.OFEFR_DETAILS}
                showNotice={!isDatedOffer}
                brandPhoneNumber={phoneNumber}
                depositAmount={room.depositAmount}
              ></OpenDatedNotice>
            </div>
          </div>
        </div>
      </>
    );
  });
  return (
    <React.Fragment>
      <div className="roomTypePad">{roomTypes}</div>
    </React.Fragment>
  );
};
export default withRouter(withLocalization(withBrands(RoomTypeInformation)));
