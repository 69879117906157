/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import apiClient from '../../apiClient';
import { DetailOfferType, newOfferDetailsType, createLeadRequestType, updateLeadRequestType } from './types';
export const getOfferDetailsDataByLOC = async (loc: string, defaultLoc: string): Promise<any> => {
  //const requestUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/offerDetailsByLOC/${loc}?of=true&defaultloc=${defaultLoc}`;
  const requestUrl = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_OFFER_DETAILS_BY_LOC_API_PATH}${loc}?of=true&opLoc=${defaultLoc}`;
  const returnValue: any = {
    hasError: false,
    loading: false,
  };
  try {
    const response = await apiClient.get<DetailOfferType>(requestUrl);
    returnValue.newOfferData = response;
    if (response?.offers === undefined) {
      returnValue.hasError = true;
      returnValue.loading = true;
    }
    return returnValue;
  } catch (e: any) {
    console.log('error is:', e);
    returnValue.hasError = true;
    return returnValue;
  }
};

export const createNewOfferLead = async (payload: any): Promise<any> => {
  let returnValue: any = {
    hasLeadError: false,
    loading: false,
    hasOffers: false,
  };
  const leadApiUrl: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CREATE_LEAD_REQUEST}`;
  //const leadApiUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/createLeadRequestInfo`;
  try {
    const leadCreateResponse = await apiClient.post<createLeadRequestType, any>(leadApiUrl, payload);
    returnValue.leadDetail = leadCreateResponse;
  } catch (e: any) {
    returnValue.hasLeadError = true;
  }
  return returnValue;
};
export const updateNewOfferLead = async (payload: any): Promise<any> => {
  let returnValue: any = {
    hasUpdateLeadError: false,
    loading: false,
  };
  const leadUpdateApiUrl: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_UPDATE_LEAD_REQUEST}`;
  // const leadUpdateApiUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/updateLead`;
  try {
    const leadUpdateResponse = await apiClient.put(leadUpdateApiUrl, payload);
    returnValue.updateLeadDetail = leadUpdateResponse;
  } catch (e: any) {
    returnValue.hasUpdateLeadError = true;
  }
  return returnValue;
};
