/* eslint-disable */

import apiClient from '../../apiClient';
import { createError, PAYMENT_DONE_BOOKING_FAILED, UNABLE_TO_CONTACT_PROVIDER } from '../../util';
import BookingConfirmmationRequest, {
  BookingConfirmationResponse,
  PaymentContextResponse,
  // eslint-disable-next-line prettier/prettier
  PaymentSubmitResponse,
  PaymentAndBookingRequest,
} from './types';
import { payerAuthReqType } from '../newOfferPayment/types';

// const PAYMENT_CONTEXT_ENDPOINT = 'https://st01.gateway.marriottvacationclub.com/proxy/pse/keyId';

const CONFIRM_API_ENDPOINT: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CONFIRM_API_PATH}`;
const PAYMENT_CONTEXT_ENDPOINT = `${process.env.REACT_APP_PAYMENT_CONTEXT_ENDPOINT}`;
const PAYMENT_SUBMISSION_ENDPOINT = process.env.REACT_APP_MAKE_PAYMENT_ENDPOINT || '';
export const commitReservation = async (request: BookingConfirmmationRequest): Promise<BookingConfirmationResponse> => {
  const returnValue: BookingConfirmationResponse = {
    hasError: false,
  };
  try {
    const response: BookingConfirmationResponse = await apiClient.post<
      BookingConfirmmationRequest,
      BookingConfirmationResponse
    >(CONFIRM_API_ENDPOINT, request);
    returnValue.ConfirmationNumber = response.ConfirmationNumber;
  } catch (e: any) {
    returnValue.hasError = true;

    if (e.response && e.response.data && e.response.data.errorCode) {
      const {
        response: {
          data: { errorCode },
        },
      } = e;
      returnValue.error = createError(errorCode);
    } else {
      returnValue.error = createError(PAYMENT_DONE_BOOKING_FAILED);
    }
  }

  return returnValue;
};
export const getPaymentContext = async (merchanrGroupId: string, origin: string): Promise<PaymentContextResponse> => {
  const requestUrl = `${PAYMENT_CONTEXT_ENDPOINT}?targetOrigin=${origin}&merchantGroupId=${merchanrGroupId}`;
  const returnValue: PaymentContextResponse = {
    hasError: false,
  };
  try {
    const response = await apiClient.get<PaymentContextResponse>(requestUrl);
    //TODO: remove this, this is only for testing
    // const response = paymentContext;
    returnValue.status = response.status;
    returnValue.contextTokens = response.contextTokens;
    //TODO: uncomment this after development
    if (response.status !== 'success') {
      response.hasError = true;
    }
  } catch (e) {
    returnValue.hasError = true;
    returnValue.error = createError(UNABLE_TO_CONTACT_PROVIDER);
  }

  return returnValue;
};

export const submitPaymentAndBooking = async (req: PaymentAndBookingRequest): Promise<BookingConfirmationResponse> => {
  const returnValue: any = {
    hasError: false,
    ConfirmationNumber: undefined,
    error: undefined,
    confirmationdetails: undefined,
  };
  try {
    const response: any = await apiClient.post<PaymentAndBookingRequest, PaymentSubmitResponse>(
      PAYMENT_SUBMISSION_ENDPOINT,
      req,
    );
    const responseStatus = response?.status?.toLowerCase() || 'unknown';
    if (responseStatus === 'pending_authentication') {
      returnValue.confirmationdetails = response;
    } else {
      returnValue.ConfirmationNumber = response.ConfirmationNumber;
    }
    //returnValue.ConfirmationNumber = response.ConfirmationNumber;
  } catch (e: any) {
    returnValue.hasError = true;
    if (e.response && e.response.data && e.response.data.errorCode) {
      const {
        response: {
          data: { errorCode },
        },
      } = e;
      returnValue.error = createError(errorCode);
    } else {
      returnValue.error = createError(PAYMENT_DONE_BOOKING_FAILED);
    }
  }

  return returnValue;
};
export const setUpProspectPayerAuth = async (payload: payerAuthReqType): Promise<any> => {
  let returnValue: any = {
    authError: false,
    payAuthResponse: '',
    error: '',
  };

  //const requestUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/payer/setup-authentication`;
  const requestUrl: string = `${process.env.REACT_APP_API_PROSPECT_SETUP_PAYER_AUTH_KEY}`;
  try {
    const Response = await apiClient.post<payerAuthReqType, any>(requestUrl, payload);
    returnValue.payAuthResponse = Response;
  } catch (e: any) {
    console.log(e);
    returnValue.authError = true;
    returnValue.error = createError('CREDIT_CARD_DECLINED_GENERIC');
  }
  return returnValue;
};
