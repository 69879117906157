/* eslint-disable */

import React, { FC, ReactElement, Suspense, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import LogContext from '../../context/logContext';
import { RootState } from '../../redux/rootReducer';
import { OFFER_DESTINATION_PATH } from '../../util/strings';
import { Offer } from '../offerSearch/types';
import StateSearchPanel from './StateSearchPanel';
import { logAnalyticData } from '../../analytics';

let logAnalyticCalled = false;
const OfferStateSelect: FC<any> = (props: any): ReactElement => {
    const { localizeImages } = props;

    const LazyCTAError = React.lazy(() => import('./ErrorContainer'));

    // const state = useSelector((state: RootState) => state.selectState);
    const state = useSelector((state: RootState) => state.offerDetails);
    const hasError: boolean | undefined = state.hasError;
    const showCTA : boolean | undefined = state.showCTA;
    const offers: Offer[] = state.primaryOffer || [];

    const [locError, setLocError] = useState(false);

    useEffect(() => {
      if (!logAnalyticCalled) {
        logAnalyticData(
            {
              event: 'landingPage',
              siteSection: 'Preview Booking Engine',
              pageName: 'PBE - Landing Page',
              pageHierarchy: '',
              server: 'MVC',
              website: 'MVC react',
              language: 'en',
            },
            {
              flowIdentifier: 'No Pin with Calendar',
            },
          );
        logAnalyticCalled = true;
      }
    }, []);

    useEffect(() => {
        const queryParam = props.location.search;
        //TODO: come back and rewrite the logic
        const str:string[] = queryParam.split("=");
        if(validateLoc(str[1])) {
            setLocError(true);
        }
    }, []);

    useEffect(() => {
        if(offers.length > 0) {
            props.history.push(OFFER_DESTINATION_PATH);
        }
    }, [offers]);

    const { path } = localizeImages('preview-sales-engine.static-content.images.search-page-hero', 0, '4:3');
    const styleBg = {
        backgroundImage: `url("${path}")`
    };

    const validateLoc = (loc: string): boolean => {
        const re = /^[aA-zZ\d]{4}\*[aA-zZ\d]{1}\-[aA-zZ\d]{6}$/;
        return loc ? (loc.trim() === "" ||  !re.test(loc)) : true;
    }

    const showSearchPanel = !hasError && !showCTA && !locError;

    const renderCTAMessage =
    locError || hasError || showCTA  ? (
      <Suspense fallback={<></>}>
        <LazyCTAError hasLocError={locError}/>
      </Suspense>
    ) : (
      <></>
    );

    const consumerContext = useContext(LogContext);

    consumerContext.logMethod();

    return (
        <div className="StickyContainer">
            <div className="PageWrapper"> {/** //TODO: look into why we don't need this CSS property: PageWrapper-noNav ??? */}
                <section className="Login-wrapper" style={styleBg}>
                    <div className="Card">
                        <div>
                            {renderCTAMessage}
                            {showSearchPanel && <StateSearchPanel />}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default withRouter(withLocalization(OfferStateSelect));


