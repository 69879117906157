import React, { FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import {
  setLoadingStatus,
  setPaymentButtonState,
  setPaymentToken,
} from '../../features/offerConfirmation/offerConfirmationSlice';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand } from '../../util';
import { OFFER_REVIEW_PATH } from '../../util/strings';
import withLocalization from '../hoc/withLocalization';

const ContactInformationSelector: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const paymentFormValid = useSelector((state: RootState) => state.confirmation.paymentFormValid);
  const flexFormSubmitHandler = useSelector((state: RootState) => state.confirmation.flexFormSubmitHandler);
  const paymentCheckBox = useSelector((state: RootState) => state.confirmation.paymentCheckBox);

  const allContactInfoChecked = offerDetails.contactInfoChecked;
  const allBillingInfoChecked = offerDetails.billingInfoChecked;

  const btnEnable = !allContactInfoChecked && !allBillingInfoChecked && paymentFormValid && paymentCheckBox;
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;
  const handleClick = (event) => {
    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step6-YourInformation',
        siteSection: 'Preview Booking Engine',
        pageName: 'PBE-Review',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        resortID: '[Resort ID]',
        rresortName: offerDetails.property?.name,
        resortCity: offerDetails.property?.city,
        resortState: offerDetails.property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: offerDetails.property?.marshaCode,
        resortBrand: brandName,
        formLOC: offerDetails.searchParameter,
        brand: '[brand]',
        flowIdentifier: flowIdentifier,
        bookingStepName: 'Step 6 - Your Information',
      },
    );
    trackAnalyticData('Your information');

    if (flexFormSubmitHandler) {
      dispatch(setLoadingStatus(true));
      flexFormSubmitHandler((err, token) => {
        dispatch(setLoadingStatus(false));
        if (!err) {
          dispatch(setPaymentToken(token));
          props.history.push(OFFER_REVIEW_PATH);
        } else {
          setErrMsg('Error generating token');
          console.log(err);
          dispatch(setPaymentButtonState(true));
        }
      });
    }
  };

  return (
    <>
      <div className="">
        <div className="invalid-feedback-onchange">{errMsg}</div>
        {allContactInfoChecked || allBillingInfoChecked || !paymentFormValid ? (
          <div className="invalid-feedback-onchange-btn">
            {localize('preview-sales-engine.static-content.copy.Labels.labels#mandatory-field-statement')}
          </div>
        ) : (
          <></>
        )}
        <button onClick={handleClick} className="btn reviewMyStayButton" disabled={!btnEnable}>
          {localize('preview-sales-engine.static-content.copy.prospects.reviewmystay')}
        </button>
        <div className="invalid-feedback-onchange-btn">
          {localize('preview-sales-engine.static-content.copy.contact-information-page.mandatory-field-indicator')}
        </div>
      </div>
    </>
  );
};

export default withRouter(withLocalization(ContactInformationSelector));
