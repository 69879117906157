import apiClient from '../../apiClient';
import { createError, HOLD_FAILED } from '../../util';
import {
  AvailabilityDate,
  AvailabilityDateRequest,
  AvailableDates,
  HoldPropertyRequest,
  HoldPropertyResponse,
  ReleaseHoldPropertyRequest,
  // eslint-disable-next-line prettier/prettier
  ReleaseHoldPropertyResponse,
} from './types';

// const DATE_API_ENDPOINT: string = 'https://at05.gateway.marriottvacationclub.com/proxy/pse/calendarAvailability';
// const HOLD_API_ENDPOINT: string = 'https://at05.gateway.marriottvacationclub.com/proxy/pse/hold';
const DATE_API_ENDPOINT: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_DATE_API_PATH}`;
const HOLD_API_ENDPOINT: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_HOLD_API_PATH}`;
const RELEASE_HOLD_API_ENDPOINT: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_RELEASE_HOLD_API_PATH}`;
interface DateRequestType {
  avail_parameters: AvailabilityDateRequest;
}
export const fetchAvailabilityDates = async (datesRequest: AvailabilityDateRequest) => {
  let returnValue: AvailableDates = {
    isLoading: false,
    hasError: false,
    openDateSelector: false,
    navigateToEligibility: false,
    hasNoAvailability: false,
    isHoldFailed: false,
  };
  try {
    const request: DateRequestType = { avail_parameters: datesRequest };
    const dates: AvailabilityDate[] = await apiClient.post<DateRequestType, AvailabilityDate[]>(
      DATE_API_ENDPOINT,
      request,
    );

    if (dates && dates.length > 0) {
      returnValue.dates = dates;
      const premimDates: string[] | undefined = dates
        .filter((date: AvailabilityDate) => date.highSeason.toLowerCase() === 'y')
        .map((e: AvailabilityDate) => e.checkInDate);
      returnValue.premiumDates = premimDates;
    } else {
      returnValue.hasNoAvailability = true;
    }
  } catch (e: any) {
    returnValue.hasError = true;
    if (e.response && e.response.data && e.response.data.errorCode) {
      const {
        response: {
          data: { errorCode },
        },
      } = e;
      returnValue.error = createError(errorCode);
    } else {
      returnValue.error = createError('');
    }
  }
  return returnValue;
};
export const executeHoldReservation = async (request: HoldPropertyRequest): Promise<HoldPropertyResponse> => {
  let returnValue: HoldPropertyResponse = {
    hasError: false,
  };
  try {
    const response: HoldPropertyResponse = await apiClient.post<HoldPropertyRequest, HoldPropertyResponse>(
      HOLD_API_ENDPOINT,
      request,
    );
    returnValue.HoldSessionId = response.HoldSessionId;
  } catch (e: any) {
    returnValue.hasError = true;
    returnValue.error = createError(HOLD_FAILED);
  }

  return returnValue;
};

export const releaseHoldReservation = async (
  request: ReleaseHoldPropertyRequest,
): Promise<ReleaseHoldPropertyResponse> => {
  let returnValue: ReleaseHoldPropertyResponse = {
    ReleaseStatus: '',
  };
  try {
    await apiClient.post<ReleaseHoldPropertyRequest, ReleaseHoldPropertyResponse>(RELEASE_HOLD_API_ENDPOINT, request);

    //console.log(response);
  } catch (error) {
    console.log(error);
  }
  return returnValue;
};
