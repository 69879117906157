/* eslint-disable */
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { trackAnalyticData } from '../../../analytics';
import { localizeType } from '../../../commonType';
import withLocalization from '../../hoc/withLocalization';
import ParseHtml from '../ParseHtml/ParseHtml';
import './DopInformation.scss';

interface DoPDetailsType {
  dopCodeDetails?: string;
  localize?: localizeType;
}
const DopInformation: FC<DoPDetailsType> = (props: DoPDetailsType): ReactElement => {
  const [show, setShow] = useState(false);

  const { localize, dopCodeDetails } = props;

  const handleShow = () => {
    trackAnalyticData('Details of participation');
    setShow(true);
  } 
  const handleClose = () => setShow(false);
  let buttonInput: HTMLButtonElement;
  useEffect(() => {
    if (buttonInput) buttonInput.focus();
  });

  return (
    <>
      <button className="textDeco marginTop button-dop" onClick={handleShow}>
        {localize ? localize('preview-sales-engine.static-content.copy.select-destination-page.dop-link') : ''}
      </button>
      <Modal
        dialogClassName="modal-custom-dop"
        id="custom-dop"
        show={show}
        backdrop="static"
        keyboard="false"
        scrollable={true}
        animation={false}
        size="lg"
        centered
        aria-labelledby="custom-dop"
      >
        <Modal.Body role="main">
          <ParseHtml htmlInput={dopCodeDetails} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            ref={(button) => {
              buttonInput = button;
            }}
            variant="secondary"
            className="button-dop"
            onClick={handleClose}
          >
            {localize ? localize('preview-sales-engine.static-content.copy.prospects.close-label') : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withLocalization(DopInformation);
