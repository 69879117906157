import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { getFormattedCurrencyValueByBrand } from '../../../util';
import { NA, NACaps } from '../../../util/strings';
// import TravelDateInfo from '../../common/TravelDateInfo/TravelDateInfo';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';

const PackagePrice: FC<any> = (props: any): ReactElement => {
  const { localize, brand } = props;
  const availabilityDates = useSelector((state: RootState) => state.availabilityDates);
  const openDateSelector = availabilityDates.openDateSelector;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const selectedRoom = offerDetails.selectedRoomType;
  // const offer = offerDetails.currentOffer;
  const selectedDate = availabilityDates.finalSelectedDate;

  let extraNights = availabilityDates.finalSelectedExtraNights;
  extraNights = extraNights ? extraNights : 0;
  const lowSeasonCost: string = selectedRoom?.startingCost
    ? getFormattedCurrencyValueByBrand(selectedRoom?.startingCost, brand)
    : '';
  const highSeasonCost: string = selectedRoom?.highSeasonCost
    ? getFormattedCurrencyValueByBrand(selectedRoom?.highSeasonCost, brand)
    : '';
  const isLowSeasonPriceNotValid = lowSeasonCost.toLowerCase() === '' || lowSeasonCost.toLowerCase() === NA;
  const isHighSeasonPriceNotValid = highSeasonCost.toLowerCase() === '' || highSeasonCost.toLowerCase() === NA;
  const surcharge =
    localize('preview-sales-engine.static-content.copy.prospects.surcharge-label-noncalendar') || '** Surcharge';
  const surchargeCondition =
    localize('preview-sales-engine.static-content.copy.prospects.surcharge-condition-noncalendar') ||
    '** Denotes Surcharge For Holiday Or Weekend';

  const depositLabel = (
    <div>
      <hr />
      <p>
        <span className="bolderText">
          {localize('preview-sales-engine.static-content.copy.prospects.amountduetoday')}
        </span>
        <span className="finalPrice">
          {selectedRoom && selectedRoom.depositAmount
            ? getFormattedCurrencyValueByBrand(selectedRoom.depositAmount?.toString(), brand)
            : 0}
        </span>
      </p>
    </div>
  );

  const renderForSamePrice = <span className="floatRight">{selectedRoom && lowSeasonCost ? lowSeasonCost : 0}</span>;

  const onlyOnePricePresent = (
    <span className="floatRight">{selectedRoom && !isLowSeasonPriceNotValid ? lowSeasonCost : highSeasonCost}</span>
  );

  const bothPricePresent = (
    <div className="">
      <p>
        <span className="bolderText">{localize('preview-sales-engine.static-content.copy.prospects.from-label')} </span>
        <span className="floatRight">
          {selectedRoom && lowSeasonCost && !isLowSeasonPriceNotValid ? lowSeasonCost : NACaps}
        </span>
      </p>
      {/* <p>
        <span className="bolderText">
          {localize('preview-sales-engine.static-content.copy.package-summary-component.high-season-price')}{' '}
        </span>
        <span className="floatRight">
          {selectedRoom && highSeasonCost && !isHighSeasonPriceNotValid ? highSeasonCost : NACaps}
        </span>
      </p> */}

      {depositLabel}
    </div>
  );

  const notOpenDated = (
    <div className="priceWrapper">
      <p>
        <span className="bolderText">
          {localize('preview-sales-engine.static-content.copy.package-summary-component.base-price-label')}{' '}
        </span>
        <span className="floatRight">
          {getFormattedCurrencyValueByBrand(
            selectedDate && selectedDate.basePrice ? selectedDate.basePrice.toString() : '0',
            brand,
          )}
        </span>
      </p>
      {selectedDate && selectedDate.holidaySurcharge ? (
        <p>
          <span className="bolderText">{surcharge} </span>
          <span className="floatRight">
            {getFormattedCurrencyValueByBrand(selectedDate.holidaySurcharge.toString(), brand)}
          </span>
        </p>
      ) : (
        <></>
      )}
      {extraNights > 0 ? (
        <p>
          <span className="bolderText">
            {localize('preview-sales-engine.static-content.copy.Labels.labels#additional-night-charge')}
          </span>
          <span className="floatRight">
            {getFormattedCurrencyValueByBrand(
              selectedDate && selectedDate.additionalNightCharge ? selectedDate.additionalNightCharge.toString() : '0',
              brand,
            )}
          </span>
        </p>
      ) : (
        <></>
      )}
      <hr />
      <p>
        <span className="bolderText">
          {localize('preview-sales-engine.static-content.copy.package-summary-component.total-package-price-label')}
        </span>
        <span className="floatRight finalPrice">
          {getFormattedCurrencyValueByBrand(
            selectedDate && selectedDate.totalCost ? selectedDate.totalCost.toString() : '0',
            brand,
          )}
        </span>
      </p>
      {selectedDate && selectedDate.holidaySurcharge ? <p className="footerText">{surchargeCondition}</p> : <></>}
    </div>
  );

  const onePriceCondition = isLowSeasonPriceNotValid || isHighSeasonPriceNotValid;

  return (
    <>
      {openDateSelector ? (
        lowSeasonCost === highSeasonCost ? (
          <div>
            <p>
              <span className="bolderText ">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.base-price-label')}
              </span>
              {renderForSamePrice}
            </p>
            {depositLabel}
          </div>
        ) : onePriceCondition ? (
          <div>
            <p>
              <span className="bolderText ">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.base-price-label')}:
              </span>
              {onlyOnePricePresent}
            </p>
            {depositLabel}
          </div>
        ) : (
          <div>{bothPricePresent}</div>
        )
      ) : (
        <div>{notOpenDated}</div>
      )}
    </>
  );
};
export default withLocalization(withBrands(PackagePrice));
