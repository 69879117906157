import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { localizeType } from '../../commonType';
import { logAnalyticData } from '../../analytics';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand, getFormattedPhoneNumberForBrands } from '../../util';
import ParseHtml from '../common/ParseHtml';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import '../OfferContactInformation/ContactInformationHeader.scss';
interface ConfirmationHeaderType {
  confirmationNumber: string;
  localize: localizeType;
  brand?: string;
}
const ConfirmationHeader: FC<ConfirmationHeaderType> = (props: ConfirmationHeaderType): ReactElement => {
  const { localize, confirmationNumber, brand } = props;
  let callCenterPhoneNumber = useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber);
  const selectedDate = useSelector((state: RootState) => state.availabilityDates?.selectedDate);
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const confirmation = useSelector((state: RootState) => state.confirmation);
  const availabilityDates = useSelector((state: RootState) => state.availabilityDates);
  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;
  const selectedRoom = offerDetails.selectedRoomType;

  useEffect(() => {
    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step8-Confirmation',
        siteSection: 'Preview Booking Engine',
        pageName: 'PBE-Confirmation',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        resortID: '[Resort ID]',
        resortName: offerDetails.property?.name,
        resortCity: offerDetails.property?.city,
        resortState: offerDetails.property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: offerDetails.property?.marshaCode,
        resortBrand: brandName,
        formLOC: offerDetails.searchParameter,
        accommodationsType: availabilityDates.availabilityRequest?.accomodationCode,
        acessibleSlected: availabilityDates.roomADACode,
        checkInDate: selectedDate?.checkInDate.substr(0, 10),
        checkOutDate: selectedDate?.checkOutDate.substr(0, 10),
        totalAmount: selectedRoom && selectedRoom.depositAmount ? selectedRoom.depositAmount.toString() : '0',
        ReservationNumber: confirmation.ConfirmationNumber,
        flowIdentifier: flowIdentifier,
        bookingStepName: 'Step8-Confirmation',
      },
    );
  }, []);

  callCenterPhoneNumber = callCenterPhoneNumber
    ? callCenterPhoneNumber
    : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const formattedPhoneNumber = getFormattedPhoneNumberForBrands(callCenterPhoneNumber, brand);
  const confirmationMessage = confirmationNumber
    ? `${localize(
        'preview-sales-engine.static-content.copy.After Payment Page.package-confirmation',
      )} <strong>${confirmationNumber}</strong>`
    : '';
  const emailMEssage = confirmationNumber
    ? localize('preview-sales-engine.static-content.copy.prospects.receipt-confrimation-label', [
        callCenterPhoneNumber,
        formattedPhoneNumber,
      ])
    : '';
  const emailMEssagePrint = confirmationNumber
    ? localize('preview-sales-engine.static-content.copy.prospects.receipt-confirmation-print-label', [
        callCenterPhoneNumber,
        formattedPhoneNumber,
      ])
    : '';
  return (
    <>
      {confirmationNumber && (
        <div className="dateHeaderWrapper">
          <h3 className="headBaskerville">
            {localize('preview-sales-engine.static-content.copy.After Payment Page.thank-you')}
          </h3>
          <p>
            <ParseHtml htmlInput={confirmationMessage}></ParseHtml>
          </p>
          <p className="hideonPrint">
            <ParseHtml htmlInput={emailMEssage}></ParseHtml>
          </p>
          <p className="showOnPrint">
            <ParseHtml htmlInput={emailMEssagePrint}></ParseHtml>
          </p>
        </div>
      )}
    </>
  );
};
export default withLocalization(withBrands(ConfirmationHeader));
