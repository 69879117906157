import React, { FC, ReactElement } from 'react';
import withBrands from '../../../hoc/withBrands';
import withLocalization from '../../../hoc/withLocalization';
import './SelectState.scss';
import '../TextInput/TextInput.scss';
interface inputProps {
  localize?: any;
  label: string;
  value: string;
  onChange: (value: string) => void;
  isError?: boolean;
  errMsg?: string;
  handleSelectBlur: () => void;
  StateData: any;
  isErrBorder?: any;
  isFillAddress?: any;
}

const TextInput: FC<any> = (props: inputProps): ReactElement => {
  const {
    label,
    value,
    onChange,
    StateData,
    isError,
    errMsg,
    handleSelectBlur,
    localize,
    isErrBorder,
    isFillAddress,
  } = props;
  const errClc = isError && errMsg ? 'res-err-select-clc' : 'res-select-clc';
  const inputCls = isErrBorder ? 'res-select-clc' : errClc;
  const isRadiusClc = isErrBorder ? 'res-select-bill-grp' : 'res-select-grp';
  const isAddress = isErrBorder && isFillAddress ? false : true;
  const newLbl = isErrBorder ? `res-select-grp${label}` : `bill-select-grp${label}`;

  const autoLbl = isErrBorder ? 'billing address-level1' : 'address-level1';
  const handleChange = (val: any) => {
    if (onChange) onChange(val);
  };
  return (
    <>
      <div className={isRadiusClc}>
        <div className="res-lbl-grp">{label}</div>
        <select
          name="state_US"
          className={inputCls}
          id={newLbl}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleSelectBlur}
          aria-label={label}
          aria-required="true"
          autoComplete={autoLbl}
        >
          <option value="">
            {localize('preview-sales-engine.static-content.copy.eligibility-page.select-state-default')}
          </option>

          {isAddress &&
            StateData.map((state: any, index: any) => {
              //const selcetetVal = state.name === value ? 'Select' : '';
              return (
                <option key={state.name} value={state.name}>
                  {state.name}
                </option>
              );
            })}
        </select>
        {isError && errMsg && <span className="res-inline-err">{errMsg}</span>}
      </div>
    </>
  );
};
export default React.memo(withLocalization(withBrands(TextInput)));
