interface ADACode {
  title: string;
  code: string;
  includeInOption: boolean;
}

export const adaCodes: ADACode[] = [
  {
    code: 'none',
    title: 'preview-sales-engine.static-content.copy.Labels.labels#select-label',
    includeInOption: true,
  },
  {
    code: 'A5',
    title: 'preview-sales-engine.static-content.copy.prospects.adatub',
    includeInOption: true,
  },
  {
    code: 'A9',
    title: 'preview-sales-engine.static-content.copy.prospects.adaroll-inshower',
    includeInOption: true,
  },
  {
    code: 'G7',
    title: 'preview-sales-engine.static-content.copy.prospects.adatransfershower',
    includeInOption: true,
    //preview-sales-engine.static-content.copy.ADA.hearing
  },
  {
    code: 'B4',
    title: 'preview-sales-engine.static-content.copy.ADA.hearing',
    includeInOption: false,
  },
];

export const getADATitle = (code: string) => {
  const obj = adaCodes.filter((val, idx) => val.code === code);

  return obj[0].title;
};
