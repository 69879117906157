/* eslint-disable */
import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import { AvailableDates } from '../../features/offerAvailability/types';
import {
  bookReservationWithPayment,
  payerAuthProspectConfirmation,
  setPaymentEnrollError,
} from '../../features/offerConfirmation/offerConfirmationSlice';
import BookingConfirmmationRequest, {
  AmountDetails,
  BillTo,
  ChargeDetails,
  ClientReferenceInformation,
  OrderInformation,
  PaymentSubmitRequest,
  ProspectInformation,
  RoomInformation,
  TokenInformation,
  Tour,
} from '../../features/offerConfirmation/types';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand, getLoggedinUserName, numberToFixedPosition } from '../../util';
import { getStateCodeFromName, States_US } from '../../util/States';
import withLocalization from '../hoc/withLocalization';
import { getMaskedCardInformationFromToken } from '../OfferContactInformation/CyberSource/paymentUtil';
import './PackageSummaryReview.scss';
import { PayerAuthPayloadRequest } from '../../util/prospectPayerAuthMapping';
import { deviceInfoPayload } from '../../util/deviceInformation';
import { IP_ADDR_REQUEST_URL } from '../../util/strings';
interface ConfirmationSelectorType {
  localize: localizeType;
}

const ConfirmationSelector: FC<ConfirmationSelectorType> = (props: ConfirmationSelectorType): ReactElement => {
  const SOURCE_NAME = 'PBTC';
  const { localize } = props;
  const [loading, setLoading] = useState(false);
  const availabilityDates: AvailableDates = useSelector((state: RootState) => state.availabilityDates);
  const payerAuthDetails = useSelector((state: RootState) => state.confirmation.payerAuthDetails);
  const confirmationStatusDetails = useSelector((state: RootState) => state.confirmation.confirmationStatusDetails);
  const comments = useSelector((state: RootState) => state.confirmation.comments);
  const holdSessionId = availabilityDates.holdSessionId;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const offer = offerDetails.currentOffer;
  const selectedDate = availabilityDates.finalSelectedDate;
  const guests = offerDetails.guests;
  const prospect = offerDetails.prospectCopy;
  const tour = selectedDate ? selectedDate.tour : undefined;
  const extraNights = availabilityDates.finalSelectedExtraNights;
  const merchantGroupId = process.env.REACT_APP_MERCHANT_GROUPID ? process.env.REACT_APP_MERCHANT_GROUPID : '';
  const dispatch = useDispatch();
  const [ipAddress, setSetIpAddress] = useState('');
  let prospectStateName: string =
    prospect?.stateProvince && prospect.stateProvince.length > 0 ? prospect.stateProvince : '';

  const hasProspectState = prospectStateName.length > 0;
  prospectStateName = hasProspectState
    ? prospectStateName.length > 2
      ? States_US.filter((e) => e.name === prospectStateName)[0].code
      : prospectStateName
    : '';
  const jwtPaymentToken = useSelector((state: RootState) => state.confirmation.transientTokenJwt);
  const userName = getLoggedinUserName();
  useEffect(() => {
    fetch(IP_ADDR_REQUEST_URL)
      .then((response) => response.json())
      .then((data) => {
        const ipAdd = data?.ip || 'Unknown IP Address';
        setSetIpAddress(ipAdd);
      });
  }, []);
  const calculateDays = (initialDays: string, additionalNights?: number): string => {
    if (initialDays) {
      let caculatedDuration = parseInt(initialDays, 10);
      if (additionalNights && additionalNights > 0) {
        caculatedDuration += additionalNights;
      }

      return caculatedDuration.toString();
    }
    return '';
  };

  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;
  const prepairBookingRequest = () => {
    const ProspectInformation: ProspectInformation = {
      firstName: prospect && prospect.firstName ? prospect.firstName : '',
      lastName: prospect && prospect.lastName ? prospect.lastName : '',
      firstName2: prospect && prospect.spouseFirstName ? prospect.spouseFirstName : '',
      lastName2: prospect && prospect.spouseLastName ? prospect.spouseLastName : '',
      line1: prospect && prospect?.addressLine1 ? prospect?.addressLine1 : '',
      city: prospect && prospect?.city ? prospect?.city : '',
      stateProvince: prospectStateName,
      postalCode: prospect && prospect?.postalCode ? prospect?.postalCode : '',
      telephoneNumber: prospect && prospect?.phone ? prospect?.phone : '',
      emailAddress: prospect && prospect.email ? prospect.email : '',
    };
    const RoomInformation: RoomInformation = {
      accomodationCode: holdSessionId ? (selectedDate ? selectedDate.roomInformation.accomodationCode : '') : '',
      facilityPropertyID: holdSessionId
        ? selectedDate && selectedDate.roomInformation.FacilityPropertyID
          ? selectedDate.roomInformation.FacilityPropertyID
          : ''
        : '',
      miniHotelCode: holdSessionId ? (selectedDate ? selectedDate.roomInformation.miniHotelCode : '') : '',
      propertyCode: holdSessionId ? (selectedDate ? selectedDate.roomInformation.marshaCode : '') : '',
      rateCode: holdSessionId ? (selectedDate ? selectedDate.roomInformation.rateCode : '') : '',
      roomPoolCode: holdSessionId ? (selectedDate ? selectedDate.roomInformation.roomPoolCode : '') : '',
      roomTypeCode: holdSessionId
        ? selectedDate && selectedDate.roomInformation.RoomTypeCode
          ? selectedDate.roomInformation.RoomTypeCode
          : ''
        : '',
      bookingRoomType: holdSessionId
        ? selectedDate && selectedDate.roomInformation.BookingRoomType
          ? selectedDate.roomInformation.BookingRoomType
          : ''
        : '',
      numberOfDays:
        offerDetails && offerDetails.selectedRoomType
          ? calculateDays(offerDetails.selectedRoomType?.numberOfDays, extraNights)
          : '',
      numberOfNights:
        offerDetails && offerDetails.selectedRoomType
          ? calculateDays(offerDetails.selectedRoomType?.numberOfNights, extraNights)
          : '',
      numberOfGuests: offerDetails?.selectedRoomType?.sleepCapacity || 1,
      numberOfAdults: guests ? guests.adults.toString() : '',
      numberOfChildren: guests ? guests.children.toString() : '',
      preferences: holdSessionId
        ? selectedDate && selectedDate.roomInformation
          ? selectedDate.roomInformation.preferences
          : undefined
        : undefined,
    };

    const Tour: Tour = {
      offerId: offer ? offer.offerCode : '',
      tourType: tour ? tour.tourType : '',
      tourSiteCode: holdSessionId ? (tour ? tour.tourSiteCode : '') : '',
      salesCenterCode: holdSessionId ? (tour ? tour.salescenterCode : '') : '',
      appointmentDate: holdSessionId ? (tour ? tour.appointmentDate : '') : '',
    };
    const bookingReq: BookingConfirmmationRequest = {
      associateEID: userName,
      sourceName: SOURCE_NAME,
      holdSessionId: holdSessionId ? holdSessionId : '',
      pin: offerDetails.locSelectedState && offerDetails.locSelectedState !== '' ? '' : offerDetails.searchParameter,
      loc: offerDetails.locSelectedState && offerDetails.locSelectedState !== '' ? offerDetails.searchParameter : '',
      // ...(offerDetails.locSelectedState && offerDetails.locSelectedState !== '' && {loc: offerDetails.searchParameter}),
      startDate: holdSessionId
        ? selectedDate && selectedDate.checkInDate
          ? selectedDate.checkInDate.split('T')[0]
          : ''
        : '',
      endDate: holdSessionId
        ? selectedDate && selectedDate.checkOutDate
          ? selectedDate.checkOutDate.split('T')[0]
          : ''
        : '',
      holidaySurcharge: holdSessionId
        ? selectedDate && selectedDate.holidaySurcharge
          ? numberToFixedPosition(selectedDate.holidaySurcharge)
          : '0.00'
        : '',
      holidayName: holdSessionId ? (selectedDate && selectedDate.holidayName ? selectedDate.holidayName : '') : '',
      additionalNightCharge: holdSessionId
        ? selectedDate && selectedDate.additionalNightCharge
          ? numberToFixedPosition(selectedDate.additionalNightCharge)
          : '0.00'
        : '',
      totalCost: holdSessionId
        ? selectedDate && selectedDate.totalCost
          ? numberToFixedPosition(selectedDate.totalCost)
          : '0.00'
        : '',
      basePrice: holdSessionId
        ? selectedDate && selectedDate.basePrice
          ? numberToFixedPosition(selectedDate.basePrice)
          : '0.00'
        : '',
      retailPrice: holdSessionId
        ? selectedDate && selectedDate.retailPrice
          ? numberToFixedPosition(selectedDate.retailPrice)
          : '0.00'
        : '',
      additionalNights: extraNights ? extraNights : 0,
      tour: Tour,
      roomInformation: RoomInformation,
      prospectInformation: ProspectInformation,
      comments: comments,
    };
    const billingInfo = offerDetails.billingInfo;
    const cardSummary = getMaskedCardInformationFromToken(jwtPaymentToken ? jwtPaymentToken.toString() : '');
    const merchantID = process.env.REACT_APP_MERCHANTID ? process.env.REACT_APP_MERCHANTID.toString() : '';

    const chargeDetails: ChargeDetails = {
      billingAddressLine1: billingInfo && billingInfo.addressLine1 ? billingInfo.addressLine1 : '',
      billingEmailAddress: billingInfo && billingInfo.email ? billingInfo.email : '',
      billingFirstName: billingInfo && billingInfo.firstName ? billingInfo.firstName : '',
      billingLastName: billingInfo && billingInfo.lastName ? billingInfo.lastName : '',
      billingPhoneNumber: billingInfo && billingInfo.phone ? billingInfo.phone : '',
      billingCity: billingInfo && billingInfo.city ? billingInfo.city : '',
      billingCountryCode: 'US',
      billingStateProvinceCode:
        billingInfo && billingInfo.stateProvince ? getStateCodeFromName(billingInfo.stateProvince) : '',
      billingZip: billingInfo && billingInfo.postalCode ? billingInfo.postalCode : '',
      cardCheck: cardSummary.maskedCardNumber,
      currencyCode: 'USD',
      expirationDate: cardSummary.expiration,
      merchantID: merchantID,
      paymentMethodCode: cardSummary.cardType.toUpperCase(),
      preferredInvitationNumber: offerDetails.searchParameter,
      totalAmount: holdSessionId
        ? selectedDate && selectedDate.totalCost
          ? numberToFixedPosition(selectedDate.totalCost)
          : '0.00'
        : offerDetails.selectedRoomType
        ? offerDetails.selectedRoomType.depositAmount.substr(1)
        : '0.00',
      merchantGroupId,
    };
    bookingReq.charge = chargeDetails;
    return bookingReq;
  };
  const prepairPaymentRequest = (crdId, refId, transactionId) => {
    const deviceInfo = deviceInfoPayload();
    deviceInfo.ipAddress = ipAddress;

    const tokenInfo: TokenInformation = {
      transientTokenJwt: jwtPaymentToken ? jwtPaymentToken : '',
      cardinalJwt: '',
    };
    const customerId = prospect!.lastName ? prospect!.lastName.substr(0, 20) : '';

    const selectedRoomDescription = offerDetails.selectedRoomType
      ? offerDetails.selectedRoomType.roomTypeDescription
      : '';
    const paymentId = `${customerId}-${new Date().toISOString()}-${selectedRoomDescription}`;
    const clientInformation: ClientReferenceInformation = {
      code: 'WEB_PBT',
      associate: false,
      customerId: customerId,
      merchantGroupId,
      paymentId: paymentId,
      referenceId: refId,
      srcSystemName: 'WEB_PBT',
      transactionId: transactionId,
      storeCard: false,
    };
    const amountDetails: AmountDetails = {
      totalAmount: holdSessionId
        ? selectedDate && selectedDate.totalCost
          ? numberToFixedPosition(selectedDate.totalCost)
          : '0.00'
        : offerDetails.selectedRoomType
        ? offerDetails.selectedRoomType.depositAmount.substr(1)
        : '0.00',
      currency: 'USD',
    };
    const billingInfo = offerDetails.billingInfo;
    const billTo: BillTo = {
      address1: billingInfo && billingInfo.addressLine1 ? billingInfo.addressLine1 : '',
      address2: billingInfo && billingInfo.unitNo ? billingInfo.unitNo : '',
      administrativeArea: billingInfo && billingInfo.stateProvince ? billingInfo.stateProvince : '',
      country: prospect && prospect.countryCode ? prospect.countryCode.substr(0, 2) : 'US',
      email: billingInfo && billingInfo.email ? billingInfo.email : '',
      firstName: billingInfo && billingInfo.firstName ? billingInfo.firstName : '',
      lastName: billingInfo && billingInfo.lastName ? billingInfo.lastName : '',
      locality: billingInfo && billingInfo.city ? billingInfo.city : '',
      postalCode: billingInfo && billingInfo.postalCode ? billingInfo.postalCode : '',
    };
    const orderInformation: OrderInformation = {
      amountDetails: amountDetails,
      billTo: billTo,
    };

    const paymentReq: PaymentSubmitRequest = {
      tokenInformation: tokenInfo,
      clientReferenceInformation: clientInformation,
      orderInformation: orderInformation,
      deviceInformation: deviceInfo,
    };
    return paymentReq;
  };

  const onBookNow = (crdId: any, refId: any, transactionId: any) => {
    setLoading(true);
    window.scrollTo(0, 0);
    const bookingReqObj = prepairBookingRequest();
    const paymentReqObj = prepairPaymentRequest(crdId, refId, transactionId);
    paymentReqObj.deviceInformation.ipAddress = ipAddress;
    dispatch(bookReservationWithPayment(paymentReqObj, bookingReqObj));
  };
  const payerAuthEnrollment = (payAttempt) => {
    const cardinal = window.Cardinal;
    cardinal?.continue(
      'cca',
      {
        AcsUrl: payAttempt.acsUrl,
        Payload: payAttempt.paReq,
      },
      {
        OrderDetails: {
          TransactionId: payAttempt.transactionId,
        },
      },
    );

    cardinal?.on('payments.validated', async (data, cardinaljwt) => {
      console.log('Action code - ', data?.ActionCode);
      const validateData = data?.ActionCode?.toUpperCase() || 'default';
      if (validateData === 'FAILURE' || validateData === 'ERROR') {
        dispatch(setPaymentEnrollError());
      } else {
        const prTransId = data?.Payment?.ProcessorTransactionId || payAttempt.transactionId;
        onBookNow('', '', prTransId);
      }
    });
  };

  const setupCardinal = (cardinalJwt: any, referenceId: any) => {
    const cardinal = window.Cardinal;
    console.log('we are in setupCardinal function');
    cardinal?.configure({
      logging: {
        level: 'on',
      },
    });
    cardinal?.setup('init', {
      jwt: cardinalJwt,
    });
    cardinal?.on('payments.setupComplete', function (setupCompleteData) {
      console.log('payments setupComplete now call confirmation');
      onBookNow(cardinalJwt, referenceId, '');
    });
  };

  useEffect(() => {
    if (payerAuthDetails && payerAuthDetails?.payerAuthEnabled && payerAuthDetails?.referenceId !== '') {
      setupCardinal(payerAuthDetails?.cardinalJwt, payerAuthDetails?.referenceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerAuthDetails]);

  useEffect(() => {
    if (confirmationStatusDetails && confirmationStatusDetails?.status === 'pending_authentication') {
      payerAuthEnrollment(confirmationStatusDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationStatusDetails]);

  const submitPayerAuth = async (evt) => {
    setLoading(true);
    window.scrollTo(0, 0);

    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step7-Review',
        siteSection: 'Preview Booking Engine',
        pageName: 'PBE – Your Information',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        pageName: 'Step7-Review',
        resortID: '[Resort ID]',
        rresortName: offerDetails.property?.name,
        resortCity: offerDetails.property?.city,
        resortState: offerDetails.property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: offerDetails.property?.marshaCode,
        resortBrand: brandName,
        flowIdentifier: flowIdentifier,
        formLOC: offerDetails.searchParameter,
        bookingStepName: 'Step 7 -Review',
      },
    );
    trackAnalyticData('Reserve now');
    const totalAmt = holdSessionId
      ? selectedDate && selectedDate.totalCost
        ? numberToFixedPosition(selectedDate.totalCost)
        : '0.00'
      : offerDetails.selectedRoomType
      ? offerDetails.selectedRoomType.depositAmount.substr(1)
      : '0.00';
    const payerAuthObj = PayerAuthPayloadRequest(offerDetails, totalAmt, jwtPaymentToken, ipAddress);
    const bookingReqObj = prepairBookingRequest();
    const paymentReqObj = prepairPaymentRequest('', '', '');
    paymentReqObj.deviceInformation.ipAddress = ipAddress;
    dispatch(payerAuthProspectConfirmation(payerAuthObj, paymentReqObj, bookingReqObj));
  };

  return (
    <>
      <div className="eligibilityButton top40">
        <button className="checkInButton palmBg upperCase" onClick={submitPayerAuth} disabled={loading}>
          {localize('preview-sales-engine.static-content.copy.Confirmation Page.book-now-button')}
        </button>
      </div>
    </>
  );
};

export default withRouter(withLocalization(ConfirmationSelector));
