import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { loadSiteContent } from '../../features/siteContent/siteContentSlice';

export default function WithContentLoading(props: any): JSX.Element {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => {
    return state.siteContent.loading;
  });

  useEffect(() => {
    dispatch(loadSiteContent());
  }, [dispatch]);

  return <>{isLoading ? <></> : props.children}</>;
}
