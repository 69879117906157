import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';
import './ImgGalleryModal.scss';
import leftArrowLogo from '../../../features/siteTheme/Brands/tmvcs/logo/leftArrowLogo.svg';
import galleryModalCloseLogo from '../../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';

interface ImgModalProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
}

const ImgGalleryModal: FC<any> = (props: ImgModalProps): ReactElement => {
  const { localize, closeModal } = props;
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const offerDataArr: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const roomTypeData: any = (offerDataArr?.roomTypes && offerDataArr?.roomTypes[0]) || '';
  const resortName = roomTypeData?.property?.name || '';
  const imgDataArr = roomTypeData?.imgList16?.extImages || [];
  const intImgDataArr = roomTypeData?.imgList16?.intImages || [];
  // console.log(imgDataArr);
  const groupedArray: any =
    imgDataArr?.reduce((acc: any, item: any, index: number) => {
      const groupIndex: any = Math.floor(index / 3);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(item);
      return acc;
    }, []) || [];
  //console.log(groupedArray);
  const intImgGroupedArray: any =
    intImgDataArr?.reduce((acc: any, item: any, index: number) => {
      const groupIndex: any = Math.floor(index / 3);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(item);
      return acc;
    }, []) || [];

  const handleKeyDown = () => {};
  return (
    <div className="modal-gallery">
      <div className="modal-content-img-gal">
        <div className="img-container">
          <div className="img-headContainer">
            <div className="modalCloseContainer">
              <div
                className="text-link-wrapper"
                onClick={closeModal}
                arial-label={'see-detail'}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                <img src={leftArrowLogo} alt="Back" className="back-icon-gallery" />
                <div className="close-text-span">
                  {localize(`preview-sales-engine.static-content.copy.pbeo.back-button-label`)}
                </div>
              </div>
              <div className="close-btn-wrapper">
                <input
                  className="modal-close-btn"
                  src={galleryModalCloseLogo}
                  type="image"
                  onClick={closeModal}
                  aria-label="close"
                />
              </div>
            </div>
          </div>
          <div className="img-title-container">
            <div className="header-title-text">{resortName}</div>
          </div>
          <>
            {groupedArray.map((subArray, index) => (
              <>
                <div className="imgRowContainer">
                  <img src={subArray[0].photo} alt={subArray[0].altText} className="modal-image" />
                </div>
                {subArray?.length === 3 && (
                  <div className="imgRowContainer">
                    <img src={subArray[1].photo} alt={subArray[1].altText} className="modal-image-small" />
                    <img src={subArray[2].photo} alt={subArray[2].altText} className="modal-image-small" />
                  </div>
                )}
                {subArray?.length === 2 && (
                  <div className="imgRowContainer">
                    <img src={subArray[1].photo} alt={subArray[1].altText} className="modal-image-small" />
                  </div>
                )}
              </>
            ))}
          </>
          <>
            {intImgGroupedArray.map((subArray, index) => (
              <>
                <div className="imgRowContainer">
                  <img src={subArray[0].photo} alt={subArray[0].altText} className="modal-image" />
                </div>
                {subArray?.length === 3 && (
                  <div className="imgRowContainer">
                    <img src={subArray[1].photo} alt={subArray[1].altText} className="modal-image-small" />
                    <img src={subArray[2].photo} alt={subArray[2].altText} className="modal-image-small" />
                  </div>
                )}
                {subArray?.length === 2 && (
                  <div className="imgRowContainer">
                    <img src={subArray[1].photo} alt={subArray[1].altText} className="modal-image-small" />
                  </div>
                )}
              </>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(ImgGalleryModal)));
