import React, { FC } from 'react';
import './TestDetailsStyle.scss';
import { useHistory } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import { withRouter } from 'react-router-dom';
interface IofferDetails {
  resortName: string;
  stateName: string;
  cityName: string;
  numberOfNights: number;
  numberOfDays: number;
  startingCost: any;
  sleepCapacity: number;
}
interface IofferDetailsRoot {
  offerData: IofferDetails;
  localize?: any;
  localizeImages?: any;
  history: any;
  loc: any;
  flow: any;
}
const TestOfferDetails: FC<any> = ({ offerData, loc, flow }: IofferDetailsRoot, props: IofferDetailsRoot) => {
  const { resortName, stateName, cityName, numberOfDays, numberOfNights, sleepCapacity, startingCost } = offerData;
  const searchParam =
    flow === 'paid?'
      ? `&vdr=oa&dpt=cmd&chl=meta&med=paid&dest=orlando&resort=grande-vista&cid=soc-fb-paid-landing-2024-07-grande-vista&utm_source=meta&utm_medium=fb-paid&utm_campaign=soc-fb-paid-2024-07-grande-vista`
      : `&dpt=cmd&med=org&dest=orlando&resort=grande-vista`;
  let history = useHistory();
  const onClickNextBtn = () => {
    history.push({
      pathname: '/contact-information',
      search: `?loc=${loc}${searchParam}`,
    });
  };
  return (
    <div className="resortDetailsCls">
      <p className="textDetailsCls">{`The resort Name is ${resortName}`}</p>
      <p className="textDetailsCls">{`The state Name is ${stateName}`}</p>
      <p className="textDetailsCls">{`The City Name is ${cityName}`}</p>
      <p className="textDetailsCls">{`For ${numberOfDays} number of days`}</p>
      <p className="textDetailsCls">{`For ${numberOfNights} number of nights`}</p>
      <p className="textDetailsCls">{`Grande Vista Showroom from ${startingCost} per stay.`}</p>
      <p className="textDetailsCls">{`Max Guests is ${sleepCapacity}`}</p>
      <button className="offerDetailsBtn" onClick={onClickNextBtn}>
        Next
      </button>
    </div>
  );
};
export default React.memo(withRouter(withLocalization(TestOfferDetails)));
