import React, { FC, ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { localizeType } from '../../commonType';
import { setHearingADACode, setRoomADACode } from '../../features/offerAvailability/offerAvailabilitySlice';
import { AvailableDates } from '../../features/offerAvailability/types';
import { RootState } from '../../redux/rootReducer';
import { adaCodes } from '../../util/Ada';
import { B4, none } from '../../util/strings';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';

interface ADAProps {
  localize: localizeType;
  onADASelectionChange: () => void;
}

const ADASelector: FC<ADAProps> = (props: ADAProps): ReactElement => {
  const { localize } = props;
  const availabilityDates: AvailableDates = useSelector((state: RootState) => state.availabilityDates);
  const adaStatus: boolean = availabilityDates.hearingADACode !== undefined;
  const selectedADARoomCode = availabilityDates.roomADACode;
  const dispatch = useDispatch();
  const handleADARoomTypeChange = (evt) => {
    dispatch(setRoomADACode(evt.target.value === none ? undefined : evt.target.value));
  };
  const handleHearingADASelectionChange = (evt) => {
    dispatch(setHearingADACode(evt.target.checked === true ? B4 : undefined));
  };

  //const [show, setShow] = useState(false);

  return (
    <div className="row checkBoxWrapper">
      <div className="col-md-6">
        <p> {localize('preview-sales-engine.static-content.copy.prospects.accessible-label')}</p>
        <div className="premiumText">
          <div className="adaSelectorWrapper">
            <div className="select">
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <label htmlFor="ada-selection">
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <select
                  name="ada-selection"
                  id="ada-selection"
                  title="ada-selection"
                  className="ada-selection"
                  onChange={handleADARoomTypeChange}
                  defaultValue={selectedADARoomCode}
                >
                  {adaCodes
                    .filter((x) => x.includeInOption)
                    .map((adaCode, index) => {
                      return (
                        <option key={index} value={adaCode.code}>
                          {localize(adaCode.title)}
                        </option>
                      );
                    })}
                </select>
              </label>
            </div>
            <div className="questionWrapper">
              <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                delay={{ show: 250, hide: 400 }}
                transition={false}
                overlay={
                  <Tooltip id="ada-tooltip-info" className="tooltip-info" role="contentinfo">
                    <ParseHtml
                      htmlInput={localize('preview-sales-engine.static-content.copy.prospects.accessibledetails')}
                    ></ParseHtml>
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <span className="question-helper" {...triggerHandler} ref={ref}></span>
                )}
              </OverlayTrigger>
            </div>
          </div>
          <label htmlFor="ada">
            <input id="ada" type="checkbox" defaultChecked={adaStatus} onChange={handleHearingADASelectionChange} />
            {localize('preview-sales-engine.static-content.copy.ADA.hearing')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default withLocalization(ADASelector);
