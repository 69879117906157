import React, { FC, ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import withLocalization from '../../hoc/withLocalization';
import galleryModalCloseLogo from '../../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import './ContactInfoIneligibleStateModal.scss';
import { replacePlaceholder } from '../../../util/CommonLogic';
import ParseHtml from '../../common/ParseHtml';

interface DoPDetailsType {
  dopCodeDetails?: string;
  onCloseIneligibleStateModalForm: () => void;
  stateProvince?: string;
  localize?: any;
  desc?: any;
  phone?: any;
}
const ContactInfoModalForm: FC<DoPDetailsType> = (props: DoPDetailsType): ReactElement => {
  const { onCloseIneligibleStateModalForm, stateProvince = '', localize, desc, phone } = props;
  const phoneNum: any = phone || localize('preview-sales-engine.static-content.copy.pbeo.default-loc-phone');

  const billingContent = localize(
    `preview-sales-engine.static-content.copy.pbeo.ineligible-state-thank-you-modal-description`,
  );
  const contactInfoContent = localize(
    `preview-sales-engine.static-content.copy.Error Message.ineligible-state-error-description`,
  );
  const finalContent = desc ? billingContent : contactInfoContent;
  const updateStateContent = replacePlaceholder(finalContent, '{state}', stateProvince) || '';
  const updatePhone = replacePlaceholder(updateStateContent, '{1}', phoneNum) || '';
  const updatedClassContent = replacePlaceholder(updatePhone, 'xx', 'contactNumberDescription') || '';

  return (
    <>
      <Modal
        dialogClassName="modal-ineligible-state"
        id="modal-ineligible-state"
        show={true}
        backdrop="static"
        keyboard="false"
        scrollable={true}
        animation={false}
        size="lg"
        centered
        aria-labelledby="modal-ineligible-state"
      >
        <Modal.Body role="main">
          <div className="reservationPageContainer">
            <span className="contactInfoHeaderContainer">
              <input
                className="modal-close-btn"
                src={galleryModalCloseLogo}
                type="image"
                onClick={onCloseIneligibleStateModalForm}
                aria-label="close"
              />
            </span>
            <span className="contactinfopageheadertitle">
              {localize('preview-sales-engine.static-content.copy.pbeo.ineligible-state-thank-you-modal-title')}
            </span>

            <div className="ineligibleModalTitleContainer">
              <span className="reservationCardDescription">
                <ParseHtml htmlInput={updatedClassContent}></ParseHtml>
              </span>
            </div>
            <div className="payNowBtnContainer">
              <button className="closeBtn" onClick={onCloseIneligibleStateModalForm}>
                {localize('preview-sales-engine.static-content.copy.pbeo.ineligible-state-thank-you-modal-cta')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withLocalization(ContactInfoModalForm);
