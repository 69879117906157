/* eslint-disable jsx-a11y/no-onchange */
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  // eslint-disable-next-line prettier/prettier
  filterByCustomerSelectedState,
} from '../../features/offerSearch/offerSearchSlice';
import { RootState } from '../../redux/rootReducer';
import withLocalization from '../hoc/withLocalization';

const ViewDestinationHeader: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  let offerLocation = useSelector((state: RootState) => state.offerDetails.primaryOfferLocations);
  offerLocation = offerLocation
    ? JSON.parse(JSON.stringify(offerLocation)).sort((e1, e2) => {
        if (e1 && e2) {
          if (e1.stateName && e2.stateName) {
            const first = e1.stateName.toLowerCase();
            const second = e2.stateName.toLowerCase();
            if (first > second) return 1;
            if (first < second) return -1;
            return 0;
          }
        }
        return 0;
      })
    : undefined;
  const isMobile = useMediaQuery({ query: `(max-width: 650px)` });

  const customerSelectedStateCode = useSelector((state: RootState) => state.offerDetails.customerSelectedStateCode);
  const dispatch = useDispatch();

  const onCustomerSelectedStateChange = (e) => {
    dispatch(filterByCustomerSelectedState(e.target.value));
  };

  const optionsOfferLocation = offerLocation?.map((e, index) => {
    return (
      <option value={e.stateCode} key={index}>
        {e.stateName}
      </option>
    );
  });
  return (
    <div className="destinationHeaderWrapper">
      {!isMobile ? (
        <div className="dateHeaderWrapper">
          <h1 className="headBaskerville">
            {localize('preview-sales-engine.static-content.copy.prospects.selectresort')}
          </h1>
        </div>
      ) : (
        <div className="dateHeaderWrapper heading-none">
          <h1 className="headBaskerville">
            {localize('preview-sales-engine.static-content.copy.prospects.selectresort')}
          </h1>
        </div>
      )}
      <div className="row">
        <div className="col-lg-7">
          <div>{localize('preview-sales-engine.static-content.copy.prospects.brandarrayofofferwinning')}</div>
        </div>
        <div className="col-lg-5 form-row state-custom-style customer-state">
          <div className="form-group custom-style-state">
            {offerLocation && offerLocation.length > 2 ? (
              <label htmlFor="onCustomerSelectedStateChange" className="state-label">
                <span>{localize('preview-sales-engine.static-content.copy.prospects.filter-resort-text')}</span>
                <select
                  onChange={onCustomerSelectedStateChange}
                  className="form-control upperCase"
                  id="onCustomerSelectedStateChange"
                  defaultValue={customerSelectedStateCode}
                >
                  <option value={-1}>
                    {localize('preview-sales-engine.static-content.copy.eligibility-page.select-state-default')}
                  </option>
                  {optionsOfferLocation}
                </select>
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withLocalization(ViewDestinationHeader);
