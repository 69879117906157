import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  releaseHoldProperty,
  resetAvailabilityErrorStatus,
  // eslint-disable-next-line prettier/prettier
  resetNavigationStatus
} from '../../../features/offerAvailability/offerAvailabilitySlice';
import { ReleaseHoldPropertyRequest } from '../../../features/offerAvailability/types';
import { RootState } from '../../../redux/rootReducer';
import { getFormattedPhoneNumberForBrands } from '../../../util';
import { OFFER_DATES_PATH } from '../../../util/strings';
import ParseHtml from '../../common/ParseHtml';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';

type onTimerCompletedType = () => void;
interface TimerPropType {
  countdownDuration: number;
  onTimerCompleted?: onTimerCompletedType;
  localize: any;
  history?: any;
  brand: string;
  isMobile: boolean;
}

const ConuntdownTimer: FC<TimerPropType> = (props: TimerPropType): ReactElement => {
  const { countdownDuration, onTimerCompleted, localize, brand, isMobile } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let dynamicPhoneNumber = useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber);
  const holdSessionId = useSelector((state: RootState) => state.availabilityDates.holdSessionId);
  dynamicPhoneNumber = dynamicPhoneNumber
    ? dynamicPhoneNumber
    : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');

  const formattedPhoneNumber = getFormattedPhoneNumberForBrands(dynamicPhoneNumber, brand);
  const errorMessage = localize('preview-sales-engine.static-content.copy.prospects.reservation-hold-expires', [
    dynamicPhoneNumber,
    formattedPhoneNumber,
  ]);
  const [timeRemaining, setRemainingTime] = useState(countdownDuration);
  const onNavigateToAvailability = () => {
    handleClose();
    dispatch(resetNavigationStatus());
    dispatch(resetAvailabilityErrorStatus());
    setTimeout(() => {
      props.history.push(OFFER_DATES_PATH);
    }, 0);
  };
  useEffect(() => {
    if (!timeRemaining) {
      if (holdSessionId) {
        const releaseRequest: ReleaseHoldPropertyRequest = {
          holdId: holdSessionId,
        };
        dispatch(releaseHoldProperty(releaseRequest));
      }
      handleShow();
      if (onTimerCompleted) {
        onTimerCompleted();
      }

      return;
    }

    const intervalId = setInterval(() => {
      setRemainingTime(timeRemaining - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, onTimerCompleted]);

  const getHumanReadableTime = (seconds: number) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  };
  return (
    <div className={isMobile ? '' : 'row'}>
      <span className="countdownclock">
        <ParseHtml
          htmlInput={localize(
            'preview-sales-engine.static-content.copy.hold-component.hold-component-instruction',
            `<strong>${getHumanReadableTime(timeRemaining)}`,
          )}
        />
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-custom-timer"
        animation={false}
        role="dialog"
      >
        <Modal.Body>
          <ParseHtml htmlInput={errorMessage}></ParseHtml>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onNavigateToAvailability} tabIndex={0}>
            {localize('preview-sales-engine.static-content.copy.hold-component.select-a-new-date-button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default withRouter(withLocalization(withBrands(ConuntdownTimer)));
