/* eslint-disable jsx-a11y/no-onchange */
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  resetBillingCheckBox,
  setAllBillingInfoFields,
  setBillingErrorfromContactInfo,
  setBillingObjectFromContactInfo,
  // eslint-disable-next-line prettier/prettier
  setBillingObjectState,
} from '../../features/offerSearch/offerSearchSlice';
import { RootState } from '../../redux/rootReducer';
import { States_US } from '../../util/States';
import { isEqual, validateFields } from '../../util/validation';
import withLocalization from '../hoc/withLocalization';

const BillingInformationForm: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const dispatch = useDispatch();
  const billingInfo = useSelector((state: RootState) => state.offerDetails.billingInfo);
  const billingInfoChecked = useSelector((state: RootState) => state.offerDetails.billingBoxChecked);
  const billingError = useSelector((state: RootState) => state.offerDetails.billingError);
  const prospectCopy = useSelector((state: RootState) => state.offerDetails.prospectCopy);

  const [billingObject, setbillingObject] = useState({
    firstName2: {
      fieldValue: billingInfo ? billingInfo.firstName : '',
      error: billingError ? billingError.firstName : '',
    },
    lastName2: {
      fieldValue: billingInfo ? billingInfo.lastName : '',
      error: billingError ? billingError.lastName : '',
    },
    addressLine2: {
      fieldValue: billingInfo ? billingInfo.addressLine1 : '',
      error: billingError ? billingError.addressLine1 : '',
    },
    unitNo2: {
      fieldValue: billingInfo ? billingInfo.unitNo : '',
      error: '',
    },
    city2: {
      fieldValue: billingInfo ? billingInfo.city : '',
      error: billingError ? billingError.city : '',
    },
    postalCode2: {
      fieldValue: billingInfo ? billingInfo.postalCode : '',
      error: billingError ? billingError.postalCode : '',
    },
    email2: {
      fieldValue: billingInfo ? billingInfo.email : '',
      error: billingError ? billingError.email : '',
    },
    phone2: {
      fieldValue: billingInfo ? billingInfo.phone : '',
      error: billingError ? billingError.phone : '',
    },
    stateProvince2: {
      fieldValue:
        billingInfo && billingInfo.stateProvince && billingInfo.stateProvince.trim().length > 0
          ? billingInfo.stateProvince.length > 2 || billingInfo.stateProvince === '1'
            ? billingInfo.stateProvince
            : States_US.filter((e) => e.code === billingInfo.stateProvince)[0].name
          : '',
      error: billingError ? billingError.stateProvince : '',
    },
  });
  let isValidErrorValue =
    !!billingObject.firstName2.error ||
    !!billingObject.lastName2.error ||
    !!billingObject.email2.error ||
    !!billingObject.postalCode2.error ||
    !!billingObject.addressLine2.error ||
    !!billingObject.city2.error ||
    !!billingObject.phone2.error ||
    billingObject.stateProvince2.error;
  let isValidFieldValue =
    !!billingObject.firstName2.fieldValue &&
    !!billingObject.lastName2.fieldValue &&
    !!billingObject.email2.fieldValue &&
    !!billingObject.phone2.fieldValue &&
    !!billingObject.postalCode2.fieldValue &&
    !!billingObject.addressLine2.fieldValue &&
    !!billingObject.city2.fieldValue &&
    !!billingObject.stateProvince2.fieldValue;
  const dispatchInfoToState = () => {
    dispatch(
      setBillingObjectFromContactInfo({
        firstName: billingObject.firstName2.fieldValue,
        lastName: billingObject.lastName2.fieldValue,
        email: billingObject.email2.fieldValue,
        phone: billingObject.phone2.fieldValue,
        stateProvince: billingObject.stateProvince2.fieldValue
          ? billingObject.stateProvince2.fieldValue.length === 2 || billingObject.stateProvince2.fieldValue === '1'
            ? billingObject.stateProvince2.fieldValue
            : States_US.filter((e) => e.name === billingObject.stateProvince2.fieldValue)[0].code
          : '',
        addressLine1: billingObject.addressLine2.fieldValue,
        unitNo: billingObject.unitNo2.fieldValue,
        city: billingObject.city2.fieldValue,
        postalCode: billingObject.postalCode2.fieldValue,
      }),
    );
    dispatch(
      setBillingErrorfromContactInfo({
        firstName: billingObject.firstName2.error,
        lastName: billingObject.lastName2.error,
        email: billingObject.email2.error,
        phone: billingObject.phone2.error,
        stateProvince: billingObject.stateProvince2.error,
        addressLine1: billingObject.addressLine2.error,
        city: billingObject.city2.error,
        postalCode: billingObject.postalCode2.error,
      }),
    );
  };

  const handlebillingChange = (target) => {
    const { id, value } = target;

    const errMsg = validateFields(id, value);
    if (id === 'stateProvince2') {
      dispatch(setBillingObjectState(value));
    }
    if (errMsg) {
      setbillingObject({
        ...billingObject,
        [id]: { ...billingObject[id], error: errMsg, fieldValue: value },
      });
    } else {
      setbillingObject({
        ...billingObject,
        [id]: { ...billingObject[id], error: '', fieldValue: value },
      });
    }
  };
  const handleBlur = (target) => {
    const { id, value } = target;
    const errMsg = validateFields(id, value);

    if (errMsg) {
      setbillingObject({
        ...billingObject,
        [id]: { ...billingObject[id], error: errMsg, fieldValue: value },
      });
    } else {
      setbillingObject({
        ...billingObject,
        [id]: { ...billingObject[id], error: '', fieldValue: value },
      });
    }
    dispatchInfoToState();
    if (!isEqual(prospectCopy, billingObject)) {
      dispatch(resetBillingCheckBox());
    }
  };
  useEffect(() => {
    if (isValidFieldValue && !isValidErrorValue) {
      dispatch(setAllBillingInfoFields(false));
    } else {
      dispatch(setAllBillingInfoFields(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingObject]);

  useEffect(() => {
    const tempObj = {
      firstName2: {
        fieldValue: billingInfo ? billingInfo.firstName : '',
        error: billingError ? billingError.firstName : '',
      },
      lastName2: {
        fieldValue: billingInfo ? billingInfo.lastName : '',
        error: billingError ? billingError.lastName : '',
      },
      addressLine2: {
        fieldValue: billingInfo ? billingInfo.addressLine1 : '',
        error: billingError ? billingError.addressLine1 : '',
      },
      unitNo2: {
        fieldValue: billingInfo ? billingInfo.unitNo : '',
        error: '',
      },
      city2: {
        fieldValue: billingInfo ? billingInfo.city : '',
        error: billingError ? billingError.city : '',
      },
      postalCode2: {
        fieldValue: billingInfo ? billingInfo.postalCode : '',
        error: billingError ? billingError.postalCode : '',
      },
      email2: {
        fieldValue: billingInfo ? billingInfo.email : '',
        error: billingError ? billingError.email : '',
      },
      phone2: {
        fieldValue: billingInfo ? billingInfo.phone : '',
        error: billingError ? billingError.phone : '',
      },
      stateProvince2: {
        fieldValue:
          billingInfo && billingInfo.stateProvince && billingInfo.stateProvince.trim().length > 0
            ? billingInfo.stateProvince.length > 2 || billingInfo.stateProvince === '1'
              ? billingInfo.stateProvince
              : States_US.filter((e) => e.code === billingInfo.stateProvince)[0].name
            : '',
        error: billingError ? billingError.stateProvince : '',
      },
    };
    setbillingObject(tempObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfoChecked]);
  return (
    <>
      <div className="formWrapper">
        <div className="form-group" id="prospectBillingInformation">
          <label htmlFor="firstName2">
            {localize('preview-sales-engine.static-content.copy.contact-information-page.first-name')}
            <span className="asterisk">*</span>
            <input
              type="text"
              className="form-control"
              id="firstName2"
              value={billingObject.firstName2.fieldValue || ''}
              onChange={(e) => handlebillingChange(e.target)}
              onBlur={(e) => handleBlur(e.target)}
              maxLength={30}
            />
            <div className="invalid-feedback-onchange">{localize(billingObject.firstName2.error)}</div>
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="lastName2">
            {localize('preview-sales-engine.static-content.copy.contact-information-page.last-name')}
            <span className="asterisk">*</span>
            <input
              type="text"
              className="form-control"
              id="lastName2"
              value={billingObject.lastName2.fieldValue || ''}
              onChange={(e) => handlebillingChange(e.target)}
              onBlur={(e) => handleBlur(e.target)}
              maxLength={30}
            />
            <div className="invalid-feedback-onchange">{localize(billingObject.lastName2.error)}</div>
          </label>
        </div>
        <div className="form-row">
          <div className="form-group col-md-8">
            <label htmlFor="addressLine2">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.street-address')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="addressLine2"
                value={billingObject.addressLine2.fieldValue || ''}
                onChange={(e) => handlebillingChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
              />
              <div className="invalid-feedback-onchange">{billingObject.addressLine2.error}</div>
            </label>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="unitNo2">
              {localize('preview-sales-engine.static-content.copy.Labels.labels#unit-no-label')}
              <input
                type="text"
                className="form-control"
                id="unitNo2"
                value={billingObject.unitNo2.fieldValue || ''}
                onChange={(e) => handlebillingChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
              />
              <div className="invalid-feedback-onchange">{billingObject.unitNo2.error}</div>
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="city2">
            {localize('preview-sales-engine.static-content.copy.contact-information-page.city')}
            <span className="asterisk">*</span>
            <input
              type="text"
              className="form-control"
              id="city2"
              value={billingObject.city2.fieldValue || ''}
              onChange={(e) => handlebillingChange(e.target)}
              onBlur={(e) => handleBlur(e.target)}
              maxLength={30}
            />
            <div className="invalid-feedback-onchange">{billingObject.city2.error}</div>
          </label>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="stateProvince2">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.state')}
              <span className="asterisk">*</span>
              <select
                className="form-control"
                id="stateProvince2"
                value={billingObject.stateProvince2.fieldValue}
                onChange={(e) => handlebillingChange(e.target)}
              >
                <option value={'1'}>
                  {localize('preview-sales-engine.static-content.copy.eligibility-page.select-state-default')}
                </option>
                {States_US.map((state, index) => {
                  return (
                    <option key={index} value={state.name}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
              <div className="invalid-feedback-onchange">{billingObject.stateProvince2.error}</div>
            </label>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="postalCode2">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.zip-code')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="postalCode2"
                value={billingObject.postalCode2.fieldValue || ''}
                onChange={(e) => handlebillingChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={10}
              />
              <div className="invalid-feedback-onchange">{billingObject.postalCode2.error}</div>
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="email2">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.email')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="email2"
                value={billingObject.email2.fieldValue || ''}
                onChange={(e) => handlebillingChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={50}
                required
              />
              <div className="invalid-feedback-onchange">{localize(billingObject.email2.error)}</div>
            </label>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone2">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.phone')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="phone2"
                value={billingObject.phone2.fieldValue || ''}
                onChange={(e) => handlebillingChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={10}
                required
              />
              <div className="invalid-feedback-onchange">{localize(billingObject.phone2.error)}</div>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(BillingInformationForm));
