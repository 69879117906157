const subtractDollarValues = (value1: any, value2: any) => {
  if (value1 === '' || value2 === '' || value1 === undefined || value2 === undefined) {
    return '';
  }
  const num1 = parseFloat(value1.replace('$', ''));
  const num2 = parseFloat(value2.replace('$', ''));
  const result = num1 - num2;
  const formattedResult = '$' + result.toFixed(2);
  return formattedResult;
};
const calculateMonthsBetweenDates = (dateString1: any, dateString2: any) => {
  if (dateString1 === '' || dateString2 === '' || dateString1 === undefined || dateString2 === undefined) {
    return '';
  } else {
    // Parse date strings into Date objects
    const date1: any = new Date(dateString1);
    const date2: any = new Date(dateString2);

    // Calculate the difference in milliseconds between the dates
    const diffMs = Math.abs(date2 - date1);

    // Convert the difference from milliseconds to months
    // Approximation: 1 month = 30.44 days
    const msInMonth = 30.44 * 24 * 60 * 60 * 1000;
    const diffMonths = diffMs / msInMonth;
    // use Math.round instead of Math.floor to make sure it will round any fraction bigger than or euqal 5.
    return Math.round(diffMonths);
  }
};
export const refinePackageData = (data: any) => {
  const packageDetailsObj: any = {
    name: '',
    address: '',
    desc: '',
    stay: '',
    dates: '',
    maxGuest: '',
    tourGift: '',
    totalDue: '',
    dueAtBooking: '',
    dueToday: '',
    retailVal: '',
    accommodation: '',
    days: '',
    nights: '',
  };
  if (data) {
    const retailVal = data?.retailValue || '';
    const roomTypeData = data?.roomTypes[0] || {};
    const PropertyData = roomTypeData?.property || {};
    const pkgCity = roomTypeData?.property?.city || '';
    const pkgState = roomTypeData?.property?.stateProvince || '';
    const pkgAddress = `${pkgCity}, ${pkgState}`;
    const pkgDays = roomTypeData?.numberOfDays || '';
    const pkgNight = roomTypeData?.numberOfNights || '';
    const pkgStay = `${pkgDays} days / ${pkgNight} nights`;
    const pkgStartingCost = roomTypeData?.startingCost || '';
    const bookToDate = data?.bookToDate || '';
    const bookFromDate = data?.bookFromDate || '';
    const bookDate = calculateMonthsBetweenDates(bookFromDate, bookToDate);

    const pkgDepositAmount = roomTypeData?.depositAmount || '';
    const pkgDueAtAmt = subtractDollarValues(pkgStartingCost, pkgDepositAmount);
    packageDetailsObj.name = PropertyData?.name || '';
    packageDetailsObj.address = pkgAddress || '';
    packageDetailsObj.desc = roomTypeData?.roomTypeDescription || '';
    packageDetailsObj.stay = pkgStay || '';
    packageDetailsObj.dates = bookDate;
    // packageDetailsObj.maxGuest = `${roomTypeData?.sleepCapacity} Guests (adults - children)`;
    packageDetailsObj.maxGuest = `${roomTypeData?.sleepCapacity} `;
    packageDetailsObj.tourGift = (data?.premiums && data?.premiums[0]) || '';
    packageDetailsObj.totalDue = roomTypeData?.startingCost || '';
    packageDetailsObj.dueAtBooking = `${pkgDueAtAmt}`;
    packageDetailsObj.dueToday = pkgDepositAmount;
    packageDetailsObj.retailVal = retailVal;
    packageDetailsObj.accommodation = roomTypeData?.roomTypeDescription;
    packageDetailsObj.days = pkgDays;
    packageDetailsObj.nights = pkgNight;
  }
  return packageDetailsObj;
};
