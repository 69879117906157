const checkEmpty = (val: any) => {
  const isEmpty = val?.trim() === '' ? true : false;
  return isEmpty;
};
const isConsecutiveSpaces = (val: any) => {
  return val?.includes('  ');
};
const isInvalid = (val: any) => {
  // eslint-disable-next-line no-useless-escape
  const nameValidator: RegExp = new RegExp(/^(?=[a-zA-Z\\s]{1,30})(?!.*[0-9.,<>\/?`~!@#$%&*()\_=+{}\\|\^":;\[\]])/);
  return !nameValidator.test(val);
};
const isInvalidPhone = (val: any) => {
  const phoneValidator: RegExp = new RegExp(/^[1-9][0-9]{9}$/);
  return !phoneValidator.test(val);
};
const isValidEmail = (val: any) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return !emailPattern.test(val);
};
export const reserVationValidation = (field: any, val: any) => {
  if (field === 'fName') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#required`,
      };
    } else if (isConsecutiveSpaces(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#consecutive-spaces`,
      };
    } else if (isInvalid(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'lName') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.last-name-error#required`,
      };
    } else if (isInvalid(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'phone') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.phone-error#required`,
      };
    } else if (isInvalidPhone(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.phone-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'email') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        // msg: `preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid`,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.email-error#required`,
      };
    } else if (isValidEmail(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'state') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.Error Message.valid-us-state-error`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else {
    return { isError: false, msg: '' };
  }
};

export const validateReservationFields = (formData: any) => {
  let tempErr = {
    firstError: true,
    lastError: true,
    phoneError: true,
    emailError: true,
  };
  let tempErrMsg = {
    firstErrorMsg: '',
    lastErrorMsg: '',
    phoneErrorMsg: '',
    emailErrorMsg: '',
  };
  let tempData = { ...formData };
  for (let i in tempData) {
    if (i === 'firstName') {
      if (checkEmpty(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          firstErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#required`,
        };
        tempErr = { ...tempErr, firstError: true };
      } else if (isConsecutiveSpaces(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          firstErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#consecutive-spaces`,
        };
        tempErr = { ...tempErr, firstError: true };
      } else if (isInvalid(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          firstErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.first-name-error#invalid`,
        };
        tempErr = { ...tempErr, firstError: true };
      } else {
        tempErrMsg = { ...tempErrMsg, firstErrorMsg: '' };
        tempErr = { ...tempErr, firstError: false };
      }
    }
    if (i === 'lastName') {
      if (checkEmpty(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          lastErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.last-name-error#required`,
        };
        tempErr = { ...tempErr, lastError: true };
      } else if (isInvalid(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          lastErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.last-name-error#invalid`,
        };
        tempErr = { ...tempErr, lastError: true };
      } else {
        tempErrMsg = { ...tempErrMsg, lastErrorMsg: '' };
        tempErr = { ...tempErr, lastError: false };
      }
    }
    if (i === 'phoneNumber') {
      if (checkEmpty(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          phoneErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.phone-error#required`,
        };
        tempErr = { ...tempErr, phoneError: true };
      } else {
        tempErrMsg = { ...tempErrMsg, phoneErrorMsg: '' };
        tempErr = { ...tempErr, phoneError: false };
      }
    }
    if (i === 'emailAddress') {
      if (checkEmpty(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          emailErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.email-error#required`,
        };
        tempErr = { ...tempErr, emailError: true };
      } else if (isValidEmail(tempData[i])) {
        tempErrMsg = {
          ...tempErrMsg,
          emailErrorMsg: `preview-sales-engine.static-content.copy.contact-information-page.email-error#invalid`,
        };
        tempErr = { ...tempErr, emailError: true };
      } else {
        tempErrMsg = { ...tempErrMsg, emailErrorMsg: '' };
        tempErr = { ...tempErr, emailError: false };
      }
    }
  }
};
//*******************88 */

export const isReservationFormValid = (obj: any, isPaid: any) => {
  const updatedObj = isPaid ? { ...obj, stateError: false } : { ...obj };
  let isInValid = Object.values(updatedObj).every((value) => value === false);
  return isInValid;
};
export const hasBlankValues = (obj: any) => {
  return Object.values(obj).some((value) => value === '');
};

export const checkDisable = (formData: any, isPaid: boolean) => {
  let tempErr = {
    firstError: true,
    lastError: true,
    phoneError: true,
    emailError: true,
    stateError: !isPaid,
  };
  let tempData = { ...formData };
  for (let i in tempData) {
    if (i === 'firstName') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else if (isConsecutiveSpaces(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else if (isInvalid(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else {
        tempErr = { ...tempErr, firstError: false };
      }
    }
    if (i === 'lastName') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, lastError: true };
      } else if (isInvalid(tempData[i])) {
        tempErr = { ...tempErr, lastError: true };
      } else {
        tempErr = { ...tempErr, lastError: false };
      }
    }
    if (i === 'phoneNumber') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, phoneError: true };
      } else {
        tempErr = { ...tempErr, phoneError: false };
      }
    }
    if (i === 'emailAddress') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, emailError: true };
      } else if (isValidEmail(tempData[i])) {
        tempErr = { ...tempErr, emailError: true };
      } else {
        tempErr = { ...tempErr, emailError: false };
      }
    }
    if (i === 'state' && isPaid === false) {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, stateError: true };
      } else {
        tempErr = { ...tempErr, stateError: false };
      }
    }
  }

  return tempErr;
};
export const checkDisableModal = (formData: any) => {
  let tempErr = {
    firstError: true,
    lastError: true,
    phoneError: true,
    emailError: true,
  };
  let tempData = { ...formData };
  for (let i in tempData) {
    if (i === 'firstName') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else if (isConsecutiveSpaces(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else if (isInvalid(tempData[i])) {
        tempErr = { ...tempErr, firstError: true };
      } else {
        tempErr = { ...tempErr, firstError: false };
      }
    }
    if (i === 'lastName') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, lastError: true };
      } else if (isInvalid(tempData[i])) {
        tempErr = { ...tempErr, lastError: true };
      } else {
        tempErr = { ...tempErr, lastError: false };
      }
    }
    if (i === 'phoneNumber') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, phoneError: true };
      } else {
        tempErr = { ...tempErr, phoneError: false };
      }
    }
    if (i === 'emailAddress') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, emailError: true };
      } else if (isValidEmail(tempData[i])) {
        tempErr = { ...tempErr, emailError: true };
      } else {
        tempErr = { ...tempErr, emailError: false };
      }
    }
  }

  return tempErr;
};

export const formatPhoneNumber = (value) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return !match[2] ? match[1] : '(' + match[1] + ') ' + match[2] + (match[3] ? '-' + match[3] : '');
  }
  return value;
};
