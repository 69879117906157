import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import CheckEligibilitySelector from '../../components/OfferEligibility/CheckEligibilitySelector';
import DOPInformation from '../../components/OfferEligibility/DOPInformation';
import EligibilityHeader from '../../components/OfferEligibility/EligibilityHeader';
import PackageSummary from '../../components/OfferEligibility/PackageSummary';
import { RootState } from '../../redux/rootReducer';
import { OFFER_CONFIRMATION_PATH, OFFER_CONTACT_INFO_PATH } from '../../util/strings';
import {
  resetAllEligibilityOptions,
  resetErrorStatus,
  resetHoldErrorStatus,
  resetNavigationStatus,
  setAllEligibilityOptions,
  setLoadingStatus,
  // eslint-disable-next-line prettier/prettier
  setPreviousCalendarPosition,
} from '../offerAvailability/offerAvailabilitySlice';
import {
  resetPaymentContextErrorFlag,
  resetPaymentContextId,
  setPaymentButtonState,
  // eslint-disable-next-line prettier/prettier
  setPaymentcheckBox,
} from '../offerConfirmation/offerConfirmationSlice';
import { setNavigationStep } from '../offerSearch/offerSearchSlice';

const OfferEligibility: FC<any> = (props: any): ReactElement => {
  const [allCriteriaSelected, setAllCriteriaSelected] = useState<boolean>(false);
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const enableNextButton = allCriteriaSelected;
  const checkAllEligibilityOptions = useSelector(
    (state: RootState) => state.availabilityDates.allEligibilityOptionSelected,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let unMount = false;
    if (confirmationNumber && confirmationNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    return () => {
      if (!unMount) unMount = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setPreviousCalendarPosition());
    dispatch(setNavigationStep(NavigationSteps.OFFER_ELIGIBILITY));
    dispatch(resetNavigationStatus());
    dispatch(resetPaymentContextId());
    dispatch(setPaymentButtonState(false));
    dispatch(setPaymentcheckBox(false));
    dispatch(resetPaymentContextErrorFlag());
    dispatch(resetHoldErrorStatus());
    dispatch(resetErrorStatus());
    setTimeout(() => {
      dispatch(setLoadingStatus(false));
    }, 5);
  }, [dispatch]);

  const onEligibilityCriteriaChange = (allChecked: boolean) => {
    dispatch(resetAllEligibilityOptions());
    setAllCriteriaSelected(allChecked);
  };

  const onNextClicked = () => {
    if (enableNextButton) {
      dispatch(setAllEligibilityOptions());
      props.history.push(OFFER_CONTACT_INFO_PATH);
    }
  };
  const isMobile = useMediaQuery({ query: `(max-width: 650px)` });

  return (
    <>
      <div className="container">
        {/* <div className="row">
          <div className="col-md-12 mt-2">
            <OpenDatedNotice
              showNotice={!isDatedOffer || isOpenDatedOptionSelected}
              brandPhoneNumber={brandPhoneNumber}
            ></OpenDatedNotice>
          </div>
        </div> */}
        {!isMobile ? <EligibilityHeader /> : <></>}
        <div className="row">
          <div className="col-md-4">
            <PackageSummary />
          </div>
          <div className="col-md-8">
            <DOPInformation />
            <CheckEligibilitySelector
              onSelectionChange={onEligibilityCriteriaChange}
              checkAllEligibilityOptions={checkAllEligibilityOptions}
              enabled={enableNextButton}
              onClick={onNextClicked}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(OfferEligibility);
