import React, { FC, ReactElement, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ParseHtml from '../../components/common/ParseHtml';
import withBrands from '../../components/hoc/withBrands';
import withLocalization from '../../components/hoc/withLocalization';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import ConfirmationSelector from '../../components/OfferSummary/ConfirmationSelector';
import ContactInformationReview from '../../components/OfferSummary/ContactInformationReview';
import PackageSummaryReview from '../../components/OfferSummary/PackageSummaryReview';
import { RootState } from '../../redux/rootReducer';
import { getFormattedPhoneNumberForBrands } from '../../util';
import { OFFER_CONFIRMATION_PATH, OFFER_CONTACT_INFO_PATH } from '../../util/strings';
import {
  resetPaymentContextId,
  setPaymentButtonState,
  // eslint-disable-next-line prettier/prettier
  setPaymentcheckBox,
  clearProspectPayerAuthDetails,
} from '../offerConfirmation/offerConfirmationSlice';
import { setNavigationStep } from '../offerSearch/offerSearchSlice';

const OfferSummaryContainer: FC<any> = (props: any): ReactElement => {
  const dispatch = useDispatch();
  const { localize, brand } = props;
  const confirmationState = useSelector((state: RootState) => state.confirmation);
  let dynamicPhoneNumber = useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber);
  dynamicPhoneNumber = dynamicPhoneNumber
    ? dynamicPhoneNumber
    : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const confNumber = confirmationState.ConfirmationNumber;
  const hasError = confirmationState.hasError;
  const errorMessageKey = hasError ? confirmationState.error?.errorMessageKey : '';
  const errorMessage = hasError
    ? localize(errorMessageKey, [dynamicPhoneNumber, getFormattedPhoneNumberForBrands(dynamicPhoneNumber, brand)])
    : '';

  const jwtPaymentToken = useSelector((state: RootState) => state.confirmation.transientTokenJwt);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setNavigationStep(NavigationSteps.OFFER_REVIEW));
    dispatch(setPaymentButtonState(false));
    dispatch(setPaymentcheckBox(false));
    dispatch(resetPaymentContextId());
    dispatch(clearProspectPayerAuthDetails());
  }, [dispatch]);
  useEffect(() => {
    if (!hasError && confNumber && confNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, confNumber]);
  useEffect(() => {
    if (!jwtPaymentToken || jwtPaymentToken.length === 0) {
      props.history.push(OFFER_CONTACT_INFO_PATH);
    }
  }, [jwtPaymentToken, props.history]);
  return (
    <>
      <div className="container">
        {hasError ? (
          <Alert variant="danger">
            <ParseHtml htmlInput={errorMessage}></ParseHtml>
          </Alert>
        ) : (
          <></>
        )}

        <div className="row">
          <div className="col-sm-6">
            <ContactInformationReview />
          </div>
          <div className="col-sm-6">
            <PackageSummaryReview />
          </div>
        </div>
        <ConfirmationSelector />
      </div>
    </>
  );
};
export default withRouter(withLocalization(withBrands(OfferSummaryContainer)));
