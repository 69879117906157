export const States_US = [
  { name: 'ALABAMA', code: 'AL' },
  { name: 'ALASKA', code: 'AK' },
  { name: 'ARIZONA', code: 'AZ' },
  { name: 'ARKANSAS', code: 'AR' },
  { name: 'CALIFORNIA', code: 'CA' },
  { name: 'COLORADO', code: 'CO' },
  { name: 'CONNECTICUT', code: 'CT' },
  { name: 'DELAWARE', code: 'DE' },
  { name: 'DISTRICT of COLUMBIA', code: 'DC' },
  { name: 'FLORIDA', code: 'FL' },
  { name: 'GEORGIA', code: 'GA' },
  { name: 'HAWAII', code: 'HI' },
  { name: 'IDAHO', code: 'ID' },
  { name: 'ILLINOIS', code: 'IL' },
  { name: 'INDIANA', code: 'IN' },
  { name: 'IOWA', code: 'IA' },
  { name: 'KANSAS', code: 'KS' },
  { name: 'KENTUCKY', code: 'KY' },
  { name: 'LOUISIANA', code: 'LA' },
  { name: 'MAINE', code: 'ME' },
  { name: 'MARYLAND', code: 'MD' },
  { name: 'MASSACHUSETTS', code: 'MA' },
  { name: 'MICHIGAN', code: 'MI' },
  { name: 'MINNESOTA', code: 'MN' },
  { name: 'MISSISSIPPI', code: 'MS' },
  { name: 'MISSOURI', code: 'MO' },
  { name: 'MONTANA', code: 'MT' },
  { name: 'NEBRASKA', code: 'NE' },
  { name: 'NEVADA', code: 'NV' },
  { name: 'NEW HAMPSHIRE', code: 'NH' },
  { name: 'NEW JERSEY', code: 'NJ' },
  { name: 'NEW MEXICO', code: 'NM' },
  { name: 'NEW YORK', code: 'NY' },
  { name: 'NORTH CAROLINA', code: 'NC' },
  { name: 'NORTH DAKOTA', code: 'ND' },
  { name: 'OHIO', code: 'OH' },
  { name: 'OKLAHOMA', code: 'OK' },
  { name: 'OREGON', code: 'OR' },
  { name: 'PENNSYLVANIA', code: 'PA' },
  { name: 'PUERTO RICO', code: 'PR' },
  { name: 'RHODE ISLAND', code: 'RI' },
  { name: 'SOUTH CAROLINA', code: 'SC' },
  { name: 'SOUTH DAKOTA', code: 'SD' },
  { name: 'TENNESSEE', code: 'TN' },
  { name: 'TEXAS', code: 'TX' },
  { name: 'UTAH', code: 'UT' },
  { name: 'VERMONT', code: 'VT' },
  { name: 'VIRGINIA', code: 'VA' },
  { name: 'WASHINGTON', code: 'WA' },
  { name: 'WEST VIRGINIA', code: 'WV' },
  { name: 'WISCONSIN', code: 'WI' },
  { name: 'WYOMING', code: 'WY' },
];
export const getStateNameFromCode = (code: string): string => {
  if (code.length > 2) return code;
  return States_US.filter((e) => e.code === code.toUpperCase())[0].name;
};
export const getStateCodeFromName = (name: string): string => {
  const filterList = States_US.filter((e) => e.name.toUpperCase() === name.toUpperCase());
  return filterList && filterList.length > 0 ? filterList[0].code : '';
};
