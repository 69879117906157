import React, { Component } from 'react';
import { localizeType } from '../../../../commonType';
import { AvailabilityDate } from '../../../../features/offerAvailability/types';
import { getDateStamp, getWeeks } from '../../../../util/date';
import withLocalization from '../../../hoc/withLocalization';
import Week from '../Week';

interface IProps {
  month: number;
  year: number;
  previousMonth: any;
  nextMonth: any;
  selectedDates: Date[];
  premiumDates?: string[];
  datePriceInfo?: AvailabilityDate[];
  onDayClick(date: Date, dateIndex?: number): void;
  localize: localizeType;
  hasExtraNights: boolean;
  isMobile: boolean;
}

interface IState {}

class Calendar extends Component<IProps, IState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IProps) {
    super(props);
  }
  dayNames = [
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#sunday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#monday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#tuesday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#wednesday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#thursday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#friday'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.days-of-week#saturday'),
  ];
  monthNames = [
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jan'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#feb'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#mar'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#apr'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#may'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jun'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jul'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#aug'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#sept'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#oct'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#nov'),
    this.props.localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#dec'),
  ];
  render() {
    return (
      <div className="calendar">
        <div className="calendar-nav-bar">
          {this.props.previousMonth()}
          <div className="calendar-month">
            {this.monthNames[this.props.month]} {this.props.year}
          </div>
          {this.props.nextMonth()}
        </div>

        <table>
          <tbody>
            <tr>
              {this.dayNames.map((day: string) => (
                <th key={`${day}${Math.random()}`}>
                  <div className="day-heading upperCase">{day}</div>
                </th>
              ))}
            </tr>
            {getWeeks(this.props.year, this.props.month).map((week: Date[]) => (
              <Week
                key={'week-' + getDateStamp(week[0])}
                weekDays={week}
                selectedDates={this.props.selectedDates}
                onDayClick={this.props.onDayClick}
                premiumDates={this.props.premiumDates}
                datePriceInfo={this.props.datePriceInfo}
                hasExtraNights={this.props.hasExtraNights}
                isMobile={this.props.isMobile}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withLocalization(Calendar);
