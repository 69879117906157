import React, { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MARRIOTT, SHERATON, URL_MARRIOTT, URL_SHERATON, URL_WESTIN, WESTIN } from '../../util';
import marriottDesktop from './Brands/mvc/logo/mvc-desktop.png';
import marriottMobile from './Brands/mvc/logo/mvc-mobile.png';
import sheratonDesktop from './Brands/svc/logo/svc-desktop.png';
import sheratonMobile from './Brands/svc/logo/svc-mobile.png';
import westinDesktop from './Brands/wvc/logo/wvc-desktop.png';
import westinMobile from './Brands/wvc/logo/wvc-mobile.png';

interface BrandLogoType {
  currentBrand?: string;
  className: string;
}
const BrandLogo = (props: BrandLogoType): ReactElement => {
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  const getLogo = () => {
    let logo;
    switch (props.currentBrand) {
      case MARRIOTT:
        logo = isMobile ? marriottMobile : marriottDesktop;
        break;
      case WESTIN:
        logo = isMobile ? westinMobile : westinDesktop;
        break;
      case SHERATON:
        logo = isMobile ? sheratonMobile : sheratonDesktop;
        break;
    }
    return logo;
  };
  const getAltText = () => {
    let altText = '';
    switch (props.currentBrand) {
      case MARRIOTT:
        altText = `${URL_MARRIOTT} brand logo`;
        break;
      case WESTIN:
        altText = `${URL_WESTIN} brand logo`;
        break;
      case SHERATON:
        altText = `${URL_SHERATON} brand logo`;
        break;
    }
    return altText;
  };
  /* MVC- 484 - PBE - 
    To reset the landing page based on PIN / NO PIN Flow
  */

  const getLink = () => {
    let link = '/';
    if (window.location.href.includes('verify')) {
      const params = window.location.href.split('?')[1];
      link = `/verify?${params}`;
      sessionStorage.setItem('Persist:StateSearch:Link', link);
    } else if (sessionStorage.getItem('Persist:StateSearch:Active') === 'true') {
      link = sessionStorage.getItem('Persist:StateSearch:Link') || '/';
    }
    return link;
  };
  const handleLogoClick = () => {
    sessionStorage.removeItem('persist:offerDetails');
    return true;
  };
  return (
    <div className={props.className}>
      <a href={getLink()} title={getAltText()} onClick={handleLogoClick}>
        <img src={getLogo()} alt={getAltText()} />
      </a>
    </div>
  );
};
export default BrandLogo;
