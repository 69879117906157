/* eslint-disable */

// Modules
import React, { RefObject, useRef } from "react";
// @ts-ignore
//import { LocalizationContext } from 'react-localize';

// Styles
import "./ToolTip.sass";

// Interfaces
import {Tip} from './TipInterface';

export enum Position {
  CENTER = "center", // default position
  LEFT = "left",
  RIGHT = "right"
}

type ToolTipState = {
  show: boolean;
  topRefPosition: number;
  leftRefPosition: number;
  refWidth: number;
  popupWidth: number;
  popupHeight: number;
};

export interface Props {
  id: string;
  tip: Tip[];
  role?: any
  tabIndex?:any
  children?: React.ReactNode;
  disabled?: boolean;
  dataTestId? :string;
  position?: Position;
  useRefToPosition: boolean;
};

class ToolTip extends React.Component<Props, ToolTipState> {
  constructor(props:Props) {
    super(props);
    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.showToolTip = this.showToolTip.bind(this)
    this.hideToolTip = this.hideToolTip.bind(this)
    this.state = {
      show: false,
      topRefPosition: 0,
      leftRefPosition: 0,
      refWidth: 0,
      popupWidth: 0,
      popupHeight: 0,
    };
  }

  ref = (el: any) => {
    this.setRefsPosition(el);
  };

  popupRef = (el: any) => {
    this.setPopupRefsDimensions(el);
  };

  componentDidMount() {
    if (this.props.useRefToPosition) {
      window.addEventListener("scroll", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.addEventListener("resize", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.addEventListener("mousedown", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.addEventListener("keydown", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.addEventListener("scroll", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.addEventListener("resize", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.addEventListener("mousedown", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.addEventListener("keydown", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
    }
  }

  componentWillUnmount() {
    if (this.props.useRefToPosition) {
      window.removeEventListener("scroll", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.removeEventListener("resize", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.removeEventListener("mousedown", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.removeEventListener("keydown", () => {
        this.ref = (el: any) => {
          this.setRefsPosition(el);
        };
      });
      window.removeEventListener("scroll", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.removeEventListener("resize", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.removeEventListener("mousedown", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
      window.removeEventListener("keydown", () => {
        this.popupRef = (el: any) => {
          this.setPopupRefsDimensions(el);
        };
      });
    }
  }

  setRefsPosition = (el: any) => {
    if(!el) return
    this.setState({
      topRefPosition: el.getBoundingClientRect().top,
      leftRefPosition: el.getBoundingClientRect().left,
      refWidth: el.getBoundingClientRect().width,
    })
  };

  setPopupRefsDimensions = (el: any) => {
    if(!el) return
    this.setState({
      popupWidth: el.getBoundingClientRect().width,
      popupHeight: el.getBoundingClientRect().height,
    })
  };

  showToolTip() {
      this.setState({
        show: true
      });
  }

  hideToolTip() {
    this.setState({
      show: false
    });
  }

  onEnter(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({
      show: true
    });
  }
  
  onLeave(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({
      show: false
    });
  }

  /**
   * Render the tooltip popup content
   */
  tooltipPopup(tooltipId: string) {
      // const { localize } = this.context;
      const {tip} = this.props;

      if (!tip || tip.length === 0) {
          return null;
      }

      // if jsx is defined then apply it here
      // todo: make jsx more dynamic and allow it to work with the sections below
      if (tip[0].jsx) {
        return (
          <div id={tooltipId} className="tool-tip-jsx">
            {tip[0].jsx}
          </div>
        )
      }

      if (tip.length === 1 && tip[0].label === "") {
          return (
            <p id={tooltipId}>{this.props.tip[0].desc}</p>
          );
      }

      return (
          <dl className="tool-tip-list" id={tooltipId}>
            {tip.map((tip, index) => (
              <div className="tool-tip-item" key={"tip-" + index}>
                <dt aria-label={tip.label}>{tip.label}:</dt>
                <dd aria-label={tip.desc}>{tip.desc}</dd>
              </div>
            ))}
          </dl>
      );
  }

  render() {
    // const { localize } = this.context;
    let ariaLabel = this.props.tip.map(e => e.label + ": " + e.desc).join(", ").replace('null: ', '')
    const pos = this.props.position !== undefined ? this.props.position : Position.CENTER;

    const tooltipId = `tooltip-${this.props.id}`.replace(/ /g, '-');
    let popupStyles = {};

    if (this.props.useRefToPosition) {
      popupStyles = {
        position: 'fixed',
        left: this.state.leftRefPosition,
        top: this.state.topRefPosition - (this.state.popupHeight + 10) ,
        bottom: 'unset'
      };
    }

    return (
      <div
        className="tool-tip"
        role="tooltip"
        onMouseEnter={this.onEnter}
        onMouseLeave={this.onLeave}
        data-testid={this.props.dataTestId ? this.props.dataTestId : null}
        ref={this.ref}
      >
        <div
          id={`${tooltipId}-wrapper`}
          role='button'
          onClick={() => {}}
          className="tool-tip-content"
          tabIndex={this.props.tabIndex}
          onFocus={this.showToolTip}
          onBlur={this.hideToolTip}
          data-testid={this.props.dataTestId ? `${this.props.dataTestId}-content` : null}
          arial-label={ariaLabel}
        >
          {this.props.children}
        </div>
        <div
          style={popupStyles}
          className={`tool-tip-popup ${pos.toString()} ${(this.state.show && this.props.disabled !== true) ? "show" : ""}`}
          id="tool-tip-popup-question"
          data-testid={this.props.dataTestId ? `${this.props.dataTestId}-popup` : null}
          ref={this.popupRef}
        >
          {this.tooltipPopup(tooltipId)}
        </div>
      </div>
    );
  }
}

// ToolTip.contextType = LocalizationContext;

export default ToolTip;
