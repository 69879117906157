import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
  resetBillingCheckBox,
  setAllContactInfoFields,
  setContactErrorfromContactInfo,
  setProspectCopyObjectFromContactInfo,
} from '../../features/offerSearch/offerSearchSlice';
import { RootState } from '../../redux/rootReducer';
import { States_US } from '../../util/States';
import { isEqual, validateFields } from '../../util/validation';
import withLocalization from '../hoc/withLocalization';

const ContactInformationForm: FC<any> = (props: any): ReactElement => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const dispatch = useDispatch();
  const { localize } = props;

  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const prospectCopy = offerDetails.prospectCopy;
  const prospect = offerDetails.prospectCopy ? offerDetails.prospect : '';
  let prospectStateName = prospectCopy ? prospectCopy.stateProvince : ' ';
  const hasProspectState = prospectStateName && prospectStateName.length > 0;
  if (prospect) {
    prospectStateName =
      hasProspectState && prospectStateName
        ? prospectStateName.length > 2
          ? prospectStateName
          : States_US.filter((e) => e.code === prospectStateName)[0].name
        : '';
  } else {
    prospectStateName = offerDetails.locSelectedState;
  }

  const billingInfo = offerDetails.billingInfo;
  const contactError = offerDetails.contactError;

  const [prospectObject, setProspectObject] = useState({
    firstName: {
      fieldValue: prospectCopy ? prospectCopy.firstName : '',
      error: contactError ? contactError.firstName : '',
    },
    lastName: {
      fieldValue: prospectCopy ? prospectCopy.lastName : '',
      error: contactError ? contactError.lastName : '',
    },
    spouseFirstName: {
      fieldValue: prospectCopy ? prospectCopy.spouseFirstName : '',
      error: '',
    },
    spouseLastName: {
      fieldValue: prospectCopy ? prospectCopy.spouseLastName : '',
      error: '',
    },
    addressLine1: {
      fieldValue: prospectCopy ? prospectCopy.addressLine1 : '',
      error: contactError ? contactError.addressLine1 : '',
    },
    unitNo: {
      fieldValue: prospectCopy ? prospectCopy.unitNo : '',
      error: '',
    },
    city: {
      fieldValue: prospectCopy ? prospectCopy.city : '',
      error: contactError ? contactError.city : '',
    },
    postalCode: {
      fieldValue: prospectCopy ? prospectCopy.postalCode : '',
      error: contactError ? contactError.postalCode : '',
    },
    email: {
      fieldValue: prospectCopy ? prospectCopy.email : '',
      error: contactError ? contactError.email : '',
    },
    phone: {
      fieldValue: prospectCopy ? prospectCopy.phone : '',
      error: contactError ? contactError.phone : '',
    },
    stateProvince: prospectStateName,
  });

  let isValidErrorValue =
    !!prospectObject.firstName.error ||
    !!prospectObject.lastName.error ||
    !!prospectObject.spouseFirstName.error ||
    !!prospectObject.spouseLastName.error ||
    !!prospectObject.email.error ||
    !!prospectObject.postalCode.error ||
    !!prospectObject.addressLine1.error ||
    !!prospectObject.city.error ||
    !!prospectObject.phone.error;

  let isValidFieldValue =
    !!prospectObject.firstName.fieldValue &&
    !!prospectObject.lastName.fieldValue &&
    !!prospectObject.email.fieldValue &&
    !!prospectObject.phone.fieldValue;

  // const dispatchInfoToState = () => {
  //   dispatch(
  //     setProspectCopyObjectFromContactInfo({
  //       firstName: prospectObject.firstName.fieldValue,
  //       lastName: prospectObject.lastName.fieldValue,
  //       spouseFirstName: prospectObject.spouseFirstName.fieldValue,
  //       spouseLastName: prospectObject.spouseLastName.fieldValue,
  //       email: prospectObject.email.fieldValue,
  //       phone: prospectObject.phone.fieldValue,
  //       stateProvince: prospectObject.stateProvince,
  //       addressLine1: prospectObject.addressLine1.fieldValue,
  //       unitNo: prospectObject.unitNo.fieldValue,
  //       city: prospectObject.city.fieldValue,
  //       postalCode: prospectObject.postalCode.fieldValue,
  //     }),
  //   );
  //   dispatch(
  //     setContactErrorfromContactInfo({
  //       firstName: prospectObject.firstName.error,
  //       lastName: prospectObject.lastName.error,
  //       email: prospectObject.email.error,
  //       phone: prospectObject.phone.error,
  //       addressLine1: prospectObject.addressLine1.error,
  //       city: prospectObject.city.error,
  //       postalCode: prospectObject.postalCode.error,
  //     }),
  //   );
  // };

  const handleOnChange = (target) => {
    const { id, value } = target;
    const errMsg = validateFields(id, value);
    if (errMsg) {
      setProspectObject({
        ...prospectObject,
        [id]: { ...prospectObject[id], error: errMsg, fieldValue: value },
      });
    } else {
      setProspectObject({
        ...prospectObject,
        [id]: { ...prospectObject[id], error: '', fieldValue: value },
      });
    }
  };
  const handleBlur = (target) => {
    const { id, value } = target;
    const errMsg = validateFields(id, value);
    let tempProspectObject: any = {};
    if (errMsg) {
      tempProspectObject = {
        ...prospectObject,
        [id]: { ...prospectObject[id], error: errMsg, fieldValue: value },
      };
      setProspectObject({
        ...prospectObject,
        [id]: { ...prospectObject[id], error: errMsg, fieldValue: value },
      });
    } else {
      tempProspectObject = {
        ...prospectObject,
        [id]: { ...prospectObject[id], error: '', fieldValue: value },
      };
      setProspectObject({
        ...prospectObject,
        [id]: { ...prospectObject[id], error: '', fieldValue: value },
      });
    }
    // dispatchInfoToState();
    dispatch(
      setProspectCopyObjectFromContactInfo({
        firstName: tempProspectObject.firstName.fieldValue,
        lastName: tempProspectObject.lastName.fieldValue,
        spouseFirstName: tempProspectObject.spouseFirstName.fieldValue,
        spouseLastName: tempProspectObject.spouseLastName.fieldValue,
        email: tempProspectObject.email.fieldValue,
        phone: tempProspectObject.phone.fieldValue,
        stateProvince: tempProspectObject.stateProvince,
        addressLine1: tempProspectObject.addressLine1.fieldValue,
        unitNo: tempProspectObject.unitNo.fieldValue,
        city: tempProspectObject.city.fieldValue,
        postalCode: tempProspectObject.postalCode.fieldValue,
      }),
    );
    dispatch(
      setContactErrorfromContactInfo({
        firstName: tempProspectObject.firstName.error,
        lastName: tempProspectObject.lastName.error,
        email: tempProspectObject.email.error,
        phone: tempProspectObject.phone.error,
        addressLine1: tempProspectObject.addressLine1.error,
        city: tempProspectObject.city.error,
        postalCode: tempProspectObject.postalCode.error,
      }),
    );

    if (!isEqual(tempProspectObject, billingInfo, true)) {
      dispatch(resetBillingCheckBox());
    }
  };

  useEffect(() => {
    if (isValidFieldValue && !isValidErrorValue) {
      dispatch(setAllContactInfoFields(false));
    } else {
      dispatch(setAllContactInfoFields(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectObject]);

  return (
    <>
      <div className="formWrapper">
        <h3 className="contactInfoSubHead">
          {localize('preview-sales-engine.static-content.copy.contact-information-page.header')}
        </h3>
        <form noValidate>
          <div className="form-group" id="prospectInformation">
            <label htmlFor="firstName">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.first-name')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={prospectObject.firstName.fieldValue || ''}
                disabled={prospect && prospect.firstName ? true : false}
                onChange={(e) => handleOnChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
                required
              />
              <div className="invalid-feedback-onchange">{localize(prospectObject.firstName.error)}</div>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="lastName">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.last-name')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={prospectObject.lastName.fieldValue || ''}
                disabled={prospect && prospect.lastName ? true : false}
                onChange={(e) => handleOnChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
                required
              />
              <div className="invalid-feedback-onchange">{localize(prospectObject.lastName.error)}</div>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="spouseFirstName">
              {localize('preview-sales-engine.static-content.copy.prospects.spousefirstname')}
              <input
                type="text"
                className="form-control"
                id="spouseFirstName"
                value={prospectObject.spouseFirstName.fieldValue || ''}
                disabled={prospect && prospect.spouseFirstName ? true : false}
                onChange={(e) => handleOnChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
                required
              />
              <div className="invalid-feedback-onchange">{localize(prospectObject.spouseFirstName.error)}</div>
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="spouseLastName">
              {localize('preview-sales-engine.static-content.copy.prospects.spouselastname')}
              <input
                type="text"
                className="form-control"
                id="spouseLastName"
                value={prospectObject.spouseLastName.fieldValue || ''}
                disabled={prospect && prospect.spouseLastName ? true : false}
                onChange={(e) => handleOnChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
                required
              />
              <div className="invalid-feedback-onchange">{localize(prospectObject.spouseLastName.error)}</div>
            </label>
          </div>
          <div className="row">
            <div className="form-group col-md-8">
              <label htmlFor="addressLine1">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.street-address')}
                <span className="asterisk">*</span>
                <input
                  type="text"
                  className="form-control"
                  id="addressLine1"
                  value={prospectObject.addressLine1.fieldValue || ''}
                  disabled={prospect && prospect.addressLine1 ? true : false}
                  onChange={(e) => handleOnChange(e.target)}
                  onBlur={(e) => handleBlur(e.target)}
                  maxLength={30}
                />
                <div className="invalid-feedback-onchange">{prospectObject.addressLine1.error}</div>
              </label>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="unitNo">
                {localize('preview-sales-engine.static-content.copy.prospects.unitno')}
                <input
                  type="text"
                  className="form-control"
                  id="unitNo"
                  value={prospectObject.unitNo.fieldValue || ''}
                  disabled={prospect && prospect.unitNo ? true : false}
                  onChange={(e) => handleOnChange(e.target)}
                  onBlur={(e) => handleBlur(e.target)}
                  maxLength={30}
                />
                <div className="invalid-feedback-onchange">{prospectObject.unitNo.error}</div>
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="city">
              {localize('preview-sales-engine.static-content.copy.contact-information-page.city')}
              <span className="asterisk">*</span>
              <input
                type="text"
                className="form-control"
                id="city"
                value={prospectObject.city.fieldValue || ''}
                disabled={prospect && prospect.city ? true : false}
                onChange={(e) => handleOnChange(e.target)}
                onBlur={(e) => handleBlur(e.target)}
                maxLength={30}
              />
              <div className="invalid-feedback-onchange">{prospectObject.city.error}</div>
            </label>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="State">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.state')}
                <input
                  type="text"
                  className="form-control"
                  id="State"
                  value={prospectObject.stateProvince || ''}
                  disabled={prospect && prospect.stateProvince ? true : false}
                  maxLength={30}
                  readOnly
                />
              </label>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="postalCode">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.zip-code')}
                <span className="asterisk">*</span>
                <input
                  type="text"
                  className="form-control"
                  id="postalCode"
                  value={prospectObject.postalCode.fieldValue || ''}
                  disabled={prospect && prospect.postalCode ? true : false}
                  onChange={(e) => handleOnChange(e.target)}
                  onBlur={(e) => handleBlur(e.target)}
                  maxLength={9}
                />
                <div className="invalid-feedback-onchange">{prospectObject.postalCode.error}</div>
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.email')}
                <span className="asterisk">*</span>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={prospectObject.email.fieldValue || ''}
                  disabled={prospect && prospect.email ? true : false}
                  onChange={(e) => handleOnChange(e.target)}
                  onBlur={(e) => handleBlur(e.target)}
                  maxLength={50}
                  required
                />
                <div className="invalid-feedback-onchange">{localize(prospectObject.email.error)}</div>
              </label>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.phone')}
                <span className="asterisk">*</span>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={prospectObject.phone.fieldValue || ''}
                  disabled={prospect && prospect.phone ? true : false}
                  onChange={(e) => handleOnChange(e.target)}
                  onBlur={(e) => handleBlur(e.target)}
                  maxLength={10}
                  required
                />
                <div className="invalid-feedback-onchange">{localize(prospectObject.phone.error)}</div>
              </label>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default withRouter(withLocalization(ContactInformationForm));
