/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import ErrorMessage from '../../components/common/ErrorMessage';
import ParseHtml from '../../components/common/ParseHtml';
import withBrands from '../../components/hoc/withBrands';
import withLocalization from '../../components/hoc/withLocalization';
import ViewDestination from '../../components/OfferDestination/DestinationInformation';
import FlipOffer from '../../components/OfferDestination/FlipOffer';
import ViewDestinationHeader from '../../components/OfferDestination/ViewDestinationHeader';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand, getFormattedPhoneNumberForBrands } from '../../util';
import { OFFER_CONFIRMATION_PATH, VIEW_OFFERS_PATH } from '../../util/strings';
import {
  releaseHoldProperty,
  resetADACodes,
  resetNavigationStatus,
  // eslint-disable-next-line prettier/prettier
  setLoadingStatus,
} from '../offerAvailability/offerAvailabilitySlice';
import { ReleaseHoldPropertyRequest } from '../offerAvailability/types';
import {
  resetGuestsAndState,
  resetSearchMode,
  setCurrentOffer,
  // eslint-disable-next-line prettier/prettier
  setNavigationStep,
} from '../offerSearch/offerSearchSlice';
import { Offer, OfferDetails, Prospect } from '../offerSearch/types';

const OfferDestinationContainer: FC<any> = (props: any): ReactElement => {
  const dispatch = useDispatch();
  const { localize } = props;
  const holdSessionId = useSelector((state: RootState) => state.availabilityDates.holdSessionId);
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const offerDetails = useSelector((state: RootState) => state.offerDetails);

  useEffect(() => {
    let unMount = false;
    if (confirmationNumber && confirmationNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    return () => {
      if (!unMount) unMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetSearchMode(''));
    dispatch(resetGuestsAndState());
    dispatch(setNavigationStep(NavigationSteps.OFFER_DESTINATION));
    dispatch(resetNavigationStatus());
    dispatch(resetADACodes());
    setTimeout(() => {
      dispatch(setLoadingStatus(false));
    }, 10);
  }, [dispatch]);

  useEffect(() => {
    if (holdSessionId) {
      const releaseRequest: ReleaseHoldPropertyRequest = {
        holdId: holdSessionId,
      };
      dispatch(releaseHoldProperty(releaseRequest));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdSessionId]);

  const onHandleClicked = (index) => {
    const isPin = offerDetails.isPin;
    const selectedPrimaryOffer: Offer = primaryOffer!.filter((e, ind) => index === ind)[0];
    const flowIdentifier = `${isPin ? 'Pin' : 'No Pin'} ${
      offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
    }`;
    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step2-SelectResort',
        siteSection: 'Preview Booking Engine',
        pageName: 'PBE – Select Resort',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        resortID: '[Resort ID]',
        resortName: selectedPrimaryOffer?.property?.name,
        resortCity: selectedPrimaryOffer?.property?.city,
        resortState: selectedPrimaryOffer?.property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: selectedPrimaryOffer?.property?.marshaCode,
        resortBrand: brandName,
        formLOC: offer.searchParameter,
        flowIdentifier: flowIdentifier,
        bookingStepName: 'Step 2 - Select Resort',
      },
    );

    trackAnalyticData('FindMyOffer');

    dispatch(setLoadingStatus(true));
    dispatch(setCurrentOffer(index));
    props.history.push(VIEW_OFFERS_PATH);
  };

  const offer: OfferDetails = useSelector((state: RootState) => state.offerDetails);
  const prospect: Prospect | undefined = useSelector((state: RootState) => state.offerDetails.prospect);
  const primaryOffer: Offer[] | undefined = useSelector((state: RootState) => state.offerDetails.primaryOffer);
  const flipOffers: Offer[] | undefined = useSelector((state: RootState) => state.offerDetails.flipOffers);
  const hasErrors: boolean = useSelector((state: RootState) => state.offerDetails.hasError);
  const errorObj = useSelector((state: RootState) => state.offerDetails.error);
  const error = hasErrors ? <ErrorMessage errorObject={errorObj}></ErrorMessage> : <></>;
  const allPrimaryOffersExcluded =
    primaryOffer && primaryOffer.filter((x) => x.includeOffer === false).length === primaryOffer.length ? true : false;
  const allFlipOffresExcluded =
    flipOffers && flipOffers.filter((x) => x.includeOffer === false).length === flipOffers.length ? true : false;
  const hasPrimaryOffers = primaryOffer && primaryOffer.length > 0;
  const phoneNumber =
    prospect && prospect.callCenterNumber
      ? prospect.callCenterNumber
      : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  let noPrimaryOfferMessage = props.localize('preview-sales-engine.static-content.copy.prospects.no-primary-offer', [
    phoneNumber,
    getFormattedPhoneNumberForBrands(phoneNumber, props.brand),
  ]);

  return (
    <>
      <div className="container">
        {error}
        <ViewDestinationHeader />
        {!hasPrimaryOffers && (
          <Alert variant="warning">
            <ParseHtml htmlInput={noPrimaryOfferMessage}></ParseHtml>
          </Alert>
        )}
        {primaryOffer && !allPrimaryOffersExcluded ? (
          primaryOffer.map((offer, index) => (
            <ViewDestination offer={offer} key={index} onViewOfferClicked={() => onHandleClicked(index)} />
          ))
        ) : allPrimaryOffersExcluded ? (
          <Alert variant="info">
            {localize
              ? localize(
                  'preview-sales-engine.static-content.copy.prospects.no-offers-found-for-selected-location-primary',
                )
              : ''}
          </Alert>
        ) : (
          <></>
        )}
        <FlipOffer flipOffers={flipOffers} allFlipOffresExcluded={allFlipOffresExcluded}></FlipOffer>
      </div>
    </>
  );
};
export default withLocalization(withBrands(OfferDestinationContainer));
