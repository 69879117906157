/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { localizeType } from '../../../../commonType';
import { getFormattedCurrencyValueByBrand } from '../../../../util';
import { dateArrayIncludes } from '../../../../util/date';
import withBrands from '../../../hoc/withBrands';
import withLocalization from '../../../hoc/withLocalization';
import './Day.scss';

interface IProps {
  day: Date;
  selectedDates: Date[];
  onClick(date: Date, dateIndex?: number): void;
  isOtherMoth?: boolean;
  premiumDates?: string[];
  packagePrice?: string;
  surCharge?: string;
  totalPrice?: string;
  selectable?: boolean;
  dateIndex?: number;
  localize: localizeType;
  isPremiumDate: boolean;
  hasExtraNights: boolean;
  extraNightCharge?: string;
  brand?: string;
  isMobile: boolean;
}
interface IState {}

class Day extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  getClassList(): string {
    let classList = 'date day';

    if (!this.props.isOtherMoth && dateArrayIncludes(this.props.day, this.props.selectedDates)) {
      if (this.props.selectedDates[0].valueOf() === this.props.day.valueOf()) {
        classList += ' selected-first';
      } else if (this.props.selectedDates[this.props.selectedDates.length - 1].valueOf() <= this.props.day.valueOf()) {
        classList += ' selected-last';
      } else {
        classList += ' selected';
      }
    }
    if (this.props.isPremiumDate) {
      classList += ' char';
    }
    if (this.props.isOtherMoth === true) {
      classList += ' othermonth';
    }
    if (this.props.selectable === false) {
      classList += ' othermonth';
    }
    return classList;
  }
  onClick() {
    if (this.props.selectable) {
      this.props.onClick(this.props.day, this.props.dateIndex);
    }
  }

  renderTooltip() {
    const { localize, packagePrice, surCharge, totalPrice, hasExtraNights, extraNightCharge, brand } = this.props;
    return this.props.selectable ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        transition={false}
        overlay={
          <Tooltip id="priceTooltip">
            <span className="dayTooltipContent">
              {localize(
                'preview-sales-engine.static-content.copy.calendar-page.tt-package-price',
                getFormattedCurrencyValueByBrand(packagePrice?.toString(), brand),
              )}
            </span>
            <br />
            {surCharge && surCharge !== '$0.00' ? (
              <span className="dayTooltipContent">
                {localize(
                  'preview-sales-engine.static-content.copy.prospects.surcharge-price',
                  getFormattedCurrencyValueByBrand(surCharge, brand),
                )}
                <br />
              </span>
            ) : (
              <></>
            )}
            {hasExtraNights ? (
              <>
                {' '}
                <span className="dayTooltipContent">
                  {localize('preview-sales-engine.static-content.copy.Labels.labels#additional-night-charge')}{' '}
                  {getFormattedCurrencyValueByBrand(extraNightCharge?.toString(), brand)}
                </span>
                <br />
              </>
            ) : (
              <></>
            )}
            <span className="dayTooltipContent">
              {localize(
                'preview-sales-engine.static-content.copy.calendar-page.tt-total-price',
                getFormattedCurrencyValueByBrand(totalPrice?.toString(), brand),
              )}
            </span>
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div className={this.getClassList()} ref={ref} {...triggerHandler} onClick={this.onClick}>
            <button className="date">{this.props.day.getDate()}</button>
          </div>
        )}
      </OverlayTrigger>
    ) : (
      <div className={this.getClassList()}>
        <button className="date" onClick={this.onClick} disabled>
          {this.props.day.getDate()}
        </button>
      </div>
    );
  }
  renderDateForMobile() {
    return this.props.selectable ? (
      <div className={this.getClassList()} onClick={this.onClick}>
        <button className="date">{this.props.day.getDate()}</button>
      </div>
    ) : (
      <div className={this.getClassList()}>
        <button className="date" onClick={this.onClick} disabled>
          {this.props.day.getDate()}
        </button>
      </div>
    );
  }

  render() {
    const comp = !this.props.isMobile ? this.renderTooltip() : this.renderDateForMobile();
    return (
      // eslint-disable-next-line react/jsx-no-comment-textnodes
      { ...comp }
    );
  }
}

export default withLocalization(withBrands(Day));
