import React, { FC, ReactElement } from 'react';
import { localizeType } from '../../commonType';
import { AvailabilityDate } from '../../features/offerAvailability/types';
import { getFormattedCurrencyValueByBrand } from '../../util';
import { getDateString } from '../../util/date';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import './PriceShowForMobile.scss';

interface PriceDivShowType {
  localize: localizeType;
  selectedDates: Date[];
  datePriceInfo?: AvailabilityDate[];
  hasExtraNights: boolean;
  premiumDates: string[];
  brand?: string;
}

const PriceShowForMobile: FC<PriceDivShowType> = (props: PriceDivShowType): ReactElement => {
  const { localize, selectedDates, datePriceInfo, hasExtraNights, brand } = props;

  const getPackagePriceInfo = (day: Date): any => {
    if (datePriceInfo && datePriceInfo.length > 0) {
      const priceInfo = datePriceInfo.filter((e) => e.checkInDate.split('T')[0] === getDateString(day));
      if (priceInfo && priceInfo.length > 0) {
        const { basePrice, holidaySurcharge, totalCost, highSeason, additionalNightCharge } = priceInfo[0];
        const packagePrice = getFormattedCurrencyValueByBrand(basePrice?.toString(), brand);
        const surCharge = getFormattedCurrencyValueByBrand(holidaySurcharge?.toString(), brand);
        const totalPrice = getFormattedCurrencyValueByBrand(totalCost?.toString(), brand);
        const extraNightCharge = getFormattedCurrencyValueByBrand(additionalNightCharge?.toString(), brand);
        const isPremiumDate = highSeason.toLowerCase() === 'y';
        const dateIndex = datePriceInfo.indexOf(priceInfo[0]);

        return { dateIndex, packagePrice, surCharge, selectable: true, totalPrice, isPremiumDate, extraNightCharge };
      } else
        return {
          selectable: false,
        };
    }
    return undefined;
  };

  const priceInfo = selectedDates.length > 0 ? getPackagePriceInfo(selectedDates[0]) : Date;

  return priceInfo.packagePrice && selectedDates ? (
    <div>
      <div className="col-12 priceDiv">
        <span className="dayTooltipContent">
          {localize('preview-sales-engine.static-content.copy.calendar-page.tt-package-price', priceInfo.packagePrice)}
        </span>
        <br />
        {priceInfo.surCharge && priceInfo.surCharge !== '$0' ? (
          <span className="dayTooltipContent">
            {localize('preview-sales-engine.static-content.copy.prospects.surcharge-price', priceInfo.surCharge)}
            <br />
          </span>
        ) : (
          <></>
        )}
        {hasExtraNights ? (
          <>
            {' '}
            <span className="dayTooltipContent">
              {localize('preview-sales-engine.static-content.copy.Labels.labels#additional-night-charge')}{' '}
              {priceInfo.extraNightCharge}
            </span>
            <br />
          </>
        ) : (
          <></>
        )}
        <span className="dayTooltipContent">
          {localize(
            'preview-sales-engine.static-content.copy.calendar-page.tt-total-price',
            getFormattedCurrencyValueByBrand(priceInfo.totalPrice?.toString(), brand),
          )}
        </span>
      </div>
      {priceInfo.surCharge && priceInfo.surCharge !== '$0' ? (
        <p className="footerText">
          {localize('preview-sales-engine.static-content.copy.prospects.surcharge-condition')}
        </p>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

export default withLocalization(withBrands(PriceShowForMobile));
