import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand } from '../../util';
import withLocalization from '../hoc/withLocalization';

interface CheckEligibilitySelectorType {
  enabled: boolean;
  localize: localizeType;
  onClick: () => void;
  onSelectionChange: (n: boolean) => void;
  checkAllEligibilityOptions: boolean;
}

const CheckEligibilitySelector: FC<CheckEligibilitySelectorType> = (
  props: CheckEligibilitySelectorType,
): ReactElement => {
  const { localize, enabled, onClick, onSelectionChange, checkAllEligibilityOptions } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;
  useEffect(() => {
    if (checkAllEligibilityOptions) {
      onSelectionChange(true);
    }
  }, [checkAllEligibilityOptions, onSelectionChange]);
  const onCheckInClick = (evt) => {
    let brandName = getCurrentBrand();
    logAnalyticData(
      {
        event: 'Step5-DetailsOfParticipation',
        siteSection: 'Preview Booking Engine',
        pageName: 'PBE – Details of Participation',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        resortID: '[Resort ID]',
        resortName: offerDetails.property?.name,
        resortCity: offerDetails.property?.city,
        resortState: offerDetails.property?.stateCode,
        resortCountry: '[Resort Country]',
        resortPageType: '[Resort Page Type]',
        resortMarsha: offerDetails.property?.marshaCode,
        resortBrand: brandName,
        formLOC: offerDetails.searchParameter,
        bookingStepName: 'Step 5 - Details Of Participation',
        flowIdentifier: flowIdentifier,
      },
    );
    trackAnalyticData('Next');

    if (onClick) {
      onClick();
    }
  };
  const handleInputChange = (evt) => {
    onSelectionChange(evt.target.checked);
  };

  return (
    <>
      <div className="eligibilityButton">
        <label htmlFor="terms">
          <input id="terms" type="checkbox" onClick={handleInputChange} defaultChecked={checkAllEligibilityOptions} />
          {localize('preview-sales-engine.static-content.copy.prospects.participationofferagreement')}
        </label>
        <button className="checkInButton palmBg upperCase" onClick={onCheckInClick} disabled={!enabled}>
          {localize('preview-sales-engine.static-content.copy.prospects.nexttext')}
        </button>
      </div>
    </>
  );
};

export default withRouter(withLocalization(CheckEligibilitySelector));
