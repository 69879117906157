/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import { setOpenDateSelector } from '../../features/offerAvailability/offerAvailabilitySlice';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand, getFormattedCurrencyValueByBrand } from '../../util';
import { ChevronRight } from '../common/Chevron';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';

interface DatePriceSelectorProps {
  localize: localizeType;
  onNavigateToEligibility: (n: boolean) => void;
  brand?: string;
}

const DatePriceSelector: FC<DatePriceSelectorProps> = (props: DatePriceSelectorProps): ReactElement => {
  const selectedDate = useSelector((state: RootState) => state.availabilityDates?.selectedDate);
  const room = useSelector((state: RootState) => state.offerDetails.selectedRoomType);
  const availabilityDates = useSelector((state: RootState) => state.availabilityDates);
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const flowIdentifier = `${offerDetails.isPin ? 'Pin' : 'No Pin'} ${
    offerDetails.isDatedOffer ? 'with Calendar ' : 'without Calendar'
  }`;

  const deposit = room?.depositAmount;

  const { localize, onNavigateToEligibility } = props;
  const isDisabled: boolean = selectedDate !== undefined;

  const dispatch = useDispatch();

  const onCheckInClick = (evt) => {
    let brandName = getCurrentBrand();
    logAnalyticData(
      {

        event: "Step4-CalendarCheckin",
        siteSection: "Preview Booking Engine",
        pageName: "PBE – Select Calendar",
        pageHierarchy: "",
        server: "MVC",
        website: "MVC react",
        language: "en"
      },
      {  
        resortID: "[Resort ID]",
        formLOC: offerDetails.searchParameter,
        bookingStepName: "Step 4 - Calendar Check in",
        flowIdentifier:flowIdentifier,
        checkInDate:selectedDate?.checkInDate.substr(0, 10),
        checkOutDate:selectedDate?.checkOutDate.substr(0, 10),
        accommodationsType: availabilityDates.availabilityRequest?.accomodationCode,
        acessibleSlected: availabilityDates.roomADACode,
      }
    )
    trackAnalyticData('Calendar');
    dispatch(setOpenDateSelector(false));
    onNavigateToEligibility(true);
  };
  const onChooseLaterClick = (evt) => {

    let brandName = getCurrentBrand();
    logAnalyticData(
      {

        event: "Step4-CalendarChooseLater",
        siteSection: "Preview Booking Engine",
        pageName: "PBE – Select Calendar",
        pageHierarchy: "",
        server: "MVC",
        website: "MVC react",
        language: "en"
      },
      { 
        resortID: "[Resort ID]",
        flowIdentifier:flowIdentifier,
        formLOC: offerDetails.searchParameter,
        bookingStepName: "Step 4 - Calendar Choose Later",
        accommodationsType: availabilityDates.availabilityRequest?.accomodationCode,
        acessibleSlected: availabilityDates.roomADACode,
      }
    )
    trackAnalyticData('Calendar');
    dispatch(setOpenDateSelector(true));
    onNavigateToEligibility(false);
  };

  return (
    <>
      <div className="checkinWrapper">
        <div>
          <button className="checkInButton upperCase" onClick={onCheckInClick} disabled={!isDisabled}>
            {localize('preview-sales-engine.static-content.copy.calendar-page.select-check-in-date-button')}
          </button>

          <button className="chooseLater" onClick={onChooseLaterClick}>
            {localize('preview-sales-engine.static-content.copy.calendar-page.choose-dates-later-button')}
            <span className="rightArrow">{ChevronRight()}</span>
          </button>
        </div>
        <p>
          {localize(
            'preview-sales-engine.static-content.copy.prospects.reservetodaybooklater',
            getFormattedCurrencyValueByBrand(deposit, props.brand),
          )}
        </p>
      </div>
    </>
  );
};

export default withLocalization(withBrands(DatePriceSelector));
