import React, { FC, ReactElement } from 'react';
import { Alert } from 'react-bootstrap';
import { localizeType } from '../../commonType';
import { getFormattedPhoneNumberForBrands } from '../../util';
import ParseHtml from '../common/ParseHtml';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';

interface NoAvailabilityInformationType {
  hasNoAvailability: boolean;
  preferences: string[];
  localize: localizeType;
  callCenterPhoneNumber?: string;
  brand?: string;
}
const NoAvailabilityInformation: FC<NoAvailabilityInformationType> = (
  props: NoAvailabilityInformationType,
): ReactElement => {
  const { hasNoAvailability, callCenterPhoneNumber, brand, localize } = props;
  const contactNumber = callCenterPhoneNumber
    ? callCenterPhoneNumber
    : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  let displayText = localize('preview-sales-engine.static-content.copy.prospects.resortnoavailability', [
    contactNumber,
    getFormattedPhoneNumberForBrands(contactNumber, brand),
  ]);

  return (
    <>
      {hasNoAvailability ? (
        <Alert variant="warning">
          <ParseHtml htmlInput={displayText}></ParseHtml>
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

export default withLocalization(withBrands(NoAvailabilityInformation));
