import React, { ReactElement } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { localizeType } from '../../commonType';
import withLocalization from '../../components/hoc/withLocalization';
import { TMVC_URL } from '../../util/strings';
import { MARRIOTT, WESTIN } from '../../util';
import marriottIcon from './Brands/mvc/favicon/favicon.png';
import sheratonIcon from './Brands/svc/favicon/favicon.ico';
import westinIcon from './Brands/wvc/favicon/favicon.ico';
import tmvcsIcon from './Brands/tmvcs/favicon/tmvcs-Favicon.png';

interface FavIconType {
  currentBrand: string;
  localize: localizeType;
}
const Metadata = (props: FavIconType): ReactElement => {
  const isTmvc = window.location?.origin.includes(TMVC_URL);
  const icon =
    props.currentBrand === MARRIOTT ? marriottIcon : props.currentBrand === WESTIN ? westinIcon : sheratonIcon;
  const tmvcTitle = props.localize('preview-sales-engine.static-content.copy.pbeo.pbeo-page-title');
  const getIcon = isTmvc ? tmvcsIcon : icon;

  const titlePlaceHolder =
    props.currentBrand === MARRIOTT ? 'Marriott' : props.currentBrand === WESTIN ? 'Westin' : 'Sheraton';
  const backupTitle = `Book ${titlePlaceHolder} Vacation Club Today!`;
  let title = props.localize('preview-sales-engine.static-content.copy.prospects.pagetitle');
  title = title && title.trim().length > 0 ? title : backupTitle;
  const getTitle = isTmvc ? tmvcTitle : title;
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{getTitle}</title>
          <link rel="icon" type="image/png" href={getIcon} sizes="16x16" />
        </Helmet>
      </HelmetProvider>
    </>
  );
};
export default withLocalization(Metadata);
