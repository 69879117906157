import React, { FC, ReactElement } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';

const CustomSpinner: FC<any> = (props: any): ReactElement => {
  const state = useSelector((state: RootState) => state);
  const loading =
    state.availabilityDates.isLoading ||
    state.offerDetails.loading ||
    state.siteContent.loading ||
    state.confirmation.loading;
  return (
    <>
      {loading ? (
        <Modal
          dialogClassName="modal-spinner"
          show={true}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Modal.Body>
            <div className="row d-flex justify-content-center">
              <div className="spinner-custom">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomSpinner;
