import phoneNumberFormatter from 'phone-number-formats';
import { Error, ErrorType } from '../commonType';

export const NO_OFFER: string = 'NO_OFFERS';
export const INVALID_PIN = 'INVALID_PIN';
export const OFFER_EXPIRED = 'EXPIRED_OFFERS';
export const INVALID_LOC = 'INVALID_LOC';
export const PIN_UNAVAILABLE = 'PIN_UNAVAILABILITY';
export const UNABLE_TO_CONTACT_PROVIDER = 'UNABLE_TO_CONTACT_PROVIDER';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_DECLINED = 'PAYMENT_DECLINED';
export const PAYMENT_DONE_BOOKING_FAILED = 'PAYMENT_DONE_BOOKING_FAILED';
export const HOLD_FAILED = 'HOLD_FAILED';
export const INVALID_STATE = 'INVALID_STATE';

export const CREDIT_CARD_DECLINED_GENERIC = 'CREDIT_CARD_DECLINED_GENERIC';

export const MPAS_ERROR = 'MPAS_Error';
export const IIB_ERROR = 'IIB_Error';

export const createError = (errorCode: string): Error => {
  let errorType: ErrorType = ErrorType.Error;
  let errorResourceKey = '';
  switch (errorCode) {
    case NO_OFFER:
      errorResourceKey =
        'preview-sales-engine.static-content.copy.associate-search-page.search-form-errors#nooffersfound';
      break;
    case INVALID_PIN:
      errorResourceKey = 'preview-sales-engine.static-content.copy.associate-search-page.pin-field-errors#pattern';
      break;
    case INVALID_LOC:
      errorResourceKey = 'preview-sales-engine.static-content.copy.associate-search-page.loc-field-errors#pattern';
      break;
    case OFFER_EXPIRED:
      errorResourceKey =
        'preview-sales-engine.static-content.copy.associate-search-page.search-form-errors#alloffersexpired';
      break;
    case PIN_UNAVAILABLE:
      errorResourceKey =
        'preview-sales-engine.static-content.copy.associate-search-page.search-form-errors#generalerror';
      break;
    case IIB_ERROR:
      errorResourceKey = 'preview-sales-engine.static-content.copy.Error Message.iib-error';
      break;
    case UNABLE_TO_CONTACT_PROVIDER:
      errorResourceKey = 'preview-sales-engine.static-content.copy.Labels.labels#payment-provider-error-message';
      break;
    case PAYMENT_FAILED:
      errorResourceKey = 'preview-sales-engine.static-content.copy.prospects.transaction-declined';
      break;
    case PAYMENT_DECLINED:
      errorResourceKey = 'preview-sales-engine.static-content.copy.prospects.transaction-declined';
      break;
    case PAYMENT_DONE_BOOKING_FAILED:
      errorResourceKey = 'preview-sales-engine.static-content.copy.prospects.no-confirm-dates';
      break;
    case CREDIT_CARD_DECLINED_GENERIC:
      errorResourceKey = 'preview-sales-engine.static-content.copy.Labels.error-message.credit-card-declined-generic';
      break;
    default:
      errorResourceKey =
        'preview-sales-engine.static-content.copy.associate-search-page.search-form-errors#generalerror';
      break;
  }
  return {
    errorType: errorType,
    errorMessageKey: errorResourceKey,
  };
};
export const formatNumberWithCurrencySymbol = (number: number, locale: string = 'en-US'): string => {
  if (number != null) {
    if (typeof number.toString().toLowerCase() === 'number') {
      return number.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      });
    } else {
      try {
        const tempNumber = parseFloat(number.toString());
        return tempNumber.toLocaleString(locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'USD',
        });
      } catch (e) {
        return '$0.00';
      }
    }
  }
  return '$0.00';
};
export const numberToFixedPosition = (number: number): string => {
  if (number != null) {
    if (typeof number.toString().toLowerCase() === 'number') {
      return number.toFixed(2);
    } else {
      try {
        const temp = parseFloat(number.toString());
        return temp.toFixed(2);
      } catch {
        return '0.00';
      }
    }
  }
  return '0.00';
};
export const parseJWT = () => {
  try {
    const token = sessionStorage.getItem('ID_TOKEN');
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};
export const getLoggedinUserName = () => {
  const parsedToken = parseJWT();
  if (parsedToken) {
    return parsedToken['sub'];
  } else {
    return undefined;
  }
};
export const clearStorage = () => {
  sessionStorage.removeItem('persist:availabilityDates');
  sessionStorage.removeItem('persist:offerDetails');
  sessionStorage.removeItem('persist:root');
};
export const URL_MARRIOTT = 'marriott';
export const URL_SHERATON = 'sheraton';
export const URL_WESTIN = 'westin';
export const URL_TMVCS = 'tmvcs';
export const URL_BOOK_VACATION = 'book.marriottvacationclubs.com';

export const WESTIN = 'wvc';
export const SHERATON = 'svc';
export const MARRIOTT = 'mvc';
export const TMVCS = 'tmvcs';

export const MARRIOTT_FULL_NAME = 'Marriott Vacation Club.';
export const WESTIN_FULL_NAME = 'Westin Vacation Club.';
export const SHERATON_FULL_NAME = 'Sheraton Vacation Club.';
export const TMVCS_FULL_NAME = 'tmvcs.';

export const getCurrentBrand = (): string => {
  const offer_brandName: any = new URLSearchParams(window?.location?.search)?.get('brandName')?.toLocaleLowerCase();

  const origin = offer_brandName === 'tmvcs' ? offer_brandName : window.location.origin.toLocaleLowerCase();
  const currentBrand =
    origin.indexOf(URL_BOOK_VACATION) >= 0
      ? TMVCS
      : origin.indexOf(URL_WESTIN) >= 0
      ? WESTIN
      : origin.indexOf(URL_SHERATON) >= 0
      ? SHERATON
      : origin.indexOf(URL_TMVCS) >= 0
      ? TMVCS
      : MARRIOTT;
  return currentBrand;
};
export const getFormattedPhoneNumberForBrands = (
  phoneNumber: string | undefined,
  currentBrand: string | undefined,
): string => {
  if (phoneNumber) {
    let formattedNumber = '';
    switch (currentBrand) {
      case MARRIOTT:
        {
          const formatter = new phoneNumberFormatter(phoneNumber).format({ type: 'domestic', separator: '-' });
          formattedNumber = formatter.string;
        }

        break;
      case WESTIN:
        if (phoneNumber.startsWith('1') === true) {
          const formatter = new phoneNumberFormatter(phoneNumber).format({ type: 'international', separator: '.' });
          formattedNumber = formatter.string;
          formattedNumber = formattedNumber.replace('.', ' ');
        } else {
          phoneNumberFormatter.addType('westin', '+1 XXX.XXX.XXXX');
          const formatter = new phoneNumberFormatter(phoneNumber).format({ type: 'westin' });
          formattedNumber = formatter.string;
        }

        break;
      case SHERATON:
        {
          const formatter = new phoneNumberFormatter(phoneNumber).format({ type: 'domestic', separator: ' ' });
          formattedNumber = formatter.string;
        }
        break;
    }
    return formattedNumber;
  } else {
    return '';
  }
};
export const getFormattedCurrencyValueByBrand = (
  value: string | undefined,
  currentBrand: string | undefined,
): string => {
  if (value) {
    const currencySymbol = '$';
    const hasCurrencySymbol = value.indexOf(currencySymbol) === 0;
    const amountValueString = hasCurrencySymbol ? value.substring(1) : value;
    const hasDecimalPoint = amountValueString.indexOf('.') > 0;
    const desimalPart: any = hasDecimalPoint ? Number(amountValueString.indexOf('.') + 1) : amountValueString;
    const amountValueInNumber = hasDecimalPoint
      ? desimalPart > 0
        ? Number(amountValueString)
        : Number(amountValueString.substring(amountValueString.indexOf('.')))
      : Number(amountValueString);
    switch (currentBrand) {
      case MARRIOTT:
      case WESTIN:
      case SHERATON:
        return `$${amountValueInNumber}`;

      default:
        break;
    }
  }
  return '$0';
};
