import React, { FC, ReactElement } from 'react';
import withBrands from '../../../hoc/withBrands';
import withLocalization from '../../../hoc/withLocalization';
import './TextInput.scss';
interface inputProps {
  localize?: any;
  type?: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  isError?: boolean;
  errMsg?: string;
  handleInputBlur: () => void;
  maxLength: any;
  isErrBorder?: any;
}

const TextInput: FC<any> = (props: inputProps): ReactElement => {
  const { label, value, onChange, type, isError, errMsg, handleInputBlur, maxLength, isErrBorder } = props;
  const errClc = isError && errMsg ? 'res-input-errCls' : 'res-input-cls';
  const inputCls = isErrBorder ? 'res-input-cls' : errClc;
  const isRadiusClc = isErrBorder ? 'res-input-bill-grp' : 'res-input-grp';
  const newLbl = isErrBorder ? `res-input-grp${label}` : `bill-input-grp${label}`;
  let autoLbl: any = '';

  switch (label) {
    case 'Email Address':
      autoLbl = 'email';
      break;
    case 'Street Address':
      autoLbl = 'billing street-address';
      break;
    case 'City':
      autoLbl = 'billing address-level2';
      break;
    case 'ZIP Code':
      autoLbl = 'billing postal-code';
      break;
    default:
      autoLbl = label;
      break;
  }

  //autocomplete="billing street-addess"
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <>
      <div className={isRadiusClc}>
        <div className="res-lbl-grp">{label}</div>
        <input
          type={type}
          id={newLbl}
          value={value}
          onChange={handleChange}
          onBlur={handleInputBlur}
          className={inputCls}
          maxLength={maxLength ? maxLength : null}
          aria-label={newLbl}
          autoComplete={autoLbl}
        />
        {isError && errMsg && <span className="res-inline-err">{errMsg}</span>}
      </div>
    </>
  );
};
export default React.memo(withLocalization(withBrands(TextInput)));
