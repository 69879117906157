/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import ParseHtml from '../../components/common/ParseHtml';
import withBrands from '../../components/hoc/withBrands';
import withLocalization from '../../components/hoc/withLocalization';
import BillingInformationForm from '../../components/OfferContactInformation/BillingInformationForm';
import CheckBoxController from '../../components/OfferContactInformation/CheckboxController';
import ContactInformationForm from '../../components/OfferContactInformation/ContactInformationForm';
import ContactInformationHeader from '../../components/OfferContactInformation/ContactInformationHeader';
import ContactInformationSelector from '../../components/OfferContactInformation/ContactInformationSelector';
import CybeSourceFlexForm from '../../components/OfferContactInformation/CyberSource';
import { getSupportedCards } from '../../components/OfferContactInformation/CyberSource/paymentUtil';
import TermsAndConditionSelector from '../../components/OfferContactInformation/TermsAndConditionSelector';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import PackageSummary from '../../components/OfferEligibility/PackageSummary';
import { RootState } from '../../redux/rootReducer';
import { getFormattedPhoneNumberForBrands } from '../../util';
import { OFFER_CONFIRMATION_PATH } from '../../util/strings';
import {
  resetConfirmationError,
  retrievePaymentContext,
  setflexFormSubmitHandler,
  // eslint-disable-next-line prettier/prettier
  setPaymentButtonState
} from '../offerConfirmation/offerConfirmationSlice';
import { setNavigationStep } from '../offerSearch/offerSearchSlice';

const OfferContactInformationContainer: FC<any> = (props: any): ReactElement => {
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const dispatch = useDispatch();
  const { localize, brand } = props;
  const paymentContextId = useSelector((state: RootState) => state.confirmation.paymentContextId);
  const hasError = useSelector((state: RootState) => state.confirmation.hasPaymentContextError);

  let callcenterNumber = useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber);
  callcenterNumber =
    callcenterNumber && callcenterNumber.length > 0
      ? callcenterNumber
      : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const formattedPhoneNumber = getFormattedPhoneNumberForBrands(callcenterNumber, brand);
  const errorMessage = localize('preview-sales-engine.static-content.copy.prospects.bookingsystemdown', [
    callcenterNumber,
    formattedPhoneNumber,
  ]);
  const cardStyle = {
    input: {
      'font-size': '16px',
      color: '#5f5f5f',
    },
    ':focus': { color: 'blue' },
    ':disabled': { cursor: 'not-allowed' },
    valid: { color: '#000' },
    invalid: { color: '#a94442' },
  };
  const isMobile = useMediaQuery({ query: `(max-width: 650px)` });

  useEffect(() => {
    let unMount = false;
    if (confirmationNumber && confirmationNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    return () => {
      if (!unMount) unMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    //dispatch(resetConfirmationNumber());
    dispatch(setNavigationStep(NavigationSteps.OFFER_CONTACT_INFO));
    dispatch(resetConfirmationError());
  }, [dispatch]);


  useEffect(() => {
    if ((!paymentContextId || paymentContextId.length === 0) && !hasError) {
      dispatch(
        retrievePaymentContext(
          process.env.REACT_APP_MERCHANT_GROUPID ? process.env.REACT_APP_MERCHANT_GROUPID : '',
          window.location.origin,
        ),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentContextId]);
  return (
    <>
      <div className="container">
        {!isMobile ? <ContactInformationHeader /> : <></>}
        {hasError && (
          <Alert variant="danger">
            <ParseHtml htmlInput={errorMessage}></ParseHtml>
          </Alert>
        )}
        <div className="row">
          <div className="col-sm-8">
            <ContactInformationForm />
            <CheckBoxController />
            <BillingInformationForm />
            {paymentContextId && paymentContextId.length > 0 ? (
              <CybeSourceFlexForm
                context={paymentContextId ? paymentContextId : ''}
                style={cardStyle}
                cardPlaceHolder={''}
                mvwcMerchantGroupAcceptableCard={getSupportedCards()}
                showCVV={true}
                cvvPlaceHolder={''}
                emptyCreditCardMessage={localize(
                  'preview-sales-engine.static-content.copy.Labels.labels#credit-card-number-error-message',
                )}
                invalidCreditCardMessage={localize(
                  'preview-sales-engine.static-content.copy.Labels.labels#credit-card-invalid',
                )}
                invalidCvvMessage={localize('preview-sales-engine.static-content.copy.Labels.labels#cvv-error-message')}
                invalidMonthYearMessage={localize(
                  'preview-sales-engine.static-content.copy.Labels.labels#month-year-combo-error-message',
                )}
                isFormValid={(isValid) => {
                  dispatch(setPaymentButtonState(isValid));
                }}
                formObjectCallback={(obj) => {
                  dispatch(setflexFormSubmitHandler(obj));
                }}
              ></CybeSourceFlexForm>
            ) : (
              <></>
            )}
          </div>
          <div className="col-sm-4">
            <PackageSummary />
          </div>
        </div>
        <TermsAndConditionSelector />
        <ContactInformationSelector />
      </div>
    </>
  );
};
export default React.memo(withRouter(withLocalization(withBrands(OfferContactInformationContainer))));
