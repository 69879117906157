/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/first */
//declare var truste: any;
import React, { FC, ReactElement } from 'react';
import withLocalization from '../../hoc/withLocalization';

const Footer: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const content = localize(
    'preview-sales-engine.static-content.copy.prospects.legal-footer-new',
    new Date().getFullYear(),
  );

  const footerContent = { __html: content };
  return (
    <React.Fragment>
      <>
        <hr></hr>
        <footer className="footerWrapper" dangerouslySetInnerHTML={footerContent}></footer>
        <div id="consent_blackbar"></div>
      </>
    </React.Fragment>
  );
};

export default React.memo(withLocalization(Footer));
