/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import { RootState } from '../../../redux/rootReducer';
import { mappedSummaryData } from '../../../util/orderSummaryMapping';
import { getMaskedCardInformationFromToken } from '../NewOfferCyberSource/paymentUtil';
import ParseHtml from '../../common/ParseHtml';
import { replacePlaceholder } from '../../../util/CommonLogic';
import './OrderSummaryDetails.scss';
import { capitalizeFirstLetter } from '../../../util/CommonLogic';

interface tripInfolProps {
  localize?: any;
  history: any;
}
const InformationSummary: FC<tripInfolProps> = (props: tripInfolProps) => {
  const { localize } = props;
  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const flexToken = useSelector((state: RootState) => state.newOfferPaymentDetails.flexFormTokenJwt);
  const cardObj = flexToken && getMaskedCardInformationFromToken(flexToken);
  const cardDigit = cardObj?.maskedCardNumber?.slice(-4) || '';
  const cardType = cardObj?.cardType;
  const cardInfo = `${cardType} ending in ${cardDigit}`;
  const orderInfo: any = mappedSummaryData(contactInfo, newOfferData);
  /*const PhoneNo =
    (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) ||
    localize('preview-sales-engine.static-content.copy.pbeo.default-loc-phone');*/
  const PhoneNo = localize('preview-sales-engine.static-content.copy.pbeo.default-activation-phone');
  const datesContent = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-travel-dates-label');
  const updatedContent = replacePlaceholder(datesContent, '{months}', `${orderInfo?.dates}`);
  const bookContent = localize('preview-sales-engine.static-content.copy.pbeo.summary-dates-call-to-confirm');
  const maxguestContent = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-max-guests-label');
  const updatedMaxGuest = replacePlaceholder(maxguestContent, '{guests}', `${orderInfo?.maxGuest}`);
  const refineBookContent = replacePlaceholder(bookContent, '<p>', '');
  const finalContent = replacePlaceholder(refineBookContent, '</p>', '');
  const splitContent = finalContent?.split('{0}') || [];
  const callText = splitContent[0] || '';
  const travelText = (splitContent[1] && splitContent[1]?.trim()) || '';

  const phText = ` <a class="tripInfoDate-phone-wrapper" href={'tel:${PhoneNo}'}>${PhoneNo}</a>`;
  const tempData = replacePlaceholder(bookContent, '{0}', phText);
  const accommodationText = orderInfo?.accommodation || '';
  const updatedAccomodation = capitalizeFirstLetter(accommodationText);
  const stayText = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-days-nights-label');
  const updatedDays = replacePlaceholder(stayText, '{days}', orderInfo?.days);
  const updatedStay = replacePlaceholder(updatedDays, '{nights}', orderInfo?.nights);
  return (
    <>
      <div className="ord-sum-trip-info-section">
        <span className="tripInfo-title">
          {localize('preview-sales-engine.static-content.copy.pbeo.trip-information-label')}
        </span>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.summary-confirmation-number-label')}
          </span>
          <span className="tripInfo-content">{orderInfo?.locator}</span>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-location-label')}
          </span>
          <span className="tripInfo-content">{orderInfo?.location}</span>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head"> {localize('preview-sales-engine.static-content.copy.pbeo.stay-label')}</span>
          <span className="tripInfo-content">{updatedStay}</span>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-accommodation-label')}
          </span>
          <span className="tripInfo-content">{updatedAccomodation} </span>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.Labels.dates-label')}
          </span>
          <div className="tripInfoDate-text-container">
            <span className="tripInfo-content">
              <ParseHtml htmlInput={updatedContent}></ParseHtml>
            </span>

            <div className="tripInfoDate-phone-container">
              {/* <ParseHtml htmlInput={tempData}></ParseHtml>*/}
              <div className="travelDate-text-wrapper">
                <span className="tripInfoDate-call-wrapper">{callText}</span>
              </div>
              <div className="travelDate-text-wrapper">
                <a className="tripInfoDate-phone-wrapper" href={`tel:${PhoneNo}`}>
                  {PhoneNo}
                </a>
              </div>
              <div className="travelDate-text-wrapper">
                <span className="tripInfo-content">{travelText}</span>
              </div>
              {/*<span className="tripInfoDate-phone-wrapper">{PhoneNo}</span>*/}
            </div>
          </div>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">{localize('preview-sales-engine.static-content.copy.pbeo.max-guests')}</span>
          <span className="tripInfo-content">{updatedMaxGuest}</span>
        </div>
        {orderInfo?.giftOpt && (
          <div className="tripInfo-row-container">
            <span className="tripInfo-head">
              {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-gift-label')}
            </span>
            <span className="tripInfo-content">{orderInfo?.giftOpt}</span>
          </div>
        )}
      </div>
      <div className="orderSummaryDiveder"></div>
      <div className="ord-sum-payment-section">
        <span className="tripInfo-title">
          {localize('preview-sales-engine.static-content.copy.pbeo.summary-payment-section-header')}
        </span>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.summary-payment-method-label')}
          </span>
          <span className="tripInfo-content">{cardInfo}</span>
        </div>
      </div>
      <div className="orderSummaryDiveder"></div>
      <div className="ord-sum-payment-section">
        <span className="tripInfo-title">
          {localize('preview-sales-engine.static-content.copy.pbeo.summary-price-detail-label')}
        </span>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.total-due-label')}
          </span>
          <span className="summary-totalPrice-text">{orderInfo?.totalPrice}</span>
        </div>
        <div className="tripInfo-row-container">
          <span className="summary-depositPaid-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.summary-total-paid-label')}
          </span>
          <span className="summary-depositPaid-text">{orderInfo?.depositPaid}</span>
        </div>
        <div className="tripInfo-row-container">
          <span className="tripInfo-head">
            {localize('preview-sales-engine.static-content.copy.pbeo.due-at-booking-label')}
          </span>
          <span className="summary-totalPrice-text">{orderInfo?.balance}</span>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(InformationSummary));
