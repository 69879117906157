import React, { FC, ReactElement } from 'react';
import withLocalization from '../hoc/withLocalization';

const ContactInformationHeader: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  return (
    <>
      <div className="dateHeaderWrapper">
        <h3 className="headBaskerville">
          {localize('preview-sales-engine.static-content.copy.prospects.yourinformation')}
        </h3>
      </div>
    </>
  );
};
export default withLocalization(ContactInformationHeader);
