/* eslint-disable */
import { CURRENCY_CODE } from './strings';
import { deviceInfoPayload } from './deviceInformation';
export const PayerAuthPayloadRequest = (offerDetails: any, totalAmt: any, token: any, ipAddr?: any) => {
  const prospect = offerDetails.prospectCopy;
  const customerId = prospect!.lastName ? prospect!.lastName.substr(0, 20) : '';

  const selectedRoomDescription = offerDetails?.selectedRoomType?.roomTypeDescription || '';

  const paymentId = `${customerId}-${new Date().toISOString()}-${selectedRoomDescription}`;
  const billingInfo = offerDetails.billingInfo;
  const deviceInfo = deviceInfoPayload();
  deviceInfo.ipAddress = ipAddr;

  const payloadRequest: any = {
    tokenInformation: {
      jti: '',
      transientTokenJwt: token,
    },
    clientReferenceInformation: {
      code: 'WEB_PBT',
      merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID,
      paymentId: paymentId,
      referenceId: '',
    },
    orderInformation: {
      amountDetails: {
        totalAmount: totalAmt || '',
        currency: CURRENCY_CODE,
      },
      billTo: {
        address1: billingInfo.addressLine1 || '',
        address2: '',
        administrativeArea: billingInfo.stateProvince || '',
        country: 'US',
        email: billingInfo?.email || '',
        firstName: billingInfo?.firstName || '',
        lastName: billingInfo?.lastName,
        locality: billingInfo?.city || '',
        postalCode: billingInfo?.postalCode || '',
      },
    },
    deviceInformation: deviceInfo,
  };
  return payloadRequest;
};
