import React, { FC, ReactElement, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/common/ErrorMessage';
import withLocalization from '../../components/hoc/withLocalization';
import SearchPanel from '../../components/OfferSearch/Search';
import { RootState } from '../../redux/rootReducer';
import { OFFER_DESTINATION_PATH } from '../../util/strings';
import { releaseHoldProperty, resetADACodes } from '../offerAvailability/offerAvailabilitySlice';
import { ReleaseHoldPropertyRequest } from '../offerAvailability/types';
import {
  resetBonvoyValue,
  resetConfirmationNumber,
  resetErrorStatus,
  resetIIValue,
  resetPartnerCode,
  resetPartnerId,
  setPaymentButtonState,
  // eslint-disable-next-line prettier/prettier
  setPaymentcheckBox,
} from '../offerConfirmation/offerConfirmationSlice';
import {
  loadOfferDetails,
  resetAllContactBillingInfo,
  resetCustomerSelectedStates,
  resetDatedCampaign,
  resetFlipOfferDisplayMode,
  // eslint-disable-next-line prettier/prettier
  resetOfferSearch,
} from './offerSearchSlice';
import { Prospect } from './types';
import { logAnalyticData } from '../../analytics';

let logAnalyticCalled = false;
const OfferSearch: FC<any> = (props: any): ReactElement => {
  const { localizeImages } = props;
  const { path } = localizeImages('preview-sales-engine.static-content.images.search-page-hero', 0, '4:3');
  const dispatch = useDispatch();
  const LazyProspectInfo = React.lazy(() => import('../../components/OfferSearch/ProspectInformation'));

  const LazyCTAError = React.lazy(() => import('../../components/OfferSearch/CTAContainer'));

  const state = useSelector((state: RootState) => state.offerDetails);
  const holdSessionId = useSelector((state: RootState) => state.availabilityDates.holdSessionId);
  const prospectDetails: Prospect | undefined = state?.prospect;
  const isSearchLoading: boolean = state?.loading;
  const showProspect: boolean = state?.showProspect;
  const lastName: string = prospectDetails?.lastName ? prospectDetails.lastName : '';
  const [hasErrors, setHasErrors] = useState(state.hasError);
  const [offerLoading, setofferLoading] = useState(false);
  const [showOfferLoadError, setshowOfferLoadError] = useState(false);
  const errorObj = state.error;
  const renderProspect = showProspect && !isSearchLoading;
  const showCTA: boolean = state?.showCTA!;
  const showCTAMessage: boolean = showCTA && !isSearchLoading;
  const pinValue = state.searchParameter;
  const pinId = props.match.params.pinid;

  useEffect(() => {
    if (!logAnalyticCalled) {
      logAnalyticData(
        {
          event: 'landingPage',
          siteSection: 'Preview Booking Engine',
          pageName: 'PBE - Landing Page',
          pageHierarchy: '',
          server: 'MVC',
          website: 'MVC react',
          language: 'en',
        },
        {
          flowIdentifier: 'Pin with Calendar',
        },
      );
      logAnalyticCalled = true;
    }
  }, []);

  useEffect(() => {
    dispatch(resetFlipOfferDisplayMode());
    dispatch(resetCustomerSelectedStates());
    dispatch(resetConfirmationNumber());
    dispatch(resetErrorStatus());
    dispatch(resetAllContactBillingInfo());
    dispatch(resetDatedCampaign());
    dispatch(resetADACodes());
    dispatch(resetAllContactBillingInfo());
    dispatch(setPaymentButtonState(false));
    dispatch(setPaymentcheckBox(false));
    dispatch(resetPartnerId());
    dispatch(resetPartnerCode());
    dispatch(resetBonvoyValue());
    dispatch(resetIIValue());
  }, [dispatch]);

  useEffect(() => {
    if (holdSessionId) {
      const releaseRequest: ReleaseHoldPropertyRequest = {
        holdId: holdSessionId,
      };
      dispatch(releaseHoldProperty(releaseRequest));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdSessionId]);
  useEffect(() => {
    if (offerLoading && !isSearchLoading) {
      setofferLoading(false);
      if (!hasErrors) {
        props.history.push(OFFER_DESTINATION_PATH);
      } else {
        setshowOfferLoadError(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerLoading, isSearchLoading]);

  const onShowOfferClick = () => {
    setofferLoading(true);
    dispatch(loadOfferDetails(pinValue.toUpperCase(), lastName, 'y'));
  };

  const onIncorrectInfoClick = () => {
    dispatch(resetOfferSearch());
    setHasErrors(true);
  };

  const renderSearchPanel = !renderProspect && !showCTAMessage;

  const prospect = renderProspect ? (
    <Suspense fallback={<></>}>
      <LazyProspectInfo
        prospect={prospectDetails}
        onShowOfferClick={onShowOfferClick}
        onIncorrectInfoClick={onIncorrectInfoClick}
        setHasErrors={setHasErrors}
      />
    </Suspense>
  ) : (
    <></>
  );

  const renderCTAMessage =
    hasErrors || errorObj || showCTA ? (
      <Suspense fallback={<></>}>
        <LazyCTAError />
      </Suspense>
    ) : (
      <></>
    );

  const styleBg = {
    backgroundImage: `url("${path}")`,
  };
  return (
    <>
      <div className="StickyContainer">
        <div className="PageWrapper PageWrapper-noNav">
          <section className="Login-wrapper " style={styleBg}>
            <div className="Card">
              <div>
                {showOfferLoadError && <ErrorMessage errorObject={errorObj}></ErrorMessage>}
                {renderSearchPanel && <SearchPanel pinId={pinId}></SearchPanel>}
                {prospect}
                {renderCTAMessage}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(OfferSearch));
