import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { localizeType } from '../../commonType';
import { setLoadingStatus } from '../../features/offerAvailability/offerAvailabilitySlice';
import { setFlipOfferDisplayMode } from '../../features/offerSearch/offerSearchSlice';
import { Offer } from '../../features/offerSearch/types';
import { RootState } from '../../redux/rootReducer';
import { OFFER_DESTINATION_PATH } from '../../util/strings';
import withLocalization from '../hoc/withLocalization';

interface FlipOfferPropType {
  history?: any;
  localize: localizeType;
}
const FlipOfferSelector: FC<FlipOfferPropType> = (props: FlipOfferPropType): ReactElement => {
  const { localize } = props;
  const dispatch = useDispatch();
  const flipOffers: Offer[] | undefined = useSelector((state: RootState) => state.offerDetails.flipOffers);
  const hasFlipOffers = flipOffers && flipOffers.length > 0;

  const onShowFlipOfferClicked = (e) => {
    dispatch(setLoadingStatus(true));
    dispatch(setFlipOfferDisplayMode());

    props.history.push(OFFER_DESTINATION_PATH);
  };
  return (
    <>
      {hasFlipOffers ? (
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-primary flipSelectButton" onClick={onShowFlipOfferClicked}>
              {localize('preview-sales-engine.static-content.copy.prospects.showalternateoffers')}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(withRouter(withLocalization(FlipOfferSelector)));
