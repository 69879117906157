import React, { FC, ReactElement } from 'react';
import { Alert } from 'react-bootstrap';
import { ErrorType, localizeType } from '../../../commonType';
import withLocalization from '../../hoc/withLocalization';
interface ErrorMessagePropType {
  errorObject: any;
  localize: localizeType;
}
const ErorMessage: FC<ErrorMessagePropType> = (props: ErrorMessagePropType): ReactElement => {
  const { errorObject, localize } = props;
  const errorType: ErrorType = errorObject ? errorObject.errorType : ErrorType.Error;
  const resourceKey: string = errorObject
    ? errorObject.errorMessageKey
    : 'preview-sales-engine.static-content.copy.associate-search-page.search-form-errors#generalerror';
  let messageType = '';
  switch (errorType) {
    case ErrorType.Error:
      messageType = 'danger';
      break;
    case ErrorType.Information:
      messageType = 'success';
      break;
    case ErrorType.Warning:
      messageType = 'warning';
      break;
  }
  return <Alert variant={messageType}>{localize(resourceKey)}</Alert>;
};
export default withLocalization(ErorMessage);
