import React, { Component } from 'react';
import { AvailabilityDate } from '../../../../features/offerAvailability/types';
import { formatNumberWithCurrencySymbol } from '../../../../util';
import { getDateString } from '../../../../util/date';
import withLocalization from '../../../hoc/withLocalization';
import Day from '../Day';

interface IProps {
  weekDays: Date[];
  selectedDates: Date[];
  premiumDates?: string[];
  datePriceInfo?: AvailabilityDate[];
  onDayClick(date: Date, dateIndex?: number): void;
  hasExtraNights: boolean;
  isMobile: boolean;
}

interface IState {}
class Week extends Component<IProps, IState> {
  getDateStamp(date: Date) {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  }

  getEmptyStamp() {
    return `${this.props.weekDays[0].getFullYear()}-${this.props.weekDays[0].getMonth()}`;
  }
  getPackagePriceInfo(day: Date): any {
    //const dateString: string = getDateString(day);
    const { datePriceInfo } = this.props;
    if (datePriceInfo && datePriceInfo.length > 0) {
      const priceInfo = datePriceInfo.filter((e) => e.checkInDate.split('T')[0] === getDateString(day));
      if (priceInfo && priceInfo.length > 0) {
        const { basePrice, holidaySurcharge, totalCost, highSeason, additionalNightCharge } = priceInfo[0];
        const packagePrice = basePrice; // formatNumberWithCurrencySymbol(basePrice);
        const surCharge = formatNumberWithCurrencySymbol(holidaySurcharge);
        const totalPrice = totalCost; //formatNumberWithCurrencySymbol(totalCost);
        const isPremiumDate = highSeason.toLowerCase() === 'y';
        const dateIndex = datePriceInfo.indexOf(priceInfo[0]);
        const extraNightCharge = additionalNightCharge;

        return {
          dateIndex,
          packagePrice,
          surCharge,
          selectable: true,
          totalPrice,
          isPremiumDate,
          extraNightCharge,
        };
      } else
        return {
          selectable: false,
        };
    }
    return undefined;
  }
  render() {
    let firstDay = this.props.weekDays[0].getDay();
    let lastDay = this.props.weekDays[this.props.weekDays.length - 1].getDay();
    let blankStart = firstDay;
    let blankEnd = 6 - lastDay;

    return (
      <tr>
        {Array(blankStart)
          .fill(1)
          .map((el, index: number) => (
            <td key={'empty-start-' + this.getEmptyStamp() + '-' + index}></td>
          ))}
        {this.props.weekDays.map((day: Date, index: number) => {
          const priceInfo = this.getPackagePriceInfo(day);
          return (
            <td key={'day-' + this.getDateStamp(day)}>
              <Day
                premiumDates={this.props.premiumDates}
                day={day}
                selectedDates={this.props.selectedDates}
                onClick={this.props.onDayClick}
                dateIndex={priceInfo?.dateIndex}
                selectable={priceInfo?.selectable}
                packagePrice={priceInfo?.packagePrice}
                surCharge={priceInfo?.surCharge}
                totalPrice={priceInfo?.totalPrice}
                isPremiumDate={priceInfo?.isPremiumDate}
                hasExtraNights={this.props.hasExtraNights}
                extraNightCharge={priceInfo?.extraNightCharge}
                isMobile={this.props.isMobile}
              />
            </td>
          );
        })}
        {new Array(blankEnd).fill(1).map((el, index: number) => (
          <td key={'empty-end-' + this.getEmptyStamp() + '-' + index}></td>
        ))}
      </tr>
    );
  }
}

export default withLocalization(Week);
