import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';

const DOPInformation: FC<any> = (props: any): ReactElement => {
  //   const { localize } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const currentOffer = offerDetails.currentOffer;
  const dopCodeDetails = currentOffer?.dopCodeDetails;
  let newCodeDetails = dopCodeDetails?.replace(
    '<p class="deck" style="margin: 0in 0in 8pt;"><strong><span style="font-size:11pt"><span style="line-height:107%"><span style="font-family:Calibri,sans-serif"><span style="font-size:12.0pt"><span style="line-height:107%"><span style="font-family:&quot;HelveticaNeueLTStd-LtCn&quot;,sans-serif"><span style="color:#484848">Details of Participation</span></span></span></span></span></span></span></strong></p>',
    '',
  );
  return (
    <>
      <div className="dopWrapper">
        <ParseHtml htmlInput={newCodeDetails} />
      </div>
    </>
  );
};
export default withLocalization(DOPInformation);
