/* eslint-disable */

import apiClient from '../../apiClient';
import { ErrorType } from '../../commonType';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import { createError, getCurrentBrand, INVALID_STATE, NO_OFFER } from '../../util';
import { Offer, OfferDetails, OfferRequest, OfferResponse, RoomType, State, StateExclusion } from './types';

import response from '../OfferStateSelect/Service/offerbyloc.json';
import { logAnalyticData, trackAnalyticData } from '../../analytics';

const OFFER_DETAILS_BY_END_POINT = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_OFFER_DETAILS_BY_LOC_API_PATH}`;

const OFFER_BY_PIN_API_END_POINT = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_OFFER_BY_PIN_API_PATH}`;

const getDistinctState = (stateList: State[]): State[] => {
  const distinctStateCodeList = Array.from(new Set(stateList.map((s) => s.stateCode)));
  const states: State[] = distinctStateCodeList.map((code) => {
    return {
      stateCode: code,
      stateName: stateList.find((e) => e.stateCode === code)?.stateName,
    };
  });
  return states;
};

export const incorrectInfoClick = async (): Promise<OfferDetails> => {
  let returnValue: OfferDetails = {
    hasError: true,
    showProspect: false,
    loading: false,
    hasOffers: false,
    currentNavigationStep: NavigationSteps.OFEFR_DETAILS,
    displayFlipOffers: false,
    isPackageSold: false,
    isPin: true,
  };

  return returnValue;
};

const fetchOfferData = async (pin: string, lastName: string, fetchOffer: string): Promise<OfferDetails> => {
  const offerRequest: OfferRequest = {
    getOfferDetails: fetchOffer,
    lastName: lastName,
    pin: pin,
  };
  let returnValue: OfferDetails = {
    hasError: false,
    loading: false,
    showProspect: false,
    hasOffers: false,
    currentNavigationStep: NavigationSteps.OFEFR_DETAILS,
    displayFlipOffers: false,
    searchParameter: '',
    showCTA: false,
    isPackageSold: false,
    isPin: true,
  };
  const primaryOfferLocationCollection: State[] = [];
  const flipOfferLocationCollection: State[] = [];
  try {
    const offerResponse = await apiClient.post<OfferRequest, OfferResponse>(OFFER_BY_PIN_API_END_POINT, offerRequest);
    const isPackageSold = offerResponse.packageSold && offerResponse.packageSold.toLocaleLowerCase() === 'y';
    if (isPackageSold) {
      returnValue.isPackageSold = true;
      returnValue.showCTA = true;
      returnValue.callCenterNumber = offerResponse.callCenterNumber !== '' ? offerResponse.callCenterNumber : '';
      return returnValue;
    }
    if (fetchOffer.toLocaleLowerCase() === 'n') {
      returnValue.callCenterNumber = offerResponse.callCenterNumber;
    }

    returnValue.searchParameter = pin;
    returnValue.prospect = offerResponse.prospect;
    if (returnValue.prospect) {
      returnValue.showProspect = true;
    }
    if (fetchOffer.toLocaleLowerCase() === 'y') {
      const offers = offerResponse.offers;
      returnValue.isDatedOffer = offers[0].showCalendar && offers[0].showCalendar === 'S' ? true : false;

      debugger;
      let brandName = getCurrentBrand();
      logAnalyticData(
        {
          event: 'Step1-LetsFindYourOffer',
          siteSection: 'Preview Booking Engine',
          pageName: 'PBE – Let’s Find Your Offer',
          pageHierarchy: '',
          server: 'MVC',
          website: 'MVC react',
          language: 'en',
        },
        {
          resortID: '[Resort ID]',
          resortName: '[Resort Name]',
          resortCity: '[Resort City]',
          resortState: '[Resort State]',
          resortCountry: '[Resort Country]',
          resortPageType: '[Resort Page Type]',
          resortMarsha: '[Resort Marsha]',
          resortBrand: brandName,
          formLOC: returnValue.searchParameter,
          brand: brandName,
          bookingStepName: 'Step 1-Let’s Find Your Offer By Pin',
          isPin: returnValue.isDatedOffer ? "Pin with Calendar " : "Pin without Calendar",
        },
      );
      trackAnalyticData('FindMyOffer');

      if (offers && offers.length > 0) {
        const primaryOffer = offers.filter((e) => !e.isFlip);
        offers.forEach((e) => {
          e.includeOffer = true;
          if (e.premiums && e.premiums.length > 0) {
            const temp: string[] = [];
            e.premiums.forEach((x) => {
              if (x !== null) {
                let premium = x.replace('<p>', '');
                premium = premium.replace('</p>', '');
                if (x.indexOf('BonvoyTM') > 0) {
                  premium = premium.replace('BonvoyTM', 'Bonvoy<sup>TM</sup>');
                }
                temp.push(premium);
              }
            });
            e.premiums = temp;
          }
          if (e.roomTypes && e.roomTypes.length > 0) {
            const rooms = e.roomTypes.filter((x) => x.sequenceNumber === 1);
            const primaryRoom = rooms && rooms.length > 0 ? rooms[0] : e.roomTypes[0];
            e.offerDays = primaryRoom.numberOfDays;
            e.offerNights = primaryRoom.numberOfNights;
            e.offerMaxGuest = primaryRoom.sleepCapacity;
            e.offerDescription = primaryRoom.roomTypeDescription;
            e.property = primaryRoom.property;
            if (e.property && e.property.stateProvince && e.property.stateProvince) {
              const stateInfo: State = {
                stateCode: e.property?.stateCode,
                stateName: e.property?.stateProvince,
              };
              e.offerLocation = stateInfo;
              if (e.isFlip) {
                flipOfferLocationCollection.push(e.offerLocation);
              } else {
                primaryOfferLocationCollection.push(e.offerLocation);
              }
            }
          }
        });
        if (primaryOffer && primaryOffer.length > 0) {
          const flipOffers: Offer[] = offers.filter((e) => e.isFlip);

          returnValue.hasOffers = true;
          returnValue.primaryOffer = primaryOffer;
          returnValue.flipOffers = flipOffers;
          const currentOffer = primaryOffer[0];
          returnValue.currentOffer = currentOffer;
          returnValue.locDescription = currentOffer.locDescription;
          returnValue.property = currentOffer.property;
          returnValue.primaryOfferLocations = getDistinctState(primaryOfferLocationCollection);
          returnValue.flipOfferLocations = getDistinctState(flipOfferLocationCollection);
        } else {
          returnValue.hasOffers = false;
        }
        returnValue.hasError = false;
      } else {
        returnValue.hasOffers = false;
        returnValue.hasError = true;
        returnValue.error = { errorType: ErrorType.Error, errorMessageKey: NO_OFFER };
      }
    }
  } catch (e: any) {
    returnValue.hasError = true;
    if (e.response && e.response.data && e.response.data.errorCode) {
      returnValue.errorCode = e.response.data.errorCode;
      returnValue.callCenterNumber = e.response.data.callCenterNumber;
      const {
        response: {
          data: { errorCode },
        },
      } = e;
      returnValue.error = createError(errorCode);
      returnValue.showCTA = true;
    } else {
      returnValue.error = createError('');
      returnValue.showCTA = true;
    }
  }
  return returnValue;
};

export const fetchOfferResponseForLoc = async (
  loc: string,
  state: string,
  phoneNumber: string,
): Promise<OfferDetails> => {
  let returnValue: OfferDetails = {
    hasError: false,
    loading: false,
    showProspect: false,
    hasOffers: false,
    currentNavigationStep: NavigationSteps.OFEFR_DETAILS,
    displayFlipOffers: false,
    searchParameter: '',
    showCTA: false,
    isPackageSold: false, //redundant
    locSelectedState: '',
    locBrandPhoneNumber: '',
    isPin: false,
  };

  const primaryOfferLocationCollection: State[] = [];
  const flipOfferLocationCollection: State[] = [];
  try {
    const URL: string = `${OFFER_DETAILS_BY_END_POINT}${loc}`;
    const offerResponse = await apiClient.get<OfferResponse>(URL);

    const offers = offerResponse.offers;
    // offers[0].isFlip = String(true); //TODO: toggle only for testing purposes
    //TODO: what is show calendar ?
    if (offers && offers.length > 0) {
      const offerCount: number = offers.length;
      let finalOffers: Offer[] = [];
      let count: number = 0;
      for (let i = 0; i < offers.length; i++) {
        const stateExclusions: StateExclusion[] = offers[i].stateExclusions;
        if (
          stateExclusions.some((val: StateExclusion) => {
            return val.stateProvinceDescription === state;
          })
        ) {
          count++;
        } else {
          finalOffers.push(offers[i]);
        }
      }

      if (count === offerCount) {
        //selected state is present in all the state exclusions, return error
        returnValue.hasOffers = false;
        returnValue.hasError = true;
        returnValue.showCTA = true;
        returnValue.error = { errorType: ErrorType.Error, errorMessageKey: INVALID_STATE };
      } else {
        /**
         * construct their offers array from our offers array
         */
        // const offer: Offer[] = finalOffers.map((offers: Offers) => {
        //     const roomTypeArray: RoomType[] = offers.roomTypes.map((roomTypes: RoomTypes) => {
        //         const roomType: RoomType = {
        //             numberOfNights: roomTypes.numberOfNights,
        //             numberOfDays: roomTypes.numberOfDays,
        //             startingCost: roomTypes.startingCost,
        //             discountPercentage: roomTypes.discountPercentage,
        //             roomTypeDescription: roomTypes.roomTypeDescription,
        //             accomodationCode: roomTypes.accomodationCode,
        //             depositAmount: roomTypes.depositAmount,
        //             sleepCapacity: roomTypes.sleepCapacity,
        //             roomTypeCode: roomTypes.roomTypeCode,
        //             sequenceNumber: roomTypes.sequenceNumber,
        //             maxAdditionalNights: Number(roomTypes.maxAdditionalNights)
        //         }

        //         return roomType;
        //     });
        //     const offer: Offer = {
        //         offerCode: offers.offerCode,
        //         isFlip: String(offers.isFlip),
        //         bookFromDate: offers.bookFromDate,
        //         bookToDate: offers.bookToDate,
        //         roomTypes: roomTypeArray,
        //         premiums: offers.premiums,
        //         stateExclusions: offers.stateExclusions,
        //         dopCodeDetails: offers.dopCodeDetails
        //     }

        //     return offer;
        // })
        returnValue.isDatedOffer = offers[0].showCalendar && offers[0].showCalendar === 'S' ? true : false;
        const primaryOffer = finalOffers.filter((e) => {
          return !Boolean(JSON.parse(e.isFlip));
        });
        finalOffers.forEach((e) => {
          e.includeOffer = true; //TODO: what is include offer ???
          if (e.premiums && e.premiums.length > 0) {
            const temp: string[] = [];
            e.premiums.forEach((x) => {
              if (x !== null) {
                let premium = x.replace('<p>', '');
                premium = premium.replace('</p>', '');
                if (x.indexOf('BonvoyTM') > 0) {
                  premium = premium.replace('BonvoyTM', 'Bonvoy<sup>TM</sup>');
                }
                temp.push(premium);
              }
            });
            e.premiums = temp;
          }
          if (e.roomTypes && e.roomTypes.length > 0) {
            const rooms = e.roomTypes.filter((x) => x.sequenceNumber === 1);
            const primaryRoom = rooms && rooms.length > 0 ? rooms[0] : e.roomTypes[0];
            e.offerDays = primaryRoom.numberOfDays;
            e.offerNights = primaryRoom.numberOfNights;
            e.offerMaxGuest = primaryRoom.sleepCapacity;
            e.offerDescription = primaryRoom.roomTypeDescription;
            e.property = primaryRoom.property;
            if (e.property && e.property.stateProvince && e.property.stateCode) {
              const stateInfo: State = {
                stateCode: e.property?.stateCode,
                stateName: e.property?.stateProvince,
              };
              e.offerLocation = stateInfo;
              if (e.isFlip) {
                flipOfferLocationCollection.push(e.offerLocation);
              } else {
                primaryOfferLocationCollection.push(e.offerLocation);
              }
            }
          }
        });
        if (primaryOffer && primaryOffer.length > 0) {
          const flipOffers = finalOffers.filter((e) => {
            return Boolean(JSON.parse(e.isFlip));
          });

          returnValue.searchParameter = loc;
          returnValue.hasOffers = true;
          returnValue.primaryOffer = primaryOffer;
          returnValue.flipOffers = flipOffers;
          const currentOffer = primaryOffer[0];
          returnValue.currentOffer = currentOffer;
          returnValue.locDescription = currentOffer.locDescription;
          returnValue.property = currentOffer.property;
          returnValue.primaryOfferLocations = getDistinctState(primaryOfferLocationCollection);
          returnValue.flipOfferLocations = getDistinctState(flipOfferLocationCollection);
          returnValue.locSelectedState = state;
          returnValue.locBrandPhoneNumber = phoneNumber;

          let brandName = getCurrentBrand();
          logAnalyticData(
            {
              event: 'Step1-LetsFindYourOffer',
              siteSection: 'Preview Booking Engine',
              pageName: 'PBE – Let’s Find Your Offer',
              pageHierarchy: '',
              server: 'MVC',
              website: 'MVC react',
              language: 'en',
            },
            {
              resortID: '[Resort ID]',
              resortName: '[Resort Name]',
              resortCity: '[Resort City]',
              resortState: '[Resort State]',
              resortCountry: '[Resort Country]',
              resortPageType: '[Resort Page Type]',
              resortMarsha: '[Resort Marsha]',
              resortBrand: brandName,
              formLOC: returnValue.searchParameter,
              brand: brandName,
              bookingStepName: 'Step 1-Let’s Find Your Offer',
              flowIdentifier: returnValue.isDatedOffer ? "No Pin with Calendar " : "No Pin without Calendar",
            },
          );
          trackAnalyticData('FindMyOffer');
      } else {
          //no offers
          returnValue.hasOffers = false;
        }
      }
    } else {
      //no offers
      returnValue.hasOffers = false;
      returnValue.hasError = true;
      returnValue.showCTA = true;
      returnValue.error = { errorType: ErrorType.Error, errorMessageKey: INVALID_STATE };
    }
  } catch (e: any) {
    returnValue.hasError = true;
    if (e.response && e.response.data && e.response.data.errorCode) {
      returnValue.errorCode = e.response.data.errorCode;
      returnValue.callCenterNumber = e.response.data.callCenterNumber;
      const {
        response: {
          data: { errorCode },
        },
      } = e;
      returnValue.error = createError(errorCode);
      returnValue.showCTA = true;
    } else {
      returnValue.error = createError('');
      returnValue.showCTA = true;
    }
  }
  return returnValue;
};

//TODO: only for testing purposes when the offersByLoc endpoint is down
function getTestLOCData(): Offer[] {
  const responseData = response;
  // console.log(JSON.stringify(responseData));
  const offers = response.offers;
  /**
   * construct their offers array from our offers array
   */
  const offer: Offer[] = responseData.offers.map((offers: any) => {
    const roomTypeArray: RoomType[] = offers.roomTypes.map((roomTypes: any) => {
      const roomType: RoomType = {
        numberOfNights: roomTypes.numberOfNights,
        numberOfDays: roomTypes.numberOfDays,
        startingCost: roomTypes.startingCost,
        discountPercentage: roomTypes.discountPercentage,
        roomTypeDescription: roomTypes.roomTypeDescription,
        accomodationCode: roomTypes.accomodationCode,
        depositAmount: roomTypes.depositAmount,
        sleepCapacity: roomTypes.sleepCapacity,
        roomTypeCode: roomTypes.roomTypeCode,
        sequenceNumber: roomTypes.sequenceNumber,
        maxAdditionalNights: Number(roomTypes.maxAdditionalNights),
      };

      return roomType;
    });
    const offer: Offer = {
      offerCode: offers.offerCode,
      isFlip: String(offers.isFlip),
      bookFromDate: offers.bookFromDate,
      bookToDate: offers.bookToDate,
      roomTypes: roomTypeArray,
      premiums: offers.premiums,
      stateExclusions: offers.stateExclusions,
      dopCodeDetails: offers.dopCodeDetails,
    };

    return offer;
  });
  const offerResponse: Offer[] = offers.map((offer: any) => {
    const offerObject: Offer = {
      offerCode: offer.offerCode,
      isFlip: String(offer.isFlip),
      bookFromDate: offer.bookFromDate,
      bookToDate: offer.bookToDate,
      roomTypes: offer.roomTypes,
      premiums: offer.roomTypes,
      stateExclusions: offer.stateExclusions,
      dopCodeDetails: offer.dopCodeDetails,
    };
    return offerObject;
  });

  return offerResponse;
}

export default fetchOfferData;
