/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/first */
//declare var truste: any;
import React, { FC, ReactElement } from 'react';
import withLocalization from '../../../hoc/withLocalization';
import './OfferFooter.scss';
import houseLogo from '../../../../features/siteTheme/Brands/tmvcs/logo/tmvc-house.svg';
import { replaceAllPlaceholder } from '../../../../util/CommonLogic';
import ParseHtml from '../../ParseHtml';
import { useMediaQuery } from 'react-responsive';
const OfferFooter: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const path =
    'https://content-stg.vistana.com/files/live/sites/vistana-digital-content-manager/files/images/brand-logos/logos-TMVC-3-Brand-Lock-Up-mvck-201371.png';
  const footerLinkText = localize('preview-sales-engine.static-content.copy.footer.pbeo-footer-links');
  const removePipeText = replaceAllPlaceholder(footerLinkText, '|', '');
  const finalFooterLink = replaceAllPlaceholder(removePipeText, '&nbsp;', '');
  return (
    <React.Fragment>
      <>
        <footer className="mainFooterWrapper">
          <div className="logoFooterWrapper">
            <div className="ftBrandLogoWrapper">
              <img src={path} alt={'brand-logo'} className="img-fluid" />
            </div>
          </div>
          <div className="bottomFooterWrapper">
            <div className="bottomTextContentFooter">
              <p
                dangerouslySetInnerHTML={{
                  __html: localize('preview-sales-engine.static-content.copy.footer.disclaimer'),
                }}
              ></p>
            </div>
            <div className="houseFooterContainer">
              <img src={houseLogo} alt={'house-icon'} className="img-house" />
              <div className="houseTextContainer">
                <div className="upperTextContainer">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: localize('preview-sales-engine.static-content.copy.footer.timeshare-disclaimer'),
                    }}
                  ></p>
                </div>
                <div className="LowerTextContainer">
                  {isMobile ? (
                    <>
                      <ParseHtml htmlInput={finalFooterLink}></ParseHtml>
                    </>
                  ) : (
                    <>
                      <ParseHtml
                        htmlInput={localize('preview-sales-engine.static-content.copy.footer.pbeo-footer-links')}
                      ></ParseHtml>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    </React.Fragment>
  );
};

export default React.memo(withLocalization(OfferFooter));
