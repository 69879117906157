import React, { FC, ReactElement, useState } from 'react';
import TestOfferDetails from './TestOfferDetails';
import './TestDetailsStyle.scss';
import withLocalization from '../../components/hoc/withLocalization';
import { withRouter } from 'react-router-dom';
import staticOfferData from '../../features/siteContent/newOfferData.json';
interface TestInputProps {
  localize?: any;
  localizeImages?: any;
  history: any;
}
const TextInput: FC<any> = (props: TestInputProps): ReactElement => {
  const { localize } = props;
  const [textInput, setTextInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [offerDetails, setOfferDetails] = useState<any>(undefined);
  const [flow, setFlow] = useState<any>('org');

  const fetchData = async () => {
    try {
      setLoading(true);
      const default_Loc = localize('preview-sales-engine.static-content.copy.pbeo.default-loc');
      const requestUrl = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_GET_OFFER_DETAILS_BY_LOC_API_PATH}${textInput}?of=true&defaultloc=${default_Loc}`;
      /*const requestUrl = 'https://qm07-gw-sdmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/offerDetailsByLOC';
      const locEndPoint = `${requestUrl}/${textInput}`;*/
      const response = await fetch(requestUrl);
      let data: any = {};
      if (!response.ok) {
        // throw new Error('Network response was not ok.');
        data = staticOfferData;
      } else {
        data = await response.json();
      }

      //const data: any = !response.ok ? staticOfferData : response.json();
      const resortName = data.offers[0]['roomTypes'][0]['property'].name;
      const stateName = data.offers[0]['roomTypes'][0]['property'].stateProvince;
      const cityName = data.offers[0]['roomTypes'][0]['property'].city;
      const numberOfNights = data.offers[0]['roomTypes'][0]['numberOfNights'];
      const numberOfDays = data.offers[0]['roomTypes'][0]['numberOfDays'];
      const startingCost = data.offers[0]['roomTypes'][0]['startingCost'];
      const sleepCapacity = data.offers[0]['roomTypes'][0]['sleepCapacity'];
      const offers = { resortName, stateName, cityName, numberOfDays, numberOfNights, sleepCapacity, startingCost };

      setOfferDetails(offers);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };
  const handleClick = () => {
    fetchData();
  };
  const handleChange = (val) => {
    setFlow(val);
  };
  const handleSelectBlur = () => {};
  return (
    <>
      <div className="test-input-main">
        <div className="test-input-wrapper">
          <input
            className="inputBoxCls"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder={localize('preview-sales-engine.static-content.copy.package-summary-component.loc-label')}
          />
          <select
            className="test-details-select-container"
            onBlur={handleSelectBlur}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="org">org:</option>
            <option value="paid">paid</option>
          </select>
          <button className="displayAPIDataBtnCls" onClick={handleClick}>
            {localize('preview-sales-engine.static-content.copy.associate-search-page.view-offers-button')}
          </button>
        </div>
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>{offerDetails && <TestOfferDetails offerData={offerDetails} loc={textInput} flow={flow} />}</>
          )}
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(TextInput));
