import React, { Component, ErrorInfo, ReactNode } from 'react';
import ParseHtml from '../ParseHtml';
import './ErrorCustom.scss';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      //@ts-ignore
      // eslint-disable-next-line no-useless-escape
      let hasParseError = false;
      let siteContent;
      let message;
      try {
        const item = sessionStorage.getItem('persist:root');
        if (item) {
          // eslint-disable-next-line no-useless-escape
          const copy = JSON.parse(item.toString().replace(/[/\*]/g, '')).siteContent;
          siteContent = JSON.parse(copy);
          message = siteContent.resources.messages[
            'preview-sales-engine.static-content.copy.Labels.generic-error-message'
          ]
            .replace('<p>You will be redirected to homepage in<p><p class="timerStyle">%s<p><p> seconds<p>', '')
            .replace('<a href="">', '<a href="/">')
            .replace('<a>', '</a>');
        } else {
          hasParseError = true;
        }
      } catch (e) {
        hasParseError = true;
      }

      return (
        <React.Fragment>
          <div className="container customError">
            {!hasParseError ? (
              <div>
                <ParseHtml htmlInput={message} />
              </div>
            ) : (
              <div>
                <h3>SORRY!!</h3>
                <h5>SOMETHING WENT WRONG</h5>
                <p>
                  The Page you are looking for might have been removed had its name changed or is temporarily
                  unavailable
                </p>
                <p>
                  Go to <a href="/">Home page</a>
                </p>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
