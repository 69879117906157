import apiClient from '../../apiClient';
import {
  getCurrentBrand,
  MARRIOTT,
  SHERATON,
  URL_MARRIOTT,
  URL_SHERATON,
  URL_WESTIN,
  WESTIN,
  TMVCS,
  URL_TMVCS,
} from '../../util';
import { Content, ContentResponse, KeyValue } from './type';

export const getQueryStringParameter = (): string => {
  const brandCode = getCurrentBrand();
  switch (brandCode) {
    case MARRIOTT:
      return URL_MARRIOTT;
    case WESTIN:
      return URL_WESTIN;
    case SHERATON:
      return URL_SHERATON;
    case TMVCS:
      return URL_TMVCS;
    default:
      return URL_MARRIOTT;
  }
};

const fetchResourceData = async (): Promise<Content> => {
  //const API_ENDPOINT = 'https://qm07-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/getStaticContent';
  //const API_ENDPOINT = 'https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/getStaticContent';
  const API_ENDPOINT = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CONTENT_API_PATH}`;
  const brandName = getQueryStringParameter();
  const apiURL = `${API_ENDPOINT}?brandName=${brandName}`;
  let response: ContentResponse;
  let contentData: KeyValue;
  try {
    response = await apiClient.get<ContentResponse>(apiURL);
    contentData = response.success
      ? response.data
      : brandName === URL_MARRIOTT
      ? (await import('./localized-content-en-marriott.json')).default.data
      : brandName === URL_SHERATON
      ? (await import('./localized-content-en-sheraton.json')).default.data
      : brandName === URL_TMVCS
      ? (await import('./localized-content-en-tmvcs.json')).default.data
      : (await import('./localized-content-en-westin.json')).default.data;
  } catch (e: any) {
    contentData =
      brandName === URL_MARRIOTT
        ? (await import('./localized-content-en-marriott.json')).default.data
        : brandName === URL_SHERATON
        ? (await import('./localized-content-en-sheraton.json')).default.data
        : brandName === URL_TMVCS
        ? (await import('./localized-content-en-tmvcs.json')).default.data
        : (await import('./localized-content-en-westin.json')).default.data;
  }

  const images = Object.keys(contentData)
    .filter(function (k) {
      return k.indexOf('preview-sales-engine.static-content.images') === 0;
    })
    .reduce(function (newData, k) {
      // @ts-ignore
      newData[k] = contentData[k];
      return newData;
    }, {});

  const copy = Object.keys(contentData)
    .filter(function (k) {
      return k.indexOf('preview-sales-engine.static-content.copy') === 0;
    })
    .reduce(function (newData, k) {
      // @ts-ignore
      newData[k] = contentData[k];
      return newData;
    }, {});

  return {
    messages: copy,
    images: images,
  };
};

export default fetchResourceData;
