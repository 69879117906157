/* eslint-disable */
const subtractDollarValues = (value1: any, value2: any) => {
  if (value1 === '' || value2 === '' || value1 === undefined || value2 === undefined) {
    return '';
  }
  const num1 = parseFloat(value1.replace('$', ''));
  const num2 = parseFloat(value2.replace('$', ''));
  const result = num1 - num2;
  const formattedResult = '$' + result.toFixed(2);
  return formattedResult;
};
const calculateMonthsBetweenDates = (dateString1: any, dateString2: any) => {
  if (dateString1 === '' || dateString2 === '' || dateString1 === undefined || dateString2 === undefined) {
    return '';
  } else {
    // Parse date strings into Date objects
    const date1: any = new Date(dateString1);
    const date2: any = new Date(dateString2);

    // Calculate the difference in milliseconds between the dates
    const diffMs = Math.abs(date2 - date1);

    // Convert the difference from milliseconds to months
    // Approximation: 1 month = 30.44 days
    const msInMonth = 30.44 * 24 * 60 * 60 * 1000;
    const diffMonths = diffMs / msInMonth;

    // use Math.round instead of Math.floor to make sure it will round any fraction bigger than or euqal 5.
    return Math.round(diffMonths);
  }
};
export const mappedSummaryData = (contactInfo: any, offerData: any) => {
  const offerDetails = (offerData?.offers && offerData?.offers[0]) || {};
  const roomType = (offerDetails?.roomTypes && offerDetails?.roomTypes[0]) || {};
  const property = roomType?.property || {};
  const propertyLocation = `${property?.address1}, ${property?.city}, ${property?.stateProvince}`;
  const orderDays = roomType?.numberOfDays || '';
  const orderNight = roomType?.numberOfNights || '';
  const orderStay = `${orderDays} days / ${orderNight} nights`;
  const gift = (offerDetails?.premiums && offerDetails?.premiums[0]) || '';
  const startingCast = roomType?.startingCost || '';
  const depositAmt = roomType?.depositAmount || '';
  const balance = subtractDollarValues(startingCast, depositAmt);
  const bookToDate = offerDetails?.bookToDate || '';
  const bookFromDate = offerDetails?.bookFromDate || '';
  const bookDate = calculateMonthsBetweenDates(bookFromDate, bookToDate);
  const summaryObj = {
    locator: contactInfo?.phoneNumber,
    location: propertyLocation,
    stay: orderStay,
    accommodation: roomType?.roomTypeDescription,
    dates: bookDate,
    maxGuest: roomType?.sleepCapacity,
    giftOpt: gift,
    totalPrice: startingCast,
    depositPaid: depositAmt,
    balance: balance,
    days: orderDays,
    nights: orderNight,
  };
  return summaryObj;
};
