class CardStorage {
  private static month: string = '';
  private static year: string = '';
  private static cardName: string = '';
  private static isCreditCardValid: boolean = false;
  private static isCVVValid: boolean = false;
  public static setCardName(value): void {
    this.cardName = value;
  }
  public static getCardName(): string {
    return this.cardName;
  }
  public static setMonth(value: any): void {
    //value = value?.trim();
    this.month = value;
  }
  public static getMonth(): string {
    return this.month;
  }
  public static setYear(value): void {
    this.year = value;
  }
  public static getYear(): string {
    return this.year;
  }
  public static setCreditCardValidity(value): void {
    this.isCreditCardValid = value;
  }
  public static setCVVValidity(value): void {
    this.isCVVValid = value;
  }
  public static isFlexFormValid(cb): void {
    const isInvalid = (val: any) => {
      // eslint-disable-next-line no-useless-escape
      const nameValidator: RegExp = new RegExp(/^(?=[a-zA-Z\\s]{1,30})(?!.*[0-9.,<>\/?`~!@#$%&*()\_=+{}\\|\^":;\[\]])/);
      return !nameValidator.test(val);
    };
    const isEmpty = this.cardName?.trim() === '' ? true : false;
    const isValidName = isInvalid(this.cardName) || isEmpty ? false : true;
    const isValid = this.isCreditCardValid && this.isCVVValid;
    let monthYearComboValid = this.isMonthYearComboValid();
    let isNameValid = this.isCardNameValid();
    cb(isValid && isValidName && monthYearComboValid && isNameValid);
  }
  public static isCardNameValid() {
    let isCardNameValid = false;
    let holderName: any = this.cardName;
    if (holderName !== '' && isNaN(holderName[0])) {
      isCardNameValid = true;
    }
    return isCardNameValid;
  }
  public static isMonthYearComboValid() {
    let monthYearComboValid = false;
    //const tempMonth = parseInt(this.month, 10);
    //const tempYear = parseInt(this.year, 10);
    const currentMonth = new Date().getMonth() + 1; // Months are zero-based in JavaScript
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of the year

    const tempMonth: any = parseInt(this.month);
    const tempYear: any = parseInt(this.year);
    //const today = new Date();
    // const currentYear = today.getFullYear();
    // const currentMonth = today.getMonth() + 1;
    /* if (tempYear > currentYear) {
      monthYearComboValid = true;
    } else {
      if (tempMonth >= currentMonth) {
        monthYearComboValid = true;
      } else {
        monthYearComboValid = false;
      }
    }*/
    if (!this.month) {
      monthYearComboValid = false;
    } else {
      if (this.month?.trim() === '' || parseInt(this.month) === 0) {
        monthYearComboValid = false;
      } else if (parseInt(tempMonth, 10) > 12) {
        monthYearComboValid = false;
      } else if (
        parseInt(tempYear, 10) < currentYear ||
        (parseInt(tempYear, 10) === currentYear && parseInt(tempMonth, 10) < currentMonth)
      ) {
        monthYearComboValid = false;
      } else {
        monthYearComboValid = true;
      }
    }
    return monthYearComboValid;
  }
  public static resetAllValues() {
    this.month = '';
    this.year = '';
    this.isCVVValid = false;
    this.isCreditCardValid = false;
  }
  private constructor() {
    /* noop */
  }
}
export default CardStorage;
