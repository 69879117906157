import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

class APIClient {
  static timeout = 15000;
  apiClient: AxiosInstance;

  constructor() {
    this.apiClient = axios.create();
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  async get<T>(url: string): Promise<T> {
    try {
      if (url && url.length > 0) {
        const resp: AxiosResponse<T> = await this.apiClient.get<string, AxiosResponse<T>>(url);
        const { data } = resp;
        return data;
      } else {
        throw Error('Missing Url');
      }
    } catch (error) {
      const err = error as AxiosError;
      return err?.response?.data;
    }
  }
  async post<T1, T2>(url: string, params: T1): Promise<T2> {
    if (url && url.length > 0) {
      const resp: AxiosResponse<T2> = await this.apiClient.post(url, params);
      const { data } = resp;
      return data;
    } else {
      throw Error('Missing Url');
    }
  }

  async delete(url: string) {
    try {
      return await this.apiClient.delete(url);
    } catch (error) {
      console.log(error);
    }
  }

  async put(url: string, params: object) {
    try {
      return await this.apiClient.put(url, params);
    } catch (error) {
      console.log(error);
    }
  }

  async patch(url: string, params: object) {
    try {
      return await this.apiClient.patch(url, params);
    } catch (error) {
      console.log(error);
    }
  }
}

export default new APIClient();
