/* eslint-disable */
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { withRouter, useLocation } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import './NewOfferContainer.scss';
import ReservePage from '../../components/NewOffer/ReservationFormPage/ReservePage';
import PackageOverview from '../../components/NewOffer/PackageOverviewPage/PackageOverview';
import OfferStep from '../../components/common/CommonOfferComponent/BreadcrumbStep/OfferStep';
import { useMediaQuery } from 'react-responsive';
import { fetchOfferDetails, setUrlDetails, reSetContactInfo } from './NewOfferSlice';
import NewOfferLoader from '../../components/common/CommonOfferComponent/NewOfferLoader/NewOfferLoader';
import { logAnalyticData } from '../../analytics';
import { truncateString } from '../../util/CommonLogic';

const NewOffer: FC<any> = (props: any): ReactElement => {
  const { history, localize } = props;
  const hasError = useSelector((state: RootState) => state.newOfferDetails.hasError);
  const isLoading = useSelector((state: RootState) => state.newOfferDetails.loading);
  const urlParams = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const contactInfo: any = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const dispatch = useDispatch();
  const currentUrl = window.location?.href || '';
  const default_Loc = localize('preview-sales-engine.static-content.copy.pbeo.default-loc');
  const default_session_time = localize(`preview-sales-engine.static-content.copy.pbeo.pbeo-abandoned-cart-timeout`);
  const receiveOfferLoc = (newOfferData?.offers && newOfferData?.offers[0]?.loc) || '';
  const title = localize('preview-sales-engine.static-content.copy.pbeo.step-1-page-title-header');
  const PhoneNo = (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) || '';
  const OfferDataObj = (newOfferData?.offers && newOfferData?.offers[0]) || '';

  const locCode: any = new URLSearchParams(window?.location?.search)?.get('loc') || '';
  const flowIdentifier: any = new URLSearchParams(window?.location?.search)?.get('med') || '';
  const destination: any = new URLSearchParams(window?.location?.search)?.get('dest') || '';
  const department: any = new URLSearchParams(window?.location?.search)?.get('dpt') || '';
  const resort: any = new URLSearchParams(window?.location?.search)?.get('resort') || '';
  const brand: any = new URLSearchParams(window?.location?.search)?.get('brand') || '';
  const getVendor: any = new URLSearchParams(window?.location?.search)?.get('vdr') || '';
  const isPaid = flowIdentifier === 'paid' ? true : false;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!receiveOfferLoc && default_Loc !== '') {
      dispatch(reSetContactInfo());
      const seachParam = location.search;
      sessionStorage.setItem('urlSearchParam', seachParam);
      sessionStorage.setItem('vendor-id', getVendor);
      sessionStorage.setItem('vendor-id', getVendor);
      const max_length = 255;
      const trunUrl = truncateString(currentUrl, max_length);
      const flowType = sessionStorage.getItem('offer-type');
      const vendorId = sessionStorage.getItem('vendor-id');
      if (vendorId === null) {
        sessionStorage.setItem('vendor-id', getVendor);
      }
      if (flowType === null) {
        sessionStorage.setItem('offer-type', flowIdentifier);
        dispatch(
          setUrlDetails({
            url: trunUrl,
            loc: locCode,
            isPaid: isPaid,
            resort: resort,
            department: department,
            destination: destination,
            flow: flowIdentifier,
            brand: brand,
          }),
        );
      }

      dispatch(fetchOfferDetails(locCode, default_Loc));
      sessionStorage.setItem('NewOfferSessionTime', default_session_time);
    }
  }, []);
  useEffect(() => {
    if (OfferDataObj) {
      const roomType = (OfferDataObj?.roomTypes && OfferDataObj?.roomTypes[0]) || {};
      const stateCode = roomType?.property?.stateCode;
      const offerCode = (newOfferData?.offers && newOfferData?.offers[0]?.offerCode) || '';
      const isPaidFlow = urlParams?.isPaid;
      const flow_data = isPaidFlow ? 'paid' : 'organic';
      const destination_data = urlParams?.destination;
      const brandName = urlParams?.brand;
      window.dataLayer.push({ ecommerce: null });
      const pushToDataLayer = () => {
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            currency: 'USD',
            value: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
            items: [
              {
                item_id: roomType?.property?.marshaCode,
                item_name: roomType?.property?.name,
                item_brand: brandName,
                item_category: destination_data,
                item_category2: locCode,
                item_category3: 'USA',
                item_category4: stateCode,
                item_category5: roomType?.property?.city,
                item_category6: contactInfo?.state || '',
                item_category7: 'Open-Dated',
                item_category8: offerCode,
                price: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
                retail_price: OfferDataObj?.retailValue?.replace(/[$,]/g, '') || '',
                package_price: roomType?.startingCost?.replace(/[$,]/g, '') || '',
                flow_identifier: flow_data,
                quantity: 1,
              },
            ],
          },
        });
      };
      pushToDataLayer();
      logAnalyticData(
        {
          event: 'PbeBeginCheckout',
          siteSection: 'MVC Optimized PBE',
          pageName: 'MVC | PBE Step1 Begin Checkout',
          pageHierarchy: '',
          server: 'MVC',
          website: 'MVC react',
          language: 'en',
        },
        {
          formLOC: receiveOfferLoc,
          destination: destination_data,
          resortCity: roomType?.property?.city,
          resortState: roomType?.property?.stateCode,
          resortCountry: 'USA',
          resortName: roomType?.property?.name,
          resortBrand: '',
          offerCode: 'Vacation Offer',
          offerType: 'Open-Dated',
          flowIdentifier: flow_data,
          retailPrice: OfferDataObj?.retailValue?.replace(/[$,]/g, '') || '',
          packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
          depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
          currency: 'USD',
          quanity: '1',
          resortMarsha: roomType?.property?.marshaCode,
          userSelectedState: contactInfo?.state || '',
          bookingStepName: 'MVC | PBE Step1 Begin Checkout',
        },
      );
    }
  }, [OfferDataObj]);

  useEffect(() => {
    if (receiveOfferLoc !== locCode && !hasError && receiveOfferLoc !== '' && locCode) {
      const newSearchParams = new URLSearchParams(new URLSearchParams(location.search));
      newSearchParams.set('loc', receiveOfferLoc);
      history.push({ search: newSearchParams.toString() });
    }
  }, [receiveOfferLoc]);
  if (hasError || !locCode) {
    history.push({
      pathname: '/error',
    });
  }
  return (
    <>
      {isLoading && <NewOfferLoader />}
      <OfferHeader phone={PhoneNo} isThankyou={false} />
      <div className="tmvc-res-main-container">
        <div className="resevre-package-container">
          {!isMobile && <OfferStep step={1} title={title} />}
          <ReservePage />
        </div>
        <PackageOverview />
        {isMobile && <OfferStep step={1} title={title} />}
      </div>
    </>
  );
};
export default withRouter(withLocalization(NewOffer));
