import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../../redux/rootReducer';
import withLocalization from '../../hoc/withLocalization';
import { isReservationFormValid } from '../../../util/offerValidation';
import { requestPayload, requestUpdateLeadPayload } from '../../../util/leadRequestMapping';
import {
  createNewOfferLeadRequest,
  updateNewOfferLeadRequest,
  setContactInformationDetails,
  setResContactErrInfo,
  setPayNowValid,
} from '../../../features/newOffer/NewOfferSlice';
import useTimer from '../../../features/newOfferPayment/UseTimer';
import { logAnalyticData } from '../../../analytics';
import './ReservePage.scss';

declare global {
  interface Window {
    dataLayer: any[];
    ONE: any;
    Cardinal: any;
  }
}
const ContinuePayment: FC<any> = (props: any): ReactElement => {
  const { startTimer } = useTimer();
  const { history, localize, isPaynowDisabled, resFormData, resError, isOpt, setIsOpenInEligible } = props;
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const offerData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const isCreatedLeadErr = useSelector((state: RootState) => state.newOfferDetails.hasLeadError);
  const urlParams = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const webUrl = urlParams?.url || '';

  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const getCreatedLeadDetail = useSelector((state: RootState) => state.newOfferDetails.newOfferLeadDetail);
  const getOfferType: any = sessionStorage.getItem('offer-type');
  const isPaid = getOfferType === 'paid' ? true : false;
  const loc = offerData?.loc || '';
  const dispatch = useDispatch();
  const isValueInArray = (value: string, excData: any): boolean => {
    return excData.some((obj: any) => obj?.stateProvinceDescription === value);
  };
  const roomType = (offerData?.roomTypes && offerData?.roomTypes[0]) || {};
  const stateCode = roomType?.property?.stateCode;
  const flowIdentifier = isPaid ? 'paid' : 'organic';

  const destination = urlParams?.destination;

  const analyliticFunc = () => {
    const brand = urlParams?.brand;
    const offerCode = offerData?.offerCode;
    window.dataLayer.push({ ecommerce: null });
    const pushToDataLayer = () => {
      window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency: 'USD',
          value: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
          items: [
            {
              item_id: roomType?.property?.marshaCode,
              item_name: roomType?.property?.name,
              item_brand: brand,
              item_category: destination,
              item_category2: loc,
              item_category3: 'USA',
              item_category4: stateCode,
              item_category5: roomType?.property?.city,
              item_category6: isPaid ? '' : resFormData?.state,
              item_category7: 'Open-Dated',
              item_category8: offerCode,
              price: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
              retail_price: offerData?.retailValue?.replace(/[$,]/g, '') || '',
              package_price: roomType?.startingCost?.replace(/[$,]/g, '') || '',
              flow_identifier: flowIdentifier,
              quantity: 1,
            },
          ],
        },
      });
    };
    pushToDataLayer();
    logAnalyticData(
      {
        event: 'PbeContinueToPayment',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC | PBE Step1 Begin Checkout',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        formLOC: loc, //
        destination: destination,
        resortCity: roomType?.property?.city,
        resortState: roomType?.property?.stateCode,
        resortCountry: 'USA',
        resortName: roomType?.property?.name,
        resortBrand: brand,
        offerCode: 'Vacation Offer',
        offerType: 'Open-Dated',
        flowIdentifier: flowIdentifier,
        retailPrice: offerData?.retailValue?.replace(/[$,]/g, '') || '',
        packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
        depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        currency: 'USD',
        quanity: '1',
        resortMarsha: roomType?.property?.marshaCode,
        userSelectedState: isPaid ? '' : resFormData?.state,
        bookingStepName: 'MVC | PBE Step1 Begin Checkout',
      },
    );
  };
  const modalAnalyliticFunc = (selState: any) => {
    const pushToDataLayer = () => {
      window.dataLayer.push({
        event: 'error_message',
        type: 'non elegible state',
        residency: selState,
        resort: roomType?.property?.name,
      });
    };
    pushToDataLayer();
    logAnalyticData(
      {
        event: 'PbeContinueToPayment',
        siteSection: '',
        pageName: '',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: 'non elegible state', //
        underSelectedState: selState, //'[Destination]',
        resortName: roomType?.property?.name,
      },
    );
  };
  const handleClick = () => {
    const isFormValid = isReservationFormValid(resError, isPaid);
    const stateExclusionsData: any = (newOfferData?.offers && newOfferData?.offers[0]?.stateExclusions) || [];
    const isExclusionState = isValueInArray(resFormData.state, stateExclusionsData);
    setIsOpenInEligible(isExclusionState);
    if (isExclusionState) {
      modalAnalyliticFunc(resFormData?.state);
    }
    if (isFormValid && !isExclusionState) {
      const saveData: any = { ...resFormData, isOpt };
      const isNotRepeat = JSON.stringify(saveData) === JSON.stringify(contactInfo);
      const leadrequestPayload = requestPayload(saveData, newOfferData, true, webUrl);
      const updateLeadCheck = !isCreatedLeadErr && getCreatedLeadDetail && !isNotRepeat;
      if (updateLeadCheck) {
        const updateLeadRequestPayload = requestUpdateLeadPayload(saveData, getCreatedLeadDetail, true, newOfferData);
        dispatch(updateNewOfferLeadRequest(updateLeadRequestPayload));
      } else {
        if (!getCreatedLeadDetail) {
          dispatch(createNewOfferLeadRequest(leadrequestPayload));
          startTimer();
        }
      }
      const getResErr: any = isPaid ? { ...resError, stateError: false } : resError;

      dispatch(setResContactErrInfo(getResErr));
      dispatch(setPayNowValid(isPaynowDisabled));

      dispatch(setContactInformationDetails(saveData));
      analyliticFunc();
      const getUrlSearchParam = sessionStorage.getItem('urlSearchParam');
      history.push('/payment' + getUrlSearchParam);
      /*  props.history.push({
        pathname: '/payment',
        search: `?loc=${loc}`,
      });*/
    }
  };

  return (
    <>
      <div className="payNowBtnContainer">
        <button
          disabled={isPaynowDisabled}
          onClick={handleClick}
          className={isPaynowDisabled ? `continuePayBtnDisable` : `continuePayBtn`}
        >
          {localize('preview-sales-engine.static-content.copy.pbeo.continue-to-payment-button-label')}
        </button>
      </div>
    </>
  );
};

export default withRouter(withLocalization(ContinuePayment));
