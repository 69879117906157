/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { getADATitle } from '../../util/Ada';
import { fullDateFormat } from '../../util/date';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';
import { NavigationSteps } from '../OfferDetails/OfferNavigation';
import OpenDatedNotice from '../OfferDetails/OpenDatedNotice';
import PackagePrice from '../OfferEligibility/PackagePriceInformation/PackagePrice';

const PackageSummaryReview: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const selectedRoom = offerDetails.selectedRoomType;
  const availabilityDates = useSelector((state: RootState) => state.availabilityDates);
  const selectedDate = availabilityDates.finalSelectedDate;
  const offer = offerDetails.currentOffer;
  const openDateSelector = availabilityDates.openDateSelector;
  const hearingADACode = availabilityDates.hearingADACode;
  const selectedADARoomCode = availabilityDates?.roomADACode ? availabilityDates.roomADACode : '';
  const extraNights = availabilityDates.finalSelectedExtraNights ? availabilityDates.finalSelectedExtraNights : 0;
  const premiums =
    offer && offer.premiums
      ? offer?.premiums
          .filter((x) => x !== null)
          .join(', ')
          .trim()
      : '';
  const isDatedOffer: boolean =
    offerDetails && offerDetails.isDatedOffer && offerDetails.isDatedOffer === true ? true : false;
  const depositAmount = useSelector((state: RootState) => state.offerDetails.selectedRoomType?.depositAmount);

  const isPin: boolean = useSelector((state: RootState) => state.offerDetails.isPin);

  const brandPhoneNumber = isPin ? useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber) :
   useSelector((state: RootState) => state.offerDetails.locBrandPhoneNumber!);
  const isOpenDatedOptionSelected: boolean = useSelector(
    (state: RootState) => state.availabilityDates.openDateSelector,
  );
  return (
    <div>
      <div className="packageSummaryReviewDisplay">
        <h1 className="confirmationHeader">
          {localize('preview-sales-engine.static-content.copy.prospects.vacationinformation')}
        </h1>
        <div className="">
          <div className="css-table">
            <div className="css-table-body">
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.package-summary-component.resort-label')}
                </div>
                <div>{selectedRoom?.property?.name}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.package-summary-component.unit-type-label')}{' '}
                </div>
                <div>{selectedRoom?.roomTypeDescription}</div>
              </div>
              {(selectedADARoomCode || hearingADACode) && !openDateSelector ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize(
                      'preview-sales-engine.static-content.copy.package-summary-component.accessibility-selections',
                    )}{' '}
                  </div>
                  <div>
                    {selectedADARoomCode ? localize(getADATitle(selectedADARoomCode)) : ''}
                    {hearingADACode && selectedADARoomCode ? ', ' : ''}
                    {hearingADACode ? localize('preview-sales-engine.static-content.copy.ADA.hearing') : ''}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!openDateSelector && selectedDate && selectedDate.checkInDate ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.package-summary-component.check-in-label')}
                  </div>
                  <div> {fullDateFormat(selectedDate.checkInDate)}</div>
                </div>
              ) : (
                <></>
              )}
              {!openDateSelector && selectedDate && selectedDate.checkOutDate ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.package-summary-component.check-out-label')}
                  </div>
                  <div>{fullDateFormat(selectedDate.checkOutDate)}</div>
                </div>
              ) : (
                <></>
              )}

              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.package-summary-component.nights-label')}{' '}
                </div>
                <div>{selectedRoom?.numberOfNights}</div>
              </div>
              {!openDateSelector && isDatedOffer && extraNights > 0 ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.Labels.labels#extra-nights')}{' '}
                  </div>
                  <div>{extraNights}</div>
                </div>
              ) : (
                <></>
              )}
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.max-guests')}
                </div>
                <div>{selectedRoom?.sleepCapacity}</div>
              </div>

              {premiums ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.prospects.plustext')}
                  </div>
                  <div>
                    <ParseHtml htmlInput={premiums}></ParseHtml>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="priceWrapper paddingTop">
            <PackagePrice />
          </div>
          <div className="noticeWrapper">
            <OpenDatedNotice
              noticeLocation={NavigationSteps.OFFER_REVIEW}
              depositAmount={depositAmount}
              showNotice={!isDatedOffer || isOpenDatedOptionSelected}
              brandPhoneNumber={brandPhoneNumber}
            ></OpenDatedNotice>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalization(PackageSummaryReview);
