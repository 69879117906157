const getDeviceInfo = () => {
  return {
    userAgent: navigator?.userAgent,
    platform: navigator?.platform,
    language: navigator?.language,
    screenWidth: window?.screen?.width,
    screenHeight: window?.screen?.height,
    screenPixelDepth: window?.screen?.pixelDepth,
  };
};
function getBrowserInfo() {
  let ua = navigator?.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

export const captureDeviceInformation = () => {
  const deviceInformation: any = {};

  const browserInfo = getBrowserInfo();

  //fetch browser name
  var browserName = browserInfo.split(' ')[0];

  deviceInformation.httpAcceptBrowserValue = browserName;
  //fetch browser version
  var browserVersion = browserInfo.split(' ')[1];

  deviceInformation.browserVersion = browserVersion;
  //fetch OS information
  let osInfo = 'Unknown OS';
  if (navigator?.appVersion?.includes('Win')) osInfo = 'Windows';
  if (navigator?.appVersion?.includes('Mac')) osInfo = 'MacOS';
  if (navigator?.appVersion?.includes('X11')) osInfo = 'UNIX';
  if (navigator?.appVersion?.includes('Linux')) osInfo = 'Linux';
  deviceInformation.osInfo = osInfo;

  //fetch device information
  var deviceType = 'Unknown Device';
  if (navigator.userAgent.match(/Android/i)) {
    deviceType = 'Android';
  } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
    deviceType = 'iOS';
  } else if (navigator.userAgent.match(/Windows/i)) {
    deviceType = 'Windows';
  } else if (navigator.userAgent.match(/Mac/i)) {
    deviceType = 'Mac';
  } else if (navigator.userAgent.match(/Linux/i)) {
    deviceType = 'Linux';
  }

  deviceInformation.deviceType = deviceType;

  //fetch screen resolution
  deviceInformation.httpBrowserScreenHeight = window?.screen?.height || 'Unknown Resolution';
  deviceInformation.httpBrowserScreenWidth = window?.screen?.width || 'Unknown Resolution';

  //fetch screen color depth
  deviceInformation.httpBrowserColorDepth = window?.screen?.colorDepth || 'Unknown Color Depth';

  //fetch screen pixel depth
  deviceInformation.screenPixelDepth = window?.screen?.pixelDepth || 'Unknown Pixel Depth';

  //fetch browser language
  deviceInformation.httpBrowserLanguage = navigator?.language || 'Unknown Language';

  //fetch browser online status
  deviceInformation.browserOnlineStatus = navigator?.onLine || 'Unknown Online Status';

  //fetch browser platform
  deviceInformation.browserPlatform = navigator?.platform || 'Unknown Platform';

  //fetch browser product
  // console.log("browserProduct: " + browserProduct);
  deviceInformation.browserProduct = navigator?.product || 'Unknown Product';

  // console.log("browserProduct: " + browserProduct);
  deviceInformation.userAgentBrowserValue = navigator?.userAgent || 'Unknown Product';

  //check if java is enabled or not
  deviceInformation.isJavaEnabled = navigator?.javaEnabled() || 'Unknown Java Status';

  //fetch browser cookie enabled status
  deviceInformation.isCookieEnabled = navigator?.cookieEnabled || 'Unknown Cookie Status';

  //fetch browser do not track status
  deviceInformation.isDoNotTrackEnabled = navigator?.doNotTrack || 'Unknown Do Not Track Status';

  //fetch browser geolocation status
  deviceInformation.isGeolocationEnabled = navigator?.geolocation || 'Unknown Geolocation Status';

  //check if javascript is enabled in the browser
  deviceInformation.httpBrowserJavaScriptEnabled =
    typeof window !== 'undefined' &&
    typeof window.navigator !== 'undefined' &&
    typeof window.navigator.javaEnabled === 'function' &&
    window.navigator.javaEnabled();

  const utcDate: any = new Date();

  let timeZoneOffsetMinutes: any = utcDate?.getTimezoneOffset();

  timeZoneOffsetMinutes = Math.abs(timeZoneOffsetMinutes);
  deviceInformation.httpBrowserTimeDifference = timeZoneOffsetMinutes;

  /*fetch('https://api.ipify.org?format=json')
    .then((response) => response.json())
    .then((data) => {
      let ipaddress: any = data?.ip || 'Unknown IP Address';
      deviceInformation.ipaddress = ipaddress;
      // console.log('device information: ' + JSON.stringify(deviceInformation));
    })
    .catch((error) => console.log(error));*/

  const deviceInfo: any = getDeviceInfo();

  //console.log(deviceInfo);
  //console.log(deviceInformation);
  return {
    deviceInfo,
    deviceInformation,
  };
};
export const deviceInfoPayload = () => {
  let contentType: any = [];
  if (navigator.mimeTypes && navigator.mimeTypes.length) {
    for (let i = 0; i < navigator.mimeTypes.length; i++) {
      let mimeType = navigator.mimeTypes[i];
      contentType.push(mimeType.type);
    }
  }

  const contentTypeStr = contentType?.join(',') || '';
  const getDiviceInformation = captureDeviceInformation();
  const javEnableVal = getDiviceInformation?.deviceInformation?.isJavaEnabled || '';
  const isJavaEnabled = javEnableVal === 'Unknown Java Status' || javEnableVal === '' ? false : true;
  const deviceInfo = {
    httpAcceptBrowserValue: getDiviceInformation?.deviceInformation?.httpAcceptBrowserValue || '',
    userAgentBrowserValue: getDiviceInformation?.deviceInformation?.userAgentBrowserValue || '',
    httpAcceptContent: contentTypeStr,
    httpBrowserColorDepth: getDiviceInformation?.deviceInformation?.httpBrowserColorDepth || '',
    httpBrowserTimeDifference: getDiviceInformation?.deviceInformation?.httpBrowserTimeDifference || '',
    //ipAddress: getDiviceInformation?.deviceInformation?.ipaddress || '',
    ipAddress: '',
    httpBrowserLanguage: getDiviceInformation?.deviceInformation?.httpBrowserLanguage || '',
    httpBrowserScreenWidth: getDiviceInformation?.deviceInformation?.httpBrowserScreenWidth || '',
    httpBrowserJavaEnabled: isJavaEnabled,
    httpBrowserJavaScriptEnabled: getDiviceInformation?.deviceInformation?.httpBrowserJavaScriptEnabled || false,
    httpBrowserScreenHeight: getDiviceInformation?.deviceInformation?.httpBrowserScreenHeight || '',
  };
  return deviceInfo;
};
