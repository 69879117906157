/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import OfferFooter from '../../components/common/CommonOfferComponent/OfferFooter/OfferFooter';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import withLocalization from '../../components/hoc/withLocalization';
import { withRouter } from 'react-router';
import ParseHtml from '../../components/common/ParseHtml';
import './ErrorPageContainer.scss';
interface TestDetailProps {
  history: any;
  localize: any;
  phone?: any;
}

const ErrorPageContainer: FC<any> = (props: TestDetailProps): ReactElement => {
  const { localize } = props;
  const contact_num = localize('preview-sales-engine.static-content.copy.pbeo.default-loc-phone');
  return (
    <>
      <OfferHeader />

      <div className="error-details-wrapper-container">
        <h3>{localize('preview-sales-engine.static-content.copy.prospects.ourapologies')}</h3>
        <p>
          <ParseHtml
            htmlInput={localize('preview-sales-engine.static-content.copy.prospects.contactvacationspecialist', [
              contact_num,
              contact_num,
            ])}
          />
        </p>
      </div>
      <br />
      <br />
      <OfferFooter />
    </>
  );
};
export default withRouter(withLocalization(ErrorPageContainer));
