import React, { FC, ReactElement, useState } from 'react';
import './TestDetailsStyle.scss';
import TextInput from './Textdetailsinput';
import OfferFooter from '../../components/common/CommonOfferComponent/OfferFooter/OfferFooter';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import withLocalization from '../../components/hoc/withLocalization';
import { withRouter } from 'react-router';
interface TestDetailProps {
  localize?: any;
  localizeImages?: any;
  history: any;
}
const TestDetails: FC<any> = (props: TestDetailProps): ReactElement => {
  const { localize } = props;
  const [isVisibleTextInput, setVisibletextInput] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleChange = (event) => {
    setButtonDisabled(event.target.value !== 'No PIN');
  };
  const onTextInputDisplay = () => {
    setVisibletextInput((isVisibleTextInput) => !isVisibleTextInput);
  };
  return (
    <>
      <OfferHeader />

      <div className="test-details-wrapper-container">
        <h2>Test Offer Landing page</h2>
        <select className="test-details-select-container" onInput={handleChange}>
          <option>{localize('preview-sales-engine.static-content.copy.Labels.labels#please-select-label')}</option>
          <option value="PIN">
            {localize('preview-sales-engine.static-content.copy.package-summary-component.pin-label')}
          </option>
          <option value="No PIN">No PIN:</option>
        </select>
        <button className="test-Details-button-container" disabled={buttonDisabled} onClick={onTextInputDisplay}>
          {localize('preview-sales-engine.static-content.copy.contact-information-page.next-button')}
        </button>
        <div>{isVisibleTextInput && <TextInput />}</div>
      </div>
      <br />
      <br />
      <OfferFooter />
    </>
  );
};
export default withRouter(withLocalization(TestDetails));
