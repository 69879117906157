/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable import/first */
declare var Flex: any;
import React, { FC, ReactElement, useEffect, useState } from 'react';
import withLocalization from '../../hoc/withLocalization';
import CardStorage from './CardStorage';
import { CardType } from './types';
interface CybeSourceFlexFormType {
  context: string;
  style: any;
  cardPlaceHolder: string;
  mvwcMerchantGroupAcceptableCard: CardType[];
  showCVV: boolean;
  cvvPlaceHolder: string;
  formObjectCallback: (n: any) => void;
  isFormValid: (n: boolean) => void;
  emptyCreditCardMessage: string;
  invalidCreditCardMessage: string;
  localize: any;
  invalidCvvMessage: string;
  invalidMonthYearMessage: string;
}
const CybeSourceFlexForm: FC<CybeSourceFlexFormType> = (props: CybeSourceFlexFormType): ReactElement => {
  const {
    context,
    style,
    cardPlaceHolder,
    mvwcMerchantGroupAcceptableCard,
    formObjectCallback,
    showCVV,
    cvvPlaceHolder,
    emptyCreditCardMessage,
    isFormValid,
    invalidCreditCardMessage,
    invalidCvvMessage,
    invalidMonthYearMessage,
    localize,
  } = props;

  const [cardError, setCardError] = useState('');
  let currentMonth = (parseInt(new Date().getMonth().toString(), 10) + 1).toString();
  currentMonth = currentMonth.length < 2 ? `0${currentMonth}` : currentMonth;
  const currentYear = new Date().getFullYear();

  const [monthYearError, setMonthErrorYear] = useState('');

  const [cvvError, setCvvError] = useState('');
  useEffect(() => {
    CardStorage.resetAllValues();

    CardStorage.setMonth(currentMonth);
    CardStorage.setYear(currentYear);
    const flex = new Flex(context);
    const microForm = flex.microform({ styles: style });
    const number = microForm.createField('number', { placeholder: cardPlaceHolder });
    let cardHasError = true;
    let cvvHasError = true;
    number.on('change', (data) => {
      if (data.card && data.card.length > 0) {
        var inputCardType: any[] = [];
        var isCardAccepted = false;
        data.card.forEach((element) => {
          if (mvwcMerchantGroupAcceptableCard.some((x) => x.cardId === element.cybsCardType)) {
            inputCardType.push(element);
          }
        });
        if (inputCardType && inputCardType.length > 0) {
          isCardAccepted = true;
          setCardError('');
        } else {
          isCardAccepted = false;
          setCardError(invalidCreditCardMessage);
        }

        if (data.card[0].valid && isCardAccepted) {
          cardHasError = false;
          setCardError('');
        } else if (!isCardAccepted) {
          cardHasError = true;
          setCardError(invalidCreditCardMessage);
        } else {
          cardHasError = true;
          setCardError(invalidCreditCardMessage);
        }
      } else if (data.empty) {
        cardHasError = true;
        setCardError(emptyCreditCardMessage);
      } else {
        cardHasError = true;
        setCardError(invalidCreditCardMessage);
      }
      CardStorage.setCreditCardValidity(!cardHasError);
      if (!showCVV) {
        CardStorage.setCVVValidity(true);
      }
      CardStorage.isFlexFormValid(isFormValid);
    });
    number.on('blur', (data) => {
      CardStorage.setCreditCardValidity(!cardHasError);
      if (cardHasError) {
        setCardError(invalidCreditCardMessage);
      }
      if (!showCVV) {
        CardStorage.setCVVValidity(true);
      }
      CardStorage.isFlexFormValid(isFormValid);
    });
    number.load('#number-container');
    const createTokenInitiator = (cb) => {
      const options = {
        expirationMonth: CardStorage.getMonth(),
        expirationYear: CardStorage.getYear().toString(),
      };
      microForm.createToken(options, cb);
    };
    if (showCVV) {
      const cvv = microForm.createField('securityCode', { placeholder: cvvPlaceHolder });
      cvv.load('#cvv-container');
      cvv.on('change', (data) => {
        if (data.valid) {
          cvvHasError = false;
          setCvvError('');
        } else {
          cvvHasError = true;
          setCvvError(invalidCvvMessage);
        }
        CardStorage.setCVVValidity(!cvvHasError);
        CardStorage.isFlexFormValid(isFormValid);
      });
      cvv.on('blur', (data) => {
        CardStorage.setCVVValidity(!cvvHasError);
        if (cvvHasError) {
          setCvvError(invalidCvvMessage);
        }
        CardStorage.isFlexFormValid(isFormValid);
      });
    }
    formObjectCallback(createTokenInitiator);
  }, [
    context,
    style,
    cardPlaceHolder,
    mvwcMerchantGroupAcceptableCard,
    formObjectCallback,
    showCVV,
    cvvPlaceHolder,
    isFormValid,
    emptyCreditCardMessage,
    invalidCreditCardMessage,
    invalidCvvMessage,
    currentMonth,
    currentYear,
  ]);

  const onMonthChange = (evt) => {
    CardStorage.setMonth(evt.target.value);
    CardStorage.isFlexFormValid(isFormValid);
    if (CardStorage.isMonthYearComboValid()) {
      setMonthErrorYear('');
    } else {
      setMonthErrorYear(invalidMonthYearMessage);
    }
  };
  const onYearChange = (evt) => {
    CardStorage.setYear(evt.target.value);
    CardStorage.isFlexFormValid(isFormValid);
    if (CardStorage.isMonthYearComboValid()) {
      setMonthErrorYear('');
    } else {
      setMonthErrorYear(invalidMonthYearMessage);
    }
  };

  return (
    <>
      {' '}
      <div className="formWrapper">
        <h1 className="contactInfoSubHead">
          {localize('preview-sales-engine.static-content.copy.Labels.labels#credit-card-information')}
        </h1>
        <div className="cardWrapper">
          <img src="./visa_image.png" alt="visa" />
          <img src="./amex_image.png" alt="amex" />
          <img src="./club_image.png" alt="club" />
          <img src="./discover_image.png" alt="discover" />
          <img src="./JCB_image.png" alt="JCB" />
          <img src="./mastercard_image.png" alt="mastercard" />
        </div>
        <form>
          <div className="form-group">
            <span>{localize('preview-sales-engine.static-content.copy.Labels.labels#credit-debit-label')}</span>
            <span className="asterisk">*</span>
            <div id="number-container" className="form-control">
              <span>sample</span>
            </div>
            <span className="invalid-feedback-onchange">{cardError}</span>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="month">
                {localize('preview-sales-engine.static-content.copy.Labels.labels#credit-card-expiration-month')}
                <span className="asterisk">*</span>

                <select id="month" className="form-control" onChange={onMonthChange} defaultValue={currentMonth}>
                  {Array.from({ length: 12 }, (v, i) => {
                    const temp = (i + 1).toString();
                    if (temp.length === 1) return '0' + temp;
                    else return temp;
                  }).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="year">
                {localize('preview-sales-engine.static-content.copy.Labels.labels#credit-card-expiration-year')}
                <span className="asterisk">*</span>

                <select id="year" className="form-control" onChange={onYearChange} defaultValue={currentYear}>
                  {Array.from({ length: 10 }, (v, index) => {
                    const temp = new Date().getFullYear();
                    return temp + index;
                  }).map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <span className="invalid-feedback-onchange" style={{ marginTop: '-15px', paddingBottom: '20px' }}>
              {monthYearError}
            </span>
          </div>

          {showCVV ? (
            <div className="form-group">
              <span>{localize('preview-sales-engine.static-content.copy.Labels.labels#cvv-label')}</span>
              <span className="asterisk">*</span>

              <div id="cvv-container" className="form-control"></div>
              <span className="invalid-feedback-onchange">{cvvError}</span>
            </div>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
};

export default React.memo(withLocalization(CybeSourceFlexForm));
