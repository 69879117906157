/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import { Offer } from '../../features/offerSearch/types';
import { getCurrentBrand, getFormattedCurrencyValueByBrand } from '../../util';
import DopInformation from '../common/DopInformation/DopInformation';
import ParseHtml from '../common/ParseHtml';
import withBrands from '../hoc/withBrands';
// import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';
import { RootState } from '../../redux/rootReducer';

interface ViewDestinationType {
  localize: localizeType;
  offer: Offer;
  onViewOfferClicked: any;
  brand?: string;
}
const ViewDestination: FC<ViewDestinationType> = (props: ViewDestinationType): ReactElement => {
  const { localize, offer, onViewOfferClicked } = props;
  const loc: string = useSelector((state: RootState) => state.offerDetails.searchParameter);
  const property = offer.property;
  const address = `${property!.city ? property!.city : ''}, ${property!.stateProvince ? property!.stateProvince : ''}`;
  const formattedAddress = address
    .split(',')
    .filter((x) => x && x.trim().length > 0)
    .join(',')
    .trim();
  const premiums =
    offer && offer.premiums
      ? offer?.premiums
          .filter((x) => x !== null)
          .join(', ')
          .trim()
      : undefined;

  return (
    <div className="propertyWrapper">
      {offer.includeOffer ? (
        <>
          <div className="row propertyDisplay">
            <div className="col-md-3 leftPad">
              <img src={property?.heroImg} className="fullWidth destination-image" alt={`view of ${property?.name}`} />
            </div>
            <div className="col-md-6 verticalAlign">
              <div>
                <h2 className="propertHeading">{property?.name}</h2>
                <span className="propertySub">{formattedAddress}</span>
                <div>
                  <p>
                    <span className="roomDescription">{offer.offerDescription?.toLocaleLowerCase()}</span> from{' '}
                    <span className="costResortPage">
                      {getFormattedCurrencyValueByBrand(offer.minimumStartingCost, props.brand)}
                    </span>{' '}
                    {localize('preview-sales-engine.static-content.copy.prospects.perstay')}
                  </p>
                </div>

                <div>
                  {localize('preview-sales-engine.static-content.copy.select-offer-page.days-nights', [
                    offer.offerDays ? offer.offerDays : '',
                    offer.offerNights ? offer.offerNights : '',
                  ])}
                  <div className="vr"></div>
                  {localize('preview-sales-engine.static-content.copy.select-offer-page.max-guests')}{' '}
                  {offer.offerMaxGuest}
                </div>
                <div>
                  {premiums ? (
                    <p className="premium-text">
                      <ParseHtml
                        htmlInput={`${localize(
                          'preview-sales-engine.static-content.copy.prospects.plustext',
                        )} ${premiums}`}
                      ></ParseHtml>
                    </p>
                  ) : (
                    <></>
                  )}
                  <p> </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 rightAlign">
              <div>
                <button className="roomTypeSelectButton" onClick={(e) => {
                  onViewOfferClicked();
                }}>
                  {localize ? localize('preview-sales-engine.static-content.copy.prospects.selectresort') : ''}
                </button>
                <DopInformation dopCodeDetails={offer.dopCodeDetails} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withLocalization(withBrands(ViewDestination));
