const checkEmpty = (val: any) => {
  const isEmpty = val?.trim() === '' ? true : false;
  return isEmpty;
};
export const isBillingDataValid = (obj: any) => {
  let isInValid = Object.values(obj).every((value) => value === false);
  return isInValid;
};
const zipValidator = (val: any) => {
  const regex: RegExp = new RegExp(/^\d{5}(?:-\d{4})?$/);
  return !regex.test(val);
};
const cityValidator = (val: any) => {
  const regex: RegExp = new RegExp(`^([a-zA-Z\\s]){1,30}$`);
  return !regex.test(val);
};
const checkConsecutiveSpaces = (val: any) => {
  const hasConsecutiveSpaces: any = /\s{2,}/.test(val);
  return hasConsecutiveSpaces;
};

/*const isInvalid = (val: any) => {
  const regex = /^[a-zA-Z\s]*$/;
  return !regex.test(val);
};*/
export const billingDataValidation = (field: any, val: any) => {
  if (field === 'address') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.street-address-error#invalid`,
      };
    } else if (checkConsecutiveSpaces(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.street-address-error#consecutive-spaces`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'city') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.city-error#required`,
      };
    } else if (cityValidator(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.city-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'state') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.Error Message.valid-us-state-error`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else if (field === 'zipCode') {
    if (checkEmpty(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.zip-code-error#required`,
      };
    } else if (zipValidator(val)) {
      return {
        isError: true,
        msg: `preview-sales-engine.static-content.copy.contact-information-page.zip-code-error#invalid`,
      };
    } else {
      return { isError: false, msg: '' };
    }
  } else {
    return { isError: false, msg: '' };
  }
};
export const checkBtnDisable = (formData: any) => {
  let tempErr = {
    addressError: true,
    cityError: true,
    zipCodeError: true,
    stateError: true,
  };
  let tempData = { ...formData };
  for (let i in tempData) {
    if (i === 'address') {
      if (checkEmpty(tempData[i]) || checkConsecutiveSpaces(tempData[i])) {
        tempErr = { ...tempErr, addressError: true };
      } else {
        tempErr = { ...tempErr, addressError: false };
      }
    }
    if (i === 'city') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, cityError: true };
      } else if (cityValidator(tempData[i])) {
        tempErr = { ...tempErr, cityError: true };
      } else {
        tempErr = { ...tempErr, cityError: false };
      }
    }
    if (i === 'zipCode') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, zipCodeError: true };
      } else if (zipValidator(tempData[i])) {
        tempErr = { ...tempErr, zipCodeError: true };
      } else {
        tempErr = { ...tempErr, zipCodeError: false };
      }
    }
    if (i === 'state') {
      if (checkEmpty(tempData[i])) {
        tempErr = { ...tempErr, stateError: true };
      } else {
        tempErr = { ...tempErr, stateError: false };
      }
    }
  }

  return tempErr;
};
