import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const storageConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['offerDetails', 'availabilityDates', 'confirmation'],
};

const persistedReducer = persistReducer(storageConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

const persistConfig = { store, persistor };
export default persistConfig;
