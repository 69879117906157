export enum ErrorType {
  // eslint-disable-next-line no-unused-vars
  Error,
  // eslint-disable-next-line no-unused-vars
  Warning,
  // eslint-disable-next-line no-unused-vars
  Information,
}
export interface Error {
  errorType: ErrorType;
  errorMessageKey: string;
}
export interface ResponseBase {
  tokenExpired?: boolean;
}
export type localizeType = (n: string, params?: string | string[]) => string;
export type localizeObjectType = (n: string) => object;
