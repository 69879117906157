import React, { FC, ReactElement } from 'react';
import { clearStorage } from '../../util';
import withLocalization from '../hoc/withLocalization';
import './PrintConfirmation.scss';

const PrintConfirmation: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  //const dispatch = useDispatch();
  const onPrintClick = (evt) => {
    clearStorage();
    window.print();
  };

  return (
    <>
      <div className="eligibilityButton">
        <button className="checkInButton palmBg upperCase hideonPrint" onClick={onPrintClick}>
          {localize('preview-sales-engine.static-content.copy.prospects.print-label')}
        </button>
      </div>
    </>
  );
};

export default withLocalization(PrintConfirmation);
