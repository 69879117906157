/* eslint-disable */

// Modules
import React, { SyntheticEvent } from "react";

// Styles
import "./MvSelect.sass";

// Interfaces
import Item from "./ItemInterface";

// Components
import ToolTip from "../ToolTip";
import { Position } from "../ToolTip/ToolTip";

type Tip = {
  label:string;
  desc:string;
}

export type Props={
  items:Item[];
  value?: string;
  label:string;
  name:string;
  toolTip?: [Tip];
  toolTipPosition?: Position;
  required?:boolean;
  onChange: (e: SyntheticEvent) => void;
  tabIndex?:number,
  hasError?: boolean,
  errorMessage?: string
}

class MvSelect extends React.Component<Props, {}> {
  reset = "RESET";

  constructor(props:Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e:any) {
    let value = e.target.value;

    if (value === this.reset) {
      e.target.value = '';
    }
    this.props.onChange(e);
  }

  render() {
    return (
      <div className={`mv-select ${this.props.hasError ? 'mv-select-error' : ''}`}>
        <label
          htmlFor={this.props.label} className="visuallyhidden">
          {this.props.label}
        </label>
        <select
          name={this.props.name}
          tabIndex={this.props.tabIndex}
          value={this.props.value}
          id={(this.props.label).toLowerCase().replace(/ /g,'')}
          onChange={this.onChange}
          aria-label={this.props.label}
          aria-required={this.props.required ? "true" : "false"}>
            <option value={this.props.label} className="hidden-for-label">
              {this.props.label}
            </option>
            {this.props.items.map((item, i)=> {
              return(
                <option key={i}
                  id={`id_${item.description.toLowerCase().replace(/ /g,'')}`}
                  value={item.value}>
                  {item.description}
                </option>
              )
            })}
        </select>
        {this.props.toolTip &&
          <div className='mv-select-tooltip'>
            <ToolTip
              useRefToPosition={false}
              tip={this.props.toolTip}
              tabIndex={this.props.tabIndex}
              id={`mv-select-tooltip-${this.props.label}`}
              position={this.props.toolTipPosition}
            >
              <span className='question-icon'>{"?"}</span>
            </ToolTip>
          </div>
        }
        {this.props.required && <span>*</span>}
        {this.props.hasError && <div className='mv-input-err-msg'>{this.props.errorMessage}</div>}
      </div>
    );
  }
}
export default MvSelect;
