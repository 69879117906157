import React, { FC, ReactElement } from 'react';
import { Alert } from 'react-bootstrap';
import { localizeType } from '../../commonType';
import { getFormattedCurrencyValueByBrand, getFormattedPhoneNumberForBrands } from '../../util';
import ParseHtml from '../common/ParseHtml';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import { NavigationSteps } from './OfferNavigation';

interface OpenDatedNoticeType {
  showNotice: boolean;
  brandPhoneNumber: string;
  localize: localizeType;
  brand: string;
  depositAmount?: string;
  noticeLocation: NavigationSteps;
  displayAsAlert?: boolean;
}
const OpenDatedNotice: FC<OpenDatedNoticeType> = (props: OpenDatedNoticeType): ReactElement => {
  const { showNotice, localize, brand, brandPhoneNumber, noticeLocation, depositAmount, displayAsAlert } = props;

  const phoneNumber = brandPhoneNumber
    ? brandPhoneNumber
    : localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const formattedPhoneNumber = getFormattedPhoneNumberForBrands(phoneNumber, brand);

  const getNotice = (): string => {
    switch (noticeLocation) {
      case NavigationSteps.OFEFR_DETAILS:
        return localize(
          'preview-sales-engine.static-content.copy.prospects.open-dated-notice-accommodation',
          getFormattedCurrencyValueByBrand(depositAmount, brand),
        );
      case NavigationSteps.OFFER_ELIGIBILITY:
        return localize('preview-sales-engine.static-content.copy.prospects.open-dated-notice-dop', [
          getFormattedCurrencyValueByBrand(depositAmount, brand),
          phoneNumber,
          formattedPhoneNumber,
        ]);
      case NavigationSteps.OFFER_CONTACT_INFO:
        return localize('preview-sales-engine.static-content.copy.prospects.open-dated-notice-your-info', [
          getFormattedCurrencyValueByBrand(depositAmount, brand),
          phoneNumber,
          formattedPhoneNumber,
        ]);
      case NavigationSteps.OFFER_REVIEW:
        return localize('preview-sales-engine.static-content.copy.prospects.open-dated-notice-review', [
          getFormattedCurrencyValueByBrand(depositAmount, brand),
          phoneNumber,
          formattedPhoneNumber,
        ]);
      case NavigationSteps.OFFER_CONFIRMATION:
        return localize('preview-sales-engine.static-content.copy.prospects.open-dated-notice-confirmation', [
          phoneNumber,
          formattedPhoneNumber,
        ]);
      default:
        return localize('preview-sales-engine.static-content.copy.prospects.open-dated-notice', [
          phoneNumber,
          formattedPhoneNumber,
        ]);
    }
  };
  const notice = getNotice();
  return showNotice ? (
    displayAsAlert ? (
      <Alert variant="warning">
        <ParseHtml htmlInput={notice}></ParseHtml>
      </Alert>
    ) : (
      <p className="openDatedNotice">
        <ParseHtml htmlInput={notice}></ParseHtml>
      </p>
    )
  ) : (
    <></>
  );
};

export default withLocalization(withBrands(OpenDatedNotice));
