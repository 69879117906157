import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetBillingInfo, setBillingInfoIfequal } from '../../features/offerSearch/offerSearchSlice';
import { RootState } from '../../redux/rootReducer';
import withLocalization from '../hoc/withLocalization';

const CheckBoxController: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const dispatch = useDispatch();
  const checkBoxStatus = useSelector((state: RootState) => state.offerDetails.billingBoxChecked);

  const handleInputChange = (evt) => {
    if (evt.target.checked) {
      dispatch(setBillingInfoIfequal(evt.target.checked));
    } else dispatch(resetBillingInfo());
  };
  return (
    <div>
      <div>
        <h3 className="contactInfoSubHead">
          {localize('preview-sales-engine.static-content.copy.Labels.labels#billing-information-label')}
        </h3>

        <div className="checkBillingBox">
          <label htmlFor="billingChecked">
            <input id="billingChecked" type="checkbox" onChange={handleInputChange} checked={checkBoxStatus} />
            {localize('preview-sales-engine.static-content.copy.Labels.labels#billing-declaration-label')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default React.memo(withLocalization(CheckBoxController));
