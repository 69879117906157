/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmationHeader from '../../components/OfferConfirmation/ConfirmationHeader';
import PrintConfirmation from '../../components/OfferConfirmation/PrintConfirmation';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import OpenDatedNotice from '../../components/OfferDetails/OpenDatedNotice';
import ContactInformationReview from '../../components/OfferSummary/ContactInformationReview';
// import { RootState } from '../../redux/rootReducer';
import PackageSummaryReview from '../../components/OfferSummary/PackageSummaryReview';
import { RootState } from '../../redux/rootReducer';
import { setNavigationStep } from '../offerSearch/offerSearchSlice';

const OfferConfirmationContainer: FC<any> = (props: any): ReactElement => {
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const isDatedOffer = useSelector((state: RootState) => state.offerDetails.isDatedOffer);
  const isPin: boolean = useSelector((state: RootState) => state.offerDetails.isPin);
  const brandPhoneNumber = isPin ? useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber) :
   useSelector((state: RootState) => state.offerDetails.locBrandPhoneNumber!);
  const isOpenDatedOptionSelected: boolean = useSelector(
    (state: RootState) => state.availabilityDates.openDateSelector,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setNavigationStep(NavigationSteps.OFFER_CONFIRMATION));
    window.addEventListener('popstate', function () {
      window.history.pushState(null, '', '/');
      return false;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container">
        <ConfirmationHeader confirmationNumber={confirmationNumber} />
        <OpenDatedNotice
          noticeLocation={NavigationSteps.OFFER_CONFIRMATION}
          showNotice={!isDatedOffer || isOpenDatedOptionSelected}
          brandPhoneNumber={brandPhoneNumber}
        ></OpenDatedNotice>
        <div className="row">
          <div className="col-sm-6">
            <ContactInformationReview />
          </div>
          <div className="col-sm-6">
            <PackageSummaryReview />
          </div>
        </div>

        <PrintConfirmation />
      </div>
    </>
  );
};
export default withRouter(OfferConfirmationContainer);
