/* eslint-disable */

import { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import MvSelect from '../../components/common/MvSelect';
import Item from '../../components/common/MvSelect/ItemInterface';
import withLocalization from '../../components/hoc/withLocalization';
import { getCurrentBrand, MARRIOTT, SHERATON, WESTIN } from '../../util';
import { States_US } from '../../util/States';
import { loadOfferDetailsForLoc } from '../offerSearch/offerSearchSlice';

//styles
import "./StateSearchPanel.sass";

const StateSearchPanel: FC<any> = (props: any): ReactElement => {
    const { localize } = props;
    const dispatch = useDispatch();
    const [states, setStateValue] = useState<Item[]>([]);
    const [selectedState, setSelectedStateValue] = useState("Select State");
    const [pageError, setPageError] = useState({ message: "Please select a state", isErrored: false });

    const brandName: string = getCurrentBrand();
    let classNameForButton: string = "";
    let phoneNumber: string = "";


    switch (brandName) {
        case WESTIN:
            classNameForButton = "button-state-submit-westin";
            phoneNumber = localize('preview-sales-engine.static-content.copy.loc.westin-number');
            break;

        case SHERATON:
            classNameForButton = "button-state-submit-sheraton";
            phoneNumber = localize('preview-sales-engine.static-content.copy.loc.sheraton-number');
            break;

        case MARRIOTT:
            classNameForButton = "button-state-submit";
            phoneNumber = localize('preview-sales-engine.static-content.copy.loc.marriott-number');
            break;

        default:
            classNameForButton = "button-state-submit";
            phoneNumber = localize('preview-sales-engine.static-content.copy.loc.marriott-number');
            break;
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                // event.preventDefault();
                console.log("enter key detected");
                onFormSubmit(event);
            }
        }

        document.addEventListener("keydown", listener);

        const states: Item[] = States_US.map((state: any) => {
            return {
                value: state.name,
                description: state.name
            };
        })

        setStateValue(states);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [selectedState]);

    const onStateChange = (e: any): void => {
        const state = e.target.value;
        if (pageError.isErrored) {
            setPageError({ ...pageError, isErrored: false });
        }
        setSelectedStateValue(state);
    }

    const onFormSubmit = (e: any): void => {
        sessionStorage.setItem('Persist:StateSearch:Active', 'true');
        e.preventDefault();
        let findState: boolean = false;

        for (let i = 0; i < states.length; i++) {
            if (states[i].value == selectedState) {
                findState = true;
                break;
            }
        }

        if (findState) {
            if (pageError.isErrored) {
                setPageError({ ...pageError, isErrored: false });
            }
            //Get the LOC from URL
            const queryParam = props.location.search;
            const str: string[] = queryParam.split("=");
            const locCode: string = str[1];
            sessionStorage.setItem('Persist:IsPin', 'false');


            dispatch(loadOfferDetailsForLoc(locCode, selectedState,phoneNumber));
        } else {
            setPageError({ ...pageError, isErrored: true });
        }
    }

    return (
        <div className='search-container'>
            <div className='col-md-12'>
                <h1 className='card-title'>
                    {localize('preview-sales-engine.static-content.copy.prospects.letsfindoffer')}
                </h1>
                <hr />
                <p className="card-text" dangerouslySetInnerHTML={{ __html: localize('preview-sales-engine.static-content.copy.prospects.brandgreetstatename') }}>
                </p>
                <p className=''>STATE OF RESIDENCE</p>
                <form onSubmit={onFormSubmit}>
                    <MvSelect
                        name="STATE OF RESIDENCE"
                        onChange={onStateChange}
                        label={selectedState}
                        required={true}
                        items={states}
                        hasError={pageError.isErrored}
                        errorMessage={pageError.message}
                    />
                    <div className='button-box'>
                        <input
                            type="submit"
                            className={classNameForButton}
                            value="FIND MY OFFER"
                        ></input>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default withRouter(withLocalization(StateSearchPanel));