import React, { FC, ReactElement } from 'react';
import { localizeType } from '../../commonType';
import withLocalization from '../hoc/withLocalization';
//import './RoomTypeInformation.scss';
interface RoomTypeProp {
  localize: localizeType;
}

const RoomTypeInformation: FC<RoomTypeProp> = (props: RoomTypeProp): ReactElement => {
  const { localize } = props;

  return (
    <React.Fragment>
      <div className="roomTypePad">
        <h2 className="accomodationSubHeading paddingTopBot">
          {localize('preview-sales-engine.static-content.copy.prospects.selectaccomodations')}
        </h2>
      </div>
    </React.Fragment>
  );
};
export default React.memo(withLocalization(RoomTypeInformation));
