/* eslint-disable */

import React from "react";

const LogContext = React.createContext<{
    logMethod: () => void;
}>({
    logMethod: () => {}
});

export default LogContext;