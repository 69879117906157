import React, { FC, ReactElement, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router';
import ParseHtml from '../../components/common/ParseHtml';
import withBrands from '../../components/hoc/withBrands';
import withLocalization from '../../components/hoc/withLocalization';
import DatePicker from '../../components/OfferAvailability/DatePicker';
import DatePriceSelector from '../../components/OfferAvailability/DatePriceSelector';
import ExtraNightsSelector from '../../components/OfferAvailability/ExtraNightsSelector';
import NoAvailabilityInformation from '../../components/OfferAvailability/NoAvailabilityInformation';
import OfferAvailabilityHeader from '../../components/OfferAvailability/OfferAvailabilityHeader';
import PremiumTextDisplay from '../../components/OfferAvailability/PremiumTextDisplay';
import PriceShowForMobile from '../../components/OfferAvailability/PriceShowForMobile';
import RoomGuestInformation from '../../components/OfferAvailability/RoomGuestInformation';
import SelectedOffer from '../../components/OfferAvailability/SelectedOffer';
import FlipOfferSelector from '../../components/OfferDetails/FlipOfferSelector';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import { RootState } from '../../redux/rootReducer';
import { getFormattedPhoneNumberForBrands } from '../../util';
import {
  addDaysGivenDate,
  convertStringToDate,
  dateDiffInDays,
  getMonthEndDate,
  // eslint-disable-next-line prettier/prettier
  getMonthStartDate
} from '../../util/date';
import { OFFER_CONFIRMATION_PATH, OFFER_ELIGIBILITY_PATH } from '../../util/strings';
import { resetConfirmationError } from '../offerConfirmation/offerConfirmationSlice';
import { resetGuestsAndState, setNavigationStep } from '../offerSearch/offerSearchSlice';
import { Offer } from '../offerSearch/types';
import './OfferAvailabilityContainer.scss';
import {
  holdProperty,
  loadOfferDates,
  releaseHoldProperty,
  resetAllEligibilityOptions,
  resetCurrentAvailabilityDate,
  resetFinalSelectedDate,
  resetFinalSelectedExtraNights,
  setCalendarPosition,
  setConsecutiveSelecteddates,
  setCurrentAvailabilityDate,
  setCurrentCalendarPosition,
  setExtraNights,
  setFinalSelectedDate,
  setFinalSelectedExtraNights,
  setHoldRequest,
  // eslint-disable-next-line prettier/prettier
  setLoadingStatus
} from './offerAvailabilitySlice';
import {
  AvailabilityDate,
  AvailabilityDateRequest,
  AvailabilityRoomInformation,
  AvailableDates,

  // eslint-disable-next-line prettier/prettier
  HoldPropertyRequest,
  // eslint-disable-next-line prettier/prettier
  ReleaseHoldPropertyRequest
} from './types';

const OfferAvailabilityContainer: FC<any> = (props: any): ReactElement => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  const availabilityDates: AvailableDates = useSelector((state: RootState) => state.availabilityDates);
  const isLoading = availabilityDates.isLoading;
  const dateMonthRegistry = availabilityDates.dateMonthRegistry;
  const currentCalendarPosition = availabilityDates.currentCalendarPosition;
  const extraNights = availabilityDates.extraNights;
  const offerMonthList = isMobile ? availabilityDates.offerMonthRangeMobile : availabilityDates.offerMonthRangeDesktop;
  const navigateToEligibility = availabilityDates.navigateToEligibility;
  const holdSessionId = availabilityDates.holdSessionId;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const currentOffer: Offer | undefined = offerDetails.currentOffer;
  const selectedRoom = offerDetails.selectedRoomType;
  const availabilitySearchrequest: AvailabilityDateRequest | undefined = availabilityDates?.availabilityRequest;
  const hasError = availabilityDates.hasError;
  const isHoldFailed = availabilityDates.isHoldFailed;
  const phoneNumber =
    offerDetails.prospect && offerDetails.prospect.callCenterNumber
      ? offerDetails.prospect.callCenterNumber
      : props.localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  let holdFailedMessage = props.localize('preview-sales-engine.static-content.copy.prospects.bookingsystemdown', [
    phoneNumber,
    getFormattedPhoneNumberForBrands(phoneNumber, props.brand),
  ]);
  const datePriceInfo: AvailabilityDate[] | undefined = availabilityDates.dates;
  const premiumDates: string[] | undefined = availabilityDates.premiumDates;
  const maxGuests = offerDetails.selectedRoomType?.sleepCapacity;
  const guestInfo = offerDetails.guests;
  const numberofAdults = guestInfo ? guestInfo.adults : 1;
  const numberofChildren = guestInfo ? guestInfo.children : 0;
  const consecutiveSelectedDates: Date[] = availabilityDates.consecutiveSelectedDates
    ? availabilityDates.consecutiveSelectedDates
    : [];
  const currentDate = new Date();
  let startDate = currentOffer && currentOffer.bookFromDate ? new Date(currentOffer.bookFromDate) : currentDate;
  startDate = startDate > currentDate ? startDate : currentDate;
  let endDate =
    currentOffer && currentOffer.bookToDate
      ? new Date(currentOffer.bookToDate)
      : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  endDate = endDate < startDate ? startDate : endDate;

  const selectedPreferences = availabilitySearchrequest?.preferences;
  const previousCalendarPosition = availabilityDates.previousCalendarPosition
    ? availabilityDates.previousCalendarPosition
    : -1;
  const hasExtraNights: boolean = availabilityDates.extraNights && availabilityDates.extraNights > 0 ? true : false;
  const getAvailabilityStatus = (): boolean => {
    if (!datePriceInfo) return false;
    if (isLoading || hasError) return false;
    const { prevDate, nextDate } = currentCalendarPosition;
    const calendarViewStartDate = new Date(prevDate);
    const calendarViewEndDate = new Date(
      getMonthEndDate(new Date(nextDate).getMonth(), new Date(nextDate).getFullYear()),
    );
    const status = !datePriceInfo!.some((date) => {
      const checkinDate = new Date(date.checkInDate);
      return checkinDate >= calendarViewStartDate && checkinDate <= calendarViewEndDate;
    });
    return status;
  };
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const hasNoAvailability = currentCalendarPosition ? getAvailabilityStatus() : availabilityDates.hasNoAvailability;
  const isDatedPackage = useSelector((state: RootState) => state.offerDetails.isDatedOffer);
  useEffect(() => {
    let unMount = false;
    if (confirmationNumber && confirmationNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    return () => {
      if (!unMount) unMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isDatedPackage !== null && isDatedPackage === false) {
      props.history.push('/error');
    }
  }, [isDatedPackage, props.history]);
  useEffect(() => {
    if (availabilitySearchrequest && !datePriceInfo) {
      dispatch(loadOfferDates(availabilitySearchrequest));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setNavigationStep(NavigationSteps.OFFER_DATES));
    dispatch(resetGuestsAndState());
    dispatch(resetAllEligibilityOptions());
    dispatch(resetConfirmationError());
    dispatch(resetFinalSelectedDate());
    dispatch(resetFinalSelectedExtraNights());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitySearchrequest, datePriceInfo]);

  useEffect(() => {
    if (navigateToEligibility) {
      props.history.push(OFFER_ELIGIBILITY_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToEligibility]);

  useEffect(() => {
    if (holdSessionId && !navigateToEligibility) {
      const releaseRequest: ReleaseHoldPropertyRequest = {
        holdId: holdSessionId,
      };
      dispatch(releaseHoldProperty(releaseRequest));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdSessionId]);

  const onDayClick = (selectedDate: Date, dateIndex: number, calendarPosition: number) => {
    if (dateIndex >= 0 && availabilityDates && availabilityDates.dates && availabilityDates.dates.length > 0) {
      let currentAvailabilityDate = availabilityDates.dates[Math.floor(dateIndex)];
      /** Calculate Consecutive Selected Dates based on Check-in and Check-out Dates */
      const startDate: Date = convertStringToDate(currentAvailabilityDate.checkInDate);
      const endDate: Date = convertStringToDate(currentAvailabilityDate.checkOutDate);
      const lengthOfStay = dateDiffInDays(startDate, endDate);
      const consecutiveDates: Date[] = [];

      consecutiveDates.push(selectedDate);
      for (let index = 1; index <= lengthOfStay; index++) {
        consecutiveDates.push(addDaysGivenDate(selectedDate, index));
      }
      dispatch(setCalendarPosition(calendarPosition));
      dispatch(setCurrentAvailabilityDate(currentAvailabilityDate));
      dispatch(setConsecutiveSelecteddates(consecutiveDates));
    }
  };
  const onMonthChange = (date: Date, monthPosition: any) => {
    const startDate = getMonthStartDate(date.getMonth(), date.getFullYear());
    const endDate = getMonthEndDate(date.getMonth(), date.getFullYear());
    const searchIndex = `${startDate.split('-')[1]}:${startDate.split('-')[0]}`;
    dispatch(setCurrentCalendarPosition(monthPosition));
    if (dateMonthRegistry && dateMonthRegistry.indexOf(searchIndex) >= 0) {
      return;
    }
    if (availabilitySearchrequest) {
      const availabilitySearchrequestOnMonthChange: AvailabilityDateRequest = createAvailabilityRequest(
        startDate,
        endDate,
        extraNights,
      );
      dispatch(loadOfferDates(availabilitySearchrequestOnMonthChange));
    }
  };
  const onNavigateToEligibility = (isDated: boolean) => {
    if (isDated) {
      const currentSelectedDate: AvailabilityDate | undefined = availabilityDates.selectedDate;
      const roomInfo: AvailabilityRoomInformation = {
        accomodationCode: currentSelectedDate ? currentSelectedDate.roomInformation.accomodationCode : '',
        miniHotelCode: currentSelectedDate ? currentSelectedDate.roomInformation.miniHotelCode : '',
        marshaCode: currentSelectedDate ? currentSelectedDate.roomInformation.marshaCode : '',
        rateCode: currentSelectedDate ? currentSelectedDate.roomInformation.rateCode : '',
        roomPoolCode: currentSelectedDate ? currentSelectedDate.roomInformation.roomPoolCode : '',
        preferences:
          currentSelectedDate && currentSelectedDate.roomInformation && currentSelectedDate.roomInformation.preferences
            ? currentSelectedDate.roomInformation.preferences
            : undefined,
      };
      const holdRequest: HoldPropertyRequest = {
        startDate:
          currentSelectedDate && currentSelectedDate.checkInDate ? currentSelectedDate.checkInDate.split('T')[0] : '',
        endDate:
          currentSelectedDate && currentSelectedDate.checkOutDate ? currentSelectedDate.checkOutDate.split('T')[0] : '',
        roomInformation: roomInfo,
        offerCode: currentOffer?.offerCode,
        numberOfGuests: selectedRoom ? selectedRoom?.sleepCapacity : 0,
      };

      dispatch(setHoldRequest(holdRequest));
      dispatch(setFinalSelectedDate());
      dispatch(setFinalSelectedExtraNights());
      dispatch(holdProperty(holdRequest));
    } else {
      dispatch(setFinalSelectedExtraNights());
      dispatch(setLoadingStatus(true));
      props.history.push(OFFER_ELIGIBILITY_PATH);
    }
  };
  const onMonthDropDownChange = (dateRange: any) => {
    dispatch(setCurrentCalendarPosition(dateRange));
    let loadResult = true;
    let startDate = getMonthStartDate(
      new Date(dateRange.prevDate).getMonth(),
      new Date(dateRange.prevDate).getFullYear(),
    );
    let endDate = '';
    let searchIndex = `${startDate.split('-')[1]}:${startDate.split('-')[0]}`;
    if (dateMonthRegistry && dateMonthRegistry.indexOf(searchIndex) >= 0) {
      loadResult = false;
      startDate = getMonthStartDate(
        new Date(dateRange.nextDate).getMonth(),
        new Date(dateRange.nextDate).getFullYear(),
      );
      searchIndex = `${startDate.split('-')[1]}:${startDate.split('-')[0]}`;
      if (dateMonthRegistry.indexOf(searchIndex) >= 0) {
        loadResult = false;
      } else {
        loadResult = true;
        endDate = getMonthEndDate(new Date(dateRange.nextDate).getMonth(), new Date(dateRange.nextDate).getFullYear());
      }
    } else {
      loadResult = true;
      endDate = getMonthEndDate(new Date(dateRange.nextDate).getMonth(), new Date(dateRange.nextDate).getFullYear());
    }
    if (loadResult === true) {
      const availabilitySearchRequestOnMonthChange: AvailabilityDateRequest = createAvailabilityRequest(
        startDate,
        endDate,
        extraNights,
      );
      dispatch(loadOfferDates(availabilitySearchRequestOnMonthChange));
    }
  };
  const onExtraNightValueChange = (value: number) => {
    if (availabilitySearchrequest) {
      let startDate = currentCalendarPosition
        ? getMonthStartDate(
            new Date(currentCalendarPosition.prevDate).getMonth(),
            new Date(currentCalendarPosition.prevDate).getFullYear(),
          )
        : availabilitySearchrequest.beginDate;
      let endDate = currentCalendarPosition
        ? getMonthEndDate(
            new Date(currentCalendarPosition.nextDate).getMonth(),
            new Date(currentCalendarPosition.nextDate).getFullYear(),
          )
        : availabilitySearchrequest.endDate;

      const availabilitySearchRequestWithExtraNights: AvailabilityDateRequest = createAvailabilityRequest(
        startDate,
        endDate,
        value,
      );
      dispatch(setExtraNights(value));
      dispatch(resetCurrentAvailabilityDate());
      dispatch(loadOfferDates(availabilitySearchRequestWithExtraNights));
    }
  };
  const createAvailabilityRequest = (startDate: string, endDate: string, extraNights): AvailabilityDateRequest => {
    let reqBeginDate =
      new Date(startDate) < new Date(currentOffer!.bookFromDate) ? currentOffer!.bookFromDate : startDate;
    let reqEndDate = new Date(endDate) > new Date(currentOffer!.bookToDate) ? currentOffer!.bookToDate : endDate;
    reqBeginDate = reqBeginDate.indexOf('T') > 0 ? reqBeginDate.split('T')[0] : reqBeginDate;
    reqEndDate = reqEndDate.indexOf('T') > 0 ? reqEndDate.split('T')[0] : reqEndDate;
    const modifiedAvailabilitySearchrequest: AvailabilityDateRequest = {
      accomodationCode: availabilitySearchrequest ? availabilitySearchrequest.accomodationCode : '',
      beginDate: reqBeginDate ? reqBeginDate : '',
      endDate: reqEndDate ? reqEndDate : '',
      offerCode: availabilitySearchrequest ? availabilitySearchrequest.offerCode : '',
      roomTypeCode: availabilitySearchrequest ? availabilitySearchrequest.roomTypeCode : '',
      extraNights: extraNights,
      loc: availabilitySearchrequest ? availabilitySearchrequest.loc : '',
      pin: availabilitySearchrequest ? availabilitySearchrequest.pin : '',
      preferences: availabilitySearchrequest ? availabilitySearchrequest.preferences : [],
    };
    return modifiedAvailabilitySearchrequest;
  };
  return (
    <>
      <div className="container">
        {!isMobile ? <OfferAvailabilityHeader /> : <></>}

        {(isHoldFailed || hasError) && (
          <Alert variant="warning">
            <ParseHtml htmlInput={holdFailedMessage}></ParseHtml>
          </Alert>
        )}
        <NoAvailabilityInformation
          hasNoAvailability={hasNoAvailability}
          preferences={selectedPreferences}
          callCenterPhoneNumber={offerDetails?.callCenterNumber}
        ></NoAvailabilityInformation>

        <ExtraNightsSelector
          maxNights={selectedRoom?.maxAdditionalNights}
          onNightChange={onExtraNightValueChange}
        ></ExtraNightsSelector>

        {isMobile && selectedRoom && selectedRoom?.maxAdditionalNights > 0 ? <hr /> : <></>}

        <div className="calendar-wrapper">
          <DatePicker
            onDayClick={onDayClick}
            datePriceInfo={datePriceInfo}
            premiumDates={premiumDates}
            startDate={startDate}
            endDate={endDate}
            selectedDates={consecutiveSelectedDates}
            previousCalendarPosition={previousCalendarPosition}
            hasExtraNights={hasExtraNights}
            numberOfMonths={isMobile ? 1 : 2}
            onMonthChange={onMonthChange}
            offerMonthList={offerMonthList}
            onMonthDropDownChange={onMonthDropDownChange}
          ></DatePicker>
        </div>

        <PremiumTextDisplay />

        {isMobile ? (
          <PriceShowForMobile
            selectedDates={consecutiveSelectedDates}
            datePriceInfo={datePriceInfo}
            hasExtraNights={hasExtraNights}
            premiumDates={premiumDates}
          />
        ) : (
          <></>
        )}
        <hr />

        <RoomGuestInformation
          maxCapacity={maxGuests}
          selectedAdultNumber={numberofAdults}
          selectedChildrenNumber={numberofChildren}
        />

        <DatePriceSelector onNavigateToEligibility={onNavigateToEligibility} />

        <SelectedOffer isMobile={isMobile} />
        <FlipOfferSelector />
      </div>
    </>
  );
};
export default React.memo(withRouter(withLocalization(withBrands(OfferAvailabilityContainer))));
