/* eslint-disable */

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from '../src/components/common/ErrorBoundary';
import './App.scss';
import ThemeSelector from './features/siteTheme/ThemeSelector';
import Main from './Main';
import persistConfig from './redux/persistor';
import { getCurrentBrand } from './util';


function App() {
  let currentBrand = getCurrentBrand();
  return (
    <>
      <ErrorBoundary>
          <Provider store={persistConfig.store}>
            <PersistGate loading={null} persistor={persistConfig.persistor}>
              <ThemeSelector theme={currentBrand}>
                <Main lang="en" />
              </ThemeSelector>
            </PersistGate>
          </Provider>
      </ErrorBoundary>
    </>
  );
}

export default App;
