import React, { FC } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../../../hoc/withLocalization';

import './OfferStep.scss';

interface StepProps {
  localize?: any;
  localizeImages?: any;
  step?: any;
  title?: string;
}
const OfferStep: FC<StepProps> = (props: StepProps) => {
  const currentStep = props.step;
  const stepTitle = props.title;
  const { localize } = props;
  return (
    <>
      <div className="stepe-page-container">
        <span className="stepWrapper">
          {localize('preview-sales-engine.static-content.copy.pbeo.step-label')} {currentStep} of 2
        </span>
        <span className="title-step-wrapper">{`${stepTitle}`}</span>
      </div>
    </>
  );
};
export default withRouter(withLocalization(OfferStep));
