import React, { FC, ReactElement, useRef, useState, useEffect } from 'react';
import withLocalization from '../../hoc/withLocalization';
import { Popover, Overlay, Modal, Button } from 'react-bootstrap';
import './TravelDateInfo.scss';

const TravelDateInfo: FC<any> = (props: any): ReactElement => {
  const { localize, lowSeasonDateRanges, highSeasonDateRanges, placement, isMobile } = props;
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  //Date Formatter method
  var monthNames = [
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jan'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#feb'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#mar'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#apr'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#may'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jun'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#jul'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#aug'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#sept'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#oct'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#nov'),
    localize('preview-sales-engine.static-content.copy.calendar-page.months-of-year#dec'),
  ];

  const dateFormatter = (date) => {
    var t = new Date(date);
    return t.getDate() + ' ' + monthNames[t.getMonth()] + ' ' + t.getFullYear();
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let buttonInput: HTMLButtonElement;
  useEffect(() => {
    if (buttonInput) buttonInput.focus();
  });

  const TravelDateForMObile = (
    <>
      <button className="travel-date-info" onClick={handleShow}>
        {localize('preview-sales-engine.static-content.copy.package-summary-component.travel-date-information')}
      </button>
      <Modal
        dialogClassName="modal-custom-travel-date-info"
        id="contained-modal-title-vcenter"
        show={show}
        backdrop="static"
        keyboard="false"
        scrollable={true}
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          {localize('preview-sales-engine.static-content.copy.package-summary-component.travel-date-information')}
        </Modal.Header>
        <Modal.Body role="main">
          <h1 className="hidden">TDI</h1>
          <div className="popover-column-low-season">
            <div className="popover-text">
              {localize('preview-sales-engine.static-content.copy.package-summary-component.low-season-date-ranges')}
            </div>
            {lowSeasonDateRanges ? (
              <ul>
                {lowSeasonDateRanges?.split(',').map((val, idx) => (
                  <li key={idx}>
                    {val
                      .split('-')
                      .map((d) => dateFormatter(d))
                      .join(' - ')}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="error-message">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.no-dates-present')}
              </div>
            )}
          </div>

          <div className="popover-column-high-season">
            <div className="popover-text">
              {localize('preview-sales-engine.static-content.copy.package-summary-component.high-season-date-ranges')}
            </div>
            {highSeasonDateRanges ? (
              <ul>
                {highSeasonDateRanges?.split(',').map((val, idx) => (
                  <li key={idx}>
                    {val
                      .split('-')
                      .map((d) => dateFormatter(d))
                      .join(' - ')}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="error-message">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.no-dates-present')}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            ref={(button) => {
              buttonInput = button;
            }}
            variant="secondary"
            className="button-dop"
            onClick={handleClose}
          >
            {localize('preview-sales-engine.static-content.copy.prospects.close-label')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  const TravelDateForDesktop = (
    <span ref={ref} className="custom-span">
      <button className="travel-date-info" onClick={() => setShow(!show)}>
        {localize('preview-sales-engine.static-content.copy.package-summary-component.travel-date-information')}
      </button>
      <Overlay
        show={show}
        target={ref.current}
        placement={placement}
        containerPadding={100}
        transition={false}
        rootClose
        onHide={() => setShow(!show)}
      >
        <Popover id="dateRanges">
          <Popover.Title as="h3">
            {localize('preview-sales-engine.static-content.copy.package-summary-component.travel-date-information')}
          </Popover.Title>
          <Popover.Content>
            <div className="popover-column-low-season">
              <div className="popover-text">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.low-season-date-ranges')}
              </div>
              {lowSeasonDateRanges ? (
                <ul>
                  {lowSeasonDateRanges?.split(',').map((val, idx) => (
                    <li key={idx}>
                      {val
                        .split('-')
                        .map((d) => dateFormatter(d))
                        .join(' - ')}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="error-message">
                  {localize('preview-sales-engine.static-content.copy.package-summary-component.no-dates-present')}
                </div>
              )}
            </div>

            <div className="popover-column-high-season">
              <div className="popover-text">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.high-season-date-ranges')}
              </div>
              {highSeasonDateRanges ? (
                <ul>
                  {highSeasonDateRanges?.split(',').map((val, idx) => (
                    <li key={idx}>
                      {val
                        .split('-')
                        .map((d) => dateFormatter(d))
                        .join(' - ')}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="error-message">
                  {localize('preview-sales-engine.static-content.copy.package-summary-component.no-dates-present')}
                </div>
              )}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </span>
  );

  return <div>{isMobile ? TravelDateForMObile : TravelDateForDesktop}</div>;
};

export default withLocalization(TravelDateInfo);
