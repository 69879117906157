import React, { Component, Fragment } from 'react';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';

interface IProps {
  htmlInput: any;
}

interface IState {}

class ParseHtml extends Component<IProps, IState> {
  render() {
    const html = this.props.htmlInput;
    return <Fragment>{ReactHtmlParser(html)}</Fragment>;
  }
}

export default ParseHtml;
