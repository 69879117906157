import React, { FC, ReactElement } from 'react';
import { Property } from '../../features/offerSearch/types';
import ParseHtml from '../common/ParseHtml';

interface PropertyTypeProp {
  property?: Property;
}

const PropertyInformation: FC<PropertyTypeProp> = (props: PropertyTypeProp): ReactElement => {
  if (!props.property) return <></>;

  const { name, address1, stateProvince, postalCode, description, heroImg, city } = props.property;
  const completeAddress = `${address1 ? address1 : ''},${city ? city : ''}, ${stateProvince ? stateProvince : ''} ${
    postalCode ? postalCode : ''
  }`;
  const formattedAddress = completeAddress
    .split(',')
    .filter((x) => x.trim().length > 0)
    .join(', ')
    .trim();
  return (
    <React.Fragment>
      <div className="topPad3">
        <div className="row bottom10">
          <div className="col-lg-5 col-md-6">
            <img className="fullWidth" src={heroImg} alt={`viewof-${name}`}></img>
          </div>
          <div className="col-lg-7 col-md-6">
            <h1 className="headBaskerville">{name}</h1>
            <p>{formattedAddress}</p>
            <div>
              <ParseHtml htmlInput={description} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PropertyInformation;
