/* eslint-disable */
import React, { FC, useEffect } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMicroFormSubmitHandler,
  setFlexFormValidState,
  resetPaymentData,
  retrievePaymentContext,
  resetPaymentContextId,
} from '../../../features/newOfferPayment/NewOfferPaymentSlice';
import { reSetNewOfferLead, reSetContactInfo } from '../../../features/newOffer/NewOfferSlice';
import BillingDetails from '../NewOfferBillingDetails/BillingDetails';
import { RootState } from '../../../redux/rootReducer';
import NewCybeSourceFlexComponent from '../../../components/NewOffer/NewOfferCyberSource/index';
import TermsAndConditionPage from './TermAndConditionPage';
import PaymentSubmitPage from './PaymentSubmitPage';
import { getSupportedCards } from '../../OfferContactInformation/CyberSource/paymentUtil';
import useTimer from '../../../features/newOfferPayment/UseTimer';
import NewOfferLoader from '../../common/CommonOfferComponent/NewOfferLoader/NewOfferLoader';
import './PaymentDetailsPage.scss';
import lockIcon from '../../../features/siteTheme/Brands/tmvcs/logo/lockIcon.svg';
import msCardImg from '../../../features/siteTheme/Brands/tmvcs/logo/newMaster-Card.png';

interface ReservationPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
}
const PaymentDetailsPage: FC<ReservationPanelProps> = (props: ReservationPanelProps) => {
  const { localize, history } = props;
  const dispatch = useDispatch();
  const { startTimer, stopTimer, remainingTime } = useTimer();
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const offerData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const loc = offerData?.loc || '';
  const hasError = useSelector((state: RootState) => state.newOfferPaymentDetails.hasPaymentContextError);
  const paymentContextId = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentContextId);
  const isLoading = useSelector((state: RootState) => state.newOfferPaymentDetails.pageLoading);
  const isCreatedLeadErr = useSelector((state: RootState) => state.newOfferDetails.hasLeadError);

  useEffect(() => {
    if (isCreatedLeadErr) {
      stopTimer();
    }
  }, [isCreatedLeadErr]);
  useEffect(() => {
    dispatch(resetPaymentData());
    dispatch(resetPaymentContextId());
  }, []);

  useEffect(() => {
    const storedStartTime = sessionStorage.getItem('timerStartTime');
    if (remainingTime <= 0 && remainingTime !== null && storedStartTime) {
      dispatch(reSetNewOfferLead());
      stopTimer();
      dispatch(reSetContactInfo());
      const getUrlSearchParam = sessionStorage.getItem('urlSearchParam');
      history.push('/contact-information' + getUrlSearchParam);
      /*history.push({
        pathname: '/contact-information',
        search: `?loc=${loc}`,
      });*/
    }
  }, [remainingTime]);

  useEffect(() => {
    if ((!paymentContextId || paymentContextId.length === 0) && !hasError) {
      dispatch(
        retrievePaymentContext(
          process.env.REACT_APP_MERCHANT_GROUPID_3DS ? process.env.REACT_APP_MERCHANT_GROUPID_3DS : '',
          window.location.origin,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentContextId]);

  const cardStyle = {
    input: {
      'font-size': '16px',
      color: '#000000',
    },
    ':focus': { color: '#000000' },
    ':disabled': { cursor: 'not-allowed' },
    valid: { color: '#000000' },
    invalid: { color: '#000000' },
  };

  return (
    <>
      {isLoading && <NewOfferLoader />}
      <div className="reservatioPageContainer">
        <div className="formCardTitleContainer">
          <div className="card-title-img-container">
            <span className="payment-CardTitle">
              {' '}
              {localize('preview-sales-engine.static-content.copy.Labels.labels#credit-card-information')}
            </span>
            <div className="cardImg-container">
              <img src="./newVisa.png" alt="visa" />
              <img src={msCardImg} alt="master-card" />
              <img src="./newDiscover.png" alt="discover" />
              <img src="./newAmex.png" alt="amex" />

              <img src={lockIcon} alt={'lock'} className="img-lock-logo" />
            </div>
          </div>
          <span className="reservationCardDescription">
            {localize('preview-sales-engine.static-content.copy.pbeo.payment-form-description')}
          </span>
        </div>
        <div className="nameRowContainer">
          {paymentContextId && paymentContextId.length > 0 ? (
            <NewCybeSourceFlexComponent
              context={paymentContextId ? paymentContextId : ''}
              style={cardStyle}
              cardPlaceHolder={''}
              mvwcMerchantGroupAcceptableCard={getSupportedCards()}
              showCVV={true}
              cvvPlaceHolder={''}
              emptyCreditCardMessage={localize(
                'preview-sales-engine.static-content.copy.Labels.labels#credit-card-number-error-message',
              )}
              invalidCreditCardMessage={localize(
                'preview-sales-engine.static-content.copy.Labels.labels#credit-card-invalid',
              )}
              invalidCvvMessage={localize('preview-sales-engine.static-content.copy.Labels.labels#cvv-error-message')}
              invalidExpirationErrMsg={localize(
                `preview-sales-engine.static-content.copy.Labels.error-message.credit-card-invalid-expiration`,
              )}
              invalidNameMsg={localize(
                `preview-sales-engine.static-content.copy.Error Message.credit-card-name-validation-error`,
              )}
              isFormValid={(isValid) => {
                dispatch(setFlexFormValidState(isValid));
              }}
              formObjectCallback={(obj) => {
                dispatch(setMicroFormSubmitHandler(obj));
              }}
            ></NewCybeSourceFlexComponent>
          ) : (
            <></>
          )}
        </div>
        <BillingDetails />
        <TermsAndConditionPage />
        <PaymentSubmitPage />
      </div>
    </>
  );
};
export default withRouter(withLocalization(PaymentDetailsPage));
