/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-onchange */
import React, { Component } from 'react';
import { AvailabilityDate } from '../../../../features/offerAvailability/types';
import { ChevronLeft, ChevronRight } from '../../../common/Chevron';
import withLocalization from '../../../hoc/withLocalization';
import Calendar from '../Calendar';
import './DatePicker.scss';

interface IProps {
  startDate: Date;
  endDate: Date;
  selectedDates: Date[];
  premiumDates?: string[];
  numberOfMonths: number;
  datePriceInfo?: AvailabilityDate[];
  onDayClick(date: Date, dateIndex?: number, calendarPosition?: number): void;
  previousCalendarPosition: number;
  hasExtraNights: boolean;
  onMonthChange(date: Date, monthPosition: any): void;
  onMonthDropDownChange(dateRange: any): void;
  offerMonthList: string[] | undefined;
  localize: any;
}

interface IState {
  calendarPosition: number;
}

class DatePicker extends Component<IProps, IState> {
  public static defaultProps = {
    startDate: new Date(),
    selectedDates: [],
    numberOfMonths: 2,
    endDate: new Date(),
    previousCalendarPosition: -1,
    offerMonthList: [],
  };

  constructor(props: IProps) {
    super(props);
    const calPosition = props.previousCalendarPosition > 0 ? props.previousCalendarPosition : 0;
    this.state = { calendarPosition: calPosition };
    this.nextMonth = this.nextMonth.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.onMonthDropDownChange = this.onMonthDropDownChange.bind(this);
  }

  nextMonth() {
    const index = this.state.calendarPosition + 1;
    this.setState({ calendarPosition: index });

    const newDate = this.getDateForPosition(this.props.numberOfMonths === 2 ? 1 : 0);
    let prevDate = newDate.toDateString();
    const calculatedDate = new Date(newDate.setMonth(newDate.getMonth() + 1));
    const nextDate = calculatedDate.toDateString();
    if (this.props.numberOfMonths === 2) {
      this.props.onMonthChange(calculatedDate, { prevDate, nextDate });
    } else {
      prevDate = nextDate;
      this.props.onMonthChange(calculatedDate, { prevDate, nextDate });
    }
  }

  previousMonth() {
    const index = this.state.calendarPosition - 1;
    this.setState({ calendarPosition: index });

    const newDate = this.getDateForPosition(0);
    let nextDate = newDate.toDateString();
    const calculatedDate = new Date(newDate.setMonth(newDate.getMonth() - 1));
    const prevDate = calculatedDate.toDateString();

    if (this.props.numberOfMonths === 2) {
      this.props.onMonthChange(calculatedDate, { prevDate, nextDate });
    } else {
      nextDate = prevDate;
      this.props.onMonthChange(calculatedDate, { prevDate, nextDate });
    }
  }
  onMonthDropDownChange(evt) {
    const originalValue = Number(evt.target.value);
    this.setState({ calendarPosition: originalValue });
    const newDate = this.getDateForPosition(0, originalValue);
    const prevDate = newDate.toDateString();
    const calculatedDate = new Date(newDate.setMonth(newDate.getMonth() + 1));
    let nextDate = calculatedDate.toDateString();

    if (this.props.numberOfMonths === 2) {
      this.props.onMonthDropDownChange({ prevDate, nextDate });
    } else {
      nextDate = prevDate;
      this.props.onMonthDropDownChange({ prevDate, nextDate });
    }
  }

  getDateForPosition(index: number, offset: number = -1): Date {
    let startingDate = new Date(this.props.startDate);
    let monthsToAdd = offset === -1 ? index + this.state.calendarPosition : index + offset;
    const monthLength = startingDate.getMonth() + monthsToAdd;
    let datePosition = new Date();

    if (monthLength < 12) {
      // datePosition = new Date(startingDate.setMonth(startingDate.getMonth() + monthsToAdd));
      datePosition = new Date(startingDate.getFullYear(), startingDate.getMonth() + monthsToAdd, 1);
    } else {
      const yearOffSet = Math.floor(monthLength / 12);
      const yearValue = startingDate.getFullYear() + yearOffSet;
      datePosition = new Date(yearValue, monthLength - 12, 1);
    }
    return datePosition;
  }

  renderPreviousMonth = (index: number) => {
    if (this.state.calendarPosition > 0) {
      return (
        <div className="date-picker-prev">
          <button title={'Previous Month'} onClick={this.previousMonth}>
            <ChevronLeft></ChevronLeft>
          </button>
        </div>
      );
    } else return this.renderBlank();
  };

  renderNextMonth = () => {
    const calculatedDate: Date = this.getDateForPosition(this.props.numberOfMonths === 1 ? 0 : 1);

    const { endDate } = this.props;

    if (calculatedDate > endDate) {
      this.renderBlank();
    } else {
      if (calculatedDate.getMonth() === endDate.getMonth() && calculatedDate.getFullYear() === endDate.getFullYear()) {
        this.renderBlank();
      } else {
        return (
          <div className="date-picker-next ">
            <button title={'Next Month'} onClick={this.nextMonth}>
              <ChevronRight></ChevronRight>
            </button>
          </div>
        );
      }
    }
  };
  renderBlank = () => {
    return <div className="date-picker-next ">&nbsp;</div>;
  };
  onCalendarDayClick = (date: Date, dateIndex: number) => {
    this.props.onDayClick(date, dateIndex, this.state.calendarPosition);
  };
  render() {
    return (
      <div className="">
        <div className="row ">
          <div className="col-md-12 text-center">
            {/* <span>Select Month</span> */}
            <div className="select">
              <label htmlFor="select">
                {this.props.localize('preview-sales-engine.static-content.copy.Labels.labels#select-month')} <br />
                <select onChange={this.onMonthDropDownChange} value={this.state.calendarPosition} id="select">
                  {this.props.offerMonthList?.map((month, index) => (
                    <option key={index} value={index}>
                      {month}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
        <div className="date-picker">
          {Array(this.props.numberOfMonths)
            .fill(1)
            .map((item, index: number) => (
              <div
                key={`calendar-container-${this.getDateForPosition(index).getFullYear()}-${this.getDateForPosition(
                  index,
                ).getMonth()}`}
                className="calendar-container"
              >
                <Calendar
                  previousMonth={index === 0 ? this.renderPreviousMonth : this.renderBlank}
                  nextMonth={index === this.props.numberOfMonths - 1 ? this.renderNextMonth : this.renderBlank}
                  year={this.getDateForPosition(index).getFullYear()}
                  month={this.getDateForPosition(index).getMonth()}
                  selectedDates={this.props.selectedDates}
                  onDayClick={this.onCalendarDayClick}
                  premiumDates={this.props.premiumDates}
                  datePriceInfo={this.props.datePriceInfo}
                  hasExtraNights={this.props.hasExtraNights}
                  isMobile={this.props.numberOfMonths === 1}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withLocalization(DatePicker);
