import React, { FC, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';

const DefaultRoute: FC<any> = (props: any): ReactElement => {
  const { history, localize } = props;
  const default_Loc = localize('preview-sales-engine.static-content.copy.pbeo.default-loc');
  if (default_Loc) {
    const searchParam = `?loc=${default_Loc}&dpt=cmd&med=org&dest=orlando&resort=grande-vista`;
    history.push({
      pathname: '/contact-information',
      search: searchParam,
    });
  }

  return <></>;
};
export default withRouter(withLocalization(DefaultRoute));
