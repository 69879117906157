import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import { useMediaQuery } from 'react-responsive';
import ParticipationDetails from '../ParticipationDetails/ParticipationDetails';
import './PackageCollapseDetails.scss';
import './PackageOverview.scss';
import { replacePlaceholder } from '../../../util/CommonLogic';
import ParseHtml from '../../common/ParseHtml';
import { capitalizeFirstLetter } from '../../../util/CommonLogic';
interface SearchPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
  packageData: any;
}
const PackageCollapseDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  //const { localize } = props;
  const { packageData, localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const [participationModalOpen, setParticipationModalOpen] = useState(false);

  const maxguestContent = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-max-guests-label');
  const updatedContent = replacePlaceholder(maxguestContent, '{guests}', `${packageData?.maxGuest}`);
  const accommodationText = packageData?.accommodation || '';
  const updatedAccomodation = capitalizeFirstLetter(accommodationText);
  const stayText = localize('preview-sales-engine.static-content.copy.pbeo.package-overview-days-nights-label');
  const updatedDays = replacePlaceholder(stayText, '{days}', packageData?.days);
  const updatedStay = replacePlaceholder(updatedDays, '{nights}', packageData?.nights);
  const openParticipationModal = () => {
    setParticipationModalOpen(true);
  };
  const closeModal = () => {
    setParticipationModalOpen(false);
  };
  return (
    <>
      {participationModalOpen && <ParticipationDetails closeModal={closeModal} />}
      <div className="packageOverviewContentWrapper">
        <div className="packageCommonRow">
          <span className="packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.stay-label')}
          </span>
          <span className="packageDescContent">{updatedStay}</span>
        </div>
        <div className="packageCommonRow">
          <span className="packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.Labels.dates-label')}
          </span>
          <span className="packageDescContent">{`Travel within ${packageData?.dates} months`}</span>
        </div>
        <div className="packageCommonRow">
          <span className="packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-accommodation-label')}
          </span>
          <span className="packageDescContent">{updatedAccomodation}</span>
        </div>
        <div className="packageCommonRow">
          <span className="packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.max-guests')}
          </span>
          <span className="packageDescContent">
            <ParseHtml htmlInput={updatedContent}></ParseHtml>
          </span>
        </div>
        {packageData?.tourGift && (
          <div className="packageCommonRow">
            <span className="packageHeaderContent">
              {localize('preview-sales-engine.static-content.copy.pbeo.package-summary-gift-label')}
            </span>
            <span className="packageDescContent">{`${packageData?.tourGift}`}</span>
          </div>
        )}
        <div className="packageOverviewDiveder"></div>
        {packageData?.retailVal && (
          <div className="packageCommonRow">
            <span className="packageHeaderContent">
              {localize('preview-sales-engine.static-content.copy.pbeo.retail-value-label')}
            </span>
            <span className="packageDescContent-bold">
              <s>{`${packageData?.retailVal}`}</s>
            </span>
          </div>
        )}
        <div className="packageCommonRow">
          <span className="packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.total-due-label')}
          </span>
          <span className="packageDescContent-bold">{`${packageData?.totalDue}`}</span>
        </div>

        <div className="packageOverviewDiveder"></div>
        <div className="packageCommonRow">
          <span className="new-packageHeaderContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.due-today-label')}
          </span>
          <span className="dueTodaycContent">{`${packageData?.dueToday}`}</span>
        </div>
        <div className="packageOverviewDiveder"></div>
        <div className="packageCommonRow">
          <span className="new-packagebalancerContent">
            {localize('preview-sales-engine.static-content.copy.pbeo.due-at-booking-label')}
          </span>
          <span className="packageDescContent-bold">{`${packageData?.dueAtBooking}`}</span>
        </div>
        <div className="packageBodyContainer">
          <span
            className="packageBodyTextWrapper"
            dangerouslySetInnerHTML={{
              __html: localize('preview-sales-engine.static-content.copy.pbeo.overview-disclaimer'),
            }}
          ></span>
          <div className="packageBodyLinkContainer">
            <button className="packageBodyLinkText" onClick={() => openParticipationModal()}>
              {localize('preview-sales-engine.static-content.copy.pbeo.view-dop-link-label')}
            </button>
          </div>
        </div>
        {isMobile && <div className="packageOverviewDiveder"></div>}
      </div>
    </>
  );
};
export default withRouter(withLocalization(PackageCollapseDetails));
