import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { AvailableDates } from '../../features/offerAvailability/types';
import { RootState } from '../../redux/rootReducer';
import withLocalization from '../hoc/withLocalization';

interface ExtraNightsSelectorType {
  maxNights: number;
  onNightChange: (n: number) => void;
  localize: any;
}
const ExtraNightsSelector: FC<ExtraNightsSelectorType> = (props: ExtraNightsSelectorType): ReactElement => {
  const { maxNights, onNightChange, localize } = props;
  const availabilityDates: AvailableDates = useSelector((state: RootState) => state.availabilityDates);
  const extraNights = availabilityDates.extraNights ? availabilityDates.extraNights : 0;
  const disableIncrementButton: boolean = extraNights >= maxNights;
  //add-one-extra-night=Add an extra night to your stay
  const disableDecrementButton: boolean = extraNights <= 0;
  const extraNightsCaption =
    maxNights === 1
      ? localize('preview-sales-engine.static-content.copy.Labels.labels#add-one-extra-night')
      : localize('preview-sales-engine.static-content.copy.Labels.labels#add-extra-night', maxNights);

  const onIncrementStay = (e) => {
    if (extraNights < maxNights) {
      onNightChange(extraNights + 1);
    }
  };
  const onDecrementStay = (e) => {
    if (extraNights > 0) {
      onNightChange(extraNights - 1);
    }
  };
  return (
    <>
      {maxNights > 0 ? (
        <div>
          <div className="extra-night-container">
            <div className="row">
              <div className="col-8">
                <span className="extra-night-caption">{extraNightsCaption}</span>
              </div>{' '}
              <div className="col-4 removePadding">
                <button
                  className="btn btn-circle btn-circle-sm m-1"
                  onClick={onDecrementStay}
                  disabled={disableDecrementButton}
                >
                  -
                </button>{' '}
                <span className="extra-night-value">{extraNights}</span>
                <button
                  className="btn btn-circle btn-circle-sm m-1"
                  onClick={onIncrementStay}
                  disabled={disableIncrementButton}
                >
                  +
                </button>{' '}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withLocalization(ExtraNightsSelector);
