import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from '../../redux/store';
import fetchResourceData from './siteContentService';
import { Content } from './type';

interface initialStateType {
  loading: boolean;
  resources: Content;
}
const initialState: initialStateType = {
  loading: false,
  resources: { messages: {}, images: {} },
};
const resourceSlice = createSlice({
  name: 'SiteContent',
  initialState,
  reducers: {
    receiveResource(state, action: PayloadAction<Content>) {
      state.resources.images = action.payload.images;
      state.resources.messages = action.payload.messages;
    },
    resourceLoading(state: initialStateType, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});
export const loadSiteContent = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(resourceSlice.actions.resourceLoading(true));

  const resource: Content = await fetchResourceData();

  dispatch(resourceSlice.actions.receiveResource(resource));

  dispatch(resourceSlice.actions.resourceLoading(false));
};
export default resourceSlice.reducer;
