/* eslint-disable */

import React from "react";


const logAnalyticData = ({ event, siteSection, pageName, pageHierarchy, server, website, language }, otherData = {}) => {
  
    window['adobeDataLayer'] = window['adobeDataLayer'] || [];
    window['adobeDataLayer'].push({
        "event":event,
        "pageInfo": {
        "siteSection":siteSection,
        "pageName":pageName, 
        "pageHierarchy":pageHierarchy,
        "server":server,
        "website":website,
        "language":language,
        ...otherData,
        }
    });
};

const trackAnalyticData = (name) => {
    window['_satellite'] && window['_satellite'].track(name);
}

export { logAnalyticData, trackAnalyticData };