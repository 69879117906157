/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { getADATitle } from '../../util/Ada';
import { fullDateFormat } from '../../util/date';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';
import { NavigationSteps } from '../OfferDetails/OfferNavigation';
import OpenDatedNotice from '../OfferDetails/OpenDatedNotice';
import PackagePrice from './PackagePriceInformation/PackagePrice';

const PackageSummary: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const selectedRoom = offerDetails.selectedRoomType;
  const availabilityDates = useSelector((state: RootState) => state.availabilityDates);
  const selectedDate = availabilityDates.finalSelectedDate;
  const offer = offerDetails.currentOffer;
  const openDateSelector = availabilityDates.openDateSelector;
  const hearingADACode = availabilityDates.hearingADACode;
  const selectedADARoomCode = availabilityDates?.roomADACode ? availabilityDates.roomADACode : '';
  const isDatedOffer: boolean =
    offerDetails && offerDetails.isDatedOffer && offerDetails.isDatedOffer === true ? true : false;

  const extraNights = availabilityDates.finalSelectedExtraNights ? availabilityDates.finalSelectedExtraNights : 0;
  const premiums =
    offer && offer.premiums
      ? offer?.premiums
          .filter((x) => x !== null)
          .join(', ')
          .trim()
      : '';

  const depositAmount = useSelector((state: RootState) => state.offerDetails.selectedRoomType?.depositAmount);

  const isPin: boolean = useSelector((state: RootState) => state.offerDetails.isPin);

  const brandPhoneNumber = isPin ? useSelector((state: RootState) => state.offerDetails.prospect?.callCenterNumber) :
   useSelector((state: RootState) => state.offerDetails.locBrandPhoneNumber!);

  const isOpenDatedOptionSelected: boolean = useSelector(
    (state: RootState) => state.availabilityDates.openDateSelector,
  );
  return (
    <div>
      <div className="packageSummaryDisplay">
        <h1 className="vacationOverviewHeader upperCase">
          {localize('preview-sales-engine.static-content.copy.prospects.vacationoverview')}
        </h1>
        <div className="headingPadding">
          <p className="bolderText upperCase">
            {localize('preview-sales-engine.static-content.copy.package-summary-component.package-details-header')}
          </p>

          <p>
            <span className="bolderText">
              {localize('preview-sales-engine.static-content.copy.package-summary-component.resort-label')}{' '}
            </span>{' '}
            <span>{selectedRoom?.property?.name}</span>
          </p>
          <p>
            <span className="bolderText">Accomodations: {localize('')} </span>
            <span>{selectedRoom?.roomTypeDescription.toLocaleLowerCase()}</span>
          </p>
          {(selectedADARoomCode || hearingADACode) && !openDateSelector ? (
            <p>
              <span className="bolderText">
                {localize(
                  'preview-sales-engine.static-content.copy.package-summary-component.accessibility-selections',
                )}{' '}
              </span>
              <span>
                {selectedADARoomCode ? localize(getADATitle(selectedADARoomCode)) : ''}
                {hearingADACode && selectedADARoomCode ? ', ' : ''}
                {hearingADACode ? localize('preview-sales-engine.static-content.copy.ADA.hearing') : ''}
              </span>
            </p>
          ) : (
            <></>
          )}
          {!openDateSelector && selectedDate && selectedDate.checkInDate && isDatedOffer ? (
            <p>
              <span className="bolderText">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.check-in-label')}{' '}
              </span>{' '}
              <span> {fullDateFormat(selectedDate.checkInDate)}</span>
            </p>
          ) : (
            <></>
          )}

          {!openDateSelector && selectedDate && selectedDate.checkOutDate && isDatedOffer ? (
            <p>
              <span className="bolderText">
                {localize('preview-sales-engine.static-content.copy.package-summary-component.check-out-label')}
              </span>{' '}
              <span>{fullDateFormat(selectedDate.checkOutDate)}</span>
            </p>
          ) : (
            <></>
          )}

          <p>
            <span className="bolderText">
              {localize('preview-sales-engine.static-content.copy.package-summary-component.nights-label')}{' '}
            </span>{' '}
            <span>{selectedRoom?.numberOfNights}</span>
          </p>
          {!openDateSelector && isDatedOffer && extraNights > 0 ? (
            <p>
              <span className="bolderText">
                {localize('preview-sales-engine.static-content.copy.Labels.labels#extra-nights')}{' '}
              </span>
              <span>{extraNights}</span>
            </p>
          ) : (
            <></>
          )}
          <p>
            <span className="bolderText">
              {localize('preview-sales-engine.static-content.copy.Confirmation Page.max-guests')}{' '}
            </span>
            <span>{selectedRoom?.sleepCapacity}</span>
          </p>

          {premiums ? (
            <p>
              <span className="bolderText textTransformNormal">
                {localize('preview-sales-engine.static-content.copy.prospects.plustext')}
              </span>{' '}
              <ParseHtml htmlInput={premiums}></ParseHtml>
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="headingPadding">
          <p className="bolderText upperCase" style={{ marginTop: '15px' }}>
            {localize('preview-sales-engine.static-content.copy.package-summary-component.package-price-header')}
          </p>
          <PackagePrice isDatedOffer={isDatedOffer} />
          <div className="noticeWrapper">
            <OpenDatedNotice
              noticeLocation={NavigationSteps.OFFER_ELIGIBILITY}
              depositAmount={depositAmount}
              showNotice={!isDatedOffer || isOpenDatedOptionSelected}
              brandPhoneNumber={brandPhoneNumber}
            ></OpenDatedNotice>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalization(PackageSummary);
