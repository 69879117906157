/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';
import { Modal } from 'react-bootstrap';
import { RootState } from '../../../redux/rootReducer';
import './ParticipationDetails.scss';
import galleryModalCloseLogo from '../../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import ParseHtml from '../../common/ParseHtml';
interface ImgModalProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
}

const ParticipationDetails: FC<any> = (props: ImgModalProps): ReactElement => {
  const { closeModal } = props;
  const handleKeyDown = () => {};
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const offerData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const detailsContent = offerData?.dopCodeDetails || '';

  //localize('preview-sales-engine.static-content.copy.pbeo.due-at-booking-label')
  return (
    <div className="modal-overlay-part">
      <div className="modal-part">
        <div className="modal-part-head-container">
          <div className="modal-part-close">
            <input
              className="modal-close-btn"
              src={galleryModalCloseLogo}
              type="image"
              onClick={closeModal}
              aria-label="close"
            />
          </div>
        </div>
        <div className="modal-content-part">
          <ParseHtml htmlInput={detailsContent}></ParseHtml>
        </div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(ParticipationDetails)));
