import { CardSummary, CardType, PaymentToken } from './types';

export const getSupportedCards = (): CardType[] => {
  const cardListFromConfig = process.env.REACT_APP_ACCEPTED_CARDS;
  if (cardListFromConfig) {
    const supportCards = cardListFromConfig.split(',').map((x) => {
      const cardDetails = x.split('-');
      const card: CardType = {
        cardId: cardDetails[0],
        cardName: cardDetails[1],
      };
      return card;
    });
    return supportCards;
  }
  return [];
};
const getCardNameFromType = (cardType: string): string => {
  const supportedCard = getSupportedCards();
  const filteredCardNames = supportedCard.filter((x) => x.cardId === cardType);
  const cardName = filteredCardNames && filteredCardNames.length > 0 ? filteredCardNames[0].cardName : '';
  return cardName;
};
export const getMaskedCardInformationFromToken = (token: string): CardSummary => {
  const tokenObject: PaymentToken = JSON.parse(atob(token.split('.')[1]));
  const maskedCardNumber = tokenObject.content.paymentInformation.card.number.maskedValue;
  const { expirationMonth, expirationYear, type } = tokenObject.data;
  const expiration =
    expirationYear.length > 2 ? `${expirationMonth}${expirationYear.substr(2)}` : `${expirationMonth}${expirationYear}`;
  const cardName = getCardNameFromType(type);
  const cardSummary: CardSummary = {
    cardType: cardName,
    expiration: expiration,
    maskedCardNumber: maskedCardNumber,
  };

  return cardSummary;
};
