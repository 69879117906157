import React, { FC, ReactElement } from 'react';
import { localizeType } from '../../commonType';
import withLocalization from '../hoc/withLocalization';
import { useMediaQuery } from 'react-responsive';
import '../OfferDetails/ADASelector.scss';

interface ADAProps {
  localize: localizeType;
}

const PremiumTextDisplay: FC<ADAProps> = (props: ADAProps): ReactElement => {
  const { localize } = props;

  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  return (
    <>
      <div className="row checkBoxWrapper">
        <div className="col-sm-12">
          <ul className="premiumTextHighSeason">
            <li>{localize('preview-sales-engine.static-content.copy.calendar-page.high-season-symbol')}</li>
          </ul>
          {!isMobile ? (
            <p className="footerText">
              {localize('preview-sales-engine.static-content.copy.prospects.surcharge-condition')}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isMobile ? (
        <div className="custom-hr">
          <hr />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withLocalization(PremiumTextDisplay);
