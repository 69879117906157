import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Offer, RoomType } from '../../features/offerSearch/types';
import { RootState } from '../../redux/rootReducer';
import { getFormattedCurrencyValueByBrand } from '../../util';
import DopInformation from '../common/DopInformation/DopInformation';
import ParseHtml from '../common/ParseHtml';
// import ParseHtml from '../common/ParseHtml';
import TravelDateInfo from '../common/TravelDateInfo/TravelDateInfo';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';
import './SelectedOffer.scss';

interface SelectedOffeProps {
  localize?: any;
  selectedRoom: RoomType;
  brand?: string;
  isMobile?: boolean;
}

const SelectedOffer: FC<any> = (props: SelectedOffeProps): ReactElement => {
  const { localize, isMobile } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const selectedRoom = offerDetails.selectedRoomType;
  const offer: Offer | undefined = offerDetails.currentOffer;
  const calculatedNumberOfDays =
    selectedRoom && selectedRoom.numberOfDays ? parseInt(selectedRoom.numberOfDays, 10) : 0;
  const calculatedNumberOfNights =
    selectedRoom && selectedRoom.numberOfNights ? parseInt(selectedRoom.numberOfNights, 10) : 0;
  let extraNights = useSelector((state: RootState) => state.availabilityDates.extraNights);
  extraNights = extraNights ? extraNights : 0;
  const premiums =
    offer && offer.premiums
      ? offer?.premiums
          .filter((x) => x !== null)
          .join(', ')
          .trim()
      : '';

  return (
    <div className="selectedAccomodations">
      <h1 className="selectedHeader">
        {localize('preview-sales-engine.static-content.copy.prospects.selected-accomodations')}
      </h1>
      <div className="row flipOfferContainer offerDisplay">
        <div className="col-sm-9">
          <p className="propertyNameInDates">{selectedRoom?.property?.name}</p>
          {localize('preview-sales-engine.static-content.copy.select-offer-page.days-nights', [
            calculatedNumberOfDays,
            calculatedNumberOfNights,
          ])}
          {extraNights > 0 ? (
            <>
              <div className="vr"></div>
              {localize('preview-sales-engine.static-content.copy.Labels.labels#extra-nights')} {extraNights}
            </>
          ) : (
            <></>
          )}
          <div className="vr"></div>
          {localize('preview-sales-engine.static-content.copy.select-offer-page.max-guests')}{' '}
          {selectedRoom?.sleepCapacity}
          {premiums ? (
            <p className="premiums">
              <ParseHtml
                htmlInput={`${localize('preview-sales-engine.static-content.copy.prospects.plustext')} ${premiums}`}
              ></ParseHtml>
            </p>
          ) : (
            <></>
          )}
          <div className="roomCostWrapper">
            <span className="roomDescription">{selectedRoom?.roomTypeDescription.toLocaleLowerCase()}</span>{' '}
            {localize('preview-sales-engine.static-content.copy.prospects.from-label').toLocaleLowerCase()}{' '}
            <span className="cost">{getFormattedCurrencyValueByBrand(selectedRoom?.startingCost, props.brand)}</span>{' '}
            {localize('preview-sales-engine.static-content.copy.prospects.perstay')}
          </div>
          <DopInformation dopCodeDetails={offer?.dopCodeDetails} />
          <div className="travelDateInfo">
            <TravelDateInfo
              lowSeasonDateRanges={offer?.lowSeasonDateRanges}
              highSeasonDateRanges={offer?.highSeasonDateRanges}
              placement="top"
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLocalization(withBrands(SelectedOffer));
