/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { logAnalyticData, trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import ViewDestination from '../../components/OfferDestination/DestinationInformation';
import { setLoadingStatus } from '../../features/offerAvailability/offerAvailabilitySlice';
import { flipOffer, setFlipOfferDisplayMode } from '../../features/offerSearch/offerSearchSlice';
import { Offer, OfferDetails } from '../../features/offerSearch/types';
import { RootState } from '../../redux/rootReducer';
import { getCurrentBrand } from '../../util';
import { VIEW_OFFERS_PATH } from '../../util/strings';
import withLocalization from '../hoc/withLocalization';

interface FlipOfferPropType {
  flipOffers: Offer[] | [] | undefined;
  history?: any;
  localize?: localizeType;
  allFlipOffresExcluded: boolean;
}
const FlipOffer: FC<FlipOfferPropType> = (props: FlipOfferPropType): ReactElement => {
  const { flipOffers, localize, allFlipOffresExcluded } = props;
  const dispatch = useDispatch();
  const hasFlipOffers = flipOffers && flipOffers.length > 0;
  const displayFlipOffers: boolean = useSelector((state: RootState) => state.offerDetails.displayFlipOffers);
  const offerDetails: OfferDetails = useSelector((state: RootState) => state.offerDetails);

  const onShowFlipOfferClicked = (e) => {
    dispatch(setLoadingStatus(true));
    dispatch(setFlipOfferDisplayMode());
    setTimeout(() => {
      dispatch(setLoadingStatus(false));
    }, 10);
  };
  const onHandleClicked = (OfferCode: string) => {
    const selectFlipOffer: Offer = flipOffers!.filter((val: Offer) => val.offerCode === OfferCode)[0];
    let brandName = getCurrentBrand();

    logAnalyticData(
      {

          event:"Step2-SelectResort",
          siteSection:"Preview Booking Engine",
          pageName:"PBE – Select Resort", 
          pageHierarchy:"",
          server:"MVC",
          website:"MVC react",
          language:"en"  
      },
      {
          resortID:"[Resort ID]",
          resortName: selectFlipOffer?.property?.name,
          resortCity: selectFlipOffer?.property?.city,
          resortState: selectFlipOffer?.property?.stateCode,
          resortCountry:"[Resort Country]",
          resortPageType:"[Resort Page Type]",
          resortMarsha: selectFlipOffer?.property?.marshaCode,
          resortBrand:brandName,
          formLOC: offerDetails.searchParameter,
          flowIdentifier: selectFlipOffer?.showCalendar ? "No Pin with Calendar " : "No Pin without Calendar",
          bookingStepName:"Step 2 - Select Resort"
      }         
    );

    trackAnalyticData('FindMyOffer');
    dispatch(setLoadingStatus(true));
    dispatch(flipOffer(OfferCode));
    props.history.push(VIEW_OFFERS_PATH);
  };
  return (
    <>
      {hasFlipOffers ? (
        <div className="container-flex propertyWrapper">
          {displayFlipOffers ? (
            <>
              <div className="flipOfferContainer">
                <div className="col-md-12 flipOfferText">
                  <h2 className="alterNateOfferHeading">
                    {localize ? localize('preview-sales-engine.static-content.copy.prospects.alternateoffers') : ''}
                  </h2>
                  <hr></hr>
                </div>
              </div>
              <div>
                {flipOffers && !allFlipOffresExcluded ? (
                  flipOffers.map((offer, index) => (
                    <ViewDestination
                      offer={offer}
                      key={index}
                      onViewOfferClicked={() => onHandleClicked(offer.offerCode)}
                    />
                  ))
                ) : allFlipOffresExcluded ? (
                  <>
                    <Alert variant="info">
                      {localize
                        ? localize(
                            'preview-sales-engine.static-content.copy.prospects.no-offers-found-for-selected-location-alternate',
                          )
                        : ''}
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-12 text-center">
                <button className="btn btn-primary alternateOfferButton" onClick={onShowFlipOfferClicked}>
                  {localize ? localize('preview-sales-engine.static-content.copy.prospects.alternateresorts') : ''}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(withRouter(withLocalization(FlipOffer)));
