import React, { FC, ReactElement } from 'react';
import withLocalization from '../../hoc/withLocalization';
import ParseHtml from '../ParseHtml';
import './ErrorCustom.scss';

const ErrorCustom: FC<any> = (props: any): ReactElement => {
  const [seconds, setSeconds] = React.useState(20);
  const { localize } = props;

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      // eslint-disable-next-line no-restricted-globals
      window.location.href = '/';
    }
  });
  return (
    <React.Fragment>
      <div className="container customError">
        <div>
          <ParseHtml
            htmlInput={localize('preview-sales-engine.static-content.copy.Labels.generic-error-message', seconds)}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default React.memo(withLocalization(ErrorCustom));
