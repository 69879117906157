/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import OfferFooter from '../../components/common/CommonOfferComponent/OfferFooter/OfferFooter';
import './NewOfferPaymentDetailsContainer.scss';
import PackageOverview from '../../components/NewOffer/PackageOverviewPage/PackageOverview';
import OfferStep from '../../components/common/CommonOfferComponent/BreadcrumbStep/OfferStep';
import { useMediaQuery } from 'react-responsive';
import PaymentDetailsPage from '../../components/NewOffer/PaymentDetailsPage/PaymentDetailsPage';
import { RootState } from '../../redux/rootReducer';
import ErrorPageContainer from '../newOffer/ErrorPageContainer';
import { logAnalyticData } from '../../analytics';
const NewOfferPaymentDetails: FC<any> = (props: any): ReactElement => {
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const { localize, history } = props;
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const isContextErr: any = useSelector((state: RootState) => state.newOfferPaymentDetails.hasPaymentContextError);
  const confirmationDetails = useSelector((state: RootState) => state.newOfferPaymentDetails.confirmationDetails);

  const title = localize('preview-sales-engine.static-content.copy.pbeo.payment-details-header');
  const PhoneNo = (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) || '';
  const receiveOfferLoc = (newOfferData?.offers && newOfferData?.offers[0]?.loc) || '';

  if (!receiveOfferLoc) {
    history.push({
      pathname: '/error',
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const confirmationStatus = confirmationDetails?.status?.toLowerCase() || '';
    const ConfirmationNumber = confirmationDetails?.ConfirmationNumber || '';
    if (
      confirmationStatus === 'success' ||
      confirmationStatus === 'pending' ||
      ConfirmationNumber ||
      confirmationDetails === 'no-details-available'
    ) {
      const getUrlSearchParam = sessionStorage.getItem('urlSearchParam');
      history.push('/thank-you' + getUrlSearchParam);
      /*  history.push({
        pathname: '/thank-you',
        search: `?loc=${receiveOfferLoc}`,
      });*/
    }
  }, [confirmationDetails]);
  return (
    <>
      <OfferHeader phone={PhoneNo} isThankyou={false} />
      <div className="tmvc-payment-main-container">
        <div className="resevre-package-container">
          {!isMobile && <OfferStep step={'2'} title={title} />}
          <PaymentDetailsPage />
        </div>
        <PackageOverview />
        {isMobile && <OfferStep step={'2'} title={title} />}
      </div>
      {/* <OfferFooter /> */}
    </>
  );
};
export default withRouter(withLocalization(NewOfferPaymentDetails));
