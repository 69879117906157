/* eslint-disable */
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withLocalization from '../../components/hoc/withLocalization';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import OfferFooter from '../../components/common/CommonOfferComponent/OfferFooter/OfferFooter';
import OrderSummaryDetails from '../../components/NewOffer/OrderSummaryDetails/OrderSummaryDetails';
import { useMediaQuery } from 'react-responsive';
import ThankYouDetailsPage from '../../components/NewOffer/ThankYouDetailsPage/ThankYouDetailsPage';
import { RootState } from '../../redux/rootReducer';
import { clearPayerAuthDetails, resetconfirmationDetails } from '../newOfferPayment/NewOfferPaymentSlice';
import ErrorPageContainer from '../newOffer/ErrorPageContainer';
import './NewOfferThankYou.scss';
import TravelInspiration from '../../components/NewOffer/TravelInspiration/TravelInspiration';
//import { fetchOfferDetails } from './NewOfferSlice';

const NewOfferThankYou: FC<any> = (props: any): ReactElement => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const { localize } = props;
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const offerDetails = (newOfferData?.offers && newOfferData?.offers[0]) || {};
  const PhoneNo = offerDetails?.locPhoneNumber || '';
  const receiveOfferLoc = offerDetails?.loc || '';

  useEffect(() => {
    dispatch(clearPayerAuthDetails());
    /*setTimeout(() => {
      dispatch(resetconfirmationDetails());
    }, 100);*/
  }, [dispatch]);
  if (!receiveOfferLoc) {
    return <ErrorPageContainer phone={PhoneNo} />;
  }
  return (
    <>
      <OfferHeader phone={PhoneNo} isThankyou={true} />
      <div className="tmvc-thankYou-main-container">
        <div className="thankYou-info-page-container">
          <ThankYouDetailsPage />
          {/* {!isMobile && <TravelInspiration />}*/}
        </div>
        <OrderSummaryDetails />
        {isMobile && (
          <div className="thankYou-info-page-container">
            {/*
            Todo work on future 
            <TravelInspiration />
            */}
          </div>
        )}
      </div>

      {/* <OfferFooter /> */}
    </>
  );
};
export default withRouter(withLocalization(NewOfferThankYou));
