import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { getFormattedPhoneNumberForBrands } from '../../util';
//import './PackageSummaryReview.scss';
import { States_US } from '../../util/States';
import withBrands from '../hoc/withBrands';
import withLocalization from '../hoc/withLocalization';

const ContactInformationReview: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const offerDetails = useSelector((state: RootState) => state.offerDetails);
  const prospect = offerDetails.prospectCopy;
  const guestInfo = offerDetails.guests;
  const stateName =
    prospect && prospect.stateProvince
      ? prospect.stateProvince.length > 2
        ? prospect.stateProvince
        : States_US.filter((e) => e.code === prospect.stateProvince)[0].name.toLocaleLowerCase()
      : '';

  return (
    <>
      <div className="packageSummaryReviewDisplay">
        <h1 className="confirmationHeader">
          {localize('preview-sales-engine.static-content.copy.contact-information-page.header')}
        </h1>
        <div className="" id="prospectInformation">
          <div className="css-table">
            <div className="css-table-body">
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.first-name')}
                </div>
                <div>{prospect?.firstName}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.last-name')}
                </div>
                <div>{prospect?.lastName}</div>
              </div>
              {prospect?.spouseFirstName ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.prospects.spousefirstname')}:
                  </div>
                  <div>{prospect?.spouseFirstName}</div>
                </div>
              ) : (
                <></>
              )}
              {prospect?.spouseLastName ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.prospects.spouselastname')}:
                  </div>
                  <div>{prospect?.spouseLastName}</div>
                </div>
              ) : (
                <></>
              )}
              <div className="css-table-row">
                <div className="bolderText paddingTop">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.street-address')}
                </div>
                <div className="paddingTop">{prospect?.addressLine1}</div>
              </div>
              {prospect?.unitNo ? (
                <div className="css-table-row">
                  <div className="bolderText">
                    {localize('preview-sales-engine.static-content.copy.Labels.labels#unit-no-label')}
                  </div>
                  <div>{prospect?.unitNo}:</div>
                </div>
              ) : (
                <></>
              )}
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.city')}
                </div>
                <div>{prospect?.city}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.state')}
                </div>
                <div>{stateName}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.zip-code')}
                </div>
                <div>{prospect?.postalCode}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText paddingTop">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.email')}
                </div>
                <div className="paddingTop">{prospect?.email}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.phone')}
                </div>
                <div>{getFormattedPhoneNumberForBrands(prospect?.phone, props.brand)}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.adults')}
                </div>
                <div>{guestInfo ? guestInfo.adults : ''}</div>
              </div>
              <div className="css-table-row">
                <div className="bolderText">
                  {localize('preview-sales-engine.static-content.copy.Confirmation Page.children')}
                </div>
                <div>{guestInfo ? guestInfo.children : ''}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withLocalization(withBrands(ContactInformationReview));
