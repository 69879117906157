import React from 'react';
import { useSelector } from 'react-redux';
import { vsprintf } from 'sprintf-js';
import util from 'util';
import { Content } from '../../features/siteContent/type';
import { RootState } from '../../redux/rootReducer';

const withLocalization = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const content: Content = useSelector((state: RootState) => state.siteContent.resources);
    const resources = content.messages;
    const images = content.images;

    const localizeObject = (key: string): any => {
      try {
        return resources[key];
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    const localize = (key: string, values: string[]): string => {
      try {
        let lookup = resources[key];
        if (!lookup) {
          const keys = key.split('#');
          if (keys.length > 1) {
            lookup = resources[keys[0]][keys[1]];
          }
        }

        if (!lookup && typeof lookup !== 'string') {
          return '';
        }

        if (values) {
          return vsprintf(lookup, values);
        }

        return util.format(lookup);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return '';
    };
    const localizeImages = (key: string, index: number, size: string) => {
      try {
        const image = images[key][index];
        const imageDetail = image.photo[size];
        const path = imageDetail.path;
        const description = image.altText;
        return { path, description };
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return { path: '', description: '' };
    };
    return (
      <>
        <Component
          localizeObject={localizeObject}
          localize={localize}
          localizeImages={localizeImages}
          {...props}
        ></Component>
      </>
    );
  };
};
export default withLocalization;
