import React, { FC, ReactElement } from 'react';
import withBrands from '../../../hoc/withBrands';
import { useMediaQuery } from 'react-responsive';
import withLocalization from '../../../hoc/withLocalization';
import phoneLogo from '../../../../features/siteTheme/Brands/tmvcs/logo/phone.svg';
import './OfferHeader.scss';
interface HeaderProps {
  localize?: any;
  brand?: any;
  localizeImages?: any;
  phone?: any;
  isThankyou?: any;
}
const OfferHeader: FC<any> = (props: HeaderProps): ReactElement => {
  const { localize, phone, isThankyou } = props;
  //const { localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const locPhNo = phone || localize('preview-sales-engine.static-content.copy.pbeo.default-loc-phone');
  const thankPhone = localize('preview-sales-engine.static-content.copy.pbeo.default-activation-phone');

  const phoneDigit = isThankyou ? thankPhone : locPhNo;
  const path =
    'https://content-qa.vistana.com/files/live/sites/vistana-digital-content-manager/files/images/brand-logos/RAD-1331_TMVCS_StackedLogo_TMVCk-201261_Black.png';

  const handleClick = () => {
    window.location.href = `tel:${phoneDigit}`;
  };
  return (
    <>
      <React.Fragment>
        <>
          <header className="topHeaderContainer">
            <div className="header-section-wrapper">
              <div className="innerHeaderContainer">
                <div className="img-head-wrapper">
                  <img src={path} alt={'brand-logo'} className="img-header-logo" />
                </div>
                <div className="phoneHeaderWraper">
                  {isMobile ? (
                    <a className="img-phone-logo" href={`tel:${phoneDigit}`} onClick={handleClick}>
                      <img src={phoneLogo} alt="Call Now" />
                    </a>
                  ) : (
                    <div className="top-textHeaderWraper">
                      <span className="textHeaderWraper">
                        {localize('preview-sales-engine.static-content.copy.pbeo.have-questions-label')}
                      </span>
                      <a className="textHeaderLinkWraper" href={`tel:${phoneDigit}`}>
                        {phoneDigit}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>
        </>
      </React.Fragment>
    </>
  );
};
export default React.memo(withLocalization(withBrands(OfferHeader)));
