/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router';
import { trackAnalyticData } from '../../analytics';
import { localizeType } from '../../commonType';
import { setLoadingStatus } from '../../features/offerAvailability/offerAvailabilitySlice';
import {
  OFFER_CONFIRMATION_PATH,
  OFFER_CONTACT_INFO_PATH,
  OFFER_DATES_PATH,
  OFFER_DESTINATION_PATH,
  OFFER_ELIGIBILITY_PATH,
  OFFER_REVIEW_PATH,
  // eslint-disable-next-line prettier/prettier
  VIEW_OFFERS_PATH
} from '../../util/strings';
import withLocalization from '../hoc/withLocalization';

interface OfferNavigationProps {
  localize: localizeType;
  stepIndex: any;
  currentStep: NavigationSteps;
  isDatedOffer: boolean;
  history?: any;
}
interface NavigationStep {
  stepKey: NavigationSteps;
  stepTextKey: string;
  isIncluded: boolean;
  navigationUrl: string;
}
export enum NavigationSteps {
  // eslint-disable-next-line no-unused-vars
  OFFER_DESTINATION,
  // eslint-disable-next-line no-unused-vars
  OFEFR_DETAILS,
  // eslint-disable-next-line no-unused-vars
  OFFER_DATES,
  // eslint-disable-next-line no-unused-vars
  OFFER_ELIGIBILITY,
  // eslint-disable-next-line no-unused-vars
  OFFER_CONTACT_INFO,
  // eslint-disable-next-line no-unused-vars
  OFFER_REVIEW,
  // eslint-disable-next-line no-unused-vars
  OFFER_CONFIRMATION,
  // eslint-disable-next-line no-unused-vars
  OFFER_SEARCH,
  // eslint-disable-next-line no-unused-vars
  STATE_SEARCH,
}

const navigationStepCollection: NavigationStep[] = [
  {
    stepKey: NavigationSteps.OFFER_DESTINATION,
    stepTextKey: 'preview-sales-engine.static-content.copy.prospects.selectresort',
    isIncluded: true,
    navigationUrl: OFFER_DESTINATION_PATH,
  },
  {
    stepKey: NavigationSteps.OFEFR_DETAILS,
    stepTextKey: 'preview-sales-engine.static-content.copy.prospects.selectaccomodations',
    isIncluded: true,
    navigationUrl: VIEW_OFFERS_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_DATES,
    stepTextKey: 'preview-sales-engine.static-content.copy.calendar-page.header',
    isIncluded: true,
    navigationUrl: OFFER_DATES_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_ELIGIBILITY,
    stepTextKey: 'preview-sales-engine.static-content.copy.select-destination-page.dop-link',
    isIncluded: true,
    navigationUrl: OFFER_ELIGIBILITY_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_CONTACT_INFO,
    stepTextKey: 'preview-sales-engine.static-content.copy.prospects.yourinformation',
    isIncluded: true,
    navigationUrl: OFFER_CONTACT_INFO_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_REVIEW,
    stepTextKey: 'preview-sales-engine.static-content.copy.prospects.reviewvacationdetails',
    isIncluded: true,
    navigationUrl: OFFER_REVIEW_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_CONFIRMATION,
    stepTextKey: 'preview-sales-engine.static-content.copy.navigation-bar.confirmation',
    isIncluded: true,
    navigationUrl: OFFER_CONFIRMATION_PATH,
  },
  {
    stepKey: NavigationSteps.OFFER_SEARCH,
    stepTextKey: '',
    isIncluded: false,
    navigationUrl: '/',
  },
  {
    stepKey: NavigationSteps.STATE_SEARCH,
    stepTextKey: '',
    isIncluded: false,
    navigationUrl: '/verify',
  },
];

const OfferNavigation: FC<OfferNavigationProps> = (props: OfferNavigationProps): ReactElement => {
  const { currentStep, isDatedOffer, localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 650px)` });
  const dispatch = useDispatch();
  const navGetFilter = (idx: number) => {
    const localizedText = navigationStepCollection
      // eslint-disable-next-line array-callback-return
      .filter((val, index) => {
        return index === idx;
      })
      .map((e, idx) => {
        return localize(e.stepTextKey);
      });
    return localizedText;
  };

  if (!isDatedOffer) {
    navigationStepCollection[2].isIncluded = false;
  } else {
    navigationStepCollection[2].isIncluded = true;
  }
  let currentStepIndex = 0;
  for (let index = 0; index <= navigationStepCollection.length; index++) {
    if (navigationStepCollection[index].stepKey === currentStep) {
      break;
    }
    currentStepIndex++;
  }
  let loopIndex: number = 0;
  const onClickButton = (navigationUrl) => {
    /**
     * determine which step that they are going back to
     */
    switch(navigationUrl) {
      case OFFER_DESTINATION_PATH:
        trackAnalyticData('Select Resort');
        break;

      // case VIEW_OFFERS_PATH:
      //   trackAnalyticData('Select accomodations');
      //   break;

      // case OFFER_DATES_PATH:
      //   trackAnalyticData('Select dates');
      //   break;

      // case OFFER_ELIGIBILITY_PATH:
      //   trackAnalyticData('Select details of participation');
      //   break;
      
      case OFFER_CONTACT_INFO_PATH:
        trackAnalyticData('Your information');
        break;
    }
    dispatch(setLoadingStatus(true));
    props.history.push(navigationUrl);
    setTimeout(() => {
      dispatch(setLoadingStatus(false));
    }, 10);
  };

  const getNavItems = navigationStepCollection
    .filter((x) => x.isIncluded)
    .map((e, index) => {
      let buttonClassName = e.stepKey === currentStep ? 'nav-link active upperCase' : 'nav-link upperCase';
      let buttonEnableStatus = !(loopIndex < currentStepIndex);
      buttonEnableStatus = currentStep === NavigationSteps.OFFER_CONFIRMATION ? true : buttonEnableStatus;
      loopIndex = loopIndex + 1;
      return (
        <li key={index} className="nav-item">
          <button
            key={loopIndex}
            disabled={buttonEnableStatus}
            className={buttonClassName}
            onClick={() => onClickButton(e.navigationUrl)}
          >
            {loopIndex}
            {'. '} {localize ? localize(e.stepTextKey) : ''}
          </button>
        </li>
      );
    });

  const getNavItemsList = getNavItems;

  const getOptions = () => {
    let arrayItems: JSX.Element[] = [];
    for (let i = currentStep - 1; i >= 0; i--) {
      navigationStepCollection
        .filter((x, indx) => x.isIncluded && indx === i)
        .map((e, index) => {
          return arrayItems.push(
            <Dropdown.Item key={e.stepKey} eventKey="1" onClick={() => onClickButton(e.navigationUrl)}>
              {localize(e.stepTextKey)}
            </Dropdown.Item>,
            <Dropdown.Divider key={e.stepTextKey} />,
          );
        });
    }

    arrayItems.pop();
    return arrayItems;
  };

  return (
    <React.Fragment>
      {!isMobile ? (
        <div className="navBg">
          <div className="nav-container">
            <ul className="nav nav-pills">{getNavItems}</ul>
          </div>
        </div>
      ) : (
        <div className="container-responsive">
          <hr />
          <div className="container circular-naviagtion-container">
            {!isDatedOffer && currentStep - 1 === getNavItemsList.length - 1 ? (
              <div className="navigation-steps">
                <span className="current-selected-path">{navGetFilter(currentStep)}</span>
              </div>
            ) : isDatedOffer && currentStep === getNavItemsList.length - 1 ? (
              <div className="navigation-steps">
                <span className="current-selected-path">{navGetFilter(currentStep)}</span>
              </div>
            ) : currentStep > 0 ? (
              <div className="navigation-steps">
                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                <DropdownButton
                  id="dropdown-item-button"
                  className="current-selected-path"
                  title={navGetFilter(currentStep)}
                >
                  {getOptions()}
                </DropdownButton>

                <span className="next-selected-path">
                  {localize('preview-sales-engine.static-content.copy.prospects.nexttext')}
                  {' : '}
                  {!isDatedOffer && currentStep === 1 ? navGetFilter(currentStep + 2) : navGetFilter(currentStep + 1)}
                </span>
              </div>
            ) : (
              <div className="navigation-steps">
                <span className="current-selected-path">{navGetFilter(currentStep)}</span>
                <span className="next-selected-path">
                  {localize('preview-sales-engine.static-content.copy.prospects.nexttext')}
                  {' : '}
                  {navGetFilter(currentStep + 1)}
                </span>
              </div>
            )}
            <div className="circular-nav">
              <CircularProgressbar
                value={
                  !isDatedOffer && currentStep > 1
                    ? (100 / getNavItemsList.length) * currentStep
                    : (100 / getNavItemsList.length) * (currentStep + 1)
                }
                text={
                  !isDatedOffer && currentStep > 1
                    ? `${currentStep} of ${getNavItemsList.length}`
                    : `${currentStep + 1} of ${getNavItemsList.length}`
                }
                strokeWidth={5}
                className="custom-circular-bar"
              />
            </div>
          </div>
          <hr />
        </div>
      )}
    </React.Fragment>
  );
};
export default withRouter(withLocalization(OfferNavigation));