/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import apiClient from '../../apiClient';
import { paymentRequestType, PaymentContextResponse } from './types';
import { createError, UNABLE_TO_CONTACT_PROVIDER } from '../../util';

export const submitBookingPayment = async (payload: paymentRequestType): Promise<any> => {
  let returnValue: any = {
    PaymentError: false,
    loading: false,
    paymentModalError: false,
    paymentModalErrMsg: '',
    paymentErrStatus: '',
    paymentErrCode: '',
    byPassError: false,
  };
  const bookPaymentApiUrl: string = process.env.REACT_APP_MAKE_PAYMENT_ENDPOINT || '';
  //const bookPaymentApiUrl = `https://at03-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/cconfirmPayment`;
  try {
    const confirmPaymentResponse = await apiClient.post<paymentRequestType, any>(bookPaymentApiUrl, payload);
    const responseStatus = confirmPaymentResponse?.status?.toLowerCase() || 'unknown';
    const statusArr = ['success', 'pending', 'pending_authentication'];
    if (statusArr.includes(responseStatus) || confirmPaymentResponse?.ConfirmationNumber) {
      returnValue.confirmationDetail = confirmPaymentResponse;
    } else {
      returnValue.paymentModalError = true;
      returnValue.paymentModalErrMsg = 'payment error' || '';
      returnValue.paymentErrStatus = 'DEFAULT_ERROR';
    }
  } catch (e: any) {
    const { status } = e?.response || 504;
    const errStatus = e?.response?.data?.errorCode || 'DEFAULT_ERROR';
    if (status >= 400 && status < 500) {
      returnValue.paymentModalError = true;
      returnValue.paymentModalErrMsg = 'payment error' || '';
      returnValue.paymentErrStatus = errStatus;
    } else {
      const errCode = errStatus.toUpperCase();
      // const errCode: any = 'IIB_ERROR';
      if (errCode === 'PAYMENT_FAILED' || errCode === 'PAYMENT_DECLINED' || errCode === 'INVALID_STATE') {
        returnValue.paymentModalError = true;
        returnValue.paymentErrStatus = errStatus;
        returnValue.paymentModalErrMsg = 'payment error' || '';
      } else if (errCode === 'IIB_ERROR' || errCode === 'PAYMENT_DONE_BOOKING_FAILED') {
        /*returnValue.paymentErrStatus = errCode;
        returnValue.paymentModalError = true;
        returnValue.paymentModalErrMsg = 'payment error' || '';*/
        returnValue.confirmationDetail = 'no-details-available';
      } else {
        returnValue.PaymentError = true;
      }
    }
  }
  return returnValue;
};

export const getPaymentContext = async (merchanrGroupId: string, origin: string): Promise<PaymentContextResponse> => {
  const contextUrl = `${process.env.REACT_APP_PAYMENT_CONTEXT_ENDPOINT}`;
  const requestUrl = `${contextUrl}?targetOrigin=${origin}&merchantGroupId=${merchanrGroupId}`;
  const returnValue: PaymentContextResponse = {
    hasError: false,
  };
  try {
    const response = await apiClient.get<PaymentContextResponse>(requestUrl);
    returnValue.status = response.status;
    returnValue.contextTokens = response.contextTokens;
    if (response.status !== 'success') {
      returnValue.hasError = true;
    }
  } catch (e) {
    returnValue.hasError = true;
  }
  return returnValue;
};
export const createLeadBeforeBooking = async (payload: any): Promise<any> => {
  let returnValue: any = {
    hasLeadError: false,
    loading: false,
    hasOffers: false,
  };
  const leadApiUrl: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_CREATE_LEAD_REQUEST}`;
  //const leadApiUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/createLeadRequestInfo`;
  try {
    const leadCreateResponse = await apiClient.post<any, any>(leadApiUrl, payload);
    returnValue.leadDetail = leadCreateResponse;
  } catch (e: any) {
    returnValue.hasLeadError = true;
  }
  return returnValue;
};
export const setUpPayerAuth = async (payload: any): Promise<any> => {
  let returnValue: any = {
    authError: false,
    loading: false,
    payAuthResponse: '',
  };
  const requestUrl: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_SETUP_PAYER_AUTH_ENDPOINT}`;
  // const requestUrl = `https://id01-msb-vo.mvwc.com/api/sys/payment/v1/payer/setup-authentication`;
  //const requestUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/payer/setup-authentication`;
  try {
    const Response = await apiClient.post<paymentRequestType, any>(requestUrl, payload);
    returnValue.payAuthResponse = Response;
  } catch (e: any) {
    console.log(e);
    returnValue.authError = true;
  }
  return returnValue;
};
export const paymentAuth = async (payload: any): Promise<any> => {
  let returnValue: any = {
    paymentAuthError: false,
    loading: false,
    paymentAuthResponse: '',
  };
  const requestUrl: string = `${process.env.REACT_APP_MAKEPAYMENT_ENDPOINT}`;
  //const requestUrl = `https://id01-msb-vo.mvwc.com/api/sys/payment/v1/payment`;
  try {
    const Response = await apiClient.post<paymentRequestType, any>(requestUrl, payload);
    returnValue.paymentAuthResponse = Response;
  } catch (e: any) {
    console.log(e);
    returnValue.paymentAuthError = true;
  }
  return returnValue;
};
export const paymentConfirmation = async (payload: any): Promise<any> => {
  let returnValue: any = {
    paymentConfirmError: false,
    loading: false,
    paymentConfirmResponse: '',
  };
  // const requestUrl: string = `${process.env.REACT_APP_MAKEPAYMENT_ENDPOINT}`;
  const requestUrl = `https://id01-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/confirm`;
  try {
    const Response = await apiClient.post<paymentRequestType, any>(requestUrl, payload);
    returnValue.paymentConfirmResponse = Response;
  } catch (e: any) {
    console.log(e);
    returnValue.paymentConfirmError = true;
  }
  return returnValue;
};
