import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Error } from '../../commonType';
import { AppDispatch, AppThunk } from '../../redux/store';
import {
  commitReservation,
  getPaymentContext,
  submitPaymentAndBooking,
  setUpProspectPayerAuth,
} from './offerConfirmationService';
import BookingConfirmmationRequest, {
  BookingConfirmationResponse,
  PaymentContextResponse,
  PaymentSubmitRequest,
  // eslint-disable-next-line prettier/prettier
  PaymentSubmitResponse,
  PaymentAndBookingRequest,
} from './types';
import { createError } from '../../util';
interface bookingConfirmationState {
  loading: boolean;
  ConfirmationNumber: string | undefined;
  error?: Error;
  hasError: boolean;
  comments?: string;
  paymentContextId?: string;
  flexFormSubmitHandler?: any;
  transientTokenJwt?: string;
  paymentFormValid: boolean;
  paymentCheckBox: boolean;
  hasPaymentContextError: boolean;
  id?: string;
  bonvoyValue?: string;
  IIValue?: string;
  PartnerId?: string;
  PartnerCode?: string;
  payerAuthDetails?: any;
  payerAuthError: boolean;
  paymentAuthProspectDetails?: any;
  paymentAuthSuccess: boolean;
  prospectCardinalJwt?: any;
  prospectReferenceId?: any;
  confirmationStatusDetails?: any;
}
const initialState: bookingConfirmationState = {
  loading: false,
  ConfirmationNumber: undefined,
  hasError: false,
  paymentFormValid: false,
  paymentCheckBox: false,
  hasPaymentContextError: false,
  payerAuthDetails: undefined,
  payerAuthError: false,
  paymentAuthProspectDetails: undefined,
  paymentAuthSuccess: false,
  prospectCardinalJwt: '',
  prospectReferenceId: '',
  confirmationStatusDetails: undefined,
};
const confirmationSlice = createSlice({
  name: 'bookingConfirmationState',
  initialState,
  reducers: {
    receiveConfirmationNumber(state, action: PayloadAction<BookingConfirmationResponse>) {
      state.ConfirmationNumber = action.payload.ConfirmationNumber;
      state.error = action.payload.error;
      state.hasError = action.payload.hasError;
      state.confirmationStatusDetails = undefined;
      state.payerAuthDetails = undefined;
    },
    receiveConfirmationStatus(state, action: PayloadAction<any>) {
      state.ConfirmationNumber = undefined;
      state.error = undefined;
      state.hasError = false;
      state.confirmationStatusDetails = action.payload;
      state.payerAuthDetails = undefined;
    },
    setLoadingStatus(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetErrorStatus(state, action: PayloadAction<void>) {
      state.hasError = false;
    },
    resetConfirmationNumber(state, action: PayloadAction<void>) {
      state.ConfirmationNumber = undefined;
    },
    resetConfirmationError(state, action: PayloadAction<void>) {
      state.hasError = false;
    },
    setConfirmationComments(state, action: PayloadAction<string>) {
      state.comments = action.payload;
    },
    setPaymentContextId(state, action: PayloadAction<PaymentContextResponse>) {
      state.hasPaymentContextError = action.payload.hasError;
      state.error = action.payload.error;
      state.paymentContextId =
        action.payload.contextTokens && action.payload.contextTokens.length > 0
          ? action.payload.contextTokens[0].key
          : '';
    },
    setPaymentId(state, action: PayloadAction<PaymentSubmitResponse>) {
      state.id = action.payload.id;
    },
    resetPaymentContextId(state, action: PayloadAction<void>) {
      state.paymentContextId = undefined;
    },

    setflexFormSubmitHandler(state, action: PayloadAction<any>) {
      state.flexFormSubmitHandler = action.payload;
    },
    setPaymentToken(state, action: PayloadAction<string>) {
      state.transientTokenJwt = action.payload;
    },
    setPaymentButtonState(state, action: PayloadAction<boolean>) {
      state.paymentFormValid = action.payload;
    },
    setPaymentcheckBox(state, action: PayloadAction<boolean>) {
      state.paymentCheckBox = action.payload;
    },
    resetConfirmationComments(state, action: PayloadAction<void>) {
      state.comments = undefined;
    },
    resetPaymentContextErrorFlag(state, action: PayloadAction<void>) {
      state.hasPaymentContextError = false;
    },
    setBonvoyValue(state, action: PayloadAction<string>) {
      state.bonvoyValue = action.payload;
    },
    resetBonvoyValue(state, action: PayloadAction<void>) {
      state.bonvoyValue = undefined;
    },
    setIIValue(state, action: PayloadAction<string>) {
      state.IIValue = action.payload;
    },
    resetIIValue(state, action: PayloadAction<void>) {
      state.IIValue = undefined;
    },
    setPartnerId(state, action: PayloadAction<string>) {
      state.PartnerId = action.payload;
    },
    setPartnerCode(state, action: PayloadAction<string>) {
      state.PartnerCode = action.payload;
    },
    resetPartnerId(state, action: PayloadAction<void>) {
      state.PartnerId = undefined;
    },
    resetPartnerCode(state, action: PayloadAction<void>) {
      state.PartnerCode = undefined;
    },
    clearProspectPayerAuthDetails(state, action: PayloadAction<void>) {
      state.payerAuthDetails = undefined;
      state.payerAuthError = false;
    },
    receiveProspectPayerAuthDetails(state, action: PayloadAction<any>) {
      if (action.payload.authError) {
        state.loading = false;
        state.hasError = true;
        state.error = action.payload.error;
      } else {
        state.payerAuthDetails = action.payload.authResponse;
        state.payerAuthError = false;
        state.prospectCardinalJwt = action.payload.authResponse?.cardinalJwt;
        state.prospectReferenceId = action.payload.authResponse?.referenceId;
      }
    },
    resetPaymentAuthDetails(state, action: PayloadAction<void>) {
      state.paymentAuthSuccess = false;
      state.payerAuthDetails = undefined;
      state.payerAuthError = false;
      state.paymentAuthProspectDetails = undefined;
      state.paymentAuthSuccess = false;
    },
    setPaymentEnrollError(state, action: PayloadAction<void>) {
      state.ConfirmationNumber = undefined;
      state.hasError = true;
      state.error = createError('CREDIT_CARD_DECLINED_GENERIC');
      state.confirmationStatusDetails = undefined;
      state.payerAuthDetails = undefined;
    },
  },
});
export const {
  receiveConfirmationNumber,
  resetErrorStatus,
  resetConfirmationNumber,
  resetConfirmationError,
  setConfirmationComments,
  setPaymentContextId,
  resetPaymentContextId,
  setflexFormSubmitHandler,
  setPaymentToken,
  setPaymentButtonState,
  setPaymentcheckBox,
  setLoadingStatus,
  resetConfirmationComments,
  resetPaymentContextErrorFlag,
  setPaymentId,
  setBonvoyValue,
  resetBonvoyValue,
  setIIValue,
  resetIIValue,
  setPartnerId,
  setPartnerCode,
  resetPartnerId,
  resetPartnerCode,
  receiveProspectPayerAuthDetails,
  clearProspectPayerAuthDetails,
  resetPaymentAuthDetails,
  receiveConfirmationStatus,
  setPaymentEnrollError,
} = confirmationSlice.actions;
export const bookReservation = (bookingConfirmationRequest: BookingConfirmmationRequest): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(confirmationSlice.actions.setLoadingStatus(true));
  const response: BookingConfirmationResponse = await commitReservation(bookingConfirmationRequest);
  dispatch(confirmationSlice.actions.receiveConfirmationNumber(response));
  dispatch(confirmationSlice.actions.setLoadingStatus(false));
};
export const retrievePaymentContext = (merchantGroupId: string, origin: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(confirmationSlice.actions.setLoadingStatus(true));
  const response: PaymentContextResponse = await getPaymentContext(merchantGroupId, origin);
  dispatch(confirmationSlice.actions.setPaymentContextId(response));
  dispatch(confirmationSlice.actions.setLoadingStatus(false));
};
export const bookReservationWithPayment = (
  paymentSubmitRequest: PaymentSubmitRequest,
  bookingConfirmationRequest: BookingConfirmmationRequest,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(confirmationSlice.actions.setLoadingStatus(true));
  const paymentAndBookingRequest: PaymentAndBookingRequest = {
    payment: paymentSubmitRequest,
    confirm: bookingConfirmationRequest,
  };
  const bookingResponse: any = await submitPaymentAndBooking(paymentAndBookingRequest);

  if (bookingResponse.hasError) {
    const tempError: BookingConfirmationResponse = {
      hasError: true,
      error: bookingResponse.error,
    };
    dispatch(confirmationSlice.actions.receiveConfirmationNumber(tempError));
  } else if (
    bookingResponse?.confirmationdetails &&
    bookingResponse?.confirmationdetails?.status === 'pending_authentication'
  ) {
    dispatch(confirmationSlice.actions.receiveConfirmationStatus(bookingResponse?.confirmationdetails));
  } else {
    dispatch(confirmationSlice.actions.receiveConfirmationNumber(bookingResponse));
  }

  dispatch(confirmationSlice.actions.setLoadingStatus(false));
};
export const payerAuthProspectConfirmation = (
  authObj: any,
  bookPaymentObj: any,
  bookConfirmObj: any,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(confirmationSlice.actions.setLoadingStatus(true));
  const authResponse: any = await setUpProspectPayerAuth(authObj);
  if (
    authResponse?.payAuthResponse &&
    authResponse?.payAuthResponse?.payerAuthEnabled &&
    authResponse?.payAuthResponse?.referenceId !== ''
  ) {
    const authData = {
      authResponse: authResponse.payAuthResponse,
      authError: false,
      error: '',
    };
    dispatch(confirmationSlice.actions.receiveProspectPayerAuthDetails(authData));
  } else if (authResponse?.payAuthResponse && authResponse?.payAuthResponse?.payerAuthEnabled === false) {
    dispatch(bookReservationWithPayment(bookPaymentObj, bookConfirmObj));
  } else {
    const authData = {
      authError: true,
      error: authResponse.error,
    };
    dispatch(confirmationSlice.actions.receiveProspectPayerAuthDetails(authData));
  }
};

export default confirmationSlice.reducer;
