/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import withBrands from '../../../hoc/withBrands';
import { useMediaQuery } from 'react-responsive';
import withLocalization from '../../../hoc/withLocalization';
import Lottie from 'lottie-react';
import tmvcSpinner from '../../../../features/siteTheme/Brands/tmvcs/tmvcs-spinner.json';
import './NewOfferLoader.scss';
interface HeaderProps {
  localize?: any;
  brand?: any;
  localizeImages?: any;
}
const NewOfferLoader: FC<any> = (props: HeaderProps): ReactElement => {
  const { localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  return (
    <>
      <div className="newOffer-loader-modal">
        <div className="newOffer-loader-container">
          <div className="spinner-container-modal">
            <Lottie animationData={tmvcSpinner} loop autoplay />
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(withLocalization(withBrands(NewOfferLoader)));
