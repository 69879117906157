class CardStorage {
  private static month: string = '';
  private static year: string = '';
  private static isCreditCardValid: boolean = false;
  private static isCVVValid: boolean = false;
  public static setMonth(value): void {
    this.month = value;
  }
  public static getMonth(): string {
    return this.month;
  }
  public static setYear(value): void {
    this.year = value;
  }
  public static getYear(): string {
    return this.year;
  }
  public static setCreditCardValidity(value): void {
    this.isCreditCardValid = value;
  }
  public static setCVVValidity(value): void {
    this.isCVVValid = value;
  }
  public static isFlexFormValid(cb): void {
    const isValid = this.isCreditCardValid && this.isCVVValid;
    let monthYearComboValid = this.isMonthYearComboValid();
    cb(isValid && monthYearComboValid);
  }
  public static isMonthYearComboValid() {
    let monthYearComboValid = false;
    const tempMonth = parseInt(this.month, 10);
    const tempYear = parseInt(this.year, 10);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    if (tempYear > currentYear) {
      monthYearComboValid = true;
    } else {
      if (tempMonth >= currentMonth) {
        monthYearComboValid = true;
      } else {
        monthYearComboValid = false;
      }
    }
    return monthYearComboValid;
  }
  public static resetAllValues() {
    this.month = '';
    this.year = '';
    this.isCVVValid = false;
    this.isCreditCardValid = false;
  }
  private constructor() {
    /* noop */
  }
}
export default CardStorage;
