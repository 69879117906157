/* eslint-disable */
import { useState, useEffect } from 'react';

const useTimer = () => {
  const getSessionTime: any = sessionStorage.getItem('NewOfferSessionTime');
  const defaulTime = (getSessionTime && parseInt(getSessionTime)) || '';
  const convertedTime = (defaulTime && defaulTime * 60 * 1000) || '';
  const MAX_TIME: any = convertedTime || 60 * 60 * 1000; // 60 minutes in milliseconds
  const storedStartTime = sessionStorage.getItem('timerStartTime');
  const [startTime, setStartTime] = useState(storedStartTime ? parseInt(storedStartTime) : null);
  const [remainingTime, setRemainingTime] = useState<any>(null);

  useEffect(() => {
    let timer;

    if (startTime !== null) {
      const elapsedTime = Date.now() - startTime;
      const timeLeft: number | null = MAX_TIME - elapsedTime;

      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
        timer = setTimeout(() => {
          setRemainingTime(0);
          setStartTime(null);
          sessionStorage.removeItem('timerStartTime');
        }, timeLeft);
      } else {
        setRemainingTime(0);
        setStartTime(null);
        sessionStorage.removeItem('timerStartTime');
      }
    }

    return () => clearTimeout(timer);
  }, [startTime]);

  const startTimer = () => {
    if (startTime === null) {
      const now = Date.now();
      setStartTime(now);
      sessionStorage.setItem('timerStartTime', now.toString());
    }
  };

  const stopTimer = () => {
    setStartTime(null);
    setRemainingTime(null);
    sessionStorage.removeItem('timerStartTime');
  };

  return { startTimer, stopTimer, remainingTime };
};

export default useTimer;
