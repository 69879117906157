import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../../redux/store';
import { executeHoldReservation, fetchAvailabilityDates, releaseHoldReservation } from './offerAvailabilityService';
import {
  AvailabilityDate,
  AvailabilityDateRequest,
  AvailableDates,
  HoldPropertyRequest,
  HoldPropertyResponse,
  // eslint-disable-next-line prettier/prettier
  ReleaseHoldPropertyRequest
} from './types';

const initialState: AvailableDates = {
  isLoading: false,
  hasError: false,
  openDateSelector: false,
  navigateToEligibility: false,
  hasNoAvailability: false,
  dateMonthRegistry: [],
  isHoldFailed: false,
  holdStartTime: new Date(),
};
const availabilitySlice = createSlice({
  name: 'AvailabilityDates',
  initialState,
  reducers: {
    setLoadingStatus(state: AvailableDates, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetErrorStatus(state: AvailableDates, action: PayloadAction<void>) {
      state.hasError = false;
    },
    receiveAvailableDates(state: AvailableDates, action: PayloadAction<AvailableDates>) {
      if (action.payload) {
        const {
          payload: { dates, premiumDates, hasError, error, hasNoAvailability },
        } = action;
        if (!state.dates) {
          state.dates = [];
        }
        if (dates) {
          state.dates.push(...dates);
        }
        state.premiumDates = premiumDates;
        state.hasError = hasError;
        state.error = error;
        state.hasNoAvailability = hasNoAvailability;
      }
    },
    setAvailabilityRequest(state: AvailableDates, action: PayloadAction<AvailabilityDateRequest>) {
      state.availabilityRequest = action.payload;
    },
    setCurrentAvailabilityDate(state: AvailableDates, action: PayloadAction<AvailabilityDate>) {
      state.selectedDate = action.payload;
      state.finalSelectedDate = action.payload;
    },
    resetCurrentAvailabilityDate(state: AvailableDates, action: PayloadAction<void>) {
      state.selectedDate = undefined;
      state.consecutiveSelectedDates = undefined;
      state.previousCalendarPosition = undefined;
      state.dates = undefined;
      state.premiumDates = undefined;
      state.finalSelectedDate = undefined;
      state.dateMonthRegistry = undefined;
    },
    resetFinalSelectedDate(state: AvailableDates, action: PayloadAction<void>) {
      state.finalSelectedDate = undefined;
    },
    setConsecutiveSelecteddates(state: AvailableDates, action: PayloadAction<Date[]>) {
      state.consecutiveSelectedDates = action.payload;
    },
    setCalendarPosition(state: AvailableDates, action: PayloadAction<number>) {
      state.calendarPosition = action.payload;
    },
    setPreviousCalendarPosition(state: AvailableDates, action: PayloadAction<void>) {
      state.previousCalendarPosition = state.calendarPosition;
    },
    setOpenDateSelector(state: AvailableDates, action: PayloadAction<boolean>) {
      state.openDateSelector = action.payload;
    },
    setHoldRequest(state: AvailableDates, action: PayloadAction<HoldPropertyRequest>) {
      state.holdRequest = action.payload;
    },
    receiveHoldResponse(state: AvailableDates, action: PayloadAction<HoldPropertyResponse>) {
      const { error, hasError, HoldSessionId } = action.payload;
      state.error = error;
      state.hasError = hasError === true ? true : false;
      state.holdSessionId = HoldSessionId;
      if (!state.hasError) {
        state.navigateToEligibility = true;
        state.holdStartTime = new Date();
      } else {
        state.isHoldFailed = true;
        state.hasError = false;
      }
    },
    resetHoldErrorStatus(state: AvailableDates, action: PayloadAction<void>) {
      state.isHoldFailed = false;
    },
    resetNavigationStatus(state: AvailableDates, action: PayloadAction<void>) {
      state.navigateToEligibility = false;
    },
    resetAvailabilityErrorStatus(state: AvailableDates, action: PayloadAction<void>) {
      state.hasError = false;
    },
    setAllEligibilityOptions(state: AvailableDates, action: PayloadAction<void>) {
      state.allEligibilityOptionSelected = true;
    },
    resetAllEligibilityOptions(state: AvailableDates, action: PayloadAction<void>) {
      state.allEligibilityOptionSelected = false;
    },
    resetHoldSessionID(state: AvailableDates, action: PayloadAction<void>) {
      state.holdSessionId = undefined;
    },
    setRoomADACode(state: AvailableDates, action: PayloadAction<string | undefined>) {
      state.roomADACode = action.payload;
    },
    setHearingADACode(state: AvailableDates, action: PayloadAction<string | undefined>) {
      state.hearingADACode = action.payload;
    },
    resetADACodes(state: AvailableDates, action: PayloadAction<void>) {
      state.roomADACode = undefined;
      state.hearingADACode = undefined;
    },
    resetNoAvailabilityStatus(state: AvailableDates, action: PayloadAction<void>) {
      state.hasNoAvailability = false;
    },
    setExtraNights(state: AvailableDates, action: PayloadAction<number>) {
      state.extraNights = action.payload;
    },
    resetExtraNights(state: AvailableDates, action: PayloadAction<void>) {
      state.extraNights = undefined;
    },
    setCurrentCalendarPosition(state: AvailableDates, action: PayloadAction<any>) {
      state.currentCalendarPosition = action.payload;
    },
    resetCurrentCalendarPosition(state: AvailableDates, action: PayloadAction<void>) {
      state.currentCalendarPosition = undefined;
    },
    setOfferMonthRangeDesktop(state: AvailableDates, action: PayloadAction<string[]>) {
      state.offerMonthRangeDesktop = action.payload;
    },
    setOfferMonthRangeMobile(state: AvailableDates, action: PayloadAction<string[]>) {
      state.offerMonthRangeMobile = action.payload;
    },
    resetOfferMonthRange(state: AvailableDates, action: PayloadAction<void>) {
      state.offerMonthRangeDesktop = undefined;
      state.offerMonthRangeMobile = undefined;
    },
    setDateMonthRegistry(state: AvailableDates, action: PayloadAction<AvailabilityDateRequest>) {
      const startDateComponent = action.payload.beginDate.split('-');
      const endDateComponent = action.payload.endDate.split('-');
      const startYear = startDateComponent[0];
      const startMonth = startDateComponent[1];
      const endYear = endDateComponent[0];
      const endMonth = endDateComponent[1];

      if (!state.dateMonthRegistry) {
        state.dateMonthRegistry = [];
      }
      if (startYear === endYear && startMonth === endMonth) {
        state.dateMonthRegistry.push(`${startMonth}:${startYear}`);
      } else {
        state.dateMonthRegistry.push(`${startMonth}:${startYear}`);
        state.dateMonthRegistry.push(`${endMonth}:${endYear}`);
      }
    },
    setFinalSelectedDate(state: AvailableDates, action: PayloadAction<void>) {
      state.finalSelectedDate = state.selectedDate;
    },
    setFinalSelectedExtraNights(state: AvailableDates, action: PayloadAction<void>) {
      state.finalSelectedExtraNights = state.extraNights;
    },
    resetFinalSelectedExtraNights(state: AvailableDates, action: PayloadAction<void>) {
      state.finalSelectedExtraNights = undefined;
    },
  },
});
export const loadOfferDates = (request: AvailabilityDateRequest): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(availabilitySlice.actions.setLoadingStatus(true));

  const response: AvailableDates = await fetchAvailabilityDates(request);

  dispatch(availabilitySlice.actions.receiveAvailableDates(response));
  dispatch(availabilitySlice.actions.setDateMonthRegistry(request));
  dispatch(availabilitySlice.actions.setLoadingStatus(false));
};
export const holdProperty = (request: HoldPropertyRequest): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(availabilitySlice.actions.setLoadingStatus(true));

  const response: HoldPropertyResponse = await executeHoldReservation(request);

  dispatch(availabilitySlice.actions.receiveHoldResponse(response));
  dispatch(availabilitySlice.actions.setLoadingStatus(false));
};

export const releaseHoldProperty = (request: ReleaseHoldPropertyRequest): AppThunk => async (dispatch: AppDispatch) => {
  await releaseHoldReservation(request);

  dispatch(availabilitySlice.actions.resetHoldSessionID());
};
export default availabilitySlice.reducer;
export const {
  setAvailabilityRequest,
  setCurrentAvailabilityDate,
  resetErrorStatus,
  resetCurrentAvailabilityDate,
  setConsecutiveSelecteddates,
  setCalendarPosition,
  setPreviousCalendarPosition,
  setOpenDateSelector,
  setHoldRequest,
  resetNavigationStatus,
  resetAvailabilityErrorStatus,
  setAllEligibilityOptions,
  resetAllEligibilityOptions,
  resetHoldSessionID,
  setRoomADACode,
  setHearingADACode,
  resetADACodes,
  resetNoAvailabilityStatus,
  setExtraNights,
  resetExtraNights,
  setLoadingStatus,
  resetFinalSelectedDate,
  setCurrentCalendarPosition,
  resetCurrentCalendarPosition,
  setFinalSelectedDate,
  setOfferMonthRangeDesktop,
  setOfferMonthRangeMobile,
  resetOfferMonthRange,
  resetHoldErrorStatus,
  setFinalSelectedExtraNights,
  resetFinalSelectedExtraNights,
} = availabilitySlice.actions;
