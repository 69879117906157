/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import withBrands from '../../../hoc/withBrands';
import withLocalization from '../../../hoc/withLocalization';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import './CyberSourceErrorModal.scss';
import galleryModalCloseLogo from '../../../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import ParseHtml from '../../ParseHtml';
interface ImgModalProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
  msg?: string;
}

const CyberSourceErrorModal: FC<any> = (props: ImgModalProps): ReactElement => {
  const { closeModal, localize } = props;
  const modalErrStatus = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentModalErrStatus);

  const handleKeyDown = () => {};
  const cardErrorMsg = localize(
    `preview-sales-engine.static-content.copy.Labels.error-message.pbeo.auth-token-error-description`,
  );

  return (
    <div className="modal-cyberSource-err">
      <div className="modal-content-cyberSourceErr">
        <div className="cyberSourceErr-container">
          <div className="cyberSource-err-head-container">
            <div className="cyberSourceErrModal-title-container">
              <span className="cyberSourceErr-title-text">
                {localize(`preview-sales-engine.static-content.copy.Labels.error-message.pbeo.auth-token-error-title`)}
              </span>
            </div>
            <div className="cyberSource-err-close-icon-wrapper">
              <input
                className="cyberSourceErr-modal-close-btn"
                src={galleryModalCloseLogo}
                type="image"
                onClick={closeModal}
                aria-label="close"
              />
            </div>
          </div>

          <div className="cyberSourceErr-content-section">
            <div className="cyberSourceErr-desc-container">
              <div className="cyberSourceErr-desc-text">
                <ParseHtml htmlInput={cardErrorMsg} />
              </div>
            </div>
          </div>
          <div className="cyberSourceModalErr-section">
            <div
              className="cyberSourceErr-close-wrapper"
              onClick={closeModal}
              arial-label={'see-detail'}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <div className="cyberSourceErr-close-text-span">
                {localize(`preview-sales-engine.static-content.copy.pbeo.ineligible-state-thank-you-modal-cta`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(CyberSourceErrorModal)));
