import { getCookie } from './CommonLogic';
export const insertData = (val: boolean) => {
  if (window.ONE?.Dot) window.ONE.Dot.limitedDataUse = val;
};
export const getCookieOptVal = (cookieParam) => {
  const decodeCookie = decodeURIComponent(cookieParam) || '';
  if (decodeCookie.includes('intType=2')) {
    return false;
  } else {
    return true;
  }
};
export const requestPayload = (data: any, offerData: any, val: any, url?: any) => {
  //const session_vendorId = sessionStorage.getItem('vendor-id') || '';
  const referrerUrl = document?.referrer || '';
  const currentUrl = url || '';
  const isEligible = val ? 'Online Residency Qualified' : 'Online Residency Not Qualified';
  const loc = (offerData?.offers && offerData?.offers[0]?.loc) || '';
  let vendorId = window?.ONE?.Dot?.getUserId() || '';
  window.ONE?.Dot?.on('load', function () {
    const cookiesData: any = getCookie('OptanonConsent');
    vendorId = window?.ONE?.Dot?.getUserId() || '';
    const decodeCookie = decodeURIComponent(cookiesData);

    if (cookiesData) {
      if (decodeCookie.includes('C0002:0') && decodeCookie.includes('C0004:0') && decodeCookie.includes('C0010:0')) {
        //if (decodeCookie.includes('intType=2')) {
        // vendorId = '';
        insertData(false);
      } else {
        insertData(true);
      }
    } else {
      insertData(true);
    }
  });
  //const cookieOpt = vendorId ? true : false;
  /* const checkVendor = session_vendorId && session_vendorId === 'oa';
  const getVendorId = checkVendor ? vendorId : '';*/
  const cookieValue: any = getCookie('OptanonConsent') || '';
  //const decodeCookie = decodeURIComponent(cookiesData);
  const cookieOptValue = getCookieOptVal(cookieValue);
  vendorId = cookieOptValue === false ? '' : vendorId;
  const payloadRequest: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    state: data?.state || '',
    phone: data.phoneNumber,
    email: data.emailAddress,
    webOptin: data.isOpt,
    latestLeadOriginCode: loc,
    sourceSystem: 'PBE',
    channel: 'Inbound',
    leadSource: 'Website',
    vendorId: vendorId,
    cookieOptin: cookieOptValue,
    referringUrl: referrerUrl,
    website: currentUrl,
    status: isEligible,
    postalCode: '',
    country: 'United States Of America',
    createCampaignMember: true,
    company: 'mvw',
  };
  return payloadRequest;
};

export const requestUpdateLeadPayload = (data: any, id: any, val: any, offerData?: any) => {
  const isEligible = val ? 'Online Residency Qualified' : 'Online Residency Not Qualified';
  const loc = (offerData?.offers && offerData?.offers[0]?.loc) || '';
  const payloadRequest: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    state: data?.state || '',
    phone: data.phoneNumber,
    email: data.emailAddress,
    leadId: id,
    webOptin: data.isOpt,
    postalCode: '',
    status: isEligible,
    address: '',
    latestLeadOriginCode: loc,
  };
  return payloadRequest;
};
export const billingUpdateLeadPayload = (
  data: any,
  id: any,
  state: any,
  zipCode: any,
  address?: any,
  offerData?: any,
  val?: any,
) => {
  const loc = (offerData?.offers && offerData?.offers[0]?.loc) || '';
  const isEligible = val ? 'Online Residency Qualified' : 'Online Residency Not Qualified';
  const billAddress = address ? address : '';
  const payloadRequest: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    state: state,
    phone: data.phoneNumber,
    email: data.emailAddress,
    leadId: id,
    webOptin: data.isOpt,
    postalCode: zipCode,
    status: isEligible,
    address: billAddress,
    latestLeadOriginCode: loc,
  };
  return payloadRequest;
};
export const billingRequestPayload = (data: any, offerData: any, val: any, state: any, zipCode: any, url: any) => {
  //const session_vendorId = sessionStorage.getItem('vendor-id') || '';
  const referrerUrl = document?.referrer || '';
  const currentUrl = url || '';
  //const currentUrl = window.location?.href || '';
  const isEligible = val ? 'Online Residency Qualified' : 'Online Residency Not Qualified';
  const loc = (offerData?.offers && offerData?.offers[0]?.loc) || '';
  //const vendorId = sessionStorage.getItem('vender_id');
  let vendorId = window?.ONE?.Dot?.getUserId() || '';
  window.ONE?.Dot?.on('load', function () {
    const cookiesData: any = getCookie('OptanonConsent');
    vendorId = window?.ONE?.Dot?.getUserId() || '';
    const decodeCookie = decodeURIComponent(cookiesData);

    if (cookiesData) {
      if (decodeCookie.includes('C0002:0') && decodeCookie.includes('C0004:0') && decodeCookie.includes('C0010:0')) {
        vendorId = '';
        insertData(false);
      } else {
        insertData(true);
      }
    } else {
      insertData(true);
    }
  });
  //const cookieOpt = vendorId ? true : false;
  /*const checkVendor = session_vendorId && session_vendorId === 'oa';
  const getVendorId = checkVendor ? vendorId : '';*/
  const cookieValue: any = getCookie('OptanonConsent') || '';
  const cookieOptValue = getCookieOptVal(cookieValue);
  vendorId = cookieOptValue === false ? '' : vendorId;
  const payloadRequest: any = {
    firstName: data.firstName,
    lastName: data.lastName,
    state: state,
    phone: data.phoneNumber,
    email: data.emailAddress,
    webOptin: data.isOpt,
    latestLeadOriginCode: loc,
    sourceSystem: 'PBE',
    channel: 'Inbound',
    leadSource: 'Website',
    vendorId: vendorId,
    cookieOptin: cookieOptValue,
    referringUrl: referrerUrl,
    website: currentUrl,
    status: isEligible,
    postalCode: zipCode,
    country: 'United States Of America',
    createCampaignMember: true,
    company: 'mvw',
  };
  return payloadRequest;
};
