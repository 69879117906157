/* eslint-disable */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import { AppDispatch, AppThunk } from '../../redux/store';
import { validateFields } from '../../util/validation';
import fetchOfferData, { fetchOfferResponseForLoc } from './offerDetailsService';
import { BillingError, Offer, OfferDetails, Prospect, RoomType, State } from './types';

const getOffersAfterStateExclusion = (offers: Offer[], stateCode: string): Offer[] => {
  offers?.forEach((e) => {
    if (e.stateExclusions && e.stateExclusions.length > 0) {
      const excludedStatePresent = e.stateExclusions.some((offerState) => offerState.stateProvince === stateCode);
      if (excludedStatePresent) {
        e.includeOffer = false;
      }
    }
  });
  return offers;
};

const filterOfferOnCustomerSelectedState = (offers: Offer[], stateCode: string): Offer[] => {
  offers?.forEach((e) => {
    if (e.includeOffer) {
      if (e.offerLocation?.stateCode !== stateCode) {
        e.includeOffer = false;
      }
    }
  });
  return offers;
};
const initialState: OfferDetails = {
  loading: false,
  showProspect: false,
  hasOffers: true,
  hasError: false,
  currentNavigationStep: NavigationSteps.OFFER_SEARCH,
  displayFlipOffers: false,
  showCTA: false,
  isPackageSold: false,
  isPin: true,
};
const offerSlice = createSlice({
  name: 'OfferDetails',
  initialState,
  reducers: {
    receiveOffer(state, action: PayloadAction<OfferDetails>) {
      if (action.payload) {
        const {
          payload: {
            prospect,
            property,
            primaryOffer,
            hasError,
            flipOffers,
            searchMode,
            searchParameter,
            hasOffers,
            currentOffer,
            locDescription,
            primaryOfferLocations,
            showProspect,
            flipOfferLocations,
            isDatedOffer,
            error,
            showCTA,
            errorCode,
            isPackageSold,
            callCenterNumber,
            isPin
          },
        } = action;
        if (prospect) {
          state.prospect = prospect;
          state.prospectCopy = prospect;
          if (prospect.callCenterNumber && prospect.callCenterNumber.indexOf('-') > 0) {
            const phoneNumber = prospect.callCenterNumber.split('-').join('');
            state.callCenterNumber = phoneNumber;
            state.prospect.callCenterNumber = phoneNumber;
          } else {
            state.callCenterNumber = prospect.callCenterNumber;
          }
        } else {
          if (callCenterNumber) {
            if (callCenterNumber.indexOf('-') > 0) {
              const phoneNumber = callCenterNumber.split('-').join('');
              state.callCenterNumber = phoneNumber;
              state.prospect = {
                callCenterNumber: phoneNumber,
              };
            } else {
              state.callCenterNumber = callCenterNumber;
              state.prospect = {
                callCenterNumber: callCenterNumber,
              };
            }
          }
        }
        if (isDatedOffer) {
          state.isDatedOffer = isDatedOffer;
        }
        state.property = property;
        state.primaryOffer = primaryOffer;
        state.flipOffers = flipOffers;
        state.hasError = hasError;
        state.showProspect = showProspect;
        state.searchMode = searchMode;
        state.searchParameter = searchParameter;
        state.hasOffers = hasOffers;
        state.currentOffer = currentOffer;
        state.locDescription = locDescription;
        state.primaryOfferLocations = primaryOfferLocations;
        state.flipOfferLocations = flipOfferLocations;
        state.error = error;
        state.showCTA = showCTA;
        state.isPackageSold = isPackageSold;
        state.errorCode = errorCode;
        state.isPin = isPin;
      }
    },
    receiveOfferForLoc(state, action: PayloadAction<OfferDetails>) {
      if (action.payload) {
        const {
          payload: {
            property,
            primaryOffer,
            hasError,
            flipOffers,
            searchMode,
            searchParameter,
            hasOffers,
            currentOffer,
            locDescription,
            primaryOfferLocations,
            showProspect,
            flipOfferLocations,
            isDatedOffer,
            error,
            showCTA,
            errorCode,
            isPackageSold,
            locSelectedState,
            locBrandPhoneNumber,
            isPin
          },
        } = action;

        if (isDatedOffer) {
          state.isDatedOffer = isDatedOffer;
        }
        state.property = property;
        state.primaryOffer = primaryOffer;
        state.flipOffers = flipOffers;
        state.hasError = hasError;
        state.showProspect = showProspect;
        state.searchMode = searchMode;
        state.searchParameter = searchParameter;
        state.hasOffers = hasOffers;
        state.currentOffer = currentOffer;
        state.locDescription = locDescription;
        state.primaryOfferLocations = primaryOfferLocations;
        state.flipOfferLocations = flipOfferLocations;
        state.error = error;
        state.showCTA = showCTA;
        state.isPackageSold = isPackageSold;
        state.errorCode = errorCode;
        state.displayFlipOffers = true;
        state.locSelectedState = locSelectedState;
        state.locBrandPhoneNumber = locBrandPhoneNumber;
        state.isPin = isPin;
      }
    },
    offerLoading(state: OfferDetails, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetDatedCampaign(state: OfferDetails, action: PayloadAction<void>) {
      state.isDatedOffer = false;
    },
    resetSearchMode(state: OfferDetails, action: PayloadAction<string>) {
      state.searchMode = action.payload;
    },
    flipOffer(state: OfferDetails, action: PayloadAction<string>) {
      const selectedOffer = state.flipOffers?.filter((e) => e.offerCode === action.payload)[0];
      state.currentOffer = selectedOffer;
      state.property = selectedOffer?.roomTypes[0].property;
    },
    setSelectedRoom(state: OfferDetails, action: PayloadAction<RoomType>) {
      state.selectedRoomType = action.payload;
    },
    setNavigationStep(state: OfferDetails, action: PayloadAction<NavigationSteps>) {
      state.currentNavigationStep = action.payload;
    },
    setCurrentOffer(state: OfferDetails, action: PayloadAction<number>) {
      state.currentOffer = state.primaryOffer?.filter((e, index) => index === action.payload)[0];
      state.property = state.currentOffer?.property;
    },
    setProspectState(state: OfferDetails, action: PayloadAction<string>) {
      state.prospect = {
        stateProvince: action.payload,
      };
    },
    setNewState(state: OfferDetails, action: PayloadAction<string>) {
      if (state.prospect) state.prospect.stateProvince = action.payload;
    },
    setProspectObjectFromContactInfo(state: OfferDetails, action: PayloadAction<Prospect>) {
      state.prospect = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        stateProvince: action.payload.stateProvince,
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
      };
    },
    setBillingObjectFromContactInfo(state: OfferDetails, action: PayloadAction<Prospect>) {
      state.billingInfo = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        stateProvince: action.payload.stateProvince,
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
        unitNo: action.payload.unitNo,
      };
    },
    setProspectCopyObjectFromContactInfo(state: OfferDetails, action: PayloadAction<Prospect>) {
      state.prospectCopy = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        stateProvince: action.payload.stateProvince,
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
        spouseFirstName: action.payload.spouseFirstName,
        spouseLastName: action.payload.spouseLastName,
        unitNo: action.payload.unitNo,
      };
    },
    resetFlipOfferDisplayMode(state: OfferDetails, action: PayloadAction<void>) {
      state.displayFlipOffers = false;
    },
    setFlipOfferDisplayMode(state: OfferDetails, action: PayloadAction<void>) {
      if (state.displayFlipOffers) return;
      state.displayFlipOffers = true;
      const offerLocations = state.primaryOfferLocations;
      /** Union flip offer location and primary offer location */
      state.flipOfferLocations?.forEach((e) => {
        const locationPresent = offerLocations?.some((locationInfo: State) => locationInfo.stateCode === e.stateCode);
        if (!locationPresent) {
          offerLocations?.push(e);
        }
      });
      state.primaryOfferLocations = offerLocations;
    },
    resetCustomerSelectedStates(state: OfferDetails, action: PayloadAction<void>) {
      state.customerSelectedStateCode = undefined;
    },
    filterByCustomerSelectedState(state: OfferDetails, action: PayloadAction<string>) {
      state.customerSelectedStateCode = action.payload === '-1' ? undefined : action.payload;
      let primaryOffers = state.primaryOffer;
      let flipOffers = state.flipOffers;
      primaryOffers?.forEach((e) => (e.includeOffer = true));
      flipOffers?.forEach((e) => (e.includeOffer = true));
      if (state.customerExcludedStateCode) {
        if (primaryOffers) primaryOffers = getOffersAfterStateExclusion(primaryOffers, state.customerExcludedStateCode);
        if (flipOffers) flipOffers = getOffersAfterStateExclusion(flipOffers, state.customerExcludedStateCode);
      }
      if (action.payload !== '-1') {
        if (primaryOffers) primaryOffers = filterOfferOnCustomerSelectedState(primaryOffers, action.payload);
        if (flipOffers) flipOffers = filterOfferOnCustomerSelectedState(flipOffers, action.payload);
      }

      state.primaryOffer = primaryOffers;
      state.flipOffers = flipOffers;
    },
    setGuests(state: OfferDetails, action: PayloadAction<any>) {
      state.guests = {
        adults: action.payload.adults,
        children: action.payload.children,
      };
    },
    resetGuestsAndState(state: OfferDetails, action: PayloadAction<void>) {
      state.guests = {
        adults: 1,
        children: 0,
      };
    },
    resetOfferSearch(state: OfferDetails, action: PayloadAction<void>) {
      state.showProspect = false;
      state.hasError = false;
      state.showCTA = false;
    },
    resetHasErrors(state: OfferDetails, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    setBillingInfoIfequal(state: OfferDetails, action: PayloadAction<boolean>) {
      state.billingInfo = state.prospectCopy;
      state.billingBoxChecked = action.payload;
      state.billingError = {
        firstName: state.prospectCopy?.firstName ? validateFields('firstName', state.prospectCopy?.firstName) : '',
        lastName: state.prospectCopy?.lastName ? validateFields('lastName', state.prospectCopy?.lastName) : '',
        email: state.prospectCopy?.email ? validateFields('email', state.prospectCopy?.email) : '',
        phone: state.prospectCopy?.phone ? validateFields('phone', state.prospectCopy?.phone) : '',
        stateProvince: state.prospectCopy?.stateProvince
          ? validateFields('stateProvince', state.prospectCopy?.stateProvince)
          : '',
        addressLine1: state.prospectCopy?.addressLine1
          ? validateFields('addressLine1', state.prospectCopy?.addressLine1)
          : '',
        city: state.prospectCopy?.city ? validateFields('city', state.prospectCopy?.city) : '',
        postalCode: state.prospectCopy?.postalCode ? validateFields('postalCode', state.prospectCopy?.postalCode) : '',
      };
    },
    setContactErrorfromContactInfo(state: OfferDetails, action: PayloadAction<BillingError>) {
      state.contactError = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
      };
    },
    setBillingErrorfromContactInfo(state: OfferDetails, action: PayloadAction<BillingError>) {
      state.billingError = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        stateProvince: action.payload.stateProvince,
        addressLine1: action.payload.addressLine1,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
      };
    },
    resetBillingInfo(state: OfferDetails, action: PayloadAction<void>) {
      state.billingInfo = undefined;
      state.billingBoxChecked = false;
      state.billingInfoChecked = true;
      state.billingError = undefined;
    },
    resetAllContactBillingInfo(state: OfferDetails, action: PayloadAction<void>) {
      state.billingInfo = undefined;
      state.billingBoxChecked = false;
      state.billingInfoChecked = true;
      state.contactInfoChecked = true;
      state.billingError = undefined;
      state.contactError = undefined;
    },
    resetBillingCheckBox(state: OfferDetails, action: PayloadAction<void>) {
      state.billingBoxChecked = false;
    },
    setAllContactInfoFields(state: OfferDetails, action: PayloadAction<boolean>) {
      state.contactInfoChecked = action.payload;
    },
    setAllBillingInfoFields(state: OfferDetails, action: PayloadAction<boolean>) {
      state.billingInfoChecked = action.payload;
    },
    setBillingObjectState(state: OfferDetails, action: PayloadAction<string>) {
      state.billingInfo = {
        firstName: state.billingInfo?.firstName,
        lastName: state.billingInfo?.lastName,
        email: state.billingInfo?.email,
        phone: state.billingInfo?.phone,
        stateProvince: action.payload,
        addressLine1: state.billingInfo?.addressLine1,
        city: state.billingInfo?.city,
        postalCode: state.billingInfo?.postalCode,
        unitNo: state.billingInfo?.unitNo,
      };
    },
    resetShowCTA(state: OfferDetails, action: PayloadAction<boolean>) {
      state.showCTA = action.payload;
      state.error = undefined;
    },
    resetShowCTALOC(state: OfferDetails, action: PayloadAction<boolean>) {
      state.showCTA = false;
      state.hasError = false;
    }
  },
});
export const {
  setProspectState,
  resetSearchMode,
  flipOffer,
  setSelectedRoom,
  setNavigationStep,
  setCurrentOffer,
  setProspectObjectFromContactInfo,
  setFlipOfferDisplayMode,
  filterByCustomerSelectedState,
  resetFlipOfferDisplayMode,
  resetCustomerSelectedStates,
  setGuests,
  resetGuestsAndState,
  setNewState,
  resetOfferSearch,
  setBillingInfoIfequal,
  resetBillingInfo,
  setBillingObjectFromContactInfo,
  setProspectCopyObjectFromContactInfo,
  resetBillingCheckBox,
  setAllContactInfoFields,
  setAllBillingInfoFields,
  setBillingErrorfromContactInfo,
  setBillingObjectState,
  resetShowCTA,
  resetDatedCampaign,
  resetAllContactBillingInfo,
  setContactErrorfromContactInfo,
  resetShowCTALOC
} = offerSlice.actions;
export const loadOfferDetails = (pin: string, lastName: string, fetchOffer: string = 'n'): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(offerSlice.actions.offerLoading(true));

  const offerDetails: OfferDetails = await fetchOfferData(pin, lastName, fetchOffer);

  dispatch(offerSlice.actions.receiveOffer(offerDetails));

  dispatch(offerSlice.actions.offerLoading(false));

  dispatch(offerSlice.actions.resetHasErrors(false));
};

export const loadOfferDetailsForLoc = (loc: string, selectedState: string, phoneNumber: string): AppThunk => async (
  dispatch: AppDispatch,
) =>  {
  dispatch(offerSlice.actions.offerLoading(true));

  const offerDetails: OfferDetails = await fetchOfferResponseForLoc(loc, selectedState, phoneNumber);

  dispatch(offerSlice.actions.receiveOfferForLoc(offerDetails));

  dispatch(offerSlice.actions.offerLoading(false));

  if(!offerDetails.hasError) {
    dispatch(offerSlice.actions.resetHasErrors(false));
  } 
}
export default offerSlice.reducer;
