/* eslint-disable */
import { getMaskedCardInformationFromToken } from '../../components/NewOffer/NewOfferCyberSource/paymentUtil';
import { States_US } from '../../util/States';
import { deviceInfoPayload } from '../../util/deviceInformation';
export const bookPaymentPayload = (
  offerdata: any,
  billingInfo: any,
  contactInfo: any,
  token: any,
  leadId: any,
  cardinalId?: any,
  refId?: any,
  trId?: any,
  ipAddress?: any,
) => {
  const stateCode = (state: any) => {
    const stateObj = States_US?.filter((item: any) => item?.name === state);
    return stateObj && stateObj[0]?.code;
  };
  const getOfferType: any = sessionStorage.getItem('offer-type');
  const isPaid = getOfferType === 'paid' ? true : false;

  const cardObj = getMaskedCardInformationFromToken(token);
  const roomTypes = offerdata?.roomTypes && offerdata?.roomTypes[0];
  const currentDate = new Date();
  const formattedDate = currentDate?.toISOString() || '';
  const paymentId = `${contactInfo?.lastName}-${formattedDate}-${roomTypes?.roomTypeDescription}`;
  const depositAmt = (roomTypes?.depositAmount?.includes('$') && roomTypes?.depositAmount.split('$')) || '';
  const totalAmt = depositAmt && depositAmt[1];

  const deviceInfo = deviceInfoPayload();
  deviceInfo.ipAddress = ipAddress;

  const firstTwoChars = offerdata?.loc?.slice(0, 2) || '';
  const chargeSiteEnvVal = process.env.REACT_APP_CLIENT_OPB_CHARGE_SITE_ID_KEY || 'WCC';
  const chargeSite = `${firstTwoChars}*${chargeSiteEnvVal}`;
  const payloadRequest: any = {
    payment: {
      tokenInformation: {
        transientTokenJwt: token,
        cardinalJwt: '',
        // cardinalJwt: cardinalId,
      },
      clientReferenceInformation: {
        code: 'WEB_PBT',
        associate: false,
        customerId: contactInfo?.lastName,
        merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID_3DS,
        paymentId: paymentId, //currentTimestamp +roomTypes?.roomTypeDescription
        referenceId: refId,
        srcSystemName: 'WEB_PBT',
        transactionId: trId,
        storeCard: false,
      },
      orderInformation: {
        amountDetails: {
          totalAmount: totalAmt,
          currency: 'USD',
        },
        billTo: {
          address1: billingInfo?.address,
          address2: '',
          administrativeArea: isPaid ? billingInfo?.state : contactInfo?.state,
          country: 'US',
          email: contactInfo?.emailAddress,
          firstName: contactInfo?.firstName,
          lastName: contactInfo?.lastName,
          locality: billingInfo?.city,
          postalCode: billingInfo?.zipCode,
        },
      },
      deviceInformation: deviceInfo,
      merchantDefinedInformation: [
        { key: '1', value: process.env.REACT_APP_CLIENT_OPB_CYBERSOURCE_ID_KEY || 'PBTD' },
        { key: '2', value: offerdata?.loc },
        { key: '3', value: chargeSite },
        { key: '4', value: leadId || '' },
      ],
    },
    confirm: {
      sourceName: 'PBTD',
      holdSessionId: '',
      pin: '',
      loc: offerdata?.loc,
      startDate: '',
      endDate: '',
      holidaySurcharge: '',
      holidayName: '',
      additionalNightCharge: '',
      totalCost: '',
      basePrice: '',
      retailPrice: '',
      additionalNights: 0,
      tour: {
        offerId: offerdata?.offerCode,
        tourType: '',
        tourSiteCode: '',
        salesCenterCode: '',
        appointmentDate: '',
      },
      roomInformation: {
        accomodationCode: roomTypes?.accomodationCode,
        facilityPropertyID: '',
        miniHotelCode: '',
        propertyCode: '',
        rateCode: '',
        roomPoolCode: '',
        roomTypeCode: '',
        bookingRoomType: '',
        numberOfDays: roomTypes?.numberOfDays,
        numberOfNights: roomTypes?.numberOfNights,
        numberOfGuests: roomTypes?.sleepCapacity,
        numberOfAdults: '1',
        numberOfChildren: '0',
      },
      prospectInformation: {
        firstName: contactInfo?.firstName || '',
        lastName: contactInfo?.lastName || '',
        firstName2: '',
        lastName2: '',
        line1: billingInfo?.address,
        city: billingInfo?.city || '',
        stateProvince: isPaid ? stateCode(billingInfo?.state) : stateCode(contactInfo?.state),
        postalCode: billingInfo?.zipCode || '',
        telephoneNumber: contactInfo?.phoneNumber || '',
        emailAddress: contactInfo?.emailAddress || '',
      },
      charge: {
        billingAddressLine1: billingInfo?.address,
        billingEmailAddress: contactInfo?.emailAddress,
        billingFirstName: contactInfo?.firstName,
        billingLastName: contactInfo?.lastName,
        billingPhoneNumber: contactInfo?.phoneNumber,
        billingCity: billingInfo?.city,
        billingCountryCode: 'US',
        billingStateProvinceCode: stateCode(billingInfo?.state),
        billingZip: billingInfo?.zipCode || '',
        cardCheck: cardObj?.maskedCardNumber,
        currencyCode: 'USD',
        expirationDate: cardObj?.expiration,
        merchantID: process.env.REACT_APP_MERCHANTID_3DS,
        paymentMethodCode: cardObj?.cardType,
        preferredInvitationNumber: offerdata?.loc,
        totalAmount: totalAmt,
        merchantGroupId: process.env.REACT_APP_MERCHANT_GROUPID_3DS,
      },
    },
    updateLead: {
      status: 'Online Package Sale',
      leadId: leadId || '',
      state: isPaid ? billingInfo?.state : contactInfo?.state,
      postalCode: billingInfo?.zipCode,
      address: billingInfo?.address,
      latestLeadOriginCode: offerdata?.loc,
    },
  };
  return payloadRequest;
};
