/* eslint-disable */

import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ADASelector from '../../components/OfferDetails/ADASelector';
import FlipOfferSelector from '../../components/OfferDetails/FlipOfferSelector';
import { NavigationSteps } from '../../components/OfferDetails/OfferNavigation';
import OpenDatedNotice from '../../components/OfferDetails/OpenDatedNotice';
import PropertyInformation from '../../components/OfferDetails/PropertyInformation';
import RoomTypeInformation from '../../components/OfferDetails/RoomTypeInformation';
import SelectOfferHeader from '../../components/OfferDetails/SelectOfferHeader';
import { RootState } from '../../redux/rootReducer';
import { OFFER_CONFIRMATION_PATH } from '../../util/strings';
import {
  releaseHoldProperty,
  resetAllEligibilityOptions,
  resetCurrentAvailabilityDate,
  resetCurrentCalendarPosition,
  resetErrorStatus,
  resetExtraNights,
  resetFinalSelectedDate,
  resetFinalSelectedExtraNights,
  resetHoldErrorStatus,
  resetNavigationStatus,
  resetNoAvailabilityStatus,
  resetOfferMonthRange,
  // eslint-disable-next-line prettier/prettier
  setLoadingStatus
} from '../offerAvailability/offerAvailabilitySlice';
import { ReleaseHoldPropertyRequest } from '../offerAvailability/types';
import { resetConfirmationError } from '../offerConfirmation/offerConfirmationSlice';
import { resetGuestsAndState, resetSearchMode, setNavigationStep } from '../offerSearch/offerSearchSlice';
import { OfferDetails, Property } from '../offerSearch/types';
import './OfferDetailsContainer.scss';

const OfferDetailsContainer: FC<any> = (props: any): ReactElement => {
  const offerDetails: OfferDetails = useSelector((state: RootState) => state.offerDetails);
  const holdSessionId = useSelector((state: RootState) => state.availabilityDates.holdSessionId);
  const property: Property | undefined = offerDetails?.property;
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetSearchMode(''));
    dispatch(resetErrorStatus());
    dispatch(resetCurrentAvailabilityDate());
    dispatch(resetGuestsAndState());
    dispatch(setNavigationStep(NavigationSteps.OFEFR_DETAILS));
    dispatch(resetNavigationStatus());
    dispatch(resetConfirmationError());
    dispatch(resetNoAvailabilityStatus());
    dispatch(resetExtraNights());
    dispatch(resetAllEligibilityOptions());
    dispatch(resetCurrentCalendarPosition());
    dispatch(resetOfferMonthRange());
    dispatch(resetHoldErrorStatus());
    dispatch(resetFinalSelectedDate());
    dispatch(resetFinalSelectedExtraNights());
  }, [dispatch]);

  useEffect(() => {
    if (holdSessionId) {
      const releaseRequest: ReleaseHoldPropertyRequest = {
        holdId: holdSessionId,
      };
      dispatch(releaseHoldProperty(releaseRequest));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdSessionId]);

  const pin = offerDetails.searchParameter;
  const isDatedOffer = offerDetails.isDatedOffer && offerDetails.isDatedOffer === true;
  const phoneNumber = offerDetails.prospect?.callCenterNumber;
  const confirmationNumber = useSelector((state: RootState) => state.confirmation.ConfirmationNumber);
  const isPin: boolean = offerDetails.isPin;
  useEffect(() => {
    let unMount = false;
    setTimeout(() => {
      dispatch(setLoadingStatus(false));
    }, 5);

    if (confirmationNumber && confirmationNumber.length > 0) {
      props.history.push(OFFER_CONFIRMATION_PATH);
    }
    return () => {
      if (!unMount) unMount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="container wrapSearchOffer">
        <PropertyInformation property={property} />
        <div className="row">
          <div className="col-md-12">
            <OpenDatedNotice
              showNotice={!isDatedOffer}
              brandPhoneNumber={phoneNumber}
              displayAsAlert="true"
            ></OpenDatedNotice>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ADASelector></ADASelector>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <SelectOfferHeader />
          </div>
        </div>
        <RoomTypeInformation
          offer={offerDetails.currentOffer}
          pin={isPin ? pin : null}
          isDatedOffer={isDatedOffer}
          phoneNumber={phoneNumber}
          loc={isPin ? "" : offerDetails.searchParameter}
        />
        <FlipOfferSelector />
      </div>
    </>
  );
};

export default OfferDetailsContainer;
