import React, { FC, ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { localizeType } from '../../commonType';
import { setGuests } from '../../features/offerSearch/offerSearchSlice';
import withLocalization from '../hoc/withLocalization';
// import { RootState } from '../../redux/rootReducer';

interface GuestInformationType {
  localize: localizeType;
  selectedAdultNumber: number;
  selectedChildrenNumber: number;
  maxCapacity: number;
}
const GuestInformation: FC<GuestInformationType> = (props: GuestInformationType): ReactElement => {
  const { selectedAdultNumber, selectedChildrenNumber, maxCapacity, localize } = props;
  const dispatch = useDispatch();
  const [currentAdults, setCurrentAdults] = useState(selectedAdultNumber);
  const [currentChildren, setCurrentChildren] = useState(selectedChildrenNumber);

  const [maxAdult, setMaxAdult] = useState(maxCapacity - selectedChildrenNumber);
  const [minAdult, setMinAdult] = useState(1);

  const [maxChildren, setMaxChildren] = useState(
    selectedAdultNumber > 0 ? maxCapacity - selectedAdultNumber : maxCapacity - 1,
  );
  const [minChildren, setMinChildren] = useState(0);

  const disableIncrementAdultButton: boolean = currentAdults >= maxAdult;
  const disableDecrementAdultButton: boolean = currentAdults <= minAdult;

  const disableIncrementChildrenButton: boolean = currentChildren >= maxChildren;
  const disableDecrementChildrenButton: boolean = currentChildren <= minChildren;

  const onIncrementAdult = (e) => {
    const tempCurrentAdult = currentAdults + 1;
    const tempMaxChildren = maxCapacity - tempCurrentAdult;
    setCurrentAdults(tempCurrentAdult);
    setMaxChildren(tempMaxChildren);
    setMinChildren(0);
    dispatch(setGuests({ adults: tempCurrentAdult, children: currentChildren }));
  };
  const onDecrementAdult = (e) => {
    const tempCurrentAdult = currentAdults - 1;
    const tempMaxChildren = maxCapacity - tempCurrentAdult;
    setCurrentAdults(tempCurrentAdult);
    setMaxChildren(tempMaxChildren);
    setMinChildren(0);
    dispatch(setGuests({ adults: tempCurrentAdult, children: currentChildren }));
  };
  const onIncrementChild = (e) => {
    const tempCurrentChildren = currentChildren + 1;
    const tempMaxAdult = maxCapacity - tempCurrentChildren;
    setCurrentChildren(tempCurrentChildren);
    setMaxAdult(tempMaxAdult);
    setMinAdult(1);
    dispatch(setGuests({ adults: currentAdults, children: tempCurrentChildren }));
  };
  const onDecrementChild = (e) => {
    const tempCurrentChildren = currentChildren - 1;
    const tempMaxAdult = maxCapacity - tempCurrentChildren;
    setCurrentChildren(tempCurrentChildren);
    setMaxAdult(tempMaxAdult);
    setMinAdult(1);
    dispatch(setGuests({ adults: currentAdults, children: tempCurrentChildren }));
  };
  return (
    <>
      <div className="guest-date-page">
        <div>
          <div className="adult-container">
            <div className="adult-count-container">
              <span className="adult-count-caption">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.adults')}
              </span>
              <div className="adult-count-button">
                <button
                  className="btn btn-circle m-1 leftHalf"
                  onClick={onDecrementAdult}
                  disabled={disableDecrementAdultButton}
                >
                  -
                </button>{' '}
                <span className="adult-count-value">{currentAdults}</span>
                <button
                  className="btn btn-circle m-1 rightHalf"
                  onClick={onIncrementAdult}
                  disabled={disableIncrementChildrenButton}
                >
                  +
                </button>{' '}
              </div>
            </div>
          </div>
          <div className="children-container">
            <div className="children-count-container">
              <span className="children-count-caption">
                {localize('preview-sales-engine.static-content.copy.contact-information-page.children')}
              </span>
              <div className="children-count-button">
                <button
                  className="btn btn-circle m-1 leftHalf"
                  onClick={onDecrementChild}
                  disabled={disableDecrementChildrenButton}
                >
                  -
                </button>{' '}
                <span className="children-count-value">{currentChildren}</span>
                <button
                  className="btn btn-circle m-1 rightHalf"
                  onClick={onIncrementChild}
                  disabled={disableIncrementAdultButton}
                >
                  +
                </button>{' '}
              </div>
            </div>
          </div>
          <div className="col-12 maxGuestText">
            {localize(
              'preview-sales-engine.static-content.copy.prospects.percentageguestmaxcount',
              maxCapacity.toString(),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withLocalization(GuestInformation);
