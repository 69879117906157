import React from 'react';
import { getCurrentBrand } from '../../util';

const withBrands = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const currentBrand = getCurrentBrand();
    return (
      <>
        <Component brand={currentBrand} {...props}></Component>
      </>
    );
  };
};

export default withBrands;
