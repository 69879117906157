import React, { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { trackAnalyticData } from '../../analytics';
import { setPaymentcheckBox } from '../../features/offerConfirmation/offerConfirmationSlice';
import ParseHtml from '../common/ParseHtml';
import withLocalization from '../hoc/withLocalization';

const TermsAndConditionSelector: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const dispatch = useDispatch();
  const handleInputChange = (evt) => {
    trackAnalyticData('PrivacyPolicy');
    trackAnalyticData('Terms of use');
    dispatch(setPaymentcheckBox(evt.target.checked));
  };
  return (
    <>
      <div className="checkPaymentBox">
        <p>{localize('preview-sales-engine.static-content.copy.prospects.emailpaymentreceipt')}</p>

        <label htmlFor="paymentChecked">
          <input id="paymentChecked" type="checkbox" onChange={handleInputChange} />
          <ParseHtml
            htmlInput={localize('preview-sales-engine.static-content.copy.prospects.brandpayonlineacknowledgement')}
          ></ParseHtml>
        </label>
      </div>
    </>
  );
};

export default withLocalization(TermsAndConditionSelector);
