/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import packageOverviewLogo from '../../../features/siteTheme/Brands/tmvcs/logo/package-overview.svg';
import packageOverviewIcon from '../../../features/siteTheme/Brands/tmvcs/logo/tmvc-pkg-icon.svg';
import downArrorwIcon from '../../../features/siteTheme/Brands/tmvcs/logo/downArrowLogo.svg';
import { RootState } from '../../../redux/rootReducer';
import ImgGalleryModal from '../ImgGalleryModal/ImgGalleryModal';
import InformationSummary from './InformationSummary';
import { useMediaQuery } from 'react-responsive';
import ParticipationDetails from '../ParticipationDetails/ParticipationDetails';
import { refinePackageData } from '../../../util/packagingOverviewMapping';
import './OrderSummaryDetails.scss';

interface SearchPanelProps {
  localize?: any;
  history: any;
}
const OrderSummaryDetails: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const newOfferData: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const refineData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const packageData = refinePackageData(refineData);
  const [modalOpen, setModalOpen] = useState(false);

  const [participationModalOpen, setParticipationModalOpen] = useState(false);

  const roomTypeData = (refineData?.roomTypes && refineData?.roomTypes[0]) || '';
  const imgData = (roomTypeData?.property?.imgList4 && roomTypeData?.property?.imgList4[0]) || '';
  const path = imgData?.path || '';
  const imgDataArr = roomTypeData?.imgList16?.extImages || [];
  const intImgDataArr = roomTypeData?.imgList16?.intImages || [];
  const showGallery = imgDataArr.length > 0 || intImgDataArr > 0 ? true : false;

  const openParticipationModal = () => {
    setParticipationModalOpen(true);
  };
  const closeModal = () => {
    setParticipationModalOpen(false);
  };
  const openModal = () => {
    setModalOpen(true);
  };

  const closeGallaryModal = () => {
    setModalOpen(false);
  };
  const handleKeyDown = () => {};
  const viewResortDetails = () => {
    const roomType = (refineData?.roomTypes && refineData?.roomTypes[0]) || '';
    const marsha_code = roomType?.property?.marshaCode || 0;
    const resortUrl = `https://www.marriottvacationclubs.com/s/${marsha_code}`;
    window.open(resortUrl);
  };
  return (
    <>
      {participationModalOpen && <ParticipationDetails closeModal={closeModal} />}
      {modalOpen && <ImgGalleryModal closeModal={closeGallaryModal} />}
      <div className="orderSummary-container">
        <div className="innerOrderSummaryContainer">
          <div className="inner-orderSummary-title-container">
            <span className="orderSummary-title-wrapper">
              {localize('preview-sales-engine.static-content.copy.pbeo.order-summary-title')}
            </span>
          </div>
          <div className="packageOverviewOfferCardContainer">
            <div className="summary-resortInfo-container">
              <div className="resortInfo_img-container">
                <img src={path} alt={'house-icon'} className="thumbnail-package-overview" />
                {showGallery && (
                  <div className="Pkg-icon-container">
                    <input
                      type="image"
                      src={packageOverviewIcon}
                      alt={'gallary-icon'}
                      className="pkg-overview-icon"
                      onClick={() => openModal()}
                      arial-label={'image-galarry-detail'}
                    />
                  </div>
                )}
              </div>
              <div className="summary-resortInfo_desc-container">
                <span className="orderSummary-dsc-name-wrapper">{`${packageData?.name}`}</span>
                <div
                  className="desc-resort-detail-link"
                  onClick={viewResortDetails}
                  onKeyDown={handleKeyDown}
                  arial-label={'view-resort-detail'}
                  tabIndex={0}
                >
                  {localize('preview-sales-engine.static-content.copy.pbeo.view-resort-details-link-label')}
                </div>
              </div>
            </div>
          </div>
          <div className="orderSummary-content-section">
            <InformationSummary />
            <div
              className="summary-tax-info-span"
              dangerouslySetInnerHTML={{
                __html: localize('preview-sales-engine.static-content.copy.pbeo.overview-disclaimer'),
              }}
            ></div>
            <div className="orderSummaryDiveder"></div>
            <div className="summaryBodyLinkContainer">
              <button className="summaryBodyLinkText" onClick={() => openParticipationModal()}>
                {localize('preview-sales-engine.static-content.copy.pbeo.view-dop-link-label')}
              </button>
            </div>
          </div>

          {/*isSeeDetails && <PackageCollapseDetails packageData={packageData} />*/}
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(OrderSummaryDetails));
