/* eslint-disable */
import React, { FC, ReactElement } from 'react';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import './PaymentErrorModal.scss';
import galleryModalCloseLogo from '../../../features/siteTheme/Brands/tmvcs/logo/galleryModalCloseLogo.svg';
import ParseHtml from '../../common/ParseHtml';
interface ImgModalProps {
  localize?: any;
  image?: string;
  closeModal: () => void;
  msg?: string;
}

const PaymentErrorModal: FC<any> = (props: ImgModalProps): ReactElement => {
  const { closeModal, localize } = props;
  const modalErrStatus = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentModalErrStatus);
  const errCode = modalErrStatus?.toUpperCase() || '';
  const handleKeyDown = () => {};
  const getErrMsg = (): string => {
    switch (errCode) {
      case 'CREDIT_CARD_DECLINED_GENERIC':
        return localize(
          `preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-declined-generic`,
        );
      case 'CREDIT_CARD_INVALID_DATA':
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-invalid-data`);
      case 'CREDIT_CARD_INVALID_ADDR':
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-invalid-addr`);
      case 'CREDIT_CARD_TIMEOUT':
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.credit-card-timeout`);
      case 'CREDIT_CARD_DECLINED':
        return localize(`preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-declined`);
      case 'CREDIT_CARD_DECLINED_SYSTEM':
        return localize(
          `preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-declined-system`,
        );
      default:
        return localize(
          `preview-sales-engine.static-content.copy.Labels.error-message.pbeo.credit-card-declined-generic`,
        );
    }
  };

  return (
    <div className="modal-Payment-err">
      <div className="modal-content-PaymentErr">
        <div className="paymentErr-container">
          <div className="payment-err-head-container">
            <div className="paymentErrModal-title-container">
              <span className="PaymentErr-title-text">
                {localize(
                  `preview-sales-engine.static-content.copy.Labels.error-message.credit-card-declined-modal-title`,
                )}
              </span>
            </div>
            <div className="payment-err-close-icon-wrapper">
              <input
                className="paymentErr-modal-close-btn"
                src={galleryModalCloseLogo}
                type="image"
                onClick={closeModal}
                aria-label="close"
              />
            </div>
          </div>

          <div className="paymentErr-content-section">
            <div className="paymentErr-desc-container">
              <div className="paymentErr-desc-text">
                <ParseHtml htmlInput={getErrMsg()} />
                {/*<ParseHtml
                  htmlInput={localize('preview-sales-engine.static-content.copy.prospects.contactvacationspecialist', [
                    1234,
                    1234,
                  ])}
                />*/}
              </div>
            </div>
          </div>
          <div className="paymentModalErr-section">
            <div
              className="paymentErr-close-wrapper"
              onClick={closeModal}
              arial-label={'see-detail'}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <div className="payErr-close-text-span">
                {localize(`preview-sales-engine.static-content.copy.pbeo.ineligible-state-thank-you-modal-cta`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(withLocalization(withBrands(PaymentErrorModal)));
