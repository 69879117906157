import React, { FC, ReactElement } from 'react';
import BrandLogo from '../../../features/siteTheme/BrandLogo';
import withBrands from '../../hoc/withBrands';
import withLocalization from '../../hoc/withLocalization';

interface HeaderProps {
  localize?: any;
  brand?: any;
}

const Header: FC<any> = (props: HeaderProps): ReactElement => {
  sessionStorage.setItem('PBTC Version', '1.31');
  return (
    <React.Fragment>
      <header className="topheader">
        <BrandLogo className={props.brand} currentBrand={props.brand}></BrandLogo>
      </header>
    </React.Fragment>
  );
};
export default React.memo(withLocalization(withBrands(Header)));
